import { DialogActions } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement, useRef } from 'react';
import { BaseRow, Importer, ImporterField, ImporterFilePreview } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { useTranslation } from 'react-i18next';

interface Props {
  onFinish: (rows: BaseRow[]) => void;
  onClose: () => void;
}

function CsvImporter({ onFinish, onClose }: Props): ReactElement {
  const { t } = useTranslation(['global', 'payments']);

  const calcIntervalDay = () => {
    return new Date().getDate() >= 15 ? 1 : 15;
  };

  const getHeaderValueForField = (mappedName: string, columnFields: (string | undefined)[], preview: ImporterFilePreview): string | undefined => {
    const index = columnFields.indexOf(mappedName);
    return preview.columns[index].header;
  };

  const allRows = useRef<BaseRow[]>([]);
  return (
    <>
      <Importer
        assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
        restartable={false} // optional, lets user choose to upload another file when import is complete
        onStart={({ file, fields }) => {
          // optional, invoked when user has mapped columns and started import
        }}
        processChunk={async (rows) => {
          const rowsWithId = rows.map((row) => ({
            id: Math.floor(Math.random() * 1000000),
            ...row,
          }));
          allRows.current.push(...rowsWithId);
        }}
        onComplete={({ file, preview, fields, columnFields }) => {
          // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
          const allData = allRows.current.map((value) => {
            const metadata = {};

            if (value.metadata1) {
              // @ts-ignore
              metadata[getHeaderValueForField('metadata1', columnFields, preview)] = value.metadata1;
            }
            if (value.metadata2) {
              // @ts-ignore
              metadata[getHeaderValueForField('metadata2', columnFields, preview)] = value.metadata2;
            }
            if (value.metadata3) {
              // @ts-ignore
              metadata[getHeaderValueForField('metadata3', columnFields, preview)] = value.metadata3;
            }

            return {
              id: value.id,
              firstName: value.firstName,
              lastName: value.lastName,
              emailAddress: value.emailAddress,
              purpose: value.purpose,
              amount: value.amount,
              instalmentRate: value.instalmentRate,
              downPayment: value.downPayment,
              intervalDay: value.intervalDay ? value.intervalDay : calcIntervalDay(),
              iban: value.iban,
              bic: value.bic,
              metadata: metadata,
              metadataString: JSON.stringify(metadata),
            };
          });
          onFinish(allData);
        }}
        onClose={() => {
          // optional, invoked when import is done and user clicked "Finish"
          // (if this is not specified, the widget lets the user upload another file)
          console.log('importer dismissed');
        }}
      >
        <ImporterField name="firstName" label={t('global:fieldNames.firstName')} />
        <ImporterField name="lastName" label={t('global:fieldNames.lastName')} />
        <ImporterField name="emailAddress" label={t('global:fieldNames.emailAddress')} />
        <ImporterField name="purpose" label={t('global:fieldNames.purpose')} />
        <ImporterField name="amount" label={t('global:fieldNames.amount')} />
        <ImporterField name="instalmentRate" label={t('global:fieldNames.instalmentRate')} />
        <ImporterField name="downPayment" label={t('global:fieldNames.downPayment')} optional />
        <ImporterField name="iban" label={t('global:fieldNames.iban')} optional />
        <ImporterField name="bic" label={t('global:fieldNames.bic')} optional />
        <ImporterField name="intervalDay" label={t('global:fieldNames.intervalDay')} optional />
        <ImporterField name="metadata1" label={t('global:metaNumber', { number: 1 })} optional />
        <ImporterField name="metadata2" label={t('global:metaNumber', { number: 2 })} optional />
        <ImporterField name="metadata3" label={t('global:metaNumber', { number: 3 })} optional />
      </Importer>
      <DialogActions>
        <Button label={t('global:close')} name="import" onClick={onClose} />
      </DialogActions>
    </>
  );
}

export { CsvImporter };
