import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import PageHeader from '../../components/ui/blocks/PageHeader';
import CheckoutTable from './table/CheckoutTable';
import GenericTableFilters from '../../components/filter/GenericTableFilters';
import CheckoutListDataContextProvider, { CheckoutListDataContext } from './context/CheckoutListDataContext';
import CheckoutService from '../../services/CheckoutService';

const CheckoutsView = () => {
  const { t } = useTranslation(['global', 'checkouts']);

  const [productNames, setProductNames] = useState<any[]>([]);

  useEffect(() => {
    CheckoutService.getCheckouts(100, 0, 'desc', {
      debtorRef: undefined,
    }).then((res) => {
      const productNames: any[] = [];
      res.results.forEach((checkout) => {
        if (!productNames.find((arrElement) => arrElement.value === checkout.productId)) {
          productNames.push({ name: checkout.productTitle, value: checkout.productId });
        }
      });
      setProductNames(productNames);
    });
  }, []);

  return (
    <CheckoutListDataContextProvider pageSizeOptions={[5, 10, 30]}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid>
          <PageHeader title={t('checkouts:current checkouts')} subtitle={t('checkouts:checkouts')} />
        </Grid>
      </Grid>
      <Grid>
        <GenericTableFilters states={productNames} context={CheckoutListDataContext} t={t} label={t('filter by product')} />
      </Grid>
      <CheckoutTable showPagination={true} />
    </CheckoutListDataContextProvider>
  );
};

export default CheckoutsView;
