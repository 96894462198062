import CancelInvoiceDialog from '../dialogs/CancelInvoiceDialog';
import React, { useContext, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { useTranslation } from 'react-i18next';
import { InvoiceListDataContext } from '../InvoiceListDataContextProvider';
import { InvoiceActionType } from './InvoiceActionHelper';
import InvoiceService from '../../../services/InvoiceService';
import ReloadContext from '../../../components/contexts/ReloadContext';

const InvoiceCancelAction = ({ invoice, closeMenu, label }: InvoiceActionType) => {
  const [showCancel, setShowCancel] = useState(false);
  const [cancellationInvoiceId, setCancellationInvoiceId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [invoicePdf, setInvoicePdf] = useState<{ name: string; content: any }>();

  const { t } = useTranslation(['debitors', 'invoices']);
  const invoiceReload = useContext(InvoiceListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;

  const reload = () => {
    invoiceReload();
    contextReload();
  };
  const alerting = useContext(AlertContext);

  const cancelInvoice = (reasons: any) => {
    InvoiceService.createCancellationInvoice(invoice.id, reasons)
      .then(({ id }) => {
        setCancellationInvoiceId(id);
        InvoiceService.previewCancelInvoice(id).then((invoicePdfDoc) => {
          setInvoicePdf(invoicePdfDoc);
        });
      })
      .catch((err) => {
        alerting.sendAlert('error', t('unsuccessfully cancelled'));
      });
  };

  const finalizeInvoiceCancellation = () => {
    setLoading(true);
    InvoiceService.finalizeInvoiceCancellation(cancellationInvoiceId ? cancellationInvoiceId : invoice.id).then((res) => {
      if (res) {
        setLoading(false);
        alerting.sendAlert('success', t('successfully sent'));
        reload();
      } else {
        alerting.sendAlert('error', t('unsuccessfully sent'));
        reload();
      }
      setShowCancel(false);
      setInvoicePdf(undefined);
    });
  };

  return (
    <div key={invoice.id + 'cancelC'}>
      <MenuItem
        key={invoice.id + 'cancel'}
        onClick={() => {
          closeMenu();
          setShowCancel(true);
        }}
      >
        {label}
      </MenuItem>

      {showCancel && (
        <CancelInvoiceDialog
          key={invoice.id + 'cancel'}
          onCancel={() => {
            setShowCancel(false);
            setCancellationInvoiceId(undefined);
            reload();
          }}
          onDelete={cancelInvoice}
          onFinalize={finalizeInvoiceCancellation}
          invoicePdf={invoicePdf}
          loading={loading}
          open={true}
        />
      )}
    </div>
  );
};

export default InvoiceCancelAction;
