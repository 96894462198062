import { Debtor } from '../Debtor/Debtor';
import { Metadata } from '../Metadata';
import { BankAccount } from '../Bank/BankAccount';
import { SubscriptionRate } from './SubscriptionRate';

export type Subscription = {
  bankAccount: BankAccount;
  createdDateTime: string;
  id: string;
  debtor: Debtor;
  interval: Subscriptions.Interval;
  metadata: Metadata;
  nextPaymentDueDate: string;
  price: number;
  startDate: string;
  status: Subscriptions.Status;
  cancellationRequest: Subscriptions.CancellationRequest;
  subscriptionsRates: SubscriptionRate[];
  subscriptionTitle: string;
  correlationId: string;
};

export type NewSubscription = {
  productId: string;
  debtorId: string;
  bankAccountId: string;
  startDate?: string;
  interval?: Subscriptions.Interval;
  generateInvoice?: boolean;
  metadata?: Metadata;
};

export namespace Subscriptions {
  export enum Interval {
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
  }

  export enum Status {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',

    CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  }

  export enum CancellationType {
    ORDINARY = 'ORDINARY',
    EXTRAORDINARY = 'EXTRAORDINARY',
  }

  export type CancellationRequest = {
    type: CancellationType;
    reason: string;
    targetDate: Date;
    requestedOn: Date;
  };
}
