import { Invoice } from '../../../types/Invoice/Invoice';
import React, { ReactElement, ReactNode } from 'react';
import InvoiceEventAction from './InvoiceEventAction';
import { TFunction } from 'i18next';
import InvoiceEditAction from './InvoiceEditAction';
import InvoiceCancelAction from './InvoiceCancelAction';
import InvoiceCorrectionAction from './InvoiceCorrectionAction';
import InvoiceDeleteAction from './InvoiceDeleteAction';
import InvoiceDownloadAction from './InvoiceDownloadAction';
import InvoiceRefundCompleteAction from './InvoiceRefundCompleteAction';
import InvoiceFinalizeAction from './InvoiceFinalizeAction';
import InvoiceDetailsAction from './InvoiceDetailsAction';
import { PaymentMethod } from '../../../types/Invoice/PaymentMethod';
import { Debtor } from '../../../types/Debtor/Debtor';
import MenuItemNotAllowed from '../../../components/MenuItemNotAllowed';
import { InvoiceState } from '../../../types/Invoice/InvoiceState';

const getActionsForInvoice = (invoice: Invoice, closeMenu: () => void, t: TFunction, debtor?: Debtor, cardView = false): ReactNode[] => {
  const actions: ReactNode[] = [];

  function addDownloadIfAvailable() {
    if (hasDownloadAction(invoice)) {
      actions.push(<InvoiceDownloadAction invoice={invoice} closeMenu={closeMenu} label={t('download pdf')} />);
    }
  }

  function addDeleteIfAvailable() {
    if (['DRAFT'].includes(invoice.status)) {
      actions.push(<InvoiceDeleteAction invoice={invoice} closeMenu={closeMenu} label={t('delete')} />);
    }
  }

  function addDetailsActions(cardView: boolean, status: InvoiceState) {
    if (!cardView) {
      actions.push(<InvoiceEventAction invoice={invoice} closeMenu={closeMenu} label={t('events')} />);
      if (status !== InvoiceState.DRAFT) {
        actions.push(<InvoiceDetailsAction invoice={invoice} closeMenu={closeMenu} label={t('Details')} />);
      }
    }
  }

  if (!debtor || !debtor.id) {
    addDeleteIfAvailable();
    addDownloadIfAvailable();
    addDetailsActions(cardView, invoice.status);

    return actions;
  } else {
    if (invoice.status === 'DRAFT' && invoice.invoiceType === 'INVOICE') {
      actions.push(<InvoiceEditAction invoice={invoice} closeMenu={closeMenu} label={t('edit')} />);
    }

    if (!['CANCELLED', 'CANCELLATION_COMPLETED', 'DRAFT'].includes(invoice.status)) {
      if (invoice.paymentMethod && (PaymentMethod.DIRECT_DEBIT !== invoice.paymentMethod || (PaymentMethod.DIRECT_DEBIT === invoice.paymentMethod && invoice.status === InvoiceState.PAID))) {
        actions.push(<InvoiceCancelAction invoice={invoice} closeMenu={closeMenu} label={t('invoice_cancel')} />);
      } else {
        actions.push(<MenuItemNotAllowed label={t('invoice_cancel')} mKey={'invoice_cancel'} reason={t('not allowed for direct debit')} />);
      }
    }

    if (['OPEN', 'PAID'].includes(invoice.status)) {
      actions.push(<InvoiceCorrectionAction invoice={invoice} closeMenu={closeMenu} label={t('invoice correction')} />);
    }

    addDeleteIfAvailable();

    if (invoice.status === 'REFUND_IN_PROGRESS') {
      actions.push(<InvoiceRefundCompleteAction invoice={invoice} closeMenu={closeMenu} label={t('refund complete')} />);
    }
    if (invoice.status === 'DRAFT' && ['CORRECTIVE_INVOICE', 'CANCELLATION_INVOICE'].includes(invoice.invoiceType)) {
      actions.push(<InvoiceFinalizeAction invoice={invoice} closeMenu={closeMenu} label={t('finalize')} />);
    }

    addDownloadIfAvailable();
    addDetailsActions(cardView, invoice.status);
    return actions;
  }
};

const hasDownloadAction = (invoice: Invoice) => {
  return ['REFUND_IN_PROGRESS', 'CANCELLATION_COMPLETED', 'OPEN', 'CANCELLED', 'PAID', 'IN_DUNNING', 'REMINDED', 'UNCOLLECTIBLE'].includes(
    invoice.status,
  );
};

export type InvoiceActionType = { invoice: Invoice; closeMenu: () => void; label: string | ReactElement };

export { getActionsForInvoice, hasDownloadAction };
