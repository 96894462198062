import ApiClient from './ApiClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { CompanySettings } from '../types/Company/CompanySettings';
import { CompanyStats } from '../types/Company/CompanyStats';
import { CompanyAccount, CompanyAccountPatch } from '../types/Company/Company';

export default class CompanyAccountService extends ApiClient {
  private static get companyClient(): AxiosInstance {
    return this.client;
  }

  public static async getCompany(): Promise<CompanyAccount> {
    if (!ApiClient.cache.companyAccount) {
      ApiClient.cache.companyAccount = this.companyClient
        .request<CompanyAccount>({
          method: 'GET',
          url: this.apiDomain + '/company',
        })
        .then((res) => res.data);
    }
    return ApiClient.cache.companyAccount;
  }

  public static async updateCompany(companyData: CompanyAccountPatch): Promise<boolean> {
    let res = await this.companyClient.request<CompanyAccountPatch>({
      method: 'PATCH',
      url: this.apiDomain + '/company',
      data: companyData,
    });
    ApiClient.cache.companyAccount = undefined;
    return res.status === 200;
  }

  public static async uploadCompanyLogo(logo: File): Promise<boolean> {
    const form = new FormData();
    form.append('file', logo, logo.name);
    let res = await this.companyClient.request<FormData>({
      method: 'PUT',
      url: this.apiDomain + '/company/logo',
      data: form,
    });
    ApiClient.cache.companyAccount = undefined;
    return res.status === 200;
  }

  public static async getCompanyStats(): Promise<CompanyStats> {
    let res = await this.companyClient.request<CompanyStats>({
      method: 'GET',
      url: this.apiDomain + '/company/stats',
    });
    return res.data;
  }

  public static async updateCompanyAccountSettings(accountSettings: CompanySettings.Account): Promise<boolean> {
    let res = await this.companyClient.request<CompanySettings.Account, AxiosResponse<any>>({
      method: 'PATCH',
      url: this.apiDomain + '/company/account-settings',
      data: accountSettings,
    });
    ApiClient.cache.companyAccount = undefined;
    return res.status === 200;
  }

  public static async updateDunningSettings(dunningSettings: CompanySettings.Dunning): Promise<boolean> {
    let res = await this.companyClient.request<CompanySettings.Dunning, AxiosResponse<any>>({
      method: 'PATCH',
      url: this.apiDomain + '/company/dunning-settings',
      data: dunningSettings,
    });
    ApiClient.cache.companyAccount = undefined;
    return res.status === 200;
  }

  public static async updateBrandingSettings(brandingSettings: CompanySettings.Branding): Promise<boolean> {
    let res = await this.companyClient.request<CompanySettings.Branding, AxiosResponse<any>>({
      method: 'PATCH',
      url: this.apiDomain + '/company/branding-settings',
      data: brandingSettings,
    });
    ApiClient.cache.companyAccount = undefined;
    return res.status === 200;
  }

  public static async uploadBrandBackgroundImage(logo: File): Promise<boolean> {
    const form = new FormData();
    form.append('file', logo, logo.name);
    let res = await this.companyClient.request<FormData>({
      method: 'PUT',
      url: this.apiDomain + '/company/branding-settings/brand-background-image',
      data: form,
    });
    ApiClient.cache.companyAccount = undefined;
    return res.status === 200;
  }

  public static async updateTermsAndConditionsSettings(termsAndConditionsConfiguration: CompanySettings.TermsAndConditions): Promise<boolean> {
    let res = await this.companyClient.request<CompanySettings.TermsAndConditions, AxiosResponse<any>>({
      method: 'PATCH',
      url: this.apiDomain + '/company/terms-and-conditions',
      data: termsAndConditionsConfiguration,
    });
    ApiClient.cache.companyAccount = undefined;
    return res.status === 200;
  }
}
