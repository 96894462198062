import { FormControl, makeStyles, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Button, FormikInputField } from '@payactive/app-common';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { useFormikContext } from 'formik';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxField from '../../../../../components/FormFields/CheckboxField';
import { bankInfoByBic } from '../banks';
import { FormikValues } from '../schemas';
import EbicsService from '../../../../../services/EbicsService';

interface Props {
  ebicsConnectionId: string;
  ebicsConnection: any;
}

const useStyles = makeStyles(() => ({
  price: {
    margin: 'auto',
    width: '50%',
  },
}));

export default function RequestEbicsForm({ ebicsConnectionId, ebicsConnection }: Props): ReactElement {
  const classes = useStyles();

  const { t } = useTranslation(['sepa', 'global']);

  const [loading, setLoading] = useState(false);

  const [showInfo, setShowInfo] = useState(false);

  const { values, errors, setFieldValue } = useFormikContext<FormikValues>();

  const downloadBankRequest = async (values: any) => {
    setLoading(true);
    await EbicsService.downloadEbicsBankRequest(ebicsConnectionId, {
      withdrawLimit: values.withdrawLimit,
      creditorName: values.creditorName,
    });
    setShowInfo(true);
    setLoading(false);
  };

  return (
    <>
      <p>
        <FormikInputField name="creditorIdentifier" label={t('creditorIdentifier')} fullWidth disabled />
      </p>
      <p>
        <FormikInputField name="creditorName" label={t('creditorName')} fullWidth />
      </p>
      <p>
        <CurrencyTextField
          required
          className={classes.price}
          label={t('withdrawLimit')}
          value={values.withdrawLimit}
          name={`withdrawLimit`}
          currencySymbol={t('global:currency')}
          decimalCharacter={t('global:decimalChar')}
          digitGroupSeparator={t('global:digitGroup')}
          onChange={(event: any, value: any) => setFieldValue(event.target.name, value)}
        />
      </p>
      <p>
        <Button
          disabled={errors.withdrawLimit !== undefined || errors.creditorName !== undefined}
          onClick={() => downloadBankRequest(values)}
          variant="contained"
          color="primary"
          name="download-request"
          label={t('download ebics request form')}
          loading={loading}
        />
      </p>
      {showInfo && (
        <Alert severity="info" variant="outlined">
          <>
            {bankInfoByBic(ebicsConnection.bic).requestProcessInfo}
            {bankInfoByBic(ebicsConnection.bic).costsInfo && bankInfoByBic(ebicsConnection.bic).costsInfo}
          </>
        </Alert>
      )}
      <p>
        <FormControl component="fieldset">
          <CheckboxField name="request_nextStep" label={<Typography>{t('request ebics next step')}</Typography>} />
        </FormControl>
      </p>
    </>
  );
}
