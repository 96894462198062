import { Links } from '../Page';

export type ServiceActivation = {
  id?: string;
  service: ServiceActivations.Service;
  activationDate?: string;
  deactivationDate?: string;
  active?: boolean;
  _links?: Links;
};

export type NewServiceActivation = {
  service?: ServiceActivations.Service;
};

export namespace ServiceActivations {
  export enum Service {
    PAYACTIVE_SERVICE_SUBSCRIPTION_SERVICE = 'SUBSCRIPTION_SERVICE',
    PAYACTIVE_SERVICE_PAYMENT_SERVICE = 'PAYMENT_SERVICE',
    PAYACTIVE_SERVICE_AGENT_SERVICE = 'AGENT_SERVICE',
    PAYACTIVE_SERVICE_PREVIEW = 'PREVIEW',
  }
}
