import { FormikInputField } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  contactInformationHeader: {
    textAlign: 'left',
    display: 'block',
    color: '#0198B5',
    marginBottom: theme.spacing(2),
    fontSize: '18px',
  },
}));

export default function ContactInformation(): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation('company');
  return (
    <>
      <Typography variant="h5" className={classes.contactInformationHeader}>
        {t('contact info')}
        <Tooltip
          title={
            <React.Fragment>
              <Typography variant="caption" color="inherit">
                {t('info title')}
              </Typography>
            </React.Fragment>
          }
          placement="top"
        >
          <InfoIcon style={{ color: '#D3D3D3', marginBottom: '-6px', marginLeft: '10px' }} />
        </Tooltip>
      </Typography>
      <div>
        <FormikInputField
          type="text"
          className={classes.formControl}
          name="contactInformation.emailAddress"
          label={t('emailAddress')}
          placeholder="info@info.de"
          InputLabelProps={{ shrink: true }}
          fullWidth
          required
        />
        <FormikInputField
          className={classes.formControl}
          name="contactInformation.phoneNumber"
          InputLabelProps={{ shrink: true }}
          placeholder="01 22 33 44 55"
          label={t('phone number')}
          fullWidth
        />
        <FormikInputField
          className={classes.formControl}
          name="contactInformation.websiteUrl"
          InputLabelProps={{ shrink: true }}
          placeholder="www.payactive.eu"
          label={t('Website')}
          fullWidth
          required
        />
      </div>
    </>
  );
}
