import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.error.main,
  },
  backDrop: {
    backdropFilter: 'blur(30px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  comeBack: (success?: boolean) => void;
  origin: string;
  isEdit?: boolean;
}

export default function CancelProductDialog({ open, onClose, comeBack, origin, isEdit = false }: Props): ReactElement {
  const { t } = useTranslation('payments');
  const classes = useStyles();

  const dialogTitleKey = isEdit ? 'discard product changes' : 'discard product';
  const dialogTextKey = isEdit ? `discard product changes text from ${origin}` : `discard product text from ${origin}`;

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">{t(dialogTitleKey)}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">{t(dialogTextKey)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" name="cancel" label={t('global:cancel')} onClick={onClose} />
        <Button className={classes.button} name="delete" variant="contained" label={t(dialogTitleKey)} onClick={() => comeBack()} />
      </DialogActions>
    </Dialog>
  );
}
