import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { httpsUrlValidator } from '../../../utils/ValidatorUtil';

const createSchemas = (t: TFunction) => {
  return {
    0: Yup.object().shape({
      url: httpsUrlValidator.required(t('required', { field: t('url') })),
      name: Yup.string().required(t('required', { field: t('name') })),
    }),
    events: Yup.array().required(t('required', { field: t('events') })),
  };
};

export { createSchemas };
