import { DialogActions, DialogContent, FormControl, makeStyles, Typography } from '@material-ui/core';
import { FormikInputField } from '@payactive/app-common';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BankAccountsSuggest from '../../../components/BankAccountSuggest';
import DebtorsAutoSuggest from '../../../components/DebtorsAutoSuggest';
import ServicePeriod from './ServicePeriod';
import ViewPositions from './ViewPositions';
import CheckboxField from '../../../components/FormFields/CheckboxField';
import { Mode } from './InvoiceDialog';
import { PriorityHigh } from '@material-ui/icons';
import InvoicePositions from './InvoicePositions';
import { Invoice, InvoiceFormValues } from '../../../types/Invoice/Invoice';
import { Debtor, Debtors } from '../../../types/Debtor/Debtor';
import { FormikErrors } from 'formik/dist/types';
import DebtorService from '../../../services/DebtorService';

const useStyles = makeStyles((theme) => ({
  div: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    position: 'relative',
  },
  row: {
    display: 'flex',
    margin: theme.spacing(2),
  },
  post: {
    width: '40%',
    margin: 'auto',
    '& .MuiGrid-root': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiFormControl-root': {
      width: '80%',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '45px',
    },
    '& .MuiIcon-root': {
      position: 'absolute',
      left: '0px',
      top: '32px',
    },
  },
  quantity: {
    margin: 'auto',
    width: '9%',
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '45px',
    },
  },
  price: {
    margin: 'auto',
    width: '21%',
  },
  tax: {
    width: 'auto',
    padding: '9px',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
  moreOptions: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  dialogActions: {
    marginTop: theme.spacing(3),
  },
}));

export default function InvoiceForm({
  setFieldValue,
  invoice,
  mode,
  debtor,
}: {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<InvoiceFormValues>>;
  invoice?: Invoice;
  mode: Mode;
  debtor?: Debtor;
}) {
  const { values } = useFormikContext<InvoiceFormValues>();
  const [loadedDebtor, setLoadedDebtor] = useState<Debtor | undefined>(debtor);
  const [isDisabled, setIsDisabled] = useState(false);
  const [reverseChargePossible, setReverseChargePossible] = useState(false);
  const classes = useStyles();

  const { t } = useTranslation('debitors');

  useEffect(() => {
    if (invoice && invoice.debitor && !debtor) {
      DebtorService.getDebtor(invoice.debitor.id).then((deb) => {
        setLoadedDebtor(deb);
        setFieldValue('vatId', deb.vatId);
        if (deb.type === 'ORGANIZATION') {
          setReverseChargePossible(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DialogContent>
        <br />
        <Typography variant="h6">{t('creditor account')}</Typography>
        <BankAccountsSuggest
          disabled={mode !== Mode.EDITING}
          preselectedId={values.creditorBankAccount?.id}
          setFieldValueInvoice={setFieldValue}
          setFieldValue={setFieldValue}
          setIsConnectedBank={() => {}}
        />
        <br />
        <Typography variant="h6">{t('customer')}</Typography>
        <DebtorsAutoSuggest
          disabled={mode !== Mode.EDITING || isDisabled}
          setLocalFieldValue={setFieldValue}
          setIsDisabled={setIsDisabled}
          debtor={loadedDebtor || values.debitor}
          directDebitActive={values.directDebitActive}
          onChange={(debtor) => {
            if (!debtor || debtor.type !== Debtors.Type.ORGANIZATION) {
              setFieldValue('reverseCharge', false);
              setFieldValue('vatId', '');
            } else {
              setFieldValue('vatId', debtor.vatId);
            }
            setReverseChargePossible(!!debtor && debtor.type === Debtors.Type.ORGANIZATION);
          }}
        />
        <br />

        <Typography variant="h6">{t('servicePeriod')}</Typography>
        <ServicePeriod disabled={mode !== Mode.EDITING} />
        <br />
        <Typography variant="h6">{t('payment term')}</Typography>
        <FormikInputField
          disabled={mode !== Mode.EDITING}
          icon="event_available"
          fullWidth
          label={t('30 days default')}
          name="paymentTermInDays"
          type="number"
          defaultValue="30"
        />
        <br />
        <Typography variant="h6">{t('invoice items')}</Typography>
        {mode !== Mode.EDITING && invoice && (
          <>
            <ViewPositions
              subTotal={invoice.subTotal}
              total={invoice.total}
              taxes={invoice.taxes}
              positions={invoice.positions}
              defaultTaxRate={values.defaultTaxRate}
              reverseCharge={values.reverseCharge}
            />
            {invoice && values.reverseCharge && (
              <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0 0' }}>
                <PriorityHigh></PriorityHigh>
                <Typography variant="body2">
                  <b>{t('reverse charge checkbox')}</b>:&nbsp;{t('reverse charge')}
                </Typography>
              </div>
            )}
          </>
        )}

        <InvoicePositions mode={mode} />
        {mode === Mode.EDITING && (
          <DialogActions className={classes.dialogActions}>
            <FormControl component="fieldset" style={{ width: '100%' }}>
              <CheckboxField name="reverseCharge" disabled={!reverseChargePossible} label={<Typography>{t('reverse charge checkbox')}</Typography>} />
            </FormControl>
            <div style={{ display: 'none' }}>
              <FormikInputField disabled={!reverseChargePossible} type="hidden" name={`taxId`} />
            </div>
          </DialogActions>
        )}
      </DialogContent>
    </>
  );
}
