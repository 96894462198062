import { CreditNote } from '../../../types/CreditNote/CreditNote';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCardStyles from './CardStyles';
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, Menu, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import { formatCurrency } from '../../../utils/PaymentUtil';
import StateWithIcon from '../../../components/StateWithIcon';
import getActionsForCreditNote, { hasDownloadAction } from '../../creditNotes/actions/CreditNoteActionHelper';
import GetAppIcon from '@material-ui/icons/GetApp';
import CreditNoteDownloadAction from '../../creditNotes/actions/CreditNoteDownloadAction';

export default function CreditNoteCard({ creditNote, setRef }: { creditNote: CreditNote; setRef: (key: string) => React.RefObject<any> }) {
  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);

  const { t } = useTranslation(['debitors', 'invoices', 'global']);
  const classes = useCardStyles();
  const actions = getActionsForCreditNote(creditNote, () => {}, setMenuAnchor, true);

  return (
    <Card elevation={1} ref={setRef(creditNote.id)}>
      <CardHeader
        className={classes.alignLeft}
        avatar={
          <Avatar className={classes.avatar}>
            <DescriptionIcon />
          </Avatar>
        }
        action={
          !!actions &&
          actions.length !== 0 && (
            <IconButton aria-label="settings" onClick={(e) => setMenuAnchor(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={t('creditNotes:credit note')}
        subheader={formatDateTime(creditNote.createdDateTimeUTC)}
      />
      <CardContent>
        <Grid container spacing={2} direction={'column'} alignItems={'flex-start'}>
          <Grid item container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Grid item container direction={'column'} alignItems={'flex-start'}>
                <Typography variant="caption" style={{ color: 'gray' }}>
                  {t('amount')}
                </Typography>
                <Typography color="inherit" variant={'subtitle2'}>
                  {formatCurrency(creditNote.total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <StateWithIcon state={creditNote.status} label={t(`creditNotes:${creditNote.status}`)} />
            </Grid>
          </Grid>
          <Grid item container direction={'column'} alignItems={'flex-start'}>
            <Typography variant="caption" style={{ color: 'gray' }}>
              {t('invoices:documentNumber')}
            </Typography>
            <Typography color="inherit" variant={'subtitle2'}>
              {creditNote.invoiceNumber}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        {hasDownloadAction(creditNote) && (
          <CreditNoteDownloadAction
            creditNote={creditNote}
            closeMenu={() => {}}
            label={<GetAppIcon titleAccess={t('download pdf')} color={'primary'} />}
          />
        )}
      </CardActions>
      <Menu
        id="long-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuAnchor(undefined)}
        PaperProps={{
          style: {
            //maxHeight: ITEM_HEIGHT * 4.5,
            width: 'min-content',
          },
        }}
      >
        {actions}
      </Menu>
    </Card>
  );
}
