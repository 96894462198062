import { EbicsConnections } from '../../../../types/Ebics/EbicsConnection';

const EbicsAccountStatusCode = (status: EbicsConnections.Status): number => {
  switch (status) {
    case EbicsConnections.Status.SETTING_UP_CREDITOR_IDENTIFIER:
      return 0;
    case EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK:
      return 1;
    case EbicsConnections.Status.INITIALIZING_EBICS:
      return 2;
    case EbicsConnections.Status.SENDING_INI_LETTER:
      return 3;
    case EbicsConnections.Status.WAITING_FOR_ACTIVATION:
      return 4;
    case EbicsConnections.Status.ACTIVE:
      return 5;
  }
};

export { EbicsAccountStatusCode };
