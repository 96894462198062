import React, { ReactNode, useEffect, useState } from 'react';
import { CompanyAccount } from '../../types/Company/Company';
import CompanyAccountService from '../../services/CompanyAccountService';
import { useTranslation } from 'react-i18next';

export const CompanyAccountContext = React.createContext<{ companyAccount: CompanyAccount }>({ companyAccount: {} as CompanyAccount });

export default function WithCompanyAccountContext({ children }: { children: ReactNode }) {
  const [company, setCompany] = useState<CompanyAccount>({} as CompanyAccount);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation('global');

  useEffect(() => {
    CompanyAccountService.getCompany().then((company) => {
      setCompany(company);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <>{t('loading')}</>
  ) : (
    <CompanyAccountContext.Provider value={{ companyAccount: company }}>{children}</CompanyAccountContext.Provider>
  );
}
