import React, { createContext, useEffect, useState } from 'react';
import { SEPAMandate } from '../../types/SEPA/SEPAMandate';
import SEPAMandateService from '../../services/SEPAMandateService';
import PaginationProvider from '../../components/table/PaginationContext';
import { PageMetadata } from '../../types/Page';

export const SEPAMandateListDataContext = createContext<{
  sepaMandates: SEPAMandate[];
  page?: PageMetadata;
  reload: () => void;
  loading: boolean;
  isPrefilteredForDebtor: boolean;
  currentPage: { set: (page: number) => void; get: number };
  pageSize: { set: (size: number) => void; get: number };
}>({
  sepaMandates: [] as SEPAMandate[],
  reload: () => {},
  loading: true,
  isPrefilteredForDebtor: false,
  currentPage: {
    set: (page) => {},
    get: 0,
  },
  pageSize: {
    set: (size) => {},
    get: 0,
  },
});

type SepaMandateListDataContextProviderProps = {
  pageSizeOptions: number[];
  children: any;
  debtorId?: string;
  setSepaMandatesCount?: (sepaMandateCount: number) => void;
  hasPaginationProvider?: boolean;
};

const SepaMandateListDataContextProvider = ({
  pageSizeOptions,
  debtorId,
  children,
  setSepaMandatesCount,
  hasPaginationProvider = true,
}: SepaMandateListDataContextProviderProps) => {
  const [sepaMandateList, setSepaMandateList] = useState<SEPAMandate[]>([] as SEPAMandate[]);
  const [page, setPage] = useState<PageMetadata>();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const isInitialized = React.useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      reload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (isInitialized.current) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  useEffect(() => {
    reload();
    isInitialized.current = true;
    return () => {
      isInitialized.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reload = () => {
    setLoading(true);
    SEPAMandateService.getSEPAMandates(pageSize, currentPage, 'desc', debtorId).then(({ results, page }) => {
      setSepaMandatesCount && setSepaMandatesCount(page.totalElements);
      setSepaMandateList(results);
      setPage(page);
      setLoading(false);
    });
  };

  return (
    <SEPAMandateListDataContext.Provider
      value={{
        sepaMandates: sepaMandateList,
        page: page,
        reload: reload,
        loading: loading,
        isPrefilteredForDebtor: !!debtorId,
        currentPage: { set: setCurrentPage, get: currentPage },
        pageSize: { set: setPageSize, get: pageSize },
      }}
    >
      {hasPaginationProvider ? (
        <PaginationProvider
          pageSizeOptions={pageSizeOptions}
          currentPage={currentPage || 0}
          totalPages={page?.totalPages || 0}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          changePageSize={setPageSize}
        >
          {children}
        </PaginationProvider>
      ) : (
        children
      )}
    </SEPAMandateListDataContext.Provider>
  );
};

export default SepaMandateListDataContextProvider;
