import {
  Badge,
  Box,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionMenu, { Action } from './ActionMenu';

const useStyles = makeStyles((theme) => ({
  even: {
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  odd: {
    border: 'none',
    backgroundColor: theme.palette.background.default,
  },
  select: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  icon: {
    color: '#FFFFFF',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface Row {
  id: string;
  [key: string]: string | string[];
}

interface CellTransformations {
  [key: string]: (cell: string | string[], row: Row) => string | JSX.Element;
}

interface Column {
  label: string;
  id: string;
}

interface DataTableProps {
  columns: Column[];
  rows: Row[];
  cellTransformations?: CellTransformations;
  pageSizeOptions?: number[];
  pageSize?: number;
  totalPages?: number;
  currentPage?: number;
  onPageSizeChange?: (pageSize: number) => void;
  onPageChange?: (page: number) => void;
  showPagination: boolean;
  transformPageSizeLabel?: (pageSize: number) => string | number;
  actions?: Action[];
  filterActionsPerRow?: (row: Row, actions: Action[]) => Action[];
  showActions?: (row: Row) => boolean;
  showActionNotifications?: (row: Row) => number;
  onAction?: (action: Action, row: Row) => void;
}

const DataTable: React.FC<DataTableProps> = ({
  onPageChange,
  onPageSizeChange,
  columns,
  rows,
  cellTransformations = {},
  pageSizeOptions,
  pageSize,
  totalPages,
  currentPage = 1,
  showPagination = true,
  transformPageSizeLabel = (pageSize) => pageSize,
  actions,
  filterActionsPerRow = (row, actions) => actions,
  showActions = (row) => true,
  onAction = (action, row) => {},
  showActionNotifications = (row) => 0,
}) => {
  const { t } = useTranslation('global');

  const classes = useStyles();

  const [_currentPage, setCurrentPage] = useState(currentPage);

  useEffect(() => {
    setCurrentPage(currentPage);
  }, [currentPage]);

  const _onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    onPageChange && onPageChange(page);
  };

  return (
    <>
      <TableContainer style={{ flex: '1 1 auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <>
                {columns.map((column, i) => (
                  <TableCell key={i}>{column.label}</TableCell>
                ))}
                {actions && <TableCell>{t('actions')}</TableCell>}
              </>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={row['id'] || index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
                {columns.map((column, i) => (
                  <TableCell key={i}>
                    {cellTransformations[column.id] ? cellTransformations[column.id](row[column.id], row) : row[column.id]}
                  </TableCell>
                ))}
                {actions && (
                  <TableCell align="left">
                    {showActions(row) && (
                      <Badge badgeContent={showActionNotifications(row)} color="error" overlap="circular">
                        <ActionMenu actions={filterActionsPerRow(row, actions)} row={row} onAction={onAction} />
                      </Badge>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <>
          <Box style={{ height: '1em' }} />
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item style={{ display: 'flex' }} xs={3}>
              <Select
                onChange={(e) => onPageSizeChange && onPageSizeChange(parseInt(String(e.target.value)))}
                value={pageSize}
                input={<OutlinedInput margin="dense" />}
                className={classes.select}
                classes={{ icon: classes.icon }}
              >
                {pageSizeOptions &&
                  pageSizeOptions.map((option, i) => (
                    <MenuItem key={i} value={option}>
                      {transformPageSizeLabel(option)}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item>
              <Pagination classes={{}} onChange={_onPageChange} color="secondary" count={totalPages} page={_currentPage} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export type { DataTableProps, Row };
export default DataTable;
