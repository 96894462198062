import { FormControl, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FormikInputField } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxField from '../../../../../components/FormFields/CheckboxField';

export default function CreditorInformationForm(): ReactElement {
  const { t } = useTranslation('sepa');
  return (
    <>
      <p>
        <Typography>{t('please enter your creditor id')}</Typography>
      </p>
      <p>
        <FormikInputField name="creditorIdentifier" label={t('creditorIdentifier')} fullWidth />
      </p>
      <Alert severity="info" variant="outlined">
        <>
          <Typography variant="caption">{t('no creditor identifier')}</Typography>
          <p>
            {/* link maintained in AzureFrontDoor */}
            <a target="_blank" rel="noreferrer" href="/request-creditor-id">
              <Typography variant="caption">{t('request creditor id')}</Typography>
            </a>
          </p>
        </>
      </Alert>
      <p>
        <FormControl component="fieldset">
          <CheckboxField name="creditor_nextStep" label={<Typography>{t('creditor identifier next step')}</Typography>} />
        </FormControl>
      </p>
    </>
  );
}
