import { Step, StepLabel, Stepper as MuiStepper } from '@material-ui/core';
import { TFunction } from 'i18next';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { EbicsConnection, EbicsConnections } from '../../../../types/Ebics/EbicsConnection';
import { EbicsAccountStatusCode } from './ebics';

interface Props {
  ebicsConnection: EbicsConnection;
}

const titleByStatus = (status: EbicsConnections.Status, t: TFunction): any => {
  switch (status) {
    case EbicsConnections.Status.SETTING_UP_CREDITOR_IDENTIFIER:
      return t('step preparation title');
    case EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK:
      return t('step request title');
    case EbicsConnections.Status.INITIALIZING_EBICS:
      return t('step setup title');
    case EbicsConnections.Status.SENDING_INI_LETTER:
      return t('init letter title');
    case EbicsConnections.Status.WAITING_FOR_ACTIVATION:
    case EbicsConnections.Status.ACTIVE:
      return t('step activation title');
    default:
      return '';
  }
};

export default function Stepper({ ebicsConnection }: Props): ReactElement {
  const { t } = useTranslation('sepa');
  return (
    <MuiStepper activeStep={EbicsAccountStatusCode(ebicsConnection.status)}>
      <Step key={EbicsAccountStatusCode(EbicsConnections.Status.SETTING_UP_CREDITOR_IDENTIFIER)}>
        <StepLabel>{t('step preparation')}</StepLabel>
      </Step>
      <Step key={EbicsAccountStatusCode(EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK)}>
        <StepLabel>{t('step request')}</StepLabel>
      </Step>
      <Step key={EbicsAccountStatusCode(EbicsConnections.Status.INITIALIZING_EBICS)}>
        <StepLabel>{t('step setup')}</StepLabel>
      </Step>
      <Step key={EbicsAccountStatusCode(EbicsConnections.Status.SENDING_INI_LETTER)}>
        <StepLabel>{t('init letter')}</StepLabel>
      </Step>
      <Step key={EbicsAccountStatusCode(EbicsConnections.Status.WAITING_FOR_ACTIVATION)}>
        <StepLabel>{t('step activation')}</StepLabel>
      </Step>
    </MuiStepper>
  );
}

export { titleByStatus };
