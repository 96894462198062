import Chip from '@material-ui/core/Chip';
import { createStyles, Grid, makeStyles, Paper, TextField, Theme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

type Props = Omit<AutocompleteProps<any, any, any, any>, 'options' | 'renderInput'> & {
  name: string;
  label: string;
  onEmailsChange: (updatedEmailAddresses: string[]) => void;
  initialEmails?: string[];
};

function isValidEmail(email: string): boolean {
  if (email.trim().length <= 0) return false;

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
}

export default function EmailTextField(props: Props) {
  const { name, label, onEmailsChange, initialEmails = [] } = props;
  const [, meta] = useField<string[]>(name);
  const classes = useStyles();

  const [inputValue, setInputValue] = useState<string>('');
  const [emailAddresses, setEmailAddresses] = useState<string[]>(initialEmails);

  const deleteEmailAddress = (emailAddress: string) => () => {
    let updatedEmailAddresses = emailAddresses.filter((v) => v !== emailAddress);
    setEmailAddresses(updatedEmailAddresses);
    onEmailsChange(updatedEmailAddresses);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper component="ul" className={classes.root} elevation={0}>
          {emailAddresses.map((emailAddress) => {
            return (
              <li key={emailAddress}>
                <Chip variant="outlined" label={emailAddress} onDelete={deleteEmailAddress(emailAddress)} className={classes.chip} />
              </li>
            );
          })}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          {...props}
          freeSolo
          multiple
          options={[]}
          id="additionalEmailAddresses"
          onInputChange={(event, value) => {
            if (isValidEmail(value.trim()) && (value.indexOf(',') !== -1 || value.charAt(value.length - 1) === ' ')) {
              let updatedEmailAddresses = [value.trim().slice(0, value.length - 1), ...emailAddresses];
              setEmailAddresses(updatedEmailAddresses);
              onEmailsChange(updatedEmailAddresses);
              setInputValue('');
            } else {
              setInputValue(value);
            }
          }}
          inputValue={inputValue}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              label={label}
              error={meta?.touched && !!meta?.error}
              helperText={<ErrorMessage name={name} component={'span'} />}
              onBlur={(event) => {
                const value = event.target.value;
                if (value.trim().length > 0) {
                  let updatedEmailAddresses = [value.trim().slice(0, value.length), ...emailAddresses];
                  setEmailAddresses(updatedEmailAddresses);
                  onEmailsChange(updatedEmailAddresses);
                  setInputValue('');
                }
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
