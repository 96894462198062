import { TFunction } from 'react-i18next';
import { ReactElement } from 'react';
import { CheckoutLink } from '../../../types/CheckoutLink/CheckoutLink';
import CheckoutLinkEditAction from './CheckoutLinkEditAction';
import CheckoutLinkDeleteAction from './CheckoutLinkDeleteAction';

const getActionsForCheckoutLink = (checkoutLink: CheckoutLink, setMenuAnchor: (x: any) => void, t: TFunction, mode?: 'detailsPage') => {
  const actions: ReactElement[] = [];
  actions.push(<CheckoutLinkEditAction checkoutLink={checkoutLink} setMenuAnchor={setMenuAnchor} label={t('edit')} />);
  actions.push(<CheckoutLinkDeleteAction checkoutLink={checkoutLink} setMenuAnchor={setMenuAnchor} label={t('delete')} />);
  return actions;
};

export type CheckoutLinkActionType = {
  checkoutLink: CheckoutLink;
  setMenuAnchor: (x: any) => void;
  label: string;
};

export { getActionsForCheckoutLink };
