import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import AgentService from '../../services/AgentService';
//TODO
// to TypeScript

export default function InviteCostumerDialog(props) {
  const [invitationLink, setInvitationLink] = useState();

  const { t } = useTranslation('connectedAccounts');

  const { isOpen, onCancel } = props;

  useEffect(() => {
    loadCostumerInvitation();
  }, []);

  const loadCostumerInvitation = () => {
    AgentService.createAgentClientInvitation().then((res) => {
      if (res) {
        setInvitationLink(res);
      }
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
    >
      <DialogTitle id="alert-dialog-title">{t('invite customer title')}</DialogTitle>
      <DialogContent>
        <Typography>{t('info text')}</Typography>
        <TextField
          multiline
          value={invitationLink}
          disabled
          variant="outlined"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Copy" placement="top">
                  <CopyToClipboard text={invitationLink}>
                    <IconButton aria-label="copy">
                      <FileCopyIcon fontSize="default" />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>

      <DialogActions>
        <Tooltip title="Copy and Close" placement="top">
          <CopyToClipboard text={invitationLink}>
            <Button onClick={onCancel} color="primary" variant="contained">
              {t('close & copy to clipboard')}
            </Button>
          </CopyToClipboard>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
}
