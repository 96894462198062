import { Dialog, DialogActions, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  title: {
    alignSelf: 'center',
  },
  text: {
    padding: '0 15px',
    alignSelf: 'center',
  },
}));

interface Data {
  createdDateTimeUTC?: string;
  events?: string[];
  id?: string;
  mode?: string;
  name?: string;
  signingSecret?: string;
  url?: string;
  prefix?: string;
}

interface Props {
  open: boolean;
  data: Data;
  text: string;
  title: string;
  onClose: () => void;
  onDelete: (data: any) => void;
}

export default function DeleteWebhookDialog({ open, data, text, title, onClose, onDelete }: Props): ReactElement {
  const { t } = useTranslation('developer');
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
    >
      <DialogTitle className={classes.title} id="alert-dialog-title">
        {t(title)}
      </DialogTitle>
      <DialogContentText className={classes.text} id="alert-dialog-description">
        {text}
      </DialogContentText>
      <DialogActions>
        <Button variant="text" name="cancel" label={t('global:no')} disabled={false} loading={false} onClick={onClose} />
        <Button name="cancel" label={t('global:yes')} disabled={false} loading={false} onClick={() => onDelete(data)} />
      </DialogActions>
    </Dialog>
  );
}
