import { MenuItem } from '@material-ui/core';
import { DebtorActionType } from './DebtorActionHelper';
import { useContext, useState } from 'react';
import { DebtorListDataContext } from '../DebtorListDataContextProvider';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { useTranslation } from 'react-i18next';
import EditDebtorDialog from '../dialogs/EditDebtorDialog';
import ReloadContext from '../../../components/contexts/ReloadContext';

const DebtorEditAction = ({ debtor, setMenuAnchor, label }: DebtorActionType) => {
  const [openDialog, setOpenDialog] = useState(false);

  const debtorReload = useContext(DebtorListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;

  const reload = () => {
    debtorReload();
    contextReload();
  };
  const alerting = useContext(AlertContext);

  const { t } = useTranslation('debitors');

  const onEdited = () => {
    alerting.sendAlert('success', t('debitor edited'));
    setOpenDialog(false);
    reload();
  };

  return (
    <div key={debtor.id + 'editC'}>
      <MenuItem
        key={debtor.id + 'edit'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>

      {openDialog && <EditDebtorDialog debtor={debtor} onClose={() => setOpenDialog(false)} onEdit={onEdited} open={true} />}
    </div>
  );
};

export default DebtorEditAction;
