import agent from './logos/agent.svg';
import payments from './logos/payments.svg';
import subscription from './logos/subscription.svg';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    flexDirection: 'row',
    lineHeight: '0.75em',
  },
  logoTexts: {
    alignSelf: 'center',
  },
  logoName: {
    display: 'flex',
    flexDirection: 'row',
    lineHeight: '0.75em',
    fontSize: '1.2rem',
  },
  logoSubtitle: {
    lineHeight: '1.2em',
    fontSize: '0.7rem',
  },
}));

export default function LogoProducts({ serviceName }) {
  const classes = useStyles();

  const service = 'service';

  return (
    <div className={classes.logoContainer}>
      <img
        src={serviceName === 'agent' ? agent : serviceName === 'payments' ? payments : subscription}
        style={{
          maxWidth: 35 + 'px',
          maxHeight: 35 + 'px',
          marginRight: '12px',
        }}
        alt={'logo'}
      />
      <div className={classes.logoTexts}>
        <Typography color="secondary" className={classes.logoName} align="left" variant="h5">
          {serviceName}
        </Typography>
        <Typography color="secondary" className={classes.logoSubtitle} align="right">
          {service}
        </Typography>
      </div>
    </div>
  );
}
