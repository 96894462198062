import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../components/LoadingButton';
import { Debtor } from '../../../types/Debtor/Debtor';

interface Props {
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onFinalize: () => void;
  debtor: Debtor;
}

export default function ResendInvitationDialog({ open, onCancel, onFinalize, debtor, loading }: Props): ReactElement {
  const { t } = useTranslation('debitors');

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return false;
          }
          onCancel();
        }}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{t('resend invitation')}</DialogTitle>
        <Alert severity="info">
          <Typography>{t('resend invitation subtitle', { name: `${debtor.firstName} ${debtor.lastName}` })}</Typography>
        </Alert>
        <DialogContent>
          <TextField
            style={{ marginTop: '3%' }}
            multiline
            value={debtor.paymentMethodSetupLink}
            disabled
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Copy" placement="top">
                    <CopyToClipboard text={!!debtor.paymentMethodSetupLink ? debtor.paymentMethodSetupLink : ''}>
                      <IconButton aria-label="copy">
                        <FileCopyIcon fontSize="default" />
                      </IconButton>
                    </CopyToClipboard>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <Typography style={{ marginTop: '7%' }}>{t('resend invitation text', { name: `${debtor.firstName} ${debtor.lastName}` })}</Typography>
        </DialogContent>
        <DialogActions>
          <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:cancel')} />
          <LoadingButton loading={loading} onClick={onFinalize}>
            {t('resend invitation btn')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
