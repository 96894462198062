import React from 'react';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import { Payment } from '../../../types/Payment/Payment';
import { TFunction, Trans } from 'react-i18next';
import { groupByEventType, PEvent } from '../../../types/PEvent';
import { EventStep } from '../../../types/EventStep';
import PaymentIcon from '@material-ui/icons/Payment';
import EventAvatar from '../../../components/EventAvatar';

const eventsToSteps = (payment: Payment, events: PEvent[], t: TFunction) => {
  const steps = [] as EventStep[];
  const icon = <PaymentIcon fontSize={'small'} />;

  events.sort((a, b) => {
    return new Date(a.eventDateTime).getTime() - new Date(b.eventDateTime).getTime();
  });

  const mergedEvents = groupByEventType(events);

  mergedEvents.forEach((event) => {
    const step: EventStep = {
      count: 1,
      label: formatDateTime(event.eventDateTime),
      content: event.eventType,
      paymentId: event.paymentId,
      reason: undefined,
      reasonMessage: undefined,
      final: false,
      error: false,
      datetime: new Date(event.eventDateTime),
      relatedObject: payment.id,
      icon: <EventAvatar>{icon}</EventAvatar>,
    };

    switch (event.eventType) {
      case 'CREATED_BY_CREDITOR':
        step.content = <span>{t('online payment created')}</span>;
        break;
      case 'DIRECT_DEBIT_CREATED':
        step.content = <span>{t('direct debit created')}</span>;
        break;
      case 'DIRECT_DEBIT_PENDING':
        step.content = <span>{t('direct debit pending')}</span>;
        break;
      case 'DIRECT_DEBIT_FUNDS_CHARGED_BACK':
        step.content = <span>{t('direct debit funds charged back')}</span>;
        break;
      case 'CHARGE_BACK_NOTIFICATION_SEND':
        step.content = <span>{t('direct debit funds charged back notification send')}</span>;
        break;
      case 'CHARGE_BACK_REMINDER_SEND':
        step.content = <span>{t('direct debit funds charged back reminder send')}</span>;
        break;
      case 'INVOICE_UPLOADED_BY_CREDITOR':
        step.content = <span>{t('invoice uploaded')}</span>;
        break;
      case 'DEBIT_INVOICE_UPLOADED_BY_CREDITOR':
        step.content = <span>{t('invoice uploaded')}</span>;
        break;
      case 'RESTARTED_BY_DEBITOR':
        step.content = <span>{t('payment process restarted by debitor')}</span>;
        break;
      case 'DEBITOR_NOTIFIED':
        step.content = (
          <span>
            {t('email was sent to')} <b>{payment.debitor.emailAddress}</b>
          </span>
        );
        break;
      case 'SEND_DONATION_RECEIPT':
        let emailAddress = payment.debitor?.emailAddress;
        step.content = (
          <Trans t={t} i18nKey="donation receipt was sent to">
            Donation receipt was sent to <b>{{ emailAddress }}</b>.
          </Trans>
        );
        break;
      case 'CANCELLED_BY_CREDITOR':
        step.content = (
          <span>
            {t('cancelled by creditor')} (<b>{event.triggeredBy}</b>)
          </span>
        );
        step.reasonMessage = (
          <>
            {payment.cancelStatus && (
              <>
                <p>
                  <span> {t('reason exteral')} </span>
                  <span>{payment.cancelStatus.cancelReasonDebitor}</span>
                </p>
                {payment.cancelStatus.cancelReasonInternal && (
                  <p>
                    <span>{t('reason internal')} </span>
                    <span>{payment.cancelStatus.cancelReasonInternal}</span>
                  </p>
                )}
              </>
            )}
          </>
        );
        step.final = true;
        step.error = true;
        break;

      case 'PAYMENT_PROCESS_COMPLETED_BY_CREDITOR':
        step.content = (
          <span>
            {t('manually marked as paid')} (<b>{event.triggeredBy}</b>)
          </span>
        );
        step.final = true;
        step.error = false;
        break;
      case 'PAYMENT_PROCESS_COMPLETED_MANUALLY_BY_DEBITOR':
        step.content = (
          <span>
            {t('debitor pay manually')} (
            <b>
              {payment.debitor.firstName} {payment.debitor.lastName}
            </b>
            )
          </span>
        );
        step.final = true;
        step.error = false;
        break;
      case 'DEBITOR_OPENED_NOTIFICATION':
        step.content = (
          <span>
            {t('email was opened by')}{' '}
            <b>
              {payment.debitor.firstName} {payment.debitor.lastName}
            </b>
          </span>
        );
        break;
      case 'MARKED_AS_DONATION':
        step.content = <span>{t('payment marked as donation')}</span>;
        break;
      case 'DEBITOR_CLICKED_NOTIFICATION':
        step.content = (
          <span>
            {t('link in email clicked')}{' '}
            <b>
              {payment.debitor.firstName} {payment.debitor.lastName}
            </b>
          </span>
        );
        break;
      case 'PAYMENT_PROCESS_STARTED_BY_DEBITOR':
        step.content = (
          <span>
            <b>
              {payment.debitor.firstName} {payment.debitor.lastName}
            </b>
            {t('saw payment screen')}
          </span>
        );
        break;
      case 'PAYMENT_PROCESS_ABORTED_BY_DEBITOR':
        step.content = (
          <span>
            <b>
              {payment.debitor.firstName} {payment.debitor.lastName}
            </b>{' '}
            {t('aborted the payment')}
          </span>
        );
        if (event.reason)
          step.reason = (
            <span>
              {t('reason')} {t(event.reason)}
            </span>
          );
        step.reasonMessage = event.reasonMessage;
        step.final = false;
        step.error = true;
        break;
      case 'PAYMENT_VERIFIED':
        if (step.triggeredBy === 'MATCHING_SERVICE') {
          step.content = <span>{t('payment was matched')}</span>;
        } else {
          step.content = (
            <span>
              {t('marked as verified')} <b>{event.triggeredBy}</b>.
            </span>
          );
        }
        if (event.reason === 'OTHER') {
          step.reasonMessage = event.reasonMessage;
        }
        step.final = true;
        step.error = false;
        break;
      case 'PAYMENT_PROCESS_COMPLETED_BY_DEBITOR':
        step.content = (
          <span>
            {t('transaction successfully initiated')}{' '}
            <b>
              {payment.debitor.firstName} {payment.debitor.lastName}
            </b>
          </span>
        );
        step.final = true;
        break;
      case 'PAYMENT_REQUESTED_BY_CREDITOR':
        step.content = <span>{t('payment requested by creditor')}</span>;
        break;
      case 'PAYMENT_REMINDER_SENT':
        step.content = <span>{t('payment reminder sent', { email: payment.debitor?.emailAddress })}</span>;
        break;
      case 'PAYMENT_EMAIL_BOUNCED':
        step.content = <span>{t('payment email bounced', { email: payment.debitor?.emailAddress })}</span>;
        break;
      case 'DEBITOR_WANTS_TO_PAY_MANUALLY':
        step.content = <span>{t('debitor wants to pay manually')}</span>;
        break;
      case 'PAYMENT_REFUND_IN_PROGRESS':
        step.content = <span>{t('payment refund in progress')}</span>;
        break;
      case 'PAYMENT_REFUND_COMPLETED_BY_CREDITOR':
        step.content = (
          <span>
            {t('payment refund completed by')}
            <b>{event.triggeredBy}</b>
          </span>
        );
        break;

      case 'PAYMENT_REFUND_COMPLETED':
        step.content = (
          <span>
            {t('payment refund completed')}
            <b>{event.triggeredBy}</b>
          </span>
        );
        break;
      case 'PAYMENT_CANCELLATION':
        step.content = (
          <span>
            {t('payment cancellation')}
            <b>{event.triggeredBy}</b>.
          </span>
        );
        break;
      case 'PAYMENT_CONFIRMATION_SENT':
        step.content = <span>{t('payment confirmation sent')}.</span>;
        break;
      case 'PAYMENT_MODIFIED':
        if (event.reasonMessage) {
          const { purpose, amount } = JSON.parse(event.reasonMessage);

          step.content = (
            <>
              <span>
                {t('modified by')} (<b>{event.triggeredBy}</b>)
              </span>
              <p>
                <span>{t('purpose_modified', { before: purpose.before, after: purpose.after })} </span>
              </p>
              <p>
                <span>{t('amount_modified', { before: amount.before, after: amount.after })} </span>
              </p>
            </>
          );
        }
        break;
      default:
        break;
    }

    if (step.final && !step.error) {
      step.icon = <EventAvatar style={{ backgroundColor: 'green' }}>{icon}</EventAvatar>;
    } else if (step.final && step.error) {
      step.icon = <EventAvatar style={{ backgroundColor: 'red' }}>{icon}</EventAvatar>;
    }

    if (step.count > 1) {
      step.label = step.label + ' (' + step.count + ' in total)';
    }

    steps.push(step);
  });
  return steps;
};
export { eventsToSteps };
