import React from 'react';
import { Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { formatDate } from '../../utils/DateTimeUtil';
import { useTranslation } from 'react-i18next';
import { InstalmentPlan } from '../../types/InstalmentPlan/InstalmentPlan';
import { calculateRateInterval, lastRateDifferentThanIntervalAmount } from '../../utils/InstalmentPlanHelperFunctions';

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: 'lightgray',
  },
}));

type InstalmentPlanPreviewProps = {
  instalmentPlan: InstalmentPlan;
};
const InstalmentPlanPreview = ({ instalmentPlan }: InstalmentPlanPreviewProps) => {
  const classes = useStyles();
  const { t } = useTranslation('instalmentPlans');

  let instalmentRates = instalmentPlan.instalmentRates;
  return (
    <>
      <Grid item>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <b> {t('total amount')}</b>
                </TableCell>
                <TableCell align="right">{instalmentPlan.totalNetAmount} €</TableCell>
              </TableRow>
              {!!instalmentPlan.downPayment && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b> {t('down payment')}</b>
                  </TableCell>
                  <TableCell align="right">{instalmentPlan.downPayment} €</TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell component="th" scope="row">
                  <b> {t('rates')}</b>
                </TableCell>
                <TableCell align="right">
                  {`${calculateRateInterval(instalmentRates, instalmentPlan.downPayment)} ${t('of')} ${instalmentPlan.intervalAmount} €`}{' '}
                </TableCell>
              </TableRow>
              {lastRateDifferentThanIntervalAmount(instalmentRates) && (
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b> {t('last rate')}</b>
                  </TableCell>
                  <TableCell align="right">{instalmentRates[instalmentRates.length - 1].amount} € </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell component="th" scope="row">
                  <b> {t('interval')}</b>
                </TableCell>
                <TableCell align="right">{t(instalmentPlan.interval)} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <b> {t('first collection date')}</b>
                </TableCell>
                <TableCell align="right">{formatDate(instalmentRates[0].date)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <b> {t('last collection date')}</b>
                </TableCell>
                <TableCell align="right">{formatDate(instalmentRates[instalmentRates.length - 1].date)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default InstalmentPlanPreview;
