import { Grid, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import { Alert, Button, FormikInputField } from '@payactive/app-common';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import PayactiveHeaderLogo from '../../components/ui/blocks/PayactiveHeaderLogo';
import CentricLayout from '../../components/ui/layout/CentricLayout';
import PasswordService from '../../services/PasswordService';
import { PasswordReset } from '../../types/User/PasswordReset';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    borderRadius: '5px',
  },
  form: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formField: {
    padding: theme.spacing(1),
  },
}));

export default function PasswordResetPage() {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();

  const [redirect, setRedirect] = useState<string>();

  const token = new URLSearchParams(location.search).get('resetToken');
  if (!token) {
    throw new Error('token not in url');
    //TODO handle catch
  }

  const schema = Yup.object().shape({
    password: Yup.string().required(t('Password is required')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('Passwords must match'))
      .required(t('Password confirmation is required')),
  });

  const initialValues: PasswordReset = {
    password: '',
    passwordConfirmation: '',
    token: token,
  };

  const handleSubmit = (values: PasswordReset, { setSubmitting, setStatus }: FormikHelpers<PasswordReset>) => {
    PasswordService.resetPassword(values).then((res) => {
      setSubmitting(false);
      if (res) {
        setStatus({
          severity: 'success',
          message: t('Password reset successful'),
        });
        setTimeout(() => {
          setRedirect('/login');
        }, 3000);
      } else {
        setStatus({
          severity: 'error',
          message: t('Password reset unsuccessful'),
        });
      }
    });
  };

  return (
    <CentricLayout>
      <Grid item container alignContent={'center'} direction={'column'}>
        <Grid item xs={12}>
          <PayactiveHeaderLogo color={theme.palette.secondary.main} />
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('Request a new password')}</Typography>
        </Grid>
      </Grid>
      <Paper className={classes.paper} elevation={0}>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
          {({ values, status, isSubmitting }) => (
            <Form>
              {status && (
                <>
                  <Alert severity={status.severity}>{status.message}</Alert>
                  {redirect && <Redirect to={redirect} />}
                </>
              )}
              <FormikInputField
                disabled={isSubmitting || status?.severity === 'success'}
                value={values.password}
                fullWidth
                className={classes.formField}
                icon="lock"
                type="password"
                name="password"
                label={t('password')}
              />
              <FormikInputField
                disabled={isSubmitting || status?.severity === 'success'}
                value={values.passwordConfirmation}
                fullWidth
                className={classes.formField}
                icon="lock"
                type="password"
                name="passwordConfirmation"
                label={t('password confirmation')}
              />
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={status?.severity === 'success'}
                className={classes.formField}
                label={t('save')}
                name="submit"
                fullWidth
              />
            </Form>
          )}
        </Formik>
      </Paper>
    </CentricLayout>
  );
}
