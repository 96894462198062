import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserService from '../../../services/UserService';
import { User } from '../../../types/User/User';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  text: {
    color: 'white',
  },
}));

enum TimesOfDay {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
}

function getTimeOfDay(): TimesOfDay {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) {
    return TimesOfDay.MORNING;
  } else if (curHr < 18) {
    return TimesOfDay.AFTERNOON;
  } else {
    return TimesOfDay.EVENING;
  }
}

const UserInfo = () => {
  const { t } = useTranslation('global');

  const classes = useStyles();

  const [userInfo, setUserInfo] = useState<User>();

  useEffect(() => {
    UserService.getUser()
      .then((user) => {
        if (user) {
          setUserInfo(user);
        }
      })
      .catch(() => {
        //TODO handle catch
      });
  }, []);

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1} style={{ maxWidth: '100%' }}>
      <Grid item>
        <Avatar className={classes.large}>{userInfo ? `${userInfo.firstName[0]}${userInfo.lastName[0]}` : ''}</Avatar>
      </Grid>
      <Grid item>
        <Typography component="div" variant="subtitle1" className={classes.text}>
          {t(getTimeOfDay())}
        </Typography>
        <Typography component="div" variant="subtitle1" className={classes.text}>
          {userInfo ? `${userInfo.firstName} ${userInfo.lastName}` : <Skeleton />}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserInfo;
