import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import PageHeader from '../../components/ui/blocks/PageHeader';
import { Button } from '@payactive/app-common';
import GenericTableFilters, { StateFilter } from '../../components/filter/GenericTableFilters';
import ProductListDataContextProvider, { ProductListDataContext } from './ProductListDataContext';
import ProductsTable from './table/ProductsTable';
import { CreateProductDialog } from './dialogs/CreateProductDialog';
import { Products } from '../../types/Product/Product';

const ProductsView = () => {
  const { t } = useTranslation(['global', 'products']);
  const productTypes: StateFilter[] = [
    { name: t('products:PRODUCT'), value: Products.Type.PRODUCT },
    { name: t('products:DONATION'), value: Products.Type.DONATION },
  ];
  const [showCreateProductDialog, setShowCreateProductDialog] = useState(false);

  let newProductTitle = t('products:create product');
  return (
    <ProductListDataContextProvider pageSizeOptions={[5, 10, 30]}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeader title={t('products:products')} />
        </Grid>
        <Grid item>
          <Button onClick={() => setShowCreateProductDialog(true)} label={newProductTitle} name={newProductTitle} />
        </Grid>
      </Grid>
      <Grid>
        <GenericTableFilters states={productTypes} context={ProductListDataContext} t={t} label={t('filter by type')} />
      </Grid>
      <ProductsTable showPagination={true} />
      <ProductListDataContext.Consumer>
        {({ reload }) => (
          <>
            {showCreateProductDialog && (
              <CreateProductDialog
                variant="products"
                isOpen={true}
                comeBack={() => setShowCreateProductDialog(false)}
                onCancel={() => {}}
                redirectToOrigin={() => {
                  setShowCreateProductDialog(false);
                  reload();
                }}
              />
            )}
          </>
        )}
      </ProductListDataContext.Consumer>
    </ProductListDataContextProvider>
  );
};

export default ProductsView;
