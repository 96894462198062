import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onMarkAsDonation: () => void;
  onCancel: () => void;
}

export default function MarkAsDonationDialog({ open, onMarkAsDonation, onCancel }: Props): ReactElement {
  const { t } = useTranslation('debitors');

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
    >
      <DialogTitle id="alert-dialog-title">{t('Mark payment as donation')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Alert severity="info">
            {' '}
            <DialogContentText>{t('the payment is marked as donation')}</DialogContentText>
          </Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:close')} />
        <Button
          name="markAsDonation"
          label={t('payments:mark_donation')}
          disabled={false}
          loading={false}
          onClick={onMarkAsDonation}
          variant="contained"
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
}
