import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import TableHeader from '../../../components/table/TableHeader';
import { Skeleton } from '@material-ui/lab';
import TablePagination from '../../../components/table/Pagination';
import { ProductListDataContext } from '../ProductListDataContext';
import ProductRow from './ProductRow';

type ProductsTableProps = {
  showPagination: Boolean;
};

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: '200px',
  },
  main: {
    padding: theme.spacing(1),
  },
}));

const ProductsTable = ({ showPagination }: ProductsTableProps) => {
  const { t } = useTranslation(['products', 'payments']);
  const classes = useStyles();
  const context = useContext(ProductListDataContext);

  const columns = [
    {
      label: t('image'),
      id: 'image',
    },
    {
      label: t('payments:product name'),
      id: 'name',
    },
    {
      label: t('payments:product description'),
      id: 'productDescription',
    },
    {
      label: t('productType'),
      id: 'productType',
    },
    {
      label: t('payments:price net (tax)'),
      id: 'price',
    },
    {
      label: t('creationDate'),
      id: 'createdDateTime',
    },
  ];

  const initalLoad = !context.page && context.loading;
  const emptyResultList = !!context.page && context.products.length === 0;
  const emptyFilteredResultList = emptyResultList && context.filters?.used;

  return (
    <>
      {emptyResultList ? (
        <>
          {emptyFilteredResultList ? (
            <Typography style={{ marginTop: '40px' }}>{t('no results for your search')}</Typography>
          ) : (
            <Grid item xs={12} className={classes.main}>
              <MuiAlert elevation={6} variant="filled" severity="warning">
                {t('no products found')}
              </MuiAlert>
            </Grid>
          )}
        </>
      ) : (
        <>
          <TableContainer style={{ flex: '1 1 auto' }}>
            <Table>
              <TableHeader columns={columns} actions={true}></TableHeader>
              <TableBody>
                <>
                  {initalLoad && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1}>
                        <Skeleton className={classes.skeleton} />
                      </TableCell>
                    </TableRow>
                  )}
                  {context.products?.map((product, index) => (
                    <ProductRow product={product} index={index} key={product.id}></ProductRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          {showPagination && <TablePagination transformPageSizeLabel={(pageSize) => t('pageSize', { pageSize: pageSize }) as string} />}
        </>
      )}
    </>
  );
};

export default ProductsTable;
