import { DialogActions } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import { ReactElement, useState } from 'react';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { useTranslation } from 'react-i18next';
import PaymentService from '../../../services/PaymentService';
import { NewPayment, Payments } from '../../../types/Payment/Payment';
import DebtorService from '../../../services/DebtorService';
import BankService from '../../../services/BankService';
import { Debtor, Debtors, NewDebtor } from '../../../types/Debtor/Debtor';
import { PaymentMethod } from '../../../types/Invoice/PaymentMethod';
import { PaymentImportRow } from './Importer';
import { Metadata } from '../../../types/Metadata';
import Notifications = Payments.Notifications;

interface Props {
  rows: PaymentImportRow[];
  onSuccess: (rows: any, selectedRowsSize: number) => void;
  onClose: () => void;
}

export default function ReviewImport({ rows, onSuccess, onClose }: Props): ReactElement {
  const { t } = useTranslation(['global', 'payments']);
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<string>>(() => new Set());
  const [loading, setLoading] = useState(false);
  const columns = [
    SelectColumn,
    { key: 'firstName', name: t('global:fieldNames.firstName') },
    { key: 'lastName', name: t('global:fieldNames.lastName') },
    { key: 'emailAddress', name: t('global:fieldNames.emailAddress') },
    { key: 'amount', name: t('global:fieldNames.amount') },
    { key: 'purpose', name: t('global:fieldNames.purpose') },
    { key: 'metadataString', name: t('global:metadata') },
  ];
  const createPayments = async () => {
    setLoading(true);
    const importedRows = [];
    const bankAccountId = (await BankService.getDefaultBankAccount())?.id;
    if (!bankAccountId) {
      //TODO handle error case
      throw new Error('default bank account not found');
    }

    for (let id of Array.from(selectedRows.values())) {
      const row = rows.find((value) => value.id === id);
      if (row) {
        try {
          const amount = row.amount;
          const debtor = await findOrCreateDebtor(row);
          let finalMd: Metadata | undefined = undefined;
          if (row.metadata) {
            finalMd = [];
            const keys = Object.keys(row.metadata);
            for (const k of keys) {
              const value = row.metadata[k];
              finalMd.push({ key: k, value: value, internal: false, publicVisible: false });
            }
          }

          const createPayment: NewPayment = {
            bankAccountId: bankAccountId,
            debitorId: debtor.id,
            amount: parseFloat(amount.replace(',', '.')),
            purpose: row.purpose,
            paymentType: Payments.PaymentType.PAYMENT_REQUEST,
            metadata: finalMd,
            paymentNotifications: Notifications.EMAIL,
          };

          const payment = await PaymentService.createPayment(createPayment);
          if (payment) {
            importedRows.push(row);
          }
        } catch (exception) {
          console.log('Error while importing row ' + row);
        }
      }
    }
    setLoading(false);
    onSuccess(importedRows, selectedRows.size);
  };

  return (
    <>
      <DataGrid
        columns={columns}
        rows={rows}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectedRows}
        rowKeyGetter={(row: PaymentImportRow) => row.id}
      />
      <DialogActions>
        <Button label={t('global:close')} name="import" onClick={onClose} />
        <Button loading={loading} label={t('payments:import.import_payments', { num: selectedRows.size })} name="import" onClick={createPayments} />
      </DialogActions>
    </>
  );
}

//TODO remove row relics
async function findOrCreateDebtor(debtorData: { emailAddress: string; firstName: string; lastName: string }): Promise<Debtor> {
  const searchDebtor = await DebtorService.search(debtorData.emailAddress);
  let debtor = searchDebtor.results.find((value) => value.emailAddress === debtorData.emailAddress);
  if (debtor) {
    return debtor;
  } else {
    const newDebtor: NewDebtor = {
      paymentMethod: PaymentMethod.ONLINE_PAYMENT,
      type: Debtors.Type.PERSON,
      emailAddress: debtorData.emailAddress,
      firstName: debtorData.firstName,
      lastName: debtorData.lastName,
    };
    return await DebtorService.createDebtor(newDebtor)
      .then((debtor) => debtor)
      .catch(() => {
        throw new Error('Error while creating debtor' + debtorData);
      });
  }
}
