import { Grid, Paper, Theme, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@payactive/app-common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import { PDFReader } from 'reactjs-pdf-reader';
import ActionDialog from '../../../components/ActionDialog';
import { oidccb, signDocument } from './QesClient';
import TickSuccess from '../../_public/onboarding/TickSuccess';
import PageHeader from '../../../components/ui/blocks/PageHeader';
import StateWithIcon from '../../../components/StateWithIcon';
import { Payments } from '../../../types/Payment/Payment';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    maxWidth: '600px',
    textAlign: 'left',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ContractPage() {
  const removeQuery = () => {
    const path = window.location.pathname;
    window.history.pushState({}, document.title, path);
  };

  const classes = useStyles();

  const { t } = useTranslation('company');

  const query = useQuery();

  const [fileDownload, setFileDownload] = useState<{ url: string }>();
  const [signingInProgress, setSigningInProgress] = useState(false);
  const [showConsent, setShowConsent] = useState(false);

  const [openSignDoc, setOpenSignDoc] = useState(false);

  useEffect(() => {
    if (query.has('code')) {
      setShowConsent(true);
    }
  }, [query]);

  const giveConsent = () => {
    setSigningInProgress(true);
    removeQuery();

    oidccb(query).then((res) => {
      setFileDownload({
        url: window.URL.createObjectURL(res.signedDoc),
      });
      setTimeout(() => {
        setShowConsent(false);
      }, 2000);
      setSigningInProgress(false);
    });
  };

  const initSigning = async () => {
    let blob = await (await fetch('/assets/contract.pdf')).blob();

    const res = await signDocument(blob, 'contract.pdf');
    if (res.success && res.location) {
      window.location.assign(res.location);
    }
  };

  const viewAndSign = () => {
    setOpenSignDoc(true);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <PageHeader title={t('contract')} subtitle={t('settings')} />
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={0}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography align={'left'}>{t('Your contract status with payactive')}:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="div" color="secondary" variant="caption">
                  {t('contract')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {fileDownload ? (
                  <Link href={fileDownload.url} download="file.pdf">
                    {t('signed contract')}
                  </Link>
                ) : (
                  <Button label={t('view and sign')} onClick={viewAndSign} name={t('view and sign')} />
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography component="div" color="secondary" variant="caption">
                  {t('contract status')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <>
                  {fileDownload ? (
                    <StateWithIcon state={Payments.State.COMPLETED} label={t('signed')} />
                  ) : (
                    <StateWithIcon state={Payments.State.PENDING} label={t('not signed')} />
                  )}
                </>
              </Grid>
            </Grid>
          </Paper>

          {showConsent && (
            <ActionDialog
              onClose={() => setShowConsent(false)}
              open={true}
              content={
                <>
                  <Typography>{t('sign doc consent')}</Typography>
                  {fileDownload && <TickSuccess />}
                </>
              }
              actions={
                <>
                  {!fileDownload && (
                    <>
                      <Grid item>{t('Sign with my Bank')}</Grid>
                      <Grid item>
                        <Button loading={signingInProgress} type="submit" label="yes ®" name={''} />
                      </Grid>
                    </>
                  )}
                </>
              }
              onSubmit={giveConsent}
              text={''}
              title={''}
            />
          )}

          {openSignDoc && (
            <ActionDialog
              onClose={() => setOpenSignDoc(false)}
              open={true}
              title={t('sign contract with payactive')}
              content={<PDFReader url={'/assets/contract.pdf'} showAllPage={false} />}
              actions={
                <>
                  <Grid item>{t('Sign with my Bank')}</Grid>
                  <Grid item>
                    <Button type="submit" label="yes ®" name={'submit'} />
                  </Grid>
                </>
              }
              onSubmit={initSigning}
              text={''}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
