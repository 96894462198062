import { Debtor } from '../Debtor/Debtor';
import { BankAccount } from '../Bank/BankAccount';
import { InstalmentRate } from './InstalmentRate';
import { Metadata } from '../Metadata';

export type InstalmentPlan = NewInstalmentPlan & {
  id: string;
  debtor: Debtor;
  bankAccount: BankAccount;
  status: InstalmentPlans.Status;
  purpose: string;
  totalNetAmount: number;
  interval: InstalmentPlans.Interval;
  intervalAmount: number;
  downPayment: number;
  instalmentRates: InstalmentRate[];
  nextPaymentDueDate: Date;
  startDate: Date;
  intervalDay: number;
  createdDateTime: Date;
  metadata?: Metadata;
};

export type NewInstalmentPlan = {
  debtorId: string;
  bankAccountId: string;
  purpose: string;
  totalNetAmount: number;
  interval?: InstalmentPlans.Interval;
  intervalAmount: number;
  downPayment?: number;
  startDate: Date;
  intervalDay: number;
  metadata?: Metadata;
};

export namespace InstalmentPlans {
  export enum Interval {
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
  }

  export enum Status {
    DRAFT = 'DRAFT',
    RUNNING = 'RUNNING',
    COMPLETED = 'COMPLETED',
    WAITING_FOR_COMPLETION = 'WAITING_FOR_COMPLETION',
    CANCELLED = 'CANCELLED',
  }
}
