import { useTranslation } from 'react-i18next';
import CheckoutLinksDataContextProvider, { CheckoutLinksDataContext } from './CheckoutLinksDataContext';
import PageHeader from '../../components/ui/blocks/PageHeader';
import { Grid } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Button } from '@payactive/app-common';
import { useState } from 'react';
import { CreateCheckoutLinkDialog } from './dialogs/CreateCheckoutLinkDialog';
import CheckoutLinksTable from './table/CheckoutLinksTable';

const CheckoutLinks = () => {
  const { t } = useTranslation('payments');

  const [showCreateDialog, setShowCreateDialog] = useState(false);

  return (
    <CheckoutLinksDataContextProvider>
      <CheckoutLinksDataContext.Consumer>
        {({ reload, isInitialized, checkoutLinks }) => (
          <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <PageHeader title={t('payments links')} />
                  </Grid>
                </Grid>
              </Grid>
              {!(isInitialized && checkoutLinks.length === 0) && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddBoxIcon />}
                    onClick={() => setShowCreateDialog(true)}
                    name={t('new payment link')}
                  >
                    {t('new payment link')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <CheckoutLinksTable
              emptyListComponent={
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddBoxIcon />}
                  onClick={() => setShowCreateDialog(true)}
                  name={t('new payment link')}
                >
                  {t('new payment link')}
                </Button>
              }
            />
            {showCreateDialog && (
              <CreateCheckoutLinkDialog
                isOpen={showCreateDialog}
                onCancel={() => {
                  setShowCreateDialog(false);
                  reload();
                }}
              />
            )}
          </>
        )}
      </CheckoutLinksDataContext.Consumer>
    </CheckoutLinksDataContextProvider>
  );
};

export default CheckoutLinks;
