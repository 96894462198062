import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { ReactNode } from 'react';

export default function ActionDialog({
  title,
  text,
  content,
  actions,
  onSubmit,
  open,
  onClose,
  ...rest
}: {
  title: string;
  text: string;
  content: ReactNode;
  actions: ReactNode;
  onSubmit: () => void;
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="form-dialog-title"
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
      open={open}
      {...rest}
    >
      <form onSubmit={onSubmit}>
        {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
        <DialogContent dividers>
          {text && <DialogContentText>{text}</DialogContentText>}
          {content}
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </form>
    </Dialog>
  );
}
