import React from 'react';

const SvgIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 0 94.901 15.796">
    <g id="Group_370" data-name="Group 370" transform="translate(-121.198 -111)">
      <g id="Group_369" data-name="Group 369" transform="translate(154.056 111.077)">
        <path
          id="Path_511"
          data-name="Path 511"
          d="M271.317,137.132a.362.362,0,0,1-.382.384h-.644a.362.362,0,0,1-.382-.384V129.5a3.119,3.119,0,1,1,6.229,0v1.683a2.961,2.961,0,0,1-3.015,3.306,3.652,3.652,0,0,1-1.807-.552Zm1.7-3.886c.934,0,1.729-.552,1.729-2.143v-1.531c0-1.455-.78-2.127-1.713-2.127-.966,0-1.715.672-1.715,2.127v3.138A3.425,3.425,0,0,0,273.017,133.246Z"
          transform="translate(-269.91 -122.924)"
          fill={color}
        />
        <path
          id="Path_512"
          data-name="Path 512"
          d="M309.144,129.54h1.545v-.428c0-1.072-.52-1.667-1.515-1.667a6.588,6.588,0,0,0-1.729.26c-.244.06-.382-.048-.46-.308l-.092-.368a.39.39,0,0,1,.292-.52,7.552,7.552,0,0,1,1.989-.3,2.707,2.707,0,0,1,2.909,2.907v2.815a2.923,2.923,0,0,1-5.8,0C306.281,130.384,307.444,129.54,309.144,129.54Zm.062,3.706a1.38,1.38,0,0,0,1.483-1.319v-1.283h-1.545c-1.009,0-1.423.568-1.423,1.271A1.375,1.375,0,0,0,309.205,133.246Z"
          transform="translate(-298.245 -122.924)"
          fill={color}
        />
        <path
          id="Path_513"
          data-name="Path 513"
          d="M378.013,129.54h1.545v-.428c0-1.072-.52-1.667-1.515-1.667a6.587,6.587,0,0,0-1.729.26c-.244.06-.382-.048-.46-.308l-.092-.368a.39.39,0,0,1,.292-.52,7.551,7.551,0,0,1,1.989-.3,2.707,2.707,0,0,1,2.909,2.907v2.815a2.923,2.923,0,0,1-5.8,0C375.15,130.384,376.313,129.54,378.013,129.54Zm.062,3.706a1.38,1.38,0,0,0,1.483-1.319v-1.283h-1.545c-1.01,0-1.423.568-1.423,1.271A1.375,1.375,0,0,0,378.075,133.246Z"
          transform="translate(-351.898 -122.924)"
          fill={color}
        />
        <path
          id="Path_514"
          data-name="Path 514"
          d="M407.106,129.648c0-2.387,1.361-3.442,3.29-3.442a7.138,7.138,0,0,1,1.867.26.356.356,0,0,1,.276.488l-.092.432a.337.337,0,0,1-.46.272,6.778,6.778,0,0,0-1.531-.212c-1.285,0-1.943.672-1.943,2.279v1.24c0,1.591.658,2.283,1.943,2.283a6.733,6.733,0,0,0,1.5-.2c.26-.06.444.016.49.244l.106.46a.373.373,0,0,1-.306.476,6.878,6.878,0,0,1-1.851.26c-1.929,0-3.29-1.071-3.29-3.458Z"
          transform="translate(-376.793 -122.924)"
          fill={color}
        />
        <path
          id="Path_515"
          data-name="Path 515"
          d="M441.119,117.852h-.69a.352.352,0,0,1-.4-.3l-.138-.4c-.06-.22.032-.364.23-.364h1v-1.935a.456.456,0,0,1,.368-.476l.55-.128c.306-.064.476.1.476.332v2.207h1.209a.327.327,0,0,1,.336.348v.368a.328.328,0,0,1-.336.348h-1.209v6.713a.37.37,0,0,1-.384.4H441.5a.369.369,0,0,1-.382-.4Z"
          transform="translate(-402.322 -113.598)"
          fill={color}
        />
        <path
          id="Path_516"
          data-name="Path 516"
          d="M469.325,112.242a.89.89,0,0,1,.888-.892.9.9,0,0,1,.888.892.91.91,0,0,1-.888.9A.89.89,0,0,1,469.325,112.242Zm.184,2.695a.369.369,0,0,1,.384-.4h.642a.369.369,0,0,1,.384.4v7.38a.369.369,0,0,1-.384.4h-.642a.37.37,0,0,1-.384-.4Z"
          transform="translate(-425.265 -111.35)"
          fill={color}
        />
        <path
          id="Path_517"
          data-name="Path 517"
          d="M486.809,127.566c-.092-.276.06-.456.29-.456h.644a.454.454,0,0,1,.474.352l1.393,4.314c.152.476.306,1.148.458,1.775.138-.627.308-1.3.46-1.775l1.393-4.314a.488.488,0,0,1,.474-.352h.644c.26,0,.382.152.29.456L491,134.578a.546.546,0,0,1-.5.412h-.857a.534.534,0,0,1-.506-.4Z"
          transform="translate(-438.865 -123.628)"
          fill={color}
        />
        <path
          id="Path_518"
          data-name="Path 518"
          d="M523.221,129.42a2.942,2.942,0,0,1,3.031-3.214,2.912,2.912,0,0,1,3.045,2.922v1.24a.363.363,0,0,1-.382.384h-4.286v.46c0,1.407.69,2.035,1.837,2.035a6.908,6.908,0,0,0,1.729-.248c.244-.076.428.016.474.232l.108.476a.391.391,0,0,1-.306.5,7.07,7.07,0,0,1-2.005.276,3.015,3.015,0,0,1-3.244-3.274Zm4.668.228v-.52a1.578,1.578,0,0,0-1.637-1.683c-.949,0-1.623.644-1.623,1.867v.336Z"
          transform="translate(-467.253 -122.924)"
          fill={color}
        />
      </g>
      <path
        id="Path_519"
        data-name="Path 519"
        d="M347.492,127.1H346.1v5.609a1.494,1.494,0,0,1-2.968-.012l.013-5.6-1.452-.024v5.639a2.71,2.71,0,0,0,2.925,2.559,3.061,3.061,0,0,0,1.483-.416v1.905a1.38,1.38,0,0,1-1.483,1.319l-.8-.13-.457,1.186,1.259.183a2.723,2.723,0,0,0,2.877-2.559v-3.949h-.023c0-.035.023-.064.023-.1Z"
        transform="translate(-171.776 -12.519)"
        fill={color}
      />
      <path
        id="Path_520"
        data-name="Path 520"
        d="M137.3,117.513l-.881-1.5a.215.215,0,0,0-.37,0l-.51.862a.215.215,0,0,0,0,.218l.3.516a.994.994,0,0,1,0,1l-2.82,4.819a.994.994,0,0,1-.858.492H126.5a.994.994,0,0,1-.858-.492l-2.82-4.819a.994.994,0,0,1,0-1l2.82-4.819a.994.994,0,0,1,.858-.492h5.664a.994.994,0,0,1,.858.492l.323.551a.073.073,0,0,0,.126,0l.636-1.061a.215.215,0,0,0,0-.219l-.278-.475a1.173,1.173,0,0,0-1.012-.58h-6.972a1.173,1.173,0,0,0-1.012.58l-3.472,5.933a1.173,1.173,0,0,0,0,1.185l3.472,5.933a1.172,1.172,0,0,0,1.012.58h6.972a1.172,1.172,0,0,0,1.012-.58L137.3,118.7A1.173,1.173,0,0,0,137.3,117.513Z"
        transform="translate(0)"
        fill={color}
      />
      <path
        id="Path_521"
        data-name="Path 521"
        d="M170.564,118.7l.881,1.5a.215.215,0,0,0,.37,0l.51-.862a.215.215,0,0,0,0-.218l-.3-.516a.994.994,0,0,1,0-1l2.82-4.819a.994.994,0,0,1,.858-.492h5.664a.994.994,0,0,1,.858.492l2.82,4.819a.994.994,0,0,1,0,1l-2.82,4.819a.994.994,0,0,1-.858.492H175.7a.994.994,0,0,1-.858-.492l-.323-.551a.073.073,0,0,0-.126,0l-.636,1.061a.215.215,0,0,0,0,.219l.278.475a1.173,1.173,0,0,0,1.012.58h6.972a1.172,1.172,0,0,0,1.012-.58L186.5,118.7a1.173,1.173,0,0,0,0-1.185l-3.472-5.933a1.173,1.173,0,0,0-1.012-.58h-6.972a1.173,1.173,0,0,0-1.012.58l-3.472,5.933A1.173,1.173,0,0,0,170.564,118.7Z"
        transform="translate(-38.333)"
        fill={color}
      />
    </g>
  </svg>
);

export default function PayactiveHeaderLogo(props) {
  const { color = '#fff' } = props;

  return <SvgIcon color={color} />;
}
