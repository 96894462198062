import { Data, Headers } from 'react-csv/components/CommonPropTypes';
import { Invoice } from '../../../types/Invoice/Invoice';
import { TFunction } from 'i18next';

export type ExportData = {
  data: Data;
  headers: Headers;
};
const createCsvStructure = (invoices: Invoice[], t: TFunction) => {
  const csvData: ExportData = {
    headers: [
      `${t('invoices:creation date')}`,
      `${t('invoices:invoice number')}`,
      `${t('invoices:invoice state')}`,
      `${t('invoices:amount')}`,
      `${t('invoices:debtor mail address')}`,
      `${t('invoices:debtor firstname')}`,
      `${t('invoices:debtor name')}`,
      `${t('invoices:debtor company name')}`,
      `${t('invoices:payment state')}`,
      `${t('invoices:payment method')}`,
    ],
    data: [],
  };

  invoices.forEach((invoice: any) => {
    csvData.data.push([
      invoice.createdDateTimeUTC,
      invoice.invoiceNumber,
      invoice.status,
      invoice.total,
      invoice.debitor.emailAddress,
      invoice.debitor.firstName,
      invoice.debitor.lastName,
      invoice.debitor.companyName,
      invoice.paymentStatus,
      invoice.paymentMethod,
    ]);
  });
  return csvData;
};

export { createCsvStructure };
