import { Debtor } from '../Debtor/Debtor';
import { CompanyAccount } from '../Company/Company';

export interface Credit {
  id: string;
  customer: Debtor;
  companyAccount: CompanyAccount;
  creditMethod: Credits.CreditMethod;
  amount: number;
  purpose: string;
  creditStatus: Credits.State;
  createdDateTimeUTC: Date;
}

export namespace Credits {
  export enum State {
    CREATING = 'CREATING',
    PENDING = 'PENDING',
    SENT = 'SENT',
    ERROR = 'ERROR',
  }

  export enum CreditMethod {
    SEPA_CREDIT_TRANSFER = 'SEPA_CREDIT_TRANSFER',
  }

  export enum CreditEventType {
    CREATED = 'CREATED',
    PENDING = 'PENDING',
    SENT = 'SENT',
    ERROR = 'ERROR',
  }
}
