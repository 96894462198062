import { Debtor } from '../../../types/Debtor/Debtor';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Form } from 'formik';
import DebtorsAutoSuggest from '../../../components/DebtorsAutoSuggest';
import { FormikInputField, FormikSizedInputField } from '@payactive/app-common';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateFilter from '../../../components/filter/DateFilter';
import { IMaskInput } from 'react-imask';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import IbanMasks, { IbanMask } from './IbanMasks';
import { FormikErrors } from 'formik/dist/types';
import { AnyObject } from 'yup/es/types';

type SEPAMandateFormProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<AnyObject>>;
  debtor: Debtor;
  values: any;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const IbanMaskedField = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const [matchedMasks, setMatchedMasks] = useState<Map<string, any>>(new Map<string, any>());
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask={IbanMasks}
      placeholderChar="_"
      lazy={false}
      name="iban"
      inputRef={ref as any}
      onAccept={(value: any) => {
        return onChange({ target: { name: 'iban', value } });
      }}
      dispatch={function (appended, dynamicMasked) {
        const input = dynamicMasked.value + appended;
        const number = input.length > 1 ? input.substring(0, 2) : '__';
        if (matchedMasks.has(number)) {
          return matchedMasks.get(number);
        } else {
          const mask = dynamicMasked.compiledMasks.find(function (m) {
            return number.indexOf((m as unknown as IbanMask).startsWith) === 0;
          });
          setMatchedMasks(matchedMasks.set(number, mask));
          return mask;
        }
      }}
    />
  );
});
const SEPAMandateForm = ({ setFieldValue, debtor, values }: SEPAMandateFormProps) => {
  const { t } = useTranslation('sepa');

  return (
    <Form>
      <Grid container direction={'column'} spacing={2}>
        <Grid item>
          <DebtorsAutoSuggest
            setLocalFieldValue={setFieldValue}
            debtor={debtor}
            directDebitActive={false}
            disabled={false}
            setIsDisabled={() => {}}
          />
        </Grid>
        <Grid item>
          <FormikInputField
            name="iban"
            label={t('IBAN')}
            icon={'payment'}
            value={values.iban}
            onChange={(e) => setFieldValue('iban', e.target.value)}
            InputProps={{ inputComponent: IbanMaskedField as any }}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormikSizedInputField
            max={11}
            name="bic"
            icon={'approval'}
            label={t('BIC')}
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={values.bic}
          />
        </Grid>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateFilter
              onChange={(date) => setFieldValue('signatureDate', date)}
              label={t('signature date')}
              value={values.signatureDate}
            ></DateFilter>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid>
          <FormControlLabel
            value={values.isChecked}
            control={
              <Checkbox
                onChange={(e) => {
                  setFieldValue('isChecked', e.target.checked);
                }}
              />
            }
            label={t('agreement')}
            labelPlacement="end"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

export default SEPAMandateForm;
