import { Credit } from '../../../types/Credit/Credit';
import { CreditEvent } from '../../../types/Credit/CreditEvent';
import { TFunction } from 'react-i18next';
import { EventStep } from '../../../types/EventStep';
import PaymentIcon from '@material-ui/icons/Payment';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import EventAvatar from '../../../components/EventAvatar';
import React from 'react';

const eventsToSteps = (credit: Credit, events: CreditEvent[], t: TFunction<'credits', undefined>) => {
  const steps = [] as EventStep[];
  const icon = <PaymentIcon fontSize={'small'} />;

  events.sort((a, b) => {
    return new Date(a.eventDateTimeUTC).getTime() - new Date(b.eventDateTimeUTC).getTime();
  });

  events.forEach((event) => {
    const step: EventStep = {
      count: 1,
      label: formatDateTime(new Date(event.eventDateTimeUTC)),
      content: event.eventType,
      paymentId: event.creditId,
      reason: undefined,
      reasonMessage: undefined,
      final: false,
      error: false,
      datetime: new Date(event.eventDateTimeUTC),
      relatedObject: credit.id,
      icon: <EventAvatar>{icon}</EventAvatar>,
    };

    switch (event.eventType) {
      case 'CREATED':
        step.content = <span>{t('credits:credit created')}</span>;
        break;
      case 'PENDING':
        step.content = <span>{t('credits:credit pending')}</span>;
        break;
      case 'SENT':
        step.content = <span>{t('credits:credit sent')}</span>;
        break;
      case 'ERROR':
        step.content = <span>{t('credits:credit error')}</span>;
        break;
      default:
        break;
    }

    if (step.final && !step.error) {
      step.icon = <EventAvatar style={{ backgroundColor: 'green' }}>{icon}</EventAvatar>;
    } else if (step.final && step.error) {
      step.icon = <EventAvatar style={{ backgroundColor: 'red' }}>{icon}</EventAvatar>;
    }

    if (step.count > 1) {
      step.label = step.label + ' (' + step.count + ' in total)';
    }

    steps.push(step);
  });

  return steps;
};
export { eventsToSteps };
