import { Paper, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { default as React, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PayactiveLogoWithText from '../PayactiveLogo/PayactiveLogo';
import ApiClient from '../../services/ApiClient';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

//TODO move API call to Services
// to TypeScript
// unused States
// combine imports
export default function SignUpForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [emailAddress, setEmailAddress] = useState('');
  const [open, setOpen] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const [signUpError, setSignUpError] = useState(null);

  const handleClose = (evt) => {
    setOpen(false);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setSigningIn(true);
    setSignUpError(null);
    axios({
      method: 'post',
      url: ApiClient.apiDomain + '/sandbox/sign-up',
      data: {
        emailAddress: emailAddress,
      },
    }).then(
      (res) => {
        setSigningIn(false);
        if (res.status === 201) {
          setOpen(true);
        }
      },
      (err) => {
        setSignUpError(err.response.data.message);
        setSigningIn(false);
      },
    );
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Grid container spacing={24} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        <Grid item xm={3} style={{ width: '100%' }}>
          <PayactiveLogoWithText />
          <Paper className={classes.paper} elevation={2}>
            <Typography variant="h5">{t('headline')}</Typography>
            <Typography variant="subject">{t('tagline')}</Typography>

            <form className={classes.form} onSubmit={handleSubmit} validate>
              {signingIn && <CircularProgress color="secondary" />}
              {signUpError && <Alert severity="error">{signUpError}</Alert>}

              <TextField
                variant="outlined"
                type="email"
                margin="normal"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                required
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                autoComplete="email"
                autoFocus
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />

              {/*                             <TextField
                                value={emailAddress}
                                variant="outlined"
                                type="email"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={t('email')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={e => setEmailAddress(e.target.value)}
                            /> */}

              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} disabled={signingIn}>
                {t('button')}
              </Button>
            </form>
          </Paper>

          <Link href="/login" variant="body1">
            {t('loginref')}
          </Link>
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity="success">
          You received an E-Mail. Check your inbox.
        </Alert>
      </Snackbar>
    </Container>
  );
}
