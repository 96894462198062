import { TFunction } from 'i18next';
import * as Yup from 'yup';

const createSchemas = (t: TFunction) => {
  return {
    0: Yup.object().shape({
      emailAddress: Yup.string()
        .max(150)
        .email()
        .required(t('required', { field: t('emailAddress') })),
      firstName: Yup.string().when('type', {
        is: 'PERSON',
        then: Yup.string()
          .max(50)
          .required(t('required', { field: t('firstName') })),
      }),
      lastName: Yup.string().when('type', {
        is: 'PERSON',
        then: Yup.string()
          .max(50)
          .required(t('required', { field: t('lastName') })),
      }),
      companyName: Yup.string().when('type', {
        is: 'ORGANIZATION',
        then: Yup.string()
          .nullable()
          .max(250)
          .required(t('required', { field: t('ORGANIZATION') })),
      }),
      address: Yup.object().shape({
        zipCode: Yup.string().matches(/^(?:\d{5}|\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{2}\s\d{2})$/, t('only numbers')),
      }),
    }),
    1: Yup.object().shape({
      emailAddress: Yup.string().max(150).email().required('E-Mail Address is required'),
      address: Yup.object().shape({
        zipCode: Yup.string().matches(/^\d+$/, t('only numbers')),
      }),
    }),
  };
};

export { createSchemas };
