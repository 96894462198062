import React, { ReactElement, ReactNode } from 'react';
import { TFunction } from 'i18next';
import { Checkout } from '../../../types/Checkout/Checkout';
import CheckoutDetailsAction from './CheckoutDetailsAction';

const getActionsForCheckout = (checkout: Checkout, setMenuAnchor: (x: any) => void, t: TFunction, cardView = false): ReactNode[] | undefined => {
  const actions: ReactNode[] = [];

  if (!cardView) {
    actions.push(<CheckoutDetailsAction checkout={checkout} setMenuAnchor={setMenuAnchor} label={t('Details')} />);
  }
  if (actions.length === 0) {
    return undefined;
  }
  return actions;
};

export type CheckoutActionType = { checkout: Checkout; setMenuAnchor: (x: any) => void; label: string | ReactElement };

export { getActionsForCheckout };
