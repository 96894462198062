import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

type TableHeaderProps = {
  columns: {
    label: string;
    id: string;
  }[];
  actions: Boolean;
};

const TableHeader = ({ columns, actions }: TableHeaderProps) => {
  const { t } = useTranslation('global');
  return (
    <TableHead>
      <TableRow>
        <>
          {columns.map((column) => (
            <TableCell key={column.id}>{column.label}</TableCell>
          ))}
          {actions && <TableCell>{t('actions')}</TableCell>}
        </>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
