import { useParams } from 'react-router-dom';
import CorrelationService from '../../services/CorrelationService';
import { useEffect, useState } from 'react';

const CorrelationsView = () => {
  const { id } = useParams<{ id: string }>();
  const [correlationItems, setCorrelationItems] = useState<string>();

  useEffect(() => {
    CorrelationService.getCorrelationItems(id).then((value) => setCorrelationItems(value));
  }, []);
  return (
    <div style={{ textAlign: 'left', margin: '100px' }}>
      <pre>{JSON.stringify(correlationItems, null, 4)}</pre>
    </div>
  );
};

export default CorrelationsView;
