import React, { PropsWithChildren, useState } from 'react';

const AddressContext = React.createContext<{ isAddress: boolean; updateIsAddress: (b: boolean) => void }>({
  isAddress: false,
  updateIsAddress: () => {},
});

export default function WithActivePaymentMethodContext({ children }: PropsWithChildren<{}>) {
  const [isAddress, setIsAddress] = useState(false);

  const updateIsAddress = (isAddress: boolean) => {
    setIsAddress(isAddress);
  };

  return (
    <AddressContext.Provider
      value={{
        isAddress: isAddress,
        updateIsAddress: updateIsAddress,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export { AddressContext };
