import { AxiosInstance } from 'axios';
import ApiClient from './ApiClient';
import { authService } from './AuthService';
import { DonationReceiptPDF } from '../types/Payment/DonationReceiptPDF';

export default class DonationService extends ApiClient {
  private static get donationClient(): AxiosInstance {
    return this.client;
  }

  public static async downloadDonation(paymentId: string) {
    const headers = {
      Authorization: 'Bearer ' + authService.accessToken,
      'Content-Type': 'application/pdf; charset=utf-8',
      Accept: 'application/pdf; charset=utf-8',
    };

    let res = await this.donationClient.request<DonationReceiptPDF>({
      method: 'GET',
      url: `${ApiClient.apiDomain}/donations/${paymentId}`,
      headers: headers,
    });
    const content = res.data.content;
    if (content) DonationService.downloadPDF(`${res.data.name}`, content);
  }
}
