import { Checkbox, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormikInputField, FormikSizedInputField, InputField } from '@payactive/app-common';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WebhookService from '../../../services/WebhookService';
import { Webhook } from '../../../types/Webhook/Webhook';

export default function WebhookForm({
  status,
  values,
  setFieldValue,
}: {
  status: any;
  values: Webhook;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}) {
  const { t } = useTranslation('developer');

  const [typesOfWebhooks, setTypesOfWebhooks] = useState<string[]>();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    loadTypesOfWebhooks();
  }, []);

  const loadTypesOfWebhooks = () => {
    WebhookService.getTypesOfWebhooks().then((webhooks) => {
      setTypesOfWebhooks(webhooks);
    });
  };

  return (
    <Fragment>
      {status && <Alert severity="error">{status}</Alert>}
      <FormikInputField icon="link" value={values.url} name="url" label={t('url')} fullWidth />
      <FormikSizedInputField icon="bookmark" value={values.name} name="name" label={t('name')} max={120} fullWidth />
      <br />
      <Typography variant="body1">{t('events to send')}</Typography>
      {typesOfWebhooks && (
        <Autocomplete
          multiple
          id="agent-apps-autocomplete"
          options={typesOfWebhooks}
          disableCloseOnSelect
          onChange={(event, values) => setFieldValue('events', values)}
          getOptionLabel={(webhook) => t(webhook)}
          defaultValue={values.events || null}
          renderOption={(webhook, { selected }) => (
            <React.Fragment>
              <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: 8 }} />
              {`${t(webhook)} - [ ${webhook} ]`}
            </React.Fragment>
          )}
          renderInput={(params) => <InputField icon="person" {...params} variant="standard" name={'events'} label={t('select events')} />}
        />
      )}
    </Fragment>
  );
}
