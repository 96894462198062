import React, { PropsWithChildren, useEffect, useState } from 'react';
import FeatureService from '../../services/FeatureService';
import { ServiceActivations } from '../../types/Feature/ServiceActivation';

const ServiceContext = React.createContext({
  currentActivatedServices: [] as ServiceActivations.Service[],
  currentDeactivatedServices: [] as ServiceActivations.Service[],
  loadServices: () => {},
});

export default function WithServiceContext({ children }: PropsWithChildren<{}>) {
  const [currentActivatedServices, setCurrentActivatedServices] = useState([] as ServiceActivations.Service[]);
  const [currentDeactivatedServices, setCurrentDeactivatedServices] = useState([] as ServiceActivations.Service[]);

  useEffect(() => {
    loadServices();
  }, []);

  const loadServices = () => {
    FeatureService.getServices().then((services) => {
      const activatedServices = [] as ServiceActivations.Service[];
      const deactivatedServices = [] as ServiceActivations.Service[];
      services
        .filter((services) => services.active)
        .forEach((service) => {
          activatedServices.push(service.service);
        });

      services
        .filter((services) => !services.active)
        .forEach((service) => {
          deactivatedServices.push(service.service);
        });
      setCurrentActivatedServices(activatedServices);
      setCurrentDeactivatedServices(deactivatedServices);
    });
  };

  return (
    <ServiceContext.Provider
      value={{
        currentActivatedServices: currentActivatedServices,
        currentDeactivatedServices: currentDeactivatedServices,
        loadServices: loadServices,
      }}
    >
      {children}
    </ServiceContext.Provider>
  );
}

export { ServiceContext };
