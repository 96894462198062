import React, { useContext, useEffect, useState } from 'react';
import { Payment } from '../../../types/Payment/Payment';
import { Avatar, Badge, Icon, IconButton, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StateWithIcon from '../../../components/StateWithIcon';
import { calculateProgress, formatCurrency } from '../../../utils/PaymentUtil';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import LinearProgressWithLabel from '../../../components/ui/blocks/LinearProgressWithLabel';
import { PaymentListDataContext } from '../PaymentListDataContext';
import LinkToDebtorDetailPage from '../../debtorDetailPage/LinkToDebtorDetailPage';
import { useRowStyles } from '../../../utils/TableRowHelper';
import { getActionsForPayment } from '../actions/PaymentActionHelper';
import CompanyAccountService from '../../../services/CompanyAccountService';

type PaymentRowProps = {
  payment: Payment;
  index: number;
  as?: 'tableRow' | 'grid';
};

const PaymentRow = ({ payment, index, as = 'tableRow' }: PaymentRowProps) => {
  const { t } = useTranslation('payments');
  const classes = useRowStyles();

  const isPreFiltered = useContext(PaymentListDataContext).isPrefilteredForDebtor;

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const [actions, setActions] = useState<JSX.Element[]>([
    <div key={payment.id + 'LoadingActionsC'}>
      <MenuItem key={payment.id + 'LoadingActions'}>Loading</MenuItem>
    </div>,
  ]);
  const menuOpen = Boolean(menuAnchor);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };

  useEffect(() => {
    CompanyAccountService.getCompany().then((company) => {
      setActions(getActionsForPayment(payment, setMenuAnchor, t, false, company));
    });
  }, [t, payment]);

  return (
    <TableRow key={index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <>
        <TableCell key={payment.id + 'state'}>
          <StateWithIcon state={payment.state} label={t(payment.state)} />
        </TableCell>
        {!isPreFiltered && (
          <TableCell key={payment.id + 'debitor'}>
            <LinkToDebtorDetailPage target={'payments'} debtor={payment.debitor} debtorRef={payment.debitorRef}></LinkToDebtorDetailPage>
          </TableCell>
        )}
        <TableCell key={payment.id + 'purpose'}>{payment.purpose}</TableCell>
        <TableCell key={payment.id + 'amount'}>{formatCurrency(payment.amount)}</TableCell>
        <TableCell key={payment.id + 'paymentMethod'}>{t(payment.paymentMethod)}</TableCell>
        <TableCell key={payment.id + 'creationDate'}>{formatDateTime(payment.createdDateTimeUTC)}</TableCell>
        <TableCell key={payment.id + 'completion'} style={{ minWidth: '200px' }}>
          <LinearProgressWithLabel value={calculateProgress(payment.state)} />
        </TableCell>
        {actions && actions.length !== 0 && (
          <TableCell key={payment.id + 'action'}>
            <Badge badgeContent={0} color="error" overlap="circular">
              <IconButton onClick={handleClick}>
                <Avatar className={classes.avatar}>
                  <Icon>more_horiz</Icon>
                </Avatar>
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuAnchor(null)}
                PaperProps={{
                  className: classes.menu,
                  style: {
                    //maxHeight: ITEM_HEIGHT * 4.5,
                    width: 'min-content',
                  },
                }}
              >
                {actions}
              </Menu>
            </Badge>
          </TableCell>
        )}
      </>
    </TableRow>
  );
};

export default PaymentRow;
