import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import PageHeader from '../../components/ui/blocks/PageHeader';
import InstalmentPlanListDataContextProvider, { InstalmentPlanListDataContext } from './InstalmentPlanListDataContext';
import InstalmentPlansTable from './table/InstalmentPlansTable';
import { SplitButton } from '@payactive/app-common';
import GenericTableFilters, { StateFilter } from '../../components/filter/GenericTableFilters';
import CreateInstalmentPlanDialog from './dialogs/CreateInstalmentPlanDialog';
import InstalmentPlanImportDialog from './import/InstalmentPlanImportDialog';
import { InstalmentPlans } from '../../types/InstalmentPlan/InstalmentPlan';

const InstalmentPlansView = () => {
  const { t } = useTranslation(['global', 'instalmentPlans']);
  const filterArray: StateFilter[] = [
    { name: t('instalmentPlans:DRAFT'), value: InstalmentPlans.Status.DRAFT },
    { name: t('instalmentPlans:RUNNING'), value: InstalmentPlans.Status.RUNNING },
    { name: t('instalmentPlans:COMPLETED'), value: InstalmentPlans.Status.COMPLETED },
    //{ name: t('instalmentPlans:WAITING_FOR_COMPLETION'), value: InstalmentPlans.Status.WAITING_FOR_COMPLETION },
    { name: t('instalmentPlans:CANCELLED'), value: InstalmentPlans.Status.CANCELLED },
  ];

  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [instalmentPlanImportDialog, setInstalmentPlanImportDialog] = useState(false);

  const onInstalmentPlanCreationCanceled = () => {
    setShowCreateDialog(false);
  };
  let newInstalmentPlanTitle = t('instalmentPlans:create instalment plan');
  return (
    <InstalmentPlanListDataContextProvider pageSizeOptions={[5, 10, 30]}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeader title={t('instalmentPlans:instalment plans')} subtitle={''} />
        </Grid>
        <Grid item style={{ zIndex: 1 }}>
          <SplitButton
            onClick={() => setShowCreateDialog(true)}
            label={newInstalmentPlanTitle}
            name={newInstalmentPlanTitle}
            onOptionClick={() => setInstalmentPlanImportDialog(true)}
            options={[{ id: 'import_instalmentPlan', name: t('payments:import.importinstalmentplanrequests') }]}
          />
        </Grid>
      </Grid>
      <Grid>
        <GenericTableFilters states={filterArray} context={InstalmentPlanListDataContext} t={t} />
      </Grid>
      <InstalmentPlansTable showPagination={true} />
      <InstalmentPlanListDataContext.Consumer>
        {({ reload }) => (
          <>
            {showCreateDialog && (
              <CreateInstalmentPlanDialog
                onSuccess={() => {
                  setShowCreateDialog(false);
                  reload();
                }}
                isOpen={true}
                onCancel={onInstalmentPlanCreationCanceled}
                debtor={undefined}
              />
            )}
            {instalmentPlanImportDialog && (
              <InstalmentPlanImportDialog
                open={true}
                onClose={() => {
                  setInstalmentPlanImportDialog(false);
                }}
                onSuccess={() => {
                  setInstalmentPlanImportDialog(false);
                  reload();
                }}
              />
            )}
          </>
        )}
      </InstalmentPlanListDataContext.Consumer>
    </InstalmentPlanListDataContextProvider>
  );
};

export default InstalmentPlansView;
