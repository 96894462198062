import React from 'react';
import { InvoiceTable } from '../../invoices/table/InvoiceTable';
import { InvoiceListDataContext } from '../../invoices/InvoiceListDataContextProvider';
import PaginationProvider from '../../../components/table/PaginationContext';

export default function InvoicesTab() {
  return (
    <InvoiceListDataContext.Consumer>
      {({ currentPage, pageSize, page }) => (
        <>
          <PaginationProvider
            pageSizeOptions={[5, 10, 30]}
            currentPage={currentPage.get}
            totalPages={page?.totalPages || 0}
            pageSize={pageSize.get}
            setCurrentPage={currentPage.set}
            changePageSize={pageSize.set}
          >
            <InvoiceTable showPagination={true} />
          </PaginationProvider>
        </>
      )}
    </InvoiceListDataContext.Consumer>
  );
}
