import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';

type DateFilterProps = {
  startDate?: string | null;
  onChange: (date: any) => void;
  label: string;
  value: string;
  disablePast?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  dateItem: {
    marginLeft: '100px',
  },
  resetButtom: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '5px',
  },
}));

const DateFilter = ({ startDate, onChange, label, value, disablePast, disabled = false }: DateFilterProps) => {
  const classes = useStyles();

  const { t } = useTranslation('global');

  const [dateFilterOn, setDateFilterOn] = useState(false);
  const handleDateChange = (date: any) => {
    onChange(date);
  };

  let minDate = startDate ? new Date(startDate) : undefined;
  minDate && minDate.setDate(minDate.getDate() + 1);

  return (
    <KeyboardDatePicker
      disabled={disabled}
      minDate={minDate}
      minDateMessage={minDate && t('end date bigger than start date')}
      variant="inline"
      format="dd.MM.yyyy"
      id="date-picker-inline"
      label={label}
      disablePast={disablePast}
      value={value === '' ? null : value}
      onChange={handleDateChange}
      InputProps={{ readOnly: true }}
      KeyboardButtonProps={{
        onFocus: () => {
          setDateFilterOn(true);
        },

        'aria-label': 'change date',
      }}
      PopoverProps={{
        disableRestoreFocus: true,
        onClose: () => {
          setDateFilterOn(false);
        },
      }}
      ToolbarComponent={(props: ToolbarComponentProps) => {
        return (
          <Grid className={classes.resetButtom}>
            <Button variant="text" name={t('reset')} onClick={() => handleDateChange(null)}>
              {t('reset')}
            </Button>
          </Grid>
        );
      }}
      open={dateFilterOn}
    />
  );
};

export default DateFilter;
