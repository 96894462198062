import Grid from '@material-ui/core/Grid';
import { Button } from '@payactive/app-common';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../components/ui/blocks/DataTable';
import PageHeader from '../../../components/ui/blocks/PageHeader';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import CreateApiKeyDialog from './CreateApiKeyDialog';
import DeleteApiKeyDialog from './DeleteApiKeyDialog';
import ApiKeyService from '../../../services/ApiKeyService';
//TODO to TypeScript
// remove datatable relic
export default function ApiKeys() {
  const alerting = useContext(AlertContext);

  const [apiKeys, setApiKeys] = useState([]);
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const [deleteApiKey, setDeleteApiKey] = useState(null);

  const { t } = useTranslation('developer');

  useEffect(() => {
    updateApiKeys();
  }, []);

  const updateApiKeys = () => {
    ApiKeyService.getApiKeys().then((apiKeys) => {
      setApiKeys(apiKeys);
    });
  };

  const close = () => {
    setShowCreateDialog(false);
    updateApiKeys();
  };

  const handleDeleteKey = (apiKey) => {
    ApiKeyService.deleteApiKey(apiKey.prefix).then((res) => {
      if (res) {
        updateApiKeys();
        alerting.sendAlert('success', t('api key deleted'));
      }
      setDeleteApiKey(null);
    });
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader title={t('apikeys')} />
        <Button onClick={() => setShowCreateDialog(true)} label={t('create api key')} name="create" disabled={false} loading={false} />
      </Grid>

      <DataTable
        columns={[
          {
            label: t('apikeyname'),
            id: 'name',
          },
          {
            label: t('prefix'),
            id: 'prefix',
          },
        ]}
        rows={apiKeys}
        showPagination={false}
        actions={[{ id: 'delete', label: t('delete') }]}
        onAction={(action, row) => setDeleteApiKey(row)}
      />
      {deleteApiKey && <DeleteApiKeyDialog apiKey={deleteApiKey} onClose={() => setDeleteApiKey(null)} onDelete={handleDeleteKey} open={true} />}
      {showCreateDialog && <CreateApiKeyDialog visible={showCreateDialog} onClose={close} />}
    </React.Fragment>
  );
}
