import { Link } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertContext } from '../../components/contexts/WithAlertContext';
import { useTranslation } from 'react-i18next';
import DebtorService from '../../services/DebtorService';
import { Debtor } from '../../types/Debtor/Debtor';
import { getDebtorLabel } from '../../utils/LabelHelper';

type DetailPageLinkProps = {
  debtorRef?: string;
  debtor: { id: string; emailAddress: string; lastName: string; firstName: string } | Debtor;
  target: 'payments' | 'credits' | 'invoices' | 'subscriptions' | 'instalmentPlans' | 'creditNotes';
  text?: string;
};

const LinkToDebtorDetailPage = ({ debtorRef, debtor, target, text }: DetailPageLinkProps) => {
  const history = useHistory();
  const alerting = useContext(AlertContext);
  const { t } = useTranslation(['payments', 'debitors']);

  let detailPageLink;
  if (debtorRef) {
    detailPageLink = `/dashboard/debtors/${debtorRef}/${debtor.emailAddress}/${target}`;
  }

  const handleLink = (event: React.MouseEvent) => {
    event.preventDefault();
    if (debtorRef) {
      history.push(`/dashboard/debtors/${debtorRef}/${debtor.emailAddress}/${target}`);
    } else {
      //TODO maybe search by email?
      DebtorService.search(debtor.lastName).then((res) => {
        let debtors = res.results.filter((res) => res.emailAddress === debtor.emailAddress);
        if (debtors?.length > 0) {
          history.push(`/dashboard/debtors/${debtors[0].id}`);
        } else {
          alerting.sendAlert('warning', t('debitor does not exist'));
        }
      });
    }
  };

  return (
    <Link href={detailPageLink} onClick={handleLink}>
      {text || getDebtorLabel(debtor) || `(${t('debitors:debitor deleted')})`}
    </Link>
  );
};

export default LinkToDebtorDetailPage;
