import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    backgroundColor: 'red',
    color: 'white',
    padding: theme.spacing(1),
  },
}));

export default function Version({ version }: { version: string }) {
  const classes = useStyles();
  return <>{process.env.version && <Box className={classes.root}>Build# {version}</Box>}</>;
}
