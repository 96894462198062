import { Grid, Paper, Snackbar } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import MailIcon from '@material-ui/icons/Mail';
import ShortTextIcon from '@material-ui/icons/ShortText';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PayactiveLogoWithText from '../PayactiveLogo/PayactiveLogo';
import { authService } from '../../services/AuthService';
import ApiClient from '../../services/ApiClient';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
//TODO move API Request to Services
// to TypeScript
// unused States
// combine imports
export default function RegistrationForm() {
  const { t } = useTranslation();

  const classes = useStyles();
  const location = useLocation();

  // Form handling
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => {
    data.invitationId = invitationId;
    authService.register(
      data,
      () => {
        setRegistrationSuccess(true);
      },
      () => {
        setError(true);
      },
    );
  };

  // States
  const [emailAddress, setEmailAddress] = useState('');
  const [invitationId] = useState(() => {
    return qs.parse(location.search, { ignoreQueryPrefix: true }).invitationId;
  });
  const [error, setError] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  // On mount
  useEffect(() => {
    axios({
      method: 'GET',
      url: ApiClient.apiDomain + '/registration/invitation/' + invitationId,
    }).then((res) => {
      setEmailAddress(res.data.to);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <Grid container spacing={24} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        <Grid item xm={3}>
          <PayactiveLogoWithText />
          <Paper className={classes.paper} elevation={2}>
            <Typography variant="h5">Register</Typography>
            <Typography variant="subject">your new payactive user account</Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              {error && <Alert severity="error">Something went wrong</Alert>}

              <TextField
                variant="outlined"
                margin="normal"
                value={emailAddress}
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
                disabled={true}
              />
              <TextField
                inputRef={register}
                variant="outlined"
                required
                margin="normal"
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ShortTextIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                inputRef={register}
                variant="outlined"
                required
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ShortTextIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                inputRef={register}
                variant="outlined"
                required
                margin="normal"
                fullWidth
                id="password"
                type="password"
                label="Password"
                name="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && errors.passwordConfirmation.type === 'minLength' && 'Passwords need to be at least 8 chars long'}

              <TextField
                inputRef={register}
                variant="outlined"
                required
                margin="normal"
                fullWidth
                type="password"
                id="passwordConfirmation"
                label="Password Confirmation"
                name="passwordConfirmation"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.passwordConfirmation && errors.passwordConfirmation.type === 'minLength' && 'Passwords need to be at least 8 chars long'}
              {errors.passwordConfirmation && errors.passwordConfirmation.type === 'validate' && 'Passwords need to match'}

              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                Register
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={registrationSuccess}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success">Your account was successfully created</Alert>
      </Snackbar>
    </Container>
  );
}
