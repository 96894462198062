import { Avatar, Badge, Icon, IconButton, makeStyles, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import StateWithIcon from '../../../components/StateWithIcon';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { InstalmentPlan, InstalmentPlans } from '../../../types/InstalmentPlan/InstalmentPlan';
import { InstalmentPlanListDataContext } from '../InstalmentPlanListDataContext';
import { formatDate, formatDateTime } from '../../../utils/DateTimeUtil';
import { calculateOpenAmount, calculateRateInterval } from '../../../utils/InstalmentPlanHelperFunctions';
import DeleteInstalmentPlanDialog from '../dialogs/DeleteInstalmentPlanDialog';
import CancelInstalmentPlanDialog from '../dialogs/CancelInstalmentPlanDialog';
import InstalmentPlanDetailsDialog from '../dialogs/InstalmentPlanDetailsDialog';
import StartInstalmentPlanDialog from '../dialogs/StartInstalmentPlanDialog';
import EditInstalmentPlanDialog from '../dialogs/EditInstalmentPlanDialog';
import CustomiseInstalmentPlanDialog from '../dialogs/CustomiseInstalmentPlanDialog';
import LinkToDebtorDetailPage from '../../debtorDetailPage/LinkToDebtorDetailPage';

type InstalmentPlanRowProps = {
  instalmentPlan: InstalmentPlan;
  index: number;
};

const useStyles = makeStyles((theme) => ({
  even: {
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    whiteSpace: 'nowrap',
  },
  odd: {
    border: 'none',
    backgroundColor: theme.palette.background.default,
    whiteSpace: 'nowrap',
  },
  select: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  icon: {
    color: '#FFFFFF',
  },
  menu: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ITEM_HEIGHT = 48;

const InstalmentPlanRow = ({ instalmentPlan, index }: InstalmentPlanRowProps) => {
  const { t } = useTranslation('instalmentPlans');

  const classes = useStyles();

  const [instalmentPlanToCancel, setInstalmentPlanToCancel] = useState(false);
  const [instalmentPlanToView, setInstalmentPlanToView] = useState(false);
  const [instalmentPlanToDelete, setInstalmentPlanToDelete] = useState(false);
  const [instalmentPlanToStart, setInstalmentPlanToStart] = useState(false);
  const [instalmentPlanToEdit, setInstalmentPlanToEdit] = useState(false);
  const [instalmentPlanToCustomize, setInstalmentPlantToCustomize] = useState(false);

  const isPreFiltered = useContext(InstalmentPlanListDataContext).isPrefilteredForDebtor;

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const menuOpen = Boolean(menuAnchor);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleShowViewDetailsDialog = () => {
    setInstalmentPlanToView(true);
  };
  const handleShowDeleteInstalmentPlanDialog = () => {
    setInstalmentPlanToDelete(true);
  };
  const handleShowCancelInstalmentPlanDialog = () => {
    setInstalmentPlanToCancel(true);
  };
  const handleStartCancelInstalmentPlanDialog = () => {
    setInstalmentPlanToStart(true);
  };
  const handleShowEditInstalmentPlanDialog = () => {
    setInstalmentPlanToEdit(true);
  };
  const handleShowCustomizeInstalmentDialog = () => {
    setInstalmentPlantToCustomize(true);
  };
  const actions: Action[] = [
    { id: 'viewDetails', label: t('view details'), callback: handleShowViewDetailsDialog },
    { id: 'customize', label: t('customize instalment plan'), callback: handleShowCustomizeInstalmentDialog },
    { id: 'edit', label: t('edit'), callback: handleShowEditInstalmentPlanDialog },
    { id: 'start', label: t('start instalment plan'), callback: handleStartCancelInstalmentPlanDialog },
    { id: 'delete', label: t('delete'), callback: handleShowDeleteInstalmentPlanDialog },
    { id: 'cancel', label: t('cancel'), callback: handleShowCancelInstalmentPlanDialog },
  ];

  const filterActions = (action: Action) => {
    switch (action.id) {
      case 'cancel':
      case 'customize':
        return [InstalmentPlans.Status.RUNNING].includes(instalmentPlan.status);
      case 'delete':
      case 'start':
      case 'edit':
        return [InstalmentPlans.Status.DRAFT].includes(instalmentPlan.status);
      case 'viewDetails':
        return true;
      default:
        return false;
    }
  };
  return (
    <TableRow key={index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <TableCell key={instalmentPlan.id + 'status'}>{<StateWithIcon state={instalmentPlan.status} label={t(instalmentPlan.status)} />}</TableCell>
      {!isPreFiltered && (
        <TableCell key={instalmentPlan.id + 'debtor'}>
          <LinkToDebtorDetailPage target={'instalmentPlans'} debtor={instalmentPlan.debtor} debtorRef={instalmentPlan.debtor.id}></LinkToDebtorDetailPage>
        </TableCell>
      )}
      <TableCell key={instalmentPlan.id + 'purpose'}>{instalmentPlan.purpose}</TableCell>
      <TableCell key={instalmentPlan.id + 'amount'}>{formatCurrency(instalmentPlan.totalNetAmount)}</TableCell>
      <TableCell key={instalmentPlan.id + 'duration'}>{`${calculateRateInterval(instalmentPlan.instalmentRates, 0)} ${t(
        'duration in months',
      )}`}</TableCell>
      <TableCell key={instalmentPlan.id + 'rate'}>{`${formatCurrency(instalmentPlan.intervalAmount)}`}</TableCell>
      <TableCell key={instalmentPlan.id + 'open amount'}>{`${formatCurrency(calculateOpenAmount(instalmentPlan))}`}</TableCell>
      <TableCell key={instalmentPlan.id + 'nextPaymentDate'}>
        {!!instalmentPlan.nextPaymentDueDate ? formatDate(instalmentPlan.nextPaymentDueDate) : '--'}
      </TableCell>
      <TableCell key={instalmentPlan.id + 'creationDate'}>{formatDateTime(instalmentPlan.createdDateTime)}</TableCell>
      {!!actions && (
        <TableCell align="left">
          <Badge badgeContent={0} color="error" overlap="circular">
            <IconButton onClick={handleClick}>
              <Avatar className={classes.avatar}>
                <Icon>more_horiz</Icon>
              </Avatar>
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuAnchor(null)}
              PaperProps={{
                className: classes.menu,
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 'min-content',
                },
              }}
            >
              {actions.filter(filterActions).map((action) => (
                <MenuItem
                  key={action.id}
                  onClick={() => {
                    setMenuAnchor(null);
                    action.callback();
                  }}
                >
                  {action.label}
                </MenuItem>
              ))}
            </Menu>
          </Badge>
        </TableCell>
      )}
      <InstalmentPlanListDataContext.Consumer>
        {({ reload }) => (
          <>
            {instalmentPlanToDelete && (
              <DeleteInstalmentPlanDialog
                isOpen={true}
                onCancel={() => setInstalmentPlanToDelete(false)}
                onSuccess={() => {
                  setInstalmentPlanToDelete(false);
                  reload();
                }}
                instalmentPlanId={instalmentPlan.id}
              />
            )}
            {instalmentPlanToCancel && (
              <CancelInstalmentPlanDialog
                isOpen={true}
                onCancel={() => setInstalmentPlanToCancel(false)}
                onSuccess={() => {
                  setInstalmentPlanToCancel(false);
                  reload();
                }}
                instalmentPlanId={instalmentPlan.id}
              />
            )}
            {instalmentPlanToStart && (
              <StartInstalmentPlanDialog
                isOpen={true}
                onCancel={() => setInstalmentPlanToStart(false)}
                onSuccess={() => {
                  setInstalmentPlanToStart(false);
                  reload();
                }}
                instalmentPlanId={instalmentPlan.id}
              />
            )}
            {instalmentPlanToEdit && (
              <EditInstalmentPlanDialog
                isOpen={true}
                onCancel={() => setInstalmentPlanToEdit(false)}
                onSuccess={() => {
                  setInstalmentPlanToEdit(false);
                  reload();
                }}
                instalmentPlan={instalmentPlan}
              />
            )}
            {instalmentPlanToCustomize && (
              <CustomiseInstalmentPlanDialog
                isOpen={true}
                onCancel={() => setInstalmentPlantToCustomize(false)}
                instalmentPlan={instalmentPlan}
                onSuccess={() => {
                  setInstalmentPlantToCustomize(false);
                  reload();
                }}
              />
            )}

            {instalmentPlanToView && <InstalmentPlanDetailsDialog onClose={() => setInstalmentPlanToView(false)} instalmentPlan={instalmentPlan} />}
          </>
        )}
      </InstalmentPlanListDataContext.Consumer>
    </TableRow>
  );
};

type Action = {
  id: string;
  label: string;
  callback: () => void;
};

export default InstalmentPlanRow;
