import { MenuItem } from '@material-ui/core';
import { DebtorActionType } from './DebtorActionHelper';
import React, { useState } from 'react';
import CreateInstalmentPlanDialog from '../../instalmentPlans/dialogs/CreateInstalmentPlanDialog';

const DebtorNewInstalmentPlanAction = ({ debtor, setMenuAnchor, label }: DebtorActionType) => {
  const [openDialog, setOpenDialog] = useState(false);

  //reload count

  return (
    <div key={debtor.id + 'newInstalmentC'}>
      <MenuItem
        key={debtor.id + 'newInstalment'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>

      {openDialog && (
        <CreateInstalmentPlanDialog
          onSuccess={() => {
            setOpenDialog(false);
            //reload count
          }}
          isOpen={openDialog}
          debtor={debtor}
          onCancel={() => {
            setOpenDialog(false);
          }}
        ></CreateInstalmentPlanDialog>
      )}
    </div>
  );
};

export default DebtorNewInstalmentPlanAction;
