import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, makeStyles } from '@material-ui/core';
import { InputField } from '@payactive/app-common';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CompanySettings } from '../../../../types/Company/CompanySettings';

const useStyles = makeStyles((theme) => ({
  ac: {
    marginTop: theme.spacing(2),
  },
}));

export default function BccSelector() {
  const { values, setFieldValue } = useFormikContext<CompanySettings.Account>();

  const { t } = useTranslation('company');

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const classes = useStyles();

  return (
    <>
      <Autocomplete
        multiple
        className={classes.ac}
        id="agent-apps-autocomplete"
        options={['INVOICES', 'REMINDERS', 'DUNNING', 'UNCOLLECTABLE', 'DONATIONS']}
        disableCloseOnSelect
        onChange={(event, values) => setFieldValue('bccSelection', values)}
        getOptionLabel={(docType) => t(docType)}
        defaultValue={values.bccSelection}
        renderOption={(d, { selected }) => (
          <React.Fragment>
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: 8 }} />
            {`${t(d)}`}
          </React.Fragment>
        )}
        renderInput={(params) => <InputField name="" {...params} label={t('docTypes')} />}
      />
    </>
  );
}
