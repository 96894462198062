import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface Props {
  info: string;
  label: string;
}

export default function InfoWithLabel({ info, label, ...rest }: Props): ReactElement {
  return (
    <div {...rest}>
      <Typography component="div" color="secondary" variant="caption">
        {label}
      </Typography>
      <Typography component="div" variant="body1">
        {info}
      </Typography>
    </div>
  );
}
