import { Grid, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    textAlign: 'left',
    maxWidth: '600px',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  avatar_small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function PageHeader({ subtitle, title, onBack }: { subtitle?: string; title?: string; onBack?: MouseEventHandler }) {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
      </Grid>
      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      {onBack && (
        <Grid item xs={12}>
          <IconButton color="secondary" aria-label="back" size="small">
            <Icon onClick={onBack}>arrow_back</Icon>
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
