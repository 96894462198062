import { Paper, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useEffect, useState } from 'react';
import { eventsToSteps } from './InvoiceEventsUtil';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  other: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    marginTop: theme.spacing(1),
  },
}));

export default function InvoiceEventsDialog({ open, onCancel, invoice, events }) {
  const { t } = useTranslation('debitors');

  const classes = useStyles();

  const [isOpen, setOpen] = useState(false);
  const [steps, setSteps] = useState([]);
  //TODO refactor: usage of param as state is bad practice
  useEffect(() => {
    setOpen(open); // update the state
    setSteps(eventsToSteps(invoice, events, t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]); // hook is only triggered when value changes

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t('invoice state')}</DialogTitle>
      <DialogContent>
        {events.length > 0 ? (
          <Stepper orientation="vertical" activeStep={steps.length - 1}>
            {steps.map((step, index) => (
              <Step key={step.label + index} active={true}>
                {step.final && !step.error && <StepLabel icon={<CheckCircleIcon style={{ color: 'green' }} />}>{step.label}</StepLabel>}
                {step.final && step.error && <StepLabel icon={<ErrorIcon style={{ color: 'red' }} />}>{step.label}</StepLabel>}
                {!step.final && <StepLabel>{step.label}</StepLabel>}

                <StepContent>
                  {step.content}
                  <br />
                  {step.user}
                  {step.reason && <p>{step.reason}</p>}
                  {step.reasonMessage && (
                    <Paper elevation={0} className={classes.other}>
                      {step.reasonMessage}
                    </Paper>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        ) : (
          <Alert severity="warning">{t('no events')}</Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('global:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
