import { MenuItem } from '@material-ui/core';
import { DebtorActionType } from './DebtorActionHelper';
import React, { useState } from 'react';
import InvoiceDialog from '../../invoices/dialogs/InvoiceDialog';

const DebtorNewInvoiceAction = ({ debtor, setMenuAnchor, label }: DebtorActionType) => {
  const [openDialog, setOpenDialog] = useState(false);

  //reload count

  return (
    <div key={debtor.id + 'newInvoiceC'}>
      <MenuItem
        key={debtor.id + 'newInvoice'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>
      {openDialog && (
        <InvoiceDialog
          invoice={undefined}
          debtor={debtor}
          onClose={() => {
            setOpenDialog(false);
          }}
        ></InvoiceDialog>
      )}
    </div>
  );
};

export default DebtorNewInvoiceAction;
