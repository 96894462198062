import { createContext, Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { StateFilter } from '../../../components/filter/GenericTableFilters';

export const InvoiceExportDataContext = createContext<{
  filters: {
    stateFilter: StateFilter[];
    setStateFilter: Dispatch<SetStateAction<StateFilter[]>>;
    dateStartFilter: string;
    setDateStartFilter: Dispatch<SetStateAction<string>>;
    dateEndFilter: string;
    setDateEndFilter: Dispatch<SetStateAction<string>>;
    used: boolean;
    searchFilter: undefined;
    setSearchFilter: undefined;
  } | null;
}>({ filters: null });

const InvoiceExportDataContextProvider = ({ children }: { children: ReactElement }) => {
  const [stateFilter, setStateFilter] = useState<StateFilter[]>([] as StateFilter[]);
  const [dateStartFilter, setDateStartFilter] = useState<string>('');
  const [dateEndFilter, setDateEndFilter] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [filtersUsed, setFiltersUsed] = useState(false);

  return (
    <InvoiceExportDataContext.Provider
      value={{
        filters: {
          stateFilter: stateFilter,
          setStateFilter: setStateFilter,
          dateStartFilter: dateStartFilter,
          setDateStartFilter: setDateStartFilter,
          dateEndFilter: dateEndFilter,
          setDateEndFilter: setDateEndFilter,
          searchFilter: undefined,
          setSearchFilter: undefined,
          used: filtersUsed,
        },
      }}
    >
      {children}
    </InvoiceExportDataContext.Provider>
  );
};

export default InvoiceExportDataContextProvider;
