import React from 'react';
import { Container, Grid } from '@material-ui/core';

export default function CentricLayout({ children }) {
  return (
    <Container component="main" maxWidth="xs">
      <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
        <Grid item style={{ width: '100%' }}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
}
