import { Payments } from '../types/Payment/Payment';
import { Credits } from '../types/Credit/Credit';

const calculateProgress = (state: Payments.State | Credits.State) => {
  let progress: number;
  switch (state) {
    case Payments.State.PENDING:
    case Credits.State.PENDING:
    case Payments.State.CHARGED_BACK:
    case Payments.State.MANUAL:
    case Payments.State.REFUND_IN_PROGRESS:
      progress = 25;
      break;
    case Payments.State.COMPLETED:
      progress = 80;
      break;
    case Payments.State.ABORTED:
    case Payments.State.ERROR:
    case Credits.State.ERROR:
    case Payments.State.CANCELLED:
    case Payments.State.VERIFIED:
    case Credits.State.SENT:
    case Payments.State.REFUND_COMPLETED:
      progress = 100;
      break;
    default:
      progress = 10;
  }
  return progress;
};

const formatCurrency = (amount: number | undefined) => {
  if (amount === undefined) {
    return 'unknown';
  }
  return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'EUR' }).format(amount);
};

export { calculateProgress, formatCurrency };
