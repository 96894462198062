export type EbicsConnection = {
  id?: string;
  bankAccountId?: string;
  creditorIdentifier?: string;
  withdrawLimit?: number;
  creditorName?: string;
  bankName?: string;
  iban?: string;
  bic?: string;
  readonly status: EbicsConnections.Status;
  userId?: string;
  partnerId?: string;
};

export type RequestEbicsFromBank = {
  creditorName: string;
  withdrawLimit: number;
};

export type InitializeEbicsConnection = { userId: string; partnerId: string };

export namespace EbicsConnections {
  export enum Status {
    SETTING_UP_CREDITOR_IDENTIFIER = 'SETTING_UP_CREDITOR_IDENTIFIER',
    REQUESTING_EBICS_FROM_BANK = 'REQUESTING_EBICS_FROM_BANK',
    INITIALIZING_EBICS = 'INITIALIZING_EBICS',
    SENDING_INI_LETTER = 'SENDING_INI_LETTER',
    WAITING_FOR_ACTIVATION = 'WAITING_FOR_ACTIVATION',
    ACTIVE = 'ACTIVE',
  }
}
