import { Box, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';

export default function LinearProgressWithLabel({ value, fullWidth = false, ...rest }: { value: number; fullWidth?: boolean }) {
  return (
    <Box display="flex" alignItems="center" style={fullWidth ? { width: '100%' } : {}}>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...rest} value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}
