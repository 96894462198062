import { MenuItem } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import DeleteDraftInvoiceDialog from '../dialogs/DeleteDraftInvoiceDialog';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { InvoiceListDataContext } from '../InvoiceListDataContextProvider';
import { InvoiceActionType } from './InvoiceActionHelper';
import InvoiceService from '../../../services/InvoiceService';
import ReloadContext from '../../../components/contexts/ReloadContext';

const InvoiceDeleteAction = ({ invoice, closeMenu, label }: InvoiceActionType) => {
  const [showDelete, setShowDelete] = useState(false);

  const { t } = useTranslation(['debitors', 'invoices']);
  const alerting = useContext(AlertContext);

  const invoiceReload = useContext(InvoiceListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;

  const reload = () => {
    invoiceReload();
    contextReload();
  };
  const deleteDraftInvoice = () => {
    InvoiceService.deleteInvoice(invoice.id).then((res) => {
      if (res) {
        alerting.sendAlert('success', t('successfully deleted'));
        reload();
      } else {
        alerting.sendAlert('error', t('unsuccessfully deleted'));
      }
      setShowDelete(false);
    });
  };

  return (
    <div key={invoice.id + 'deleteC'}>
      <MenuItem
        key={invoice.id + 'delete'}
        onClick={() => {
          closeMenu();
          setShowDelete(true);
        }}
      >
        {label}
      </MenuItem>
      {showDelete && <DeleteDraftInvoiceDialog invoice={invoice} onClose={() => setShowDelete(false)} onDelete={deleteDraftInvoice} open={true} />}
    </div>
  );
};

export default InvoiceDeleteAction;
