import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FormikSizedInputField } from '@payactive/app-common';
import React, { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import PreviewTag from '../../../components/ui/blocks/PreviewTag';
import AddressInformation from '../../settings/masterdata/masterdata/AddressInformation';
import { FormikErrors } from 'formik/dist/types';
import { InvoiceFormValues } from '../../../types/Invoice/Invoice';

interface Props {
  status: string;
  values: any;
  setFieldValue: (field: string, value: string | null) => Promise<void | FormikErrors<InvoiceFormValues>>;
  defaultValue?: string;
  mode: 'create' | 'edit';
}

export default function DebtorManuallyForm({ status, values, setFieldValue, defaultValue, mode }: Props): ReactElement {
  const { t } = useTranslation('debitors');

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={values.type === 'ORGANIZATION'}
                onChange={(e) => setFieldValue('type', e.target.checked ? 'ORGANIZATION' : 'PERSON')}
                name="type"
              />
            }
            label={t('ORGANIZATION')}
          />
        </Grid>

        {mode === 'create' && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.invitationType === 'EMAIL'}
                  onChange={(e) => setFieldValue('invitationType', e.target.checked ? 'EMAIL' : 'MANUAL')}
                  name="invitationType"
                />
              }
              label={t('EMAIL')}
            />

            <PreviewTag />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormikSizedInputField required max={150} name="emailAddress" label={t('E-Mail Address')} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormikSizedInputField
            disabled={values.type !== 'ORGANIZATION'}
            required={values.type === 'ORGANIZATION'}
            max={250}
            name="companyName"
            onChange={(e) => setFieldValue('companyName', values.type === 'ORGANIZATION' ? e.target.value : null)}
            label={t('ORGANIZATION')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSizedInputField
            required={values.type !== 'ORGANIZATION'}
            max={50}
            defaultValue={defaultValue}
            name="firstName"
            label={t('First Name')}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormikSizedInputField required={values.type !== 'ORGANIZATION'} max={50} name="lastName" label={t('Last Name')} fullWidth />
        </Grid>
        <AddressInformation />
        <Grid item xs={12}>
          <FormikSizedInputField disabled={values.type !== 'ORGANIZATION'} max={50} name="vatId" label={t('vatId')} fullWidth />
        </Grid>

        {status && (
          <Grid item xs={12}>
            <Alert severity="error">{status}</Alert>
          </Grid>
        )}
      </Grid>
    </>
  );
}
