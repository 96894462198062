import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Link, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import EditDebtorDialog from '../debtors/dialogs/EditDebtorDialog';
import { AlertContext } from '../../components/contexts/WithAlertContext';
import { Debtor } from '../../types/Debtor/Debtor';
import { countries } from '../../types/Country';
import i18n from 'i18next';

const useStyles = makeStyles((theme: any) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    width: '100%',
    minHeight: '170px',
  },
  debtorInfoValue: {
    color: 'black',
    fontSize: '20pt',
  },
  debtorInfo: {
    color: '#c9c9c9',
  },
}));

interface Props {
  debtor?: Debtor;
}
export default function DebtorInfoStats({ debtor }: Props): ReactElement {
  const { t } = useTranslation(['payments', 'debitors']);
  const classes = useStyles();
  const alerting = useContext(AlertContext);
  const [debtorToEdit, setDebtorToEdit] = useState<any>(null);

  const countryType = countries.find((country) => {
    return debtor?.address?.country === country.code;
  });

  let country = debtor?.address?.country;
  if (countryType !== undefined) {
    country = countryType.labelEn;
    if (i18n.resolvedLanguage === 'de') {
      country = countryType.labelDe;
    }
  }

  const onEdited = () => {
    alerting.sendAlert('success', t('debitor edited'));
    setDebtorToEdit(null);
    window.location.reload();
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={6} style={{ overflowWrap: 'break-word' }}>
          <Typography variant="caption" style={{ color: 'gray' }} className="debtorInfo">
            {t('address')}
          </Typography>
          <Typography variant="subtitle2" className="debtorInfoValue">
            {debtor?.address?.suffix}
          </Typography>
          <Typography variant="subtitle2" className="debtorInfoValue">
            {debtor?.address?.line}
          </Typography>
          <Typography variant="subtitle2" className="debtorInfoValue">
            {debtor?.address?.zipCode}&nbsp;{debtor?.address?.city}
          </Typography>
          <Typography variant="subtitle2" className="debtorInfoValue">
            {country}
          </Typography>
        </Grid>
        <Grid container item xs={6} style={{ overflowWrap: 'break-word' }} spacing={1}>
          <Grid item direction={'row'} xs={12} style={{ overflowWrap: 'break-word' }}>
            <Typography variant="caption" style={{ color: 'gray' }} className="debtorInfo">
              {t('debitors:E-Mail Address')}
            </Typography>
            <Typography variant="subtitle2">
              <Link href={'mailto:' + debtor?.emailAddress}>{debtor?.emailAddress}</Link>
            </Typography>
            {debtor?.additionalEmailAddresses && debtor.additionalEmailAddresses.length > 0 && (
              <>
                <Typography variant="caption" style={{ color: 'gray' }} className="debtorInfo">
                  {t('debitors:additional email addresses')}
                </Typography>
                {Array.from(debtor?.additionalEmailAddresses, (value) => {
                  return (
                    <Typography variant="subtitle2">
                      <Link href={'mailto:' + value}>{value}</Link>
                    </Typography>
                  );
                })}
              </>
            )}
          </Grid>
          <Grid item xs={12} style={{ overflowWrap: 'break-word' }}>
            <Typography variant="caption" style={{ color: 'gray' }} className="debtorInfo">
              {t('payment method')}
            </Typography>
            <Typography className="debtorInfoValue" variant="subtitle2">
              {t(`${debtor?.paymentMethod}`)}
            </Typography>
          </Grid>

          {debtor?.type === 'ORGANIZATION' && (
            <Grid item xs={12} style={{ overflowWrap: 'break-word' }}>
              <Typography variant="caption" style={{ color: 'gray' }} className="debtorInfo">
                {t('debitors:vatId')}
              </Typography>
              <Typography className="debtorInfoValue" variant="subtitle2">
                {debtor?.vatId}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      {debtorToEdit && <EditDebtorDialog debtor={debtorToEdit} onClose={() => setDebtorToEdit(null)} onEdit={onEdited} open={true} />}
    </Paper>
  );
}
