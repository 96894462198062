import React from 'react';
import { Box, LinearProgress, makeStyles } from '@material-ui/core';
import { ProgressContext } from './contexts/WithProgressContext';

const useStyles = makeStyles(() => ({
  bar: {
    minHeight: '4px',
  },
}));

export default function ProgressBar() {
  const classes = useStyles();

  return (
    <ProgressContext.Consumer>
      {({ progress }) => <Box className={classes.bar}>{progress && <LinearProgress color="secondary" />}</Box>}
    </ProgressContext.Consumer>
  );
}
