import { Button, Grid, makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import AddBoxIcon from '@material-ui/icons/AddBox';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable, { Row } from '../../../components/ui/blocks/DataTable';
import PageHeader from '../../../components/ui/blocks/PageHeader';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import CreateNewWebhook from './CreateNewWebhookDialog';
import DeleteWebhookDialog from './DeleteWebhookDialog';
import EditWebhook from './EditWebhookDialog';
import SigningSecretDialog from './SigningSecretDialog';
import WebhookService from '../../../services/WebhookService';
import { NewWebhook, Webhook, Webhooks } from '../../../types/Webhook/Webhook';
import { Action } from '../../../components/ui/blocks/ActionMenu';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
  },
  mode: {
    width: 'fit-content',
    padding: '1px 8px',
    borderRadius: '4px',
  },
  live: {
    backgroundColor: theme.palette.success.main,
  },
  disabled: {
    backgroundColor: '#ffc931',
  },
}));

export default function WebHooksView() {
  const [webhooks, setWebhooks] = useState<Webhook[]>([]);
  const [showCreateNewWebhook, setShowCreateNewWebhook] = useState(false);
  const [showSigningSecretDialog, setShowSigningSecretDialog] = useState(false);
  const [signingSecret, setSigningSecret] = useState<string>();
  const [showEditWebhook, setShowEditWebhook] = useState<Webhook>();
  const [showDeleteWebhook, setShowDeleteWebhook] = useState<Webhook>();

  const { t } = useTranslation('developer');
  const classes = useStyles();
  const alerting = useContext(AlertContext);

  useEffect(() => {
    loadWebhooks();
  }, []);

  const loadWebhooks = () => {
    WebhookService.getWebhooks().then((webhooks) => {
      setWebhooks(webhooks);
    });
  };

  const onGetWebhooks = () => {
    setShowCreateNewWebhook(false);
    setShowSigningSecretDialog(true);
    loadWebhooks();
  };

  const handleDeleteWebhook = (row: Webhook) => {
    WebhookService.deleteWebhook(row.id).then((res) => {
      if (res) {
        alerting.sendAlert('success', t('successfully deleted'));
        loadWebhooks();
      } else {
        alerting.sendAlert('error', t('unsuccessfully deleted'));
        loadWebhooks();
      }
      setShowDeleteWebhook(undefined);
    });
  };

  const enableOrDisable = (action: { id: string }, row: Webhook) => {
    const values: NewWebhook = {
      url: row.url,
      events: row.events,
      name: row.name,
      mode: row.mode === Webhooks.Mode.LIVE ? Webhooks.Mode.DISABLED : Webhooks.Mode.LIVE,
    };
    WebhookService.updateWebhook(row.id, values).then(() => {
      onEdited();
    });
  };

  const onMenuClick = (action: Action, row: Row) => {
    switch (action.id) {
      case 'edit':
        setShowEditWebhook(row as Webhook);
        break;
      case 'show signing secret':
        setShowSigningSecretDialog(true);
        setSigningSecret(row.signingSecret as string);
        break;
      case 'delete':
        setShowDeleteWebhook(row as Webhook);
        break;
      case 'enable':
      case 'disable':
        enableOrDisable(action, row as Webhook);
        break;

      default:
    }
  };

  const filterActions = (row: Row, actions: Action[]) => {
    return actions.filter((action) => (action.id !== 'disable' && row.mode === 'DISABLED') || (action.id !== 'enable' && row.mode === 'LIVE'));
  };

  const onEdited = () => {
    alerting.sendAlert('success', t('webhook edited'));
    setShowEditWebhook(undefined);
    loadWebhooks();
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader title={t('webhooks')} />
        <Button variant="contained" color="primary" startIcon={<AddBoxIcon />} onClick={() => setShowCreateNewWebhook(true)}>
          {t('new endpoint')}
        </Button>
      </Grid>
      {webhooks.length !== 0 ? (
        <>
          <DataTable
            columns={[
              {
                label: t('url'),
                id: 'url',
              },
              {
                label: t('name'),
                id: 'name',
              },
              {
                label: t('events'),
                id: 'events',
              },
              {
                label: t('mode'),
                id: 'mode',
              },
            ]}
            rows={webhooks}
            showPagination={false}
            cellTransformations={{
              url: (url) => `${url}`,
              name: (name) => `${name}`,
              events: (events) => (
                <>
                  {(events as string[]).map((event) => (
                    <Chip label={t(event)} />
                  ))}
                </>
              ),
              mode: (mode) => (
                <Grid item className={`${classes.mode} ${mode === 'LIVE' ? classes.live : classes.disabled}`}>
                  {`${mode === 'LIVE' ? t('live') : t('disabled')}`}
                </Grid>
              ),
            }}
            actions={[
              {
                id: 'edit',
                label: t('edit'),
              },
              {
                id: 'show signing secret',
                label: t('show signing secret'),
              },
              {
                id: 'enable',
                label: t('enable'),
              },
              {
                id: 'disable',
                label: t('disable'),
              },
              {
                id: 'delete',
                label: t('delete'),
              },
            ]}
            filterActionsPerRow={filterActions}
            onAction={onMenuClick}
          />
        </>
      ) : (
        <Grid item xs={12} className={classes.main}>
          <Alert severity="warning">{t('no webhooks yet')}</Alert>
        </Grid>
      )}
      {showCreateNewWebhook && (
        <CreateNewWebhook
          isOpen={showCreateNewWebhook}
          onCancel={() => setShowCreateNewWebhook(false)}
          onSuccess={onGetWebhooks}
          setSigningSecret={setSigningSecret}
        />
      )}
      {showSigningSecretDialog && (
        <SigningSecretDialog isOpen={showSigningSecretDialog} onCancel={() => setShowSigningSecretDialog(false)} secretKey={signingSecret} />
      )}
      {showEditWebhook && <EditWebhook webhookRow={showEditWebhook} onClose={() => setShowEditWebhook(undefined)} onEdit={onEdited} open={true} />}
      {showDeleteWebhook && (
        <DeleteWebhookDialog webhook={showDeleteWebhook} onClose={() => setShowDeleteWebhook(undefined)} onDelete={handleDeleteWebhook} open={true} />
      )}
    </React.Fragment>
  );
}
