import { Grid, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import { Alert, Button, FormikInputField } from '@payactive/app-common';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { authService } from '../../../services/AuthService';
import PayactiveHeaderLogo from '../../../components/ui/blocks/PayactiveHeaderLogo';
import CentricLayout from '../../../components/ui/layout/CentricLayout';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    borderRadius: '5px',
  },
  formField: {
    padding: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    color: '#000000',
  },
}));

type LoginValues = { emailAddress: string; password: string };
export default function LoginPage() {
  const classes = useStyles();
  const theme = useTheme();
  const { search, state } = useLocation();
  const { t } = useTranslation('global');

  const initialEmailAddress = new URLSearchParams(search).get('emailAddress');

  const [redirect, setRedirect] = useState(false);

  const onSubmit = (values: LoginValues, { setStatus, setSubmitting }: FormikHelpers<LoginValues>) => {
    authService.login(
      values.emailAddress,
      values.password,
      () => {
        setSubmitting(false);
        setRedirect(true);
      },
      () => {
        setSubmitting(false);
        setStatus('Error while logging in');
      },
    );
  };

  const initialValues: LoginValues = {
    emailAddress: initialEmailAddress || '',
    password: '',
  };

  const schema = Yup.object().shape({
    emailAddress: Yup.string().email(t('Email address is invalid')).required(t('Email address is required')),
    password: Yup.string().required(t('Password is required')),
  });

  const onRedirect = () => {
    const typedState = state as { pendingPathname: string; pendingSearch: string } | undefined;
    if (typedState?.pendingPathname || typedState?.pendingSearch) {
      return <Redirect to={{ pathname: typedState.pendingPathname, search: typedState.pendingSearch, state: {} }} />;
    } else {
      return <Redirect to={'/dashboard/home'} />;
    }
  };

  return (
    <CentricLayout>
      {redirect && onRedirect()}
      <Grid item container alignContent={'center'} direction={'column'}>
        <Grid item xs={12}>
          <PayactiveHeaderLogo color={theme.palette.secondary.main} />
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('welcome')}</Typography>
        </Grid>
      </Grid>

      <Paper className={classes.paper} elevation={0}>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({ values, status, isSubmitting, touched }) => (
            <Form>
              {status && <Alert severity="error">{status}</Alert>}
              <FormikInputField
                disabled={isSubmitting}
                value={values.emailAddress}
                fullWidth
                className={classes.formField}
                icon="account_circle"
                name="emailAddress"
                label={t('email')}
              />
              <FormikInputField
                disabled={isSubmitting}
                value={values.password}
                fullWidth
                className={classes.formField}
                icon="lock"
                type="password"
                name="password"
                label={t('password')}
              />
              <Button type="submit" loading={isSubmitting} disabled={false} className={classes.formField} label="Anmelden" name="submit" fullWidth>
                Anmelden
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
      <Grid container alignContent={'center'} direction={'column'}>
        <Link to="/register/passwordreset/request">{t('forgot_password')}</Link>
      </Grid>
    </CentricLayout>
  );
}
