import { Avatar, Icon, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React from 'react';
import { Row } from './DataTable';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  menu: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
}));

const ITEM_HEIGHT = 48;

interface Action {
  id: string;
  label: string;
  isHighlighted?: boolean;
}

interface ActionMenuProps {
  row: Row;
  actions: Action[];
  onAction: (action: Action, row: Row) => void;
}

const ActionMenu: React.FC<ActionMenuProps> = ({ actions, row, onAction }) => {
  const classes = useStyles();

  const [menuAnchor, setMenuAnchor] = React.useState<Element>();
  const menuOpen = Boolean(menuAnchor);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };

  const _proxy_onAction = (action: Action, row: Row) => {
    setMenuAnchor(undefined);
    onAction(action, row);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Avatar className={classes.avatar}>
          <Icon>more_horiz</Icon>
        </Avatar>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuAnchor(undefined)}
        PaperProps={{
          className: classes.menu,
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'min-content',
          },
        }}
      >
        {actions.map((action) => (
          <MenuItem key={action.id} onClick={() => _proxy_onAction(action, row)}>
            {action.label} {action.isHighlighted && <FiberManualRecordIcon fontSize="small" />}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export type { ActionMenuProps, Action };
export default ActionMenu;
