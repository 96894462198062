import React, { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import AutoNumeric, { CallbackOptions } from 'autonumeric';
import { useFormikContext } from 'formik';
import { FormikInputField } from '@payactive/app-common';
import { InputAdornment, TextFieldProps } from '@material-ui/core';
import { InputFieldProps } from '@payactive/app-common/dist/input/InputField';

type Props = InputFieldProps &
  TextFieldProps & {
    name: string;
    label: string;
    currencySymbol: string;
    value?: number;
    className?: string;
    style?: CSSProperties;
    required?: boolean;
    fullWidth?: boolean;
  };

export default function CurrencyTextField(props: Props) {
  const { name, label, currencySymbol, value, className, style, required, fullWidth } = props;
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();
  const currencyInputRef = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;

  const [autoNumeric, setAutoNumeric] = useState<AutoNumeric>();

  useEffect(() => {
    let options: CallbackOptions = {
      ...AutoNumeric.getPredefinedOptions().commaDecimalCharDotSeparator,
      minimumValue: '0',
      caretPositionOnFocus: 'start',
      digitGroupSeparator: '',
    };
    let numeric = new AutoNumeric(currencyInputRef.current as HTMLElement, options);
    if (value) numeric.set(value);
    else numeric.set(name.split('.').reduce((o, i) => o[i], values)); // accesses paths in values
    setAutoNumeric(numeric);
  }, []);

  useEffect(() => {
    autoNumeric?.set(name.split('.').reduce((o, i) => o[i], values)); // accesses paths in values
  }, [values]);

  return (
    <>
      <FormikInputField
        {...props} // has to be first to be able to override attributes later on
        value={autoNumeric?.getFormatted()}
        inputRef={currencyInputRef}
        onBlur={(event) => {
          setFieldValue(name, autoNumeric?.getNumber()).then(() => {
            setFieldTouched(name, true, true).then(() => props.onBlur && props.onBlur(event));
          });
        }}
        onDrop={(event) => {
          setFieldValue(name, autoNumeric?.getNumber()).then(() => {
            setFieldTouched(name, true, true).then(() => props.onDrop && props.onDrop(event));
          });
        }}
        placeholder={'0,00'}
        InputProps={{
          startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
        }}
      />
    </>
  );
}
