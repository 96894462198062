import React, { useContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';
import PaymentService from '../../../services/PaymentService';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import LoadingButton from '../../../components/LoadingButton';

type PaymentReminderDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  paymentId: string;
  debtor: string;
};

const PaymentReminderDialog = ({ isOpen, onCancel, onSuccess, paymentId, debtor }: PaymentReminderDialogProps) => {
  const { t } = useTranslation('payments');
  const alerting = useContext(AlertContext);
  const [isLoading, setIsLoading] = useState(false);
  const sendPaymentReminder = () => {
    setIsLoading(true);
    PaymentService.sendPaymentReminder(paymentId)
      .then((res) => {
        if (res) {
          onSuccess();
          alerting.sendAlert('success', t('reminder sent successfully'));
        } else {
          alerting.sendAlert('error', t('reminder error'));
        }
      })
      .catch(() => {
        alerting.sendAlert('error', t('reminder error'));
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{`${t('resend payment reminder')} ${debtor}`}</DialogTitle>
      <DialogContent dividers>
        <MuiAlert elevation={6} variant="filled" severity="warning">
          {t('resend payment reminder alert')}
        </MuiAlert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('global:cancel')}
        </Button>
        <LoadingButton type="button" onClick={() => sendPaymentReminder()} loading={isLoading}>
          {t('payments:send payment reminder')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentReminderDialog;
