import { Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../components/LoadingButton';
import { Debtor } from '../../../types/Debtor/Debtor';

interface Props {
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onFinalize: () => void;
  debtor: Debtor;
}

export default function ChangePaymentMethodDialog({ open, onCancel, onFinalize, debtor, loading }: Props): ReactElement {
  const { t } = useTranslation('debitors');

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return false;
          }
          onCancel();
        }}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{t('change payment method title')}</DialogTitle>
        <Alert severity="info">
          <Typography>{t('change payment method subtitle', { name: `${debtor.firstName} ${debtor.lastName}` })}</Typography>
        </Alert>
        <DialogActions>
          <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:cancel')} />
          <LoadingButton loading={loading} onClick={onFinalize}>
            {t('change payment method btn')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
