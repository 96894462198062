import React from 'react';
import PaginationProvider from '../../../components/table/PaginationContext';
import { SubscriptionListDataContext } from '../../subscriptions/SubscriptionsContextProvider';
import SubscriptionTable from '../../subscriptions/table/SubscriptionTable';

export default function SubscriptionsTab() {
  return (
    <SubscriptionListDataContext.Consumer>
      {({ currentPage, pageSize, page }) => (
        <>
          <PaginationProvider
            pageSizeOptions={[5, 10, 30]}
            currentPage={currentPage.get}
            totalPages={page?.totalPages || 0}
            pageSize={pageSize.get}
            setCurrentPage={currentPage.set}
            changePageSize={pageSize.set}
          >
            <SubscriptionTable showPagination={true} />
          </PaginationProvider>
        </>
      )}
    </SubscriptionListDataContext.Consumer>
  );
}
