import React, { useContext, useRef } from 'react';
import { Form, Formik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import LoadingButton from '../../../components/LoadingButton';
import { useTranslation } from 'react-i18next';
import { SEPAMandateFormSchema } from '../schemas';
import SEPAMandateService from '../../../services/SEPAMandateService';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import SEPAMandateForm from '../forms/SEPAMandateForm';

const initialValues = {
  debitorId: '',
  iban: '',
  bic: '',
  signatureDate: '',
  isChecked: false,
};
const CreateSEPAMandateDialog = ({ onSuccess, isOpen, onCancel, debitor }: any) => {
  const formRef = useRef(null);
  const alert = useContext(AlertContext);
  const { t: tValidations } = useTranslation(['validations', 'sepa']);
  const { t } = useTranslation(['global', 'sepa']);
  const schema = SEPAMandateFormSchema(tValidations);

  function createSEPAMandate(values: any, { setSubmitting }: any) {
    SEPAMandateService.createSEPAMandate(values)
      .then((result) => {
        setSubmitting(false);
        onSuccess();
        alert.sendAlert('success', t('sepa:sepa mandate creation success'));
      })
      .catch((error) => {
        onCancel();
        alert.sendAlert('error', t('sepa:sepa mandate creation error'));
      });
  }

  return (
    <Formik innerRef={formRef} initialValues={initialValues} validationSchema={schema} onSubmit={createSEPAMandate}>
      {({ setFieldValue, setSubmitting, isValid, values, isSubmitting }) => (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={isOpen}
          onClose={(event, reason) => {
            if (reason === 'backdropClick') {
              return false;
            }
            onCancel();
          }}
          aria-labelledby="alert-dialog-title"
        >
          <Form>
            <DialogTitle id="alert-dialog-title">{t('sepa:new sepa mandate')}</DialogTitle>
            <DialogContent dividers>
              <SEPAMandateForm values={values} setFieldValue={setFieldValue} debtor={debitor} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="primary">
                {t('global:cancel')}
              </Button>
              <LoadingButton loading={isSubmitting} type="submit" disabled={!isValid}>
                {t('sepa:create mandate')}
              </LoadingButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export default CreateSEPAMandateDialog;
