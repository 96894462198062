import ApiClient from './ApiClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { Page, PageMetadata } from '../types/Page';
import { AgentApp } from '../types/Agent/AgentApp';
import { AgentClient } from '../types/Agent/AgentClient';
import { TrackedTransaction } from '../types/Agent/TrackedTransaction';
import { AgentAllowList } from '../types/Agent/AgentAllowList';

export default class AgentService extends ApiClient {
  private static get agentClient(): AxiosInstance {
    return this.client;
  }

  public static async getConnectedApps(size: number, page: number, sort: string): Promise<{ results: AgentApp[]; page: PageMetadata }> {
    const params = {
      size: size,
      page: page,
      sort: 'createdDateTimeUTC,' + sort,
    };

    const res = await this.agentClient.request<Page<AgentApp, 'connectedApps'>>({
      method: 'GET',
      url: this.apiDomain + '/agent/apps',
      params: params,
    });

    return { results: res.data._embedded?.connectedApps || [], page: res.data.page };
  }

  public static async getAgentClients(size: number, page: number, sort: string): Promise<{ results: AgentClient[]; page: PageMetadata }> {
    let params = {
      size: size,
      page: page,
      sort: 'createdAt,' + sort,
    };

    let res = await this.agentClient.request<Page<AgentClient, 'agentClients'>>({
      method: 'GET',
      url: this.apiDomain + '/agent/clients',
      params: params,
    });

    return { results: res.data._embedded?.agentClients || [], page: res.data.page };
  }

  public static async updateAgent(agentEnabled: boolean, agentId: string): Promise<boolean> {
    let res = await this.agentClient.request({
      method: 'PATCH',
      url: this.apiDomain + '/agent/' + agentId,
      data: { agentEnabled: agentEnabled },
    });

    return res.status === 200;
  }

  public static async deleteAgentClient(clientId: string) {
    let res = await this.agentClient.request({
      method: 'DELETE',
      url: this.apiDomain + '/agent/clients/' + clientId,
    });

    return res.status === 200;
  }

  public static async getTrackedTransactions(size = 20, page: number, sort: string): Promise<{ results: TrackedTransaction[]; page: PageMetadata }> {
    let params = {
      size: size,
      page: page,
      sort: 'createdDateTimeUTC,' + sort,
    };

    let res = await this.agentClient.request<Page<TrackedTransaction, 'trackedTransactions'>>({
      method: 'GET',
      url: this.apiDomain + '/agent/clients/tracked-transactions',
      params: params,
    });

    return { results: res.data._embedded?.trackedTransactions || [], page: res.data.page };
  }

  public static async createTrackedTransactions(data: AgentAllowList, paymentId: string): Promise<string> {
    return (
      await this.agentClient.request<AgentAllowList, AxiosResponse<string>>({
        method: 'POST',
        url: this.apiDomain + '/agent/clients/tracked-transactions/' + paymentId,
        data: data,
      })
    ).data;
  }

  public static async createAgentClientInvitation(): Promise<string> {
    let res = await this.agentClient.request<string>({
      method: 'POST',
      url: this.apiDomain + '/agent/clients/invitations',
    });
    return res.data;
  }
}
