import { AxiosInstance, AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import { authService } from './AuthService';
import { Invoice, InvoiceFilter, InvoiceRequest } from '../types/Invoice/Invoice';
import { InvoiceEvent } from '../types/Invoice/InvoiceEvent';
import { Page, PageMetadata } from '../types/Page';
import { InvoicePDF } from '../types/Invoice/InvoicePDF';
import { CancelReasons } from '../types/CancelReasons';

type CorrectiveData = { refToOriginalInvoice: string; invoiceCorrectionReasonExternal: string; invoiceCorrectionReasonInternal?: string };

export default class InvoiceService extends ApiClient {
  private static get invoiceClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }

  public static async createInvoice(newInvoice: InvoiceRequest): Promise<Invoice> {
    return await this.invoiceClient
      .request<InvoiceRequest, AxiosResponse<Invoice>>({
        method: 'POST',
        url: `${this.apiDomain}/invoices`,
        data: newInvoice,
      })
      .then((a) => {
        return a.data;
      });
  }

  public static async getInvoiceEvents(invoiceId: string): Promise<InvoiceEvent[]> {
    let res = await this.invoiceClient.request<Page<InvoiceEvent, 'invoiceEventsList'>>({
      method: 'GET',
      url: `${this.apiDomain}/invoices/${invoiceId}/events`,
    });

    return res.data._embedded?.invoiceEventsList || [];
  }

  public static async getInvoice(invoiceId: string, metadata = false): Promise<Invoice> {
    let params;
    if (metadata) {
      params = {
        expand: 'metadata',
      };
    }
    return await this.invoiceClient
      .request<Invoice>({
        method: 'GET',
        url: `${this.apiDomain}/invoices/${invoiceId}`,
        params: params,
      })
      .then((res) => res.data);
  }

  public static async getInvoiceReferences(invoiceId: string, metadata = false): Promise<Invoice[]> {
    let params;
    if (metadata) {
      params = {
        expand: 'metadata',
      };
    }
    return await this.invoiceClient
      .request<Invoice[]>({
        method: 'GET',
        url: `${this.apiDomain}/invoices/${invoiceId}/references`,
        params: params,
      })
      .then((res) => res.data);
  }

  public static async createFinalizeInvoice(invoiceId: string): Promise<Invoice> {
    return await this.invoiceClient
      .request<Invoice>({
        method: 'POST',
        url: `${this.apiDomain}/invoices/finalize/${invoiceId}`,
      })
      .then((res) => res.data);
  }

  public static async getInvoices(size = 0, page: any, sort: string, filters: InvoiceFilter) {
    if (size == 0) {
      return await this.getAllInvoices(sort, filters);
    }
    let params: InvoiceFilter & { size: number; page: any; sort: string } = {
      size: size,
      page: page,
      sort: 'createdDateTimeUTC,' + sort,
    };

    if (!!filters.search) params['search'] = filters.search;
    if (!!filters.statuses) params['statuses'] = filters.statuses;
    if (!!filters.startDate) params['startDate'] = filters.startDate;
    if (!!filters.endDate) params['endDate'] = filters.endDate;

    let res = await this.invoiceClient.request<Page<Invoice, 'invoices'>>({
      method: 'GET',
      url: `${this.apiDomain}/invoices`,
      params: params,
      signal: this.abortController.signal,
    });
    if (this.countListener) {
      this.countListener(res.data.page);
    }
    return { results: res.data._embedded?.invoices || [], page: res.data.page };
  }

  public static async getAllInvoices(sort: string, filters: InvoiceFilter, callback = (n: number) => {}, errorHandler = () => {}) {
    //load all with paging
    let loadedAll = false;
    let pageForAll = 0;
    let results: Invoice[] = [];
    try {
      do {
        let ret = await InvoiceService.getInvoices(100, pageForAll, sort, filters);
        results = results.concat(ret.results);
        callback(results.length);
        pageForAll++;
        loadedAll = ret.page.totalElements < pageForAll * 100;
      } while (!loadedAll);
    } catch (e) {
      errorHandler();
    }

    return {
      results: results,
      page: { size: results.length, totalElements: results.length, totalPages: 1, number: 0 },
    };
  }

  public static async deleteInvoice(invoiceId: string): Promise<boolean> {
    let res = await this.invoiceClient.request({
      method: 'DELETE',
      url: `${this.apiDomain}/invoices/${invoiceId}`,
    });

    return res.status === 200;
  }

  public static async downloadInvoice(invoiceId: string) {
    const headers = {
      Authorization: 'Bearer ' + authService.accessToken,
      'Content-Type': 'application/pdf; charset=utf-8',
      Accept: 'application/pdf; charset=utf-8',
    };

    let res = await this.invoiceClient.request<InvoicePDF>({
      method: 'GET',
      url: `${this.apiDomain}/invoices/${invoiceId}/pdf`,
      headers: headers,
    });
    const content = res.data.content;
    const fileName = `${res.data.name}.pdf`;
    InvoiceService.downloadInvoiceAsPDF(fileName, content);
  }

  private static downloadInvoiceAsPDF(filename: string, content: Array<string> | undefined) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:application/pdf;base64,' + content);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public static async completesInvoiceRefund(invoiceId: string): Promise<boolean> {
    let res = await this.invoiceClient.request({
      method: 'POST',
      url: `${this.apiDomain}/invoices/cancel/complete/${invoiceId}`,
    });
    return res.status === 200;
  }

  public static async createCorrectiveInvoice(invoiceId: string, reasons: CancelReasons): Promise<Invoice> {
    return await this.invoiceClient
      .request<CorrectiveData, AxiosResponse<Invoice>>({
        method: 'POST',
        url: `${this.apiDomain}/invoices/corrections`,
        data: {
          refToOriginalInvoice: invoiceId,
          invoiceCorrectionReasonExternal: reasons.externalReason,
          invoiceCorrectionReasonInternal: reasons.internalReason,
        },
      })
      .then((res) => res.data);
  }

  public static async finalizeInvoiceCorrection(invoiceId: string): Promise<boolean> {
    let res = await this.invoiceClient.request({
      method: 'POST',
      url: `${this.apiDomain}/invoices/corrections/finalize/${invoiceId}`,
    });
    return res.status === 200;
  }

  public static async createCancellationInvoice(invoiceId: string, reasons: CancelReasons): Promise<Invoice> {
    return await this.invoiceClient
      .request<CorrectiveData, AxiosResponse<Invoice>>({
        method: 'POST',
        url: `${this.apiDomain}/invoices/cancel`,
        data: {
          refToOriginalInvoice: invoiceId,
          invoiceCorrectionReasonExternal: reasons.externalReason,
          invoiceCorrectionReasonInternal: reasons.internalReason,
        },
      })
      .then((res) => res.data);
  }

  public static async previewCancelInvoice(invoiceId: string) {
    const headers = {
      Authorization: 'Bearer ' + authService.accessToken,
      'Content-Type': 'application/pdf; charset=utf-8',
    };

    let res = await this.invoiceClient.request<InvoicePDF>({
      method: 'POST',
      url: `${this.apiDomain}/invoices/cancel/finalize-preview/${invoiceId}`,
      headers: headers,
    });

    const content = res.data.content;
    const fileName = `${res.data.name}.pdf`;

    return { name: fileName, content: content };
  }

  public static async finalizeInvoiceCancellation(invoiceId: string): Promise<boolean> {
    let res = await this.invoiceClient.request({
      method: 'POST',
      url: `${this.apiDomain}/invoices/cancel/finalize/${invoiceId}`,
    });
    return res.status === 200;
  }

  public static async updateInvoice(invoiceId: string, data: InvoiceRequest): Promise<Invoice> {
    return await this.invoiceClient
      .request<InvoiceRequest, AxiosResponse<Invoice>>({
        method: 'PUT',
        url: `${this.apiDomain}/invoices/${invoiceId}`,
        data: data,
      })
      .then((res) => res.data);
  }

  public static async getInvoicesForDebtor(
    debtorId: string,
    size = 20,
    page = 0,
    sort = 'createdDateTimeUTC,desc',
  ): Promise<{ results: Invoice[]; page: PageMetadata }> {
    let params = {
      size: size,
      page: page,
      sort: sort,
    };

    return await this.invoiceClient
      .request<Page<Invoice, 'invoices'>>({
        method: 'GET',
        url: this.apiDomain + '/debitors/' + debtorId + '/invoices',
        params: params,
        signal: this.abortController.signal,
      })
      .then((res) => {
        if (this.countListener) {
          this.countListener(res.data.page);
        }
        return { results: res.data._embedded?.invoices || [], page: res.data.page };
      });
  }
}
