import React, { useContext, useRef, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button } from '@payactive/app-common';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelProductDialog from './CancelProductDialog';
import { productSchemas } from '../../checkoutLinks/dialogs/schemas';
import ProductService from '../../../services/ProductService';
import ProductForm from '../forms/ProductForm';
import { NewProduct, Products } from '../../../types/Product/Product';
import { CustomField } from '../../../types/Product/CustomField';
import { AlertContext } from '../../../components/contexts/WithAlertContext';

const useStyles = makeStyles(() => ({
  description: {
    '& .MuiInputAdornment-positionStart': {
      alignSelf: 'self-end',
      marginBottom: '8px',
    },
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  fieldLabel: {
    '& .MuiGrid-spacing-xs-1': {
      width: '45%',
    },
  },
  fieldType: {
    width: '40%',
  },
  customFieldContainer: {
    display: 'flex',
    margin: '20px 0px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  price: {
    width: '35%',
  },
  tax: {
    width: '20%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  totalPrice: {
    width: '15%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
  },
  backDrop: {
    backdropFilter: 'blur(30px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  checkbox: {
    width: '20%',
    '& .MuiCheckbox-root': {
      padding: '0px',
    },
    '& .MuiTypography-body1': {
      fontSize: '11px',
    },
  },
}));

interface CreateProductDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  comeBack: (success?: boolean) => void;
  redirectToOrigin: (newProduct: any) => void;
  variant: string;
}
type NewProductForm = NewProduct & { file?: File; customFields: CustomField[] };
const initialValues: NewProductForm = {
  name: '',
  description: '',
  price: 0,
  defaultDonationAmount: 0,
  productType: Products.Type.PRODUCT,
  customFields: [] as CustomField[],
  donationOptions: {
    isEnabled: false,
    option1: 15,
    option2: 25,
    option3: 50,
  },
  taxRate: {
    description: '',
    rate: 0,
  },
  recurringBillingPeriod: undefined,
  billingType: undefined,
  file: undefined,
};

enum NewProductStep {
  NEWPRODUCT = 0,
  COMEBACK = 1,
}

export const CreateProductDialog = ({ isOpen, onCancel, comeBack, redirectToOrigin, variant }: CreateProductDialogProps) => {
  const { t } = useTranslation('payments');
  const { t: tValidations } = useTranslation('validations');
  const schema = productSchemas(tValidations);
  const classes = useStyles();
  const formRef = useRef(null);
  const [step, setStep] = useState(NewProductStep.NEWPRODUCT);
  const alert = useContext(AlertContext);

  const _handleSubmit = (values: NewProductForm, formikHelpers: FormikHelpers<NewProductForm>) => {
    //what this fn does:  ALL properties of metadata are '' returns: false
    const formatMetadataValuesIfEmpty =
      values.customFields.length > 0 && Object.values(values.customFields[0]).every((x) => x === null || x === '' || x === false);
    if (formatMetadataValuesIfEmpty) {
      values.customFields = [] as CustomField[];
    }
    let image = values.file;
    delete values.file;
    if (values.taxRate && values.taxRate.description === '') {
      values.taxRate.description = `USt (${values.taxRate.rate}%)`;
    }
    const payload = {
      name: values.name,
      description: values.description,
      productType: values.productType,
      price: values.price,
      defaultDonationAmount: values.defaultDonationAmount,
      donationOptions: {
        isEnabled: values.donationOptions?.isEnabled,
        option1: values.donationOptions?.option1,
        option2: values.donationOptions?.option2,
        option3: values.donationOptions?.option3,
      },
      taxRate: values.taxRate,
      recurringBillingPeriod: values.recurringBillingPeriod,
      billingType: values.billingType,
      customFields: values.customFields,
    } as unknown as NewProduct;
    ProductService.createProduct(payload, image)
      .then((response) => {
        if (response) {
          formikHelpers.setSubmitting(false);
          redirectToOrigin(response);
        }
        comeBack(true);
      })
      .catch(() => alert.sendAlert('error', t('global:unexpected error')));
  };
  const checkBeforeComeBack = () => {
    setStep(NewProductStep.COMEBACK);
  };
  return (
    <>
      {step === 0 && (
        <Formik innerRef={formRef} initialValues={initialValues} validationSchema={schema} onSubmit={_handleSubmit} validateOnChange={false}>
          {({ isSubmitting, dirty, isValid, handleSubmit }) => (
            <Dialog
              maxWidth="sm"
              fullWidth
              open={isOpen}
              onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                  return false;
                }
                onCancel();
              }}
              aria-labelledby="alert-dialog-title"
              BackdropProps={{
                classes: {
                  root: classes.backDrop,
                },
              }}
            >
              <Form>
                <div className={classes.titleContainer}>
                  <ArrowBackIcon onClick={checkBeforeComeBack} />
                  <DialogTitle id="alert-dialog-title">{t('create new product')}</DialogTitle>
                </div>
                <DialogContent dividers>
                  <ProductForm />
                </DialogContent>
                <DialogActions>
                  <Button onClick={checkBeforeComeBack} variant="text" color="primary" name="back button" label={t('global:back')} />
                  <LoadingButton loading={isSubmitting} type="submit" disabled={!dirty || !isValid} onClick={() => handleSubmit()}>
                    {t('save product')}
                  </LoadingButton>
                </DialogActions>
              </Form>
            </Dialog>
          )}
        </Formik>
      )}
      {step === 1 && (
        <CancelProductDialog origin={variant} open={step === 1} onClose={() => setStep(NewProductStep.NEWPRODUCT)} comeBack={comeBack} />
      )}
    </>
  );
};
