import { DialogActions } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import { ReactElement, useState } from 'react';
import { BaseRow } from 'react-csv-importer';
import DataGrid, { SelectColumn } from 'react-data-grid';
import { useTranslation } from 'react-i18next';
import InstalmentPlanService from '../../../services/InstalmentPlanService';
import DebtorService from '../../../services/DebtorService';
import BankService from '../../../services/BankService';
import { Debtor, Debtors, NewDebtor } from '../../../types/Debtor/Debtor';
import { PaymentMethod } from '../../../types/Invoice/PaymentMethod';
import { InstalmentPlans, NewInstalmentPlan } from '../../../types/InstalmentPlan/InstalmentPlan';
import SEPAMandateService from '../../../services/SEPAMandateService';
import { NewSEPAMandate, SEPAMandate } from '../../../types/SEPA/SEPAMandate';

interface Props {
  rows: BaseRow[];
  onSuccess: (rows: any, selectedRowsSize: number) => void;
  onClose: () => void;
}

export default function ReviewImport({ rows, onSuccess, onClose }: Props): ReactElement {
  const { t } = useTranslation(['global', 'payments']);
  const [selectedRows, setSelectedRows] = useState<ReadonlySet<any>>(() => new Set());
  const [loading, setLoading] = useState(false);

  const columns = [
    SelectColumn,
    { key: 'firstName', name: t('global:fieldNames.firstName') },
    { key: 'lastName', name: t('global:fieldNames.lastName') },
    { key: 'emailAddress', name: t('global:fieldNames.emailAddress') },
    { key: 'purpose', name: t('global:fieldNames.purpose') },
    { key: 'amount', name: t('global:fieldNames.amount') },
    { key: 'instalmentRate', name: t('global:fieldNames.instalmentRate') },
    { key: 'downPayment', name: t('global:fieldNames.downPayment') },
    { key: 'intervalDay', name: t('global:fieldNames.intervalDay') },
    { key: 'iban', name: t('global:fieldNames.iban') },
    { key: 'bic', name: t('global:fieldNames.bic') },
    { key: 'metadataString', name: t('global:metadata') },
  ];

  const createInstalmentPlans = async () => {
    setLoading(true);
    const importedRows = [];
    const bankAccountId = (await BankService.getDefaultBankAccount())?.id;
    if (!bankAccountId) {
      //TODO handle error case
      throw new Error('default bank account not found');
    }
    for (let row of Array.from(selectedRows.values())) {
      try {
        const debtor = await findOrCreateDebtor(row);
        console.log('debtor found or created: ' + debtor);
        const sepa = row.iban && row.bic && (await findOrCreateSEPAMandate(debtor, row));
        console.log('SEPA mandate found or created: ' + sepa);
        const createInstalmentPlans: NewInstalmentPlan = {
          bankAccountId: bankAccountId,
          debtorId: debtor.id,
          purpose: row.purpose,
          totalNetAmount: row.amount,
          intervalAmount: row.instalmentRate,
          intervalDay: row.intervalDay,
          startDate: new Date(),
          downPayment: row.downPayment,
          interval: InstalmentPlans.Interval.MONTHLY,
          metadata: row.metadata,
        };

        const response = await InstalmentPlanService.createInstalmentPlanInDraft(createInstalmentPlans);
        if (response) {
          await startInstalmentPlan(response.id);
          importedRows.push(row);
        }
      } catch (exception) {
        console.error('Error while importing row: ' + exception);
      }
    }
    setLoading(false);
    onSuccess(importedRows, selectedRows.size);
  };

  return (
    <>
      <DataGrid columns={columns} rows={rows} selectedRows={selectedRows} onSelectedRowsChange={setSelectedRows} rowKeyGetter={(row: any) => row} />
      <DialogActions>
        <Button label={t('global:close')} name="import" onClick={onClose} />
        <Button
          loading={loading}
          label={t('payments:import.import_instalment_plans', { num: selectedRows.size })}
          name="import"
          onClick={createInstalmentPlans}
        />
      </DialogActions>
    </>
  );
}

async function startInstalmentPlan(id: string) {
  await InstalmentPlanService.startInstalmentPlan(id);
}

async function findOrCreateSEPAMandate(debtor: Debtor, row: { iban: string; bic: string }): Promise<SEPAMandate> {
  const searchSEPAMandate = await SEPAMandateService.getSEPAMandates(10, 0, 'desc', debtor.id);

  const found = searchSEPAMandate.results.find((el) => el.iban === row.iban && el.bic === row.bic && el.state === 'ACTIVE');
  if (found) {
    return found;
  } else {
    const newSEPAMandate: NewSEPAMandate = {
      debitorId: debtor.id,
      iban: row.iban,
      bic: row.bic,
      signatureDate: new Date(),
    };
    const createNewSEPAMandate = await SEPAMandateService.createSEPAMandate(newSEPAMandate);
    if (createNewSEPAMandate) {
      return createNewSEPAMandate;
    } else {
      throw new Error('Error while creating SEPA mandate' + row);
    }
  }
}

async function findOrCreateDebtor(row: { emailAddress: string; firstName: string; lastName: string }): Promise<Debtor> {
  const searchDebtor = await DebtorService.search(row.emailAddress);
  if (searchDebtor && searchDebtor.results.length !== 0) {
    return searchDebtor.results[0];
  } else {
    const newDebtor: NewDebtor = {
      paymentMethod: PaymentMethod.ONLINE_PAYMENT,
      type: Debtors.Type.PERSON,
      emailAddress: row.emailAddress,
      firstName: row.firstName,
      lastName: row.lastName,
    };
    const createdDebtor = await DebtorService.createDebtor(newDebtor);
    if (createdDebtor) {
      return createdDebtor;
    } else {
      throw new Error('Error while creating debtor' + row);
    }
  }
}
