import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import TickSuccess from './TickSuccess';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function SuccessScreen() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <TickSuccess />
      <Typography>Welcome onboard @payactive. You will receive an E-Mail with further instructions</Typography>
    </Box>
  );
}
