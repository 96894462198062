import { AxiosInstance, AxiosResponse } from 'axios';
import { NewPayment, Payment, Payments } from '../types/Payment/Payment';
import ApiClient from './ApiClient';
import { Page, PageMetadata } from '../types/Page';
import { PEvent } from '../types/PEvent';
import { CancelReasons } from '../types/CancelReasons';
import { PaymentLink } from '../types/Payment/PaymentLink';
import { Filter, prepareFilterParameters } from '../types/Filter';

export default class PaymentService extends ApiClient {
  private static get paymentClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }

  public static async getPayment(paymentId: string, metadata = false): Promise<Payment> {
    let params;
    if (metadata) {
      params = {
        expand: 'metadata',
      };
    }
    let res = await this.paymentClient.request<Payment>({
      method: 'GET',
      url: this.apiDomain + '/payments/' + paymentId,
      params: params,
    });
    return res.data;
  }

  public static async getPayments(size: number, page: number, sort: string, filters: Filter): Promise<{ results: Payment[]; page: PageMetadata }> {
    let params: { [k: string]: any } = {
      size: size,
      page: page,
      sort: 'createdDateTimeUTC,' + sort,
    };

    let response = await this.paymentClient.get<Page<Payment, 'payments'>>(this.apiDomain + '/payments', {
      params: { ...params, ...prepareFilterParameters(filters) },
      signal: this.abortController.signal,
    });

    if (this.countListener) {
      this.countListener(response.data.page);
    }
    return { results: response.data._embedded?.payments || [], page: response.data.page };
  }

  public static async getPaymentsForDebtor(
    debtorId: string,
    size = 20,
    page = 0,
    sort = 'createdDateTimeUTC,desc',
  ): Promise<{ results: Payment[]; page: PageMetadata }> {
    let res = await this.paymentClient.request<Page<Payment, 'paperlessList'>>({
      method: 'GET',
      url: this.apiDomain + '/debitors/' + debtorId + '/transactions',
      params: {
        size: size,
        page: page,
        sort: sort,
      },
    });

    if (this.countListener) {
      this.countListener(res.data.page);
    }
    return { results: res.data._embedded?.paperlessList || [], page: res.data.page };
  }

  public static async sendPaymentReminder(paymentId: string): Promise<boolean> {
    const response = await this.paymentClient.post(this.apiDomain + '/payments/' + paymentId + '/_send-payment-reminder');

    return response.status === 200;
  }

  public static async getPaymentEvents(paymentId: string): Promise<PEvent[]> {
    let res = await this.paymentClient.request<Page<PEvent, 'paperlessEventsList'>>({
      method: 'GET',
      url: this.apiDomain + '/payments/' + paymentId + '/events',
    });

    return res.data._embedded?.paperlessEventsList || [];
  }

  private static async createPaymentOnly(data: NewPayment): Promise<Payment> {
    // Create paperless
    let res = await this.paymentClient.request<NewPayment, AxiosResponse<Payment>>({
      method: 'POST',
      url: this.apiDomain + '/payments',
      data: data,
    });
    return res.data;
  }

  public static async createPayment(data: NewPayment, file?: File): Promise<Payment> {
    if (!file) {
      return this.createPaymentOnly(data);
    }
    // Create paperless
    return await this.createPaymentOnly(data).then((payment) => {
      if (payment.paymentType === 'INVOICE') {
        const form = new FormData();
        form.append('file', file, file.name);
        form.append('title', file.name);
        return this.paymentClient
          .request({
            method: 'PUT',
            url: this.apiDomain + '/paperless/' + payment.id + '/invoice',
            data: form,
          })
          .then(() => {
            return payment;
          });
      } else {
        return payment;
      }
    });
  }

  public static async cancelPayment(payment: Payment, reasons: CancelReasons, sendEmailNotification = false): Promise<boolean> {
    let res = await this.paymentClient.request<patchPaymentRequest>({
      method: 'PATCH',
      url: this.apiDomain + '/paperless/' + payment.id,
      data: {
        state: Payments.State.CANCELLED,
        cancelReasonDebitor: reasons.externalReason,
        cancelReasonInternal: reasons.internalReason,
        sendEmailNotification: sendEmailNotification,
      },
    });
    return res.status === 200;
  }

  public static async markPaymentAsPaid(payment: Payment, sendEmailNotification = false): Promise<boolean> {
    let res = await this.paymentClient.request<patchPaymentRequest>({
      method: 'PATCH',
      url: this.apiDomain + '/paperless/' + payment.id,
      data: {
        state: Payments.State.VERIFIED,
        sendEmailNotification: sendEmailNotification,
      },
    });
    return res.status === 200;
  }

  public static async markPaymentAsDonation(payment: Payment): Promise<boolean> {
    let res = await this.paymentClient.request({
      method: 'POST',
      url: this.apiDomain + `/payments/${payment.id}/_mark-as-donation`,
    });
    return res.status === 200;
  }

  public static async getPaymentLink(paymentId: string): Promise<string> {
    let res = await this.paymentClient.request<PaymentLink>({
      method: 'GET',
      url: this.apiDomain + '/paperless/' + paymentId + '/payment-link',
    });

    return res.data.paymentLink;
  }

  public static async completesPaymentRefund(paymentId: string) {
    let res = await this.paymentClient.request({
      method: 'POST',
      url: this.apiDomain + '/payments/' + paymentId + '/refundCompleted',
    });
    return res.status === 200;
  }
}

type patchPaymentRequest = {
  state?: Payments.State;
  cancelReasonInternal?: string;
  cancelReasonDebitor?: string;
  sendEmailNotification?: boolean;
};
