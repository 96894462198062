import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { CheckoutLink, NewCheckoutLink } from '../../../types/CheckoutLink/CheckoutLink';
import CheckoutLinkService from '../../../services/CheckoutLinkService';
import CheckoutLinkDialog from './CheckoutLinkDialog';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  checkoutLink: CheckoutLink;
}

export const EditPaymentLinkDialog = ({ isOpen, onCancel, checkoutLink }: Props) => {
  const { t } = useTranslation('payments');
  const alerting = useContext(AlertContext);

  const editCheckoutLink = (values: NewCheckoutLink, { setSubmitting }: any) => {
    const request: NewCheckoutLink = {
      id: checkoutLink.id,
      productId: values.productId,
      paymentTime: values.paymentTime,
      checkoutButtonLabel: values.checkoutButtonLabel,
      generateReceipt: values.generateReceipt,
      requireAddress: values.requireAddress,
      notifyCreditor: values.notifyCreditor,
      creditorEmailAddress: values.creditorEmailAddress,
      includeRedirectUrl: values.includeRedirectUrl,
      allowQuantityChange: values.allowQuantityChange,
      redirectUrl: values.redirectUrl,
    };
    CheckoutLinkService.editCheckoutLink(checkoutLink.id, request).then((res) => {
      setSubmitting(false);
      if (res) {
        alerting.sendAlert('success', t('checkout link edited successfully'));
        onCancel();
      }
    });
  };

  const initialValues: NewCheckoutLink & { id: string } = {
    id: checkoutLink.id,
    productId: checkoutLink.productId,
    checkoutButtonLabel: checkoutLink.checkoutButtonLabel,
    paymentTime: checkoutLink.paymentTime,
    generateReceipt: checkoutLink.generateReceipt,
    allowQuantityChange: checkoutLink.allowQuantityChange,
    requireAddress: checkoutLink.requireAddress,
    notifyCreditor: checkoutLink.notifyCreditor,
    creditorEmailAddress: checkoutLink.creditorEmailAddress,
    includeRedirectUrl: checkoutLink.includeRedirectUrl,
    redirectUrl: checkoutLink.redirectUrl === null ? '' : checkoutLink.redirectUrl,
    //metadata: [], //TODO check API if metadata is allowed
  };

  return <CheckoutLinkDialog isOpen={isOpen} onSubmit={editCheckoutLink} onCancel={onCancel} initialValues={initialValues} />;
};
