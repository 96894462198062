import { MenuItem, Tooltip } from '@material-ui/core';
import React from 'react';

const MenuItemNotAllowed = ({ label, reason, mKey }: { label: string; reason?: string; mKey: string }) => {
  const menuItem = (
    <MenuItem key={mKey} disabled>
      {label}
    </MenuItem>
  );
  return !!reason ? (
    <Tooltip title={reason} placement="left">
      <div>{menuItem}</div>
    </Tooltip>
  ) : (
    menuItem
  );
};

export default MenuItemNotAllowed;
