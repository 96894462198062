import { DialogTitle, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@payactive/app-common';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { changeStatusAndGet, getAndMergeEbicsConnection } from './actions';
import { FormikValues, initialValues, schemas } from './schemas';
import Stepper, { titleByStatus } from './Stepper';
import CreditorInformationForm from './steps/CreditorInformationForm';
import InitializingEbicsForm from './steps/InitializingEbicsForm';
import RequestEbicsForm from './steps/RequestEbicsForm';
import SendingIniLetterForm from './steps/SendingIniLetterForm';
import WaitingForActivation from './steps/WaitingForActivation';
import CompanyAccountService from '../../../../services/CompanyAccountService';
import EbicsService from '../../../../services/EbicsService';
import { EbicsConnection, EbicsConnections } from '../../../../types/Ebics/EbicsConnection';

interface Props {
  ebicsConnectionId: string;
  onClose: () => {};
}

export default function EbicsOnboardingDialog({ ebicsConnectionId, onClose }: Props): ReactElement {
  const { t } = useTranslation('sepa');
  const [ebicsConnection, setEbicsConnection] = useState<EbicsConnection>();

  useEffect(() => {
    getAndMergeEbicsConnection(ebicsConnectionId).then((res) => setEbicsConnection(res));
  }, [ebicsConnectionId, setEbicsConnection]);

  const getContentByStep = (status?: EbicsConnections.Status) => {
    if (!ebicsConnection) {
      return <></>;
    }
    switch (status) {
      case EbicsConnections.Status.SETTING_UP_CREDITOR_IDENTIFIER:
        return <CreditorInformationForm />;
      case EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK:
        return <RequestEbicsForm ebicsConnectionId={ebicsConnectionId} ebicsConnection={ebicsConnection} />;
      case EbicsConnections.Status.INITIALIZING_EBICS:
        return <InitializingEbicsForm ebicsConnectionId={ebicsConnectionId} />;
      case EbicsConnections.Status.SENDING_INI_LETTER:
        return <SendingIniLetterForm ebicsConnectionId={ebicsConnectionId} ebicsConnection={ebicsConnection} />;
      case EbicsConnections.Status.WAITING_FOR_ACTIVATION:
      case EbicsConnections.Status.ACTIVE:
        return <WaitingForActivation ebicsConnection={ebicsConnection} />;
      default:
        return <></>;
    }
  };

  const getButtonTextByStep = (status?: EbicsConnections.Status): string => {
    switch (status) {
      case EbicsConnections.Status.INITIALIZING_EBICS:
        return t('check input');
      case EbicsConnections.Status.WAITING_FOR_ACTIVATION:
      case EbicsConnections.Status.ACTIVE:
        return t('global:close');
      default:
        return t('global:continue');
    }
  };

  const getBackCheckboxByStep = (status?: EbicsConnections.Status) => {
    switch (status) {
      case EbicsConnections.Status.SETTING_UP_CREDITOR_IDENTIFIER:
        return {
          show: false,
        };
      case EbicsConnections.Status.SENDING_INI_LETTER:
        return {
          show: false,
        };
      case EbicsConnections.Status.ACTIVE:
        return {
          show: false,
        };
      default:
        return {
          show: true,
          label: t('global:back'),
        };
    }
  };

  const back = async (values: any) => {
    if (!ebicsConnection) {
      return;
    }

    switch (ebicsConnection.status) {
      case EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK:
        const res1 = await changeStatusAndGet(ebicsConnectionId, EbicsConnections.Status.SETTING_UP_CREDITOR_IDENTIFIER);
        setEbicsConnection(res1);
        break;
      case EbicsConnections.Status.INITIALIZING_EBICS:
        const res2 = await changeStatusAndGet(ebicsConnectionId, EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK);
        setEbicsConnection(res2);
        break;
      case EbicsConnections.Status.WAITING_FOR_ACTIVATION:
        const res3 = await changeStatusAndGet(ebicsConnectionId, EbicsConnections.Status.SENDING_INI_LETTER);
        setEbicsConnection(res3);
        break;
      default:
        return;
    }
  };

  const onSubmit = async (values: FormikValues, { setStatus, setSubmitting }: FormikHelpers<FormikValues>) => {
    if (!ebicsConnection) {
      return;
    }

    switch (ebicsConnection.status) {
      case EbicsConnections.Status.SETTING_UP_CREDITOR_IDENTIFIER:
        await CompanyAccountService.updateCompany({
          creditorIdentifier: values.creditorIdentifier,
        });
        const res1 = await changeStatusAndGet(ebicsConnectionId, EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK);
        setEbicsConnection(res1);
        break;
      case EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK:
        if (!values.withdrawLimit || !values.creditorName) {
          throw new Error(); //TODO handle
        }
        const res2 = await EbicsService.requestSentToBank(ebicsConnectionId, {
          withdrawLimit: values.withdrawLimit,
          creditorName: values.creditorName,
        });
        setEbicsConnection(res2);
        break;
      case EbicsConnections.Status.INITIALIZING_EBICS:
        if (!values.userId || !values.partnerId) {
          throw new Error(); //TODO handle
        }
        const res3 = await EbicsService.initEbicsConnection(ebicsConnectionId, {
          userId: values.userId,
          partnerId: values.partnerId,
        });
        if (res3) {
          setEbicsConnection(res3);
        } else {
          setStatus('Error from API');
        }
        break;
      case EbicsConnections.Status.SENDING_INI_LETTER:
        const res4 = await EbicsService.sentIniLetter(ebicsConnectionId);
        setEbicsConnection(res4);
        break;
      case EbicsConnections.Status.WAITING_FOR_ACTIVATION:
      case EbicsConnections.Status.ACTIVE:
        onClose();
        break;
      default:
    }
    setSubmitting(false);
  };

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
    >
      {ebicsConnection && (
        <>
          <Stepper ebicsConnection={ebicsConnection} />
          <Divider />
          <Formik
            initialValues={initialValues(ebicsConnection, ebicsConnection.status)}
            onSubmit={onSubmit}
            validationSchema={schemas(t, ebicsConnection.status)}
            enableReinitialize={true}
          >
            {({ values, isValid, dirty, isSubmitting }) => (
              <Form>
                <DialogTitle>{titleByStatus(ebicsConnection.status, t)}</DialogTitle>
                <DialogContent dividers>{getContentByStep(ebicsConnection.status)}</DialogContent>
                <DialogActions>
                  {getBackCheckboxByStep(ebicsConnection.status).show ? (
                    <Button
                      onClick={() => back(values)}
                      name="back"
                      label={getBackCheckboxByStep(ebicsConnection.status).label}
                      variant="text"
                      disabled={isSubmitting}
                    />
                  ) : (
                    <Button variant="text" name="cancel" label={t('global:cancel')} disabled={false} loading={false} onClick={onClose} />
                  )}

                  <Button
                    // onClick={() => next(values, setStatus)}
                    type="submit"
                    name="next"
                    loading={isSubmitting}
                    label={getButtonTextByStep(ebicsConnection.status)}
                    disabled={(!isValid || !dirty) && ebicsConnection.status !== EbicsConnections.Status.WAITING_FOR_ACTIVATION}
                  />
                </DialogActions>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Dialog>
  );
}
