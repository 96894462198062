import { MenuItem } from '@material-ui/core';
import { DebtorActionType } from './DebtorActionHelper';
import { useContext, useState } from 'react';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { useTranslation } from 'react-i18next';
import ChangePaymentMethodDialog from '../dialogs/ChangePaymentMethodDialog';
import { DebtorListDataContext } from '../DebtorListDataContextProvider';
import DebtorService from '../../../services/DebtorService';

const DebtorChangePaymentMethodAction = ({ debtor, setMenuAnchor, label }: DebtorActionType) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const alerting = useContext(AlertContext);
  const { reload } = useContext(DebtorListDataContext);

  const { t } = useTranslation('debitors');

  const changePaymentMethodForDebtor = () => {
    setLoading(true);
    DebtorService.changePaymentMethod(debtor.id)
      .then((paymentMethodSetupLink) => {
        if (paymentMethodSetupLink) {
          setLoading(false);
          alerting.sendAlert('success', t('invitation sent'));
          setOpenDialog(false);
          reload();
        } else {
          alerting.sendAlert('error', t('error'));
        }
      })
      .catch(() => {
        alerting.sendAlert('error', t('error'));
        //TODO handle catch
      });
  };

  return (
    <div key={debtor.id + 'changePayMethC'}>
      <MenuItem
        key={debtor.id + 'changePayMeth'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>

      {openDialog && (
        <ChangePaymentMethodDialog
          debtor={debtor}
          onFinalize={changePaymentMethodForDebtor}
          open={true}
          loading={loading}
          onCancel={() => setOpenDialog(false)}
        />
      )}
    </div>
  );
};

export default DebtorChangePaymentMethodAction;
