import { CircularProgress, Container, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { authService } from '../../../services/AuthService';
import UserInformationForm from '../onboarding/Forms/UserInformationForm';
import PayactiveHeaderLogo from '../../../components/ui/blocks/PayactiveHeaderLogo';
import CentricLayout from '../../../components/ui/layout/CentricLayout';
import registrationFormModel from './registrationFormModel';
import validationSchema from './validationSchema';
import TeamMemberService from '../../../services/TeamMemberService';

const useStyles = makeStyles(() => ({
  main: {
    padding: '1em',
  },
  form: {
    padding: '1em',
    minHeight: '430px',
  },
  navigation: {
    padding: '1em',
  },
  logo: {
    textAlign: 'center',
  },
}));

type Invitation = { emailAddress: string; companyName: string; userAccountExists: boolean };
type InvitationRegistration = {
  invitationId: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirmation: string;
  emailAddress: string;
};

const initialValues: InvitationRegistration = {
  emailAddress: '',
  firstName: '',
  invitationId: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
};

export default function JoinFlow() {
  const history = useHistory();
  const { t } = useTranslation('global');
  const theme = useTheme();
  const { formId, formField } = registrationFormModel;
  const classes = useStyles();
  const { invitationId } = useParams<{ invitationId: string }>();
  const [invitation, setInvitation] = useState<Invitation>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    TeamMemberService.getInvitation(invitationId)
      .then((res: Invitation) => {
        if (res) {
          setInvitation(res);
          initialValues.emailAddress = res.emailAddress;
          setIsLoading(false);
        }
      })
      .catch(() => {
        //TODO handle catch
      });
  }, [invitationId]);

  const _handleSubmit = (values: InvitationRegistration) => {
    TeamMemberService.join(invitationId, {
      invitationId: invitationId,
      registration: values,
    })
      .then((res) => {
        if (res.status === 201) {
          authService.authoriseByResponse(res);
          history.push('/dashboard');
        } else {
          //TODO handle else
        }
      })
      .catch(() => {
        //TODO handle catch
      });
  };

  return (
    <CentricLayout>
      <Container>
        <PayactiveHeaderLogo color={theme.palette.primary.main} />
        <Typography>{t('invitation', { company: invitation?.companyName })}</Typography>
      </Container>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Paper elevation={1}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={_handleSubmit}>
            {({ values }) => (
              <Form id={formId}>
                <Container className={classes.form}>
                  <UserInformationForm values={values} formField={formField} />
                  <Button
                    type="submit"
                    label={t('join', { company: invitation?.companyName })}
                    name={t('join', { company: invitation?.companyName })}
                  />
                </Container>
              </Form>
            )}
          </Formik>
        </Paper>
      )}
    </CentricLayout>
  );
}
