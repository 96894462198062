import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Invoice } from '../../types/Invoice/Invoice';
import { Payment } from '../../types/Payment/Payment';
import { Grid, Paper, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Debtor, Debtors } from '../../types/Debtor/Debtor';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { PEvent } from '../../types/PEvent';
import * as invoiceEventUtil from '../payments/dialogs/InvoiceEventsUtil';
import * as paymentEventUtil from '../payments/dialogs/PaymentEventsUtil';
import * as creditEventUtil from '../credits/dialogs/CreditEventUtil';
import { EventStep } from '../../types/EventStep';
import useDynamicRefs from '../../utils/DynamicRefsHook';
import InvoiceCard from './cards/InvoiceCard';
import PaymentCard from './cards/PaymentCard';
import { Skeleton } from '@material-ui/lab';
import InvoiceService from '../../services/InvoiceService';
import DebtorService from '../../services/DebtorService';
import PaymentService from '../../services/PaymentService';
import DebtorCard from './cards/DebtorCard';
import ReloadContext from '../../components/contexts/ReloadContext';
import CheckoutService from '../../services/CheckoutService';
import { Checkout } from '../../types/Checkout/Checkout';
import CheckoutCard from './cards/CheckoutCard';
import WithDebtor from '../../components/contexts/WithDebtor';
import CreditService from '../../services/CreditService';
import { CreditEvent } from '../../types/Credit/CreditEvent';
import { Credit } from '../../types/Credit/Credit';
import CreditCard from './cards/CreditCard';
import { CreditNote } from '../../types/CreditNote/CreditNote';
import CreditNoteService from '../../services/CreditNoteService';
import CreditNoteCard from './cards/CreditNoteCard';

type TransactionDetailPageProps = {
  source: 'payment' | 'credit' | 'invoice' | 'creditNote' | 'checkout';
};

const useStyles = makeStyles((theme) => ({
  other: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    marginTop: theme.spacing(1),
  },
  alignLeft: {
    textAlign: 'left',
  },
}));

export default function TransactionDetailPage({ source }: TransactionDetailPageProps): ReactElement {
  const { id } = useParams<{ id: string }>();
  const [invoices, setInvoices] = useState<Invoice[]>([] as Invoice[]);
  const [creditNote, setCreditNote] = useState<CreditNote>();
  const [eventSteps, setEventSteps] = useState<EventStep[]>([] as EventStep[]);
  const [getRef, setRef] = useDynamicRefs();
  const [debtor, setDebtor] = useState<Debtor>();
  const [payment, setPayment] = useState<Payment>();
  const [credit, setCredit] = useState<Credit>();
  const [checkout, setCheckout] = useState<Checkout | undefined>();
  const [loaded, setLoaded] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation(['debitors', 'invoices', 'payments', 'credits', 'creditNotes']);
  const tPayment = useTranslation(['payments']).t;

  const fetchInvoices = (id: string) => {
    InvoiceService.getInvoiceReferences(id, true).then((invoices) => {
      setInvoices(invoices);
      invoices.forEach((invoice) => {
        fetchInvoiceEvents(invoice);
      });
    });
  };

  function addEventSteps(steps: EventStep[]) {
    setEventSteps((prevState) => {
      return prevState.concat(steps).sort((a, b) => {
        return b.datetime.getTime() - a.datetime.getTime();
      });
    });
  }

  function fetchInvoiceEvents(i: Invoice) {
    InvoiceService.getInvoiceEvents(i.id).then((invoiceEvents: PEvent[]) => {
      const steps = invoiceEventUtil.eventsToSteps(i, invoiceEvents, t);
      addEventSteps(steps);
    });
  }

  const fetchDebtor = (id: string, email: string) => {
    DebtorService.getDebtor(id)
      .then((res) => {
        if (res) {
          setDebtor(res);
        } else {
          setDebtor({ emailAddress: email, firstName: 'unknown', lastName: 'unknown', type: Debtors.Type.PERSON } as Debtor);
        }
      })
      .catch(() => {
        setDebtor({ emailAddress: email, firstName: 'unknown', lastName: 'unknown', type: Debtors.Type.PERSON } as Debtor);
        //TODO handle catch
      });
  };

  const fetchPayment = (id: string) => {
    PaymentService.getPayment(id, true).then((payment: Payment | undefined) => {
      if (payment === undefined) {
        throw new Error('payment not found');
      }
      setPayment(payment);
      fetchPaymentEvents(payment);
      !debtor && fetchDebtor(payment.debitorRef, payment.debitor.emailAddress);
      checkout === undefined &&
        CheckoutService.getCheckoutForPayment(payment.id)
          .then((checkout) => setCheckout(checkout))
          .catch(() => setCheckout(undefined));
    });
  };

  function fetchPaymentEvents(p: Payment) {
    PaymentService.getPaymentEvents(p.id).then((paymentEvents: PEvent[]) => {
      const steps = paymentEventUtil.eventsToSteps(p, paymentEvents, tPayment);
      addEventSteps(steps);
    });
  }

  const fetchCredit = (id: string) => {
    CreditService.getCredit(id).then((credit: Credit | undefined) => {
      if (credit === undefined) {
        throw new Error('credit not found');
      }
      setCredit(credit);
      fetchCreditEvents(credit);
      !debtor && fetchDebtor(credit.customer.id, credit.customer.emailAddress);
      creditNote === undefined && CreditNoteService.getCreditNoteForCredit(credit.id).then((creditNote) => setCreditNote(creditNote));
    });
  };

  function fetchCreditEvents(credit: Credit) {
    CreditService.getCreditEvents(credit.id).then((creditEvents: CreditEvent[]) => {
      const steps = creditEventUtil.eventsToSteps(credit, creditEvents, t);
      addEventSteps(steps);
    });
  }

  const fetchCreditNote = (id: string) => {
    CreditNoteService.getCreditNoteReference(id).then((creditNote) => {
      if (creditNote === undefined) {
        throw new Error('credit note not found');
      }
      setCreditNote(creditNote);
      fetchCredit(creditNote.creditId);
      !debtor && fetchDebtor(creditNote.debtor.id, creditNote.debtor.emailAddress);
    });
  };

  function fetchCheckout(id: string) {
    CheckoutService.getCheckout(id)
      .then((checkout) => {
        setCheckout(checkout);
        setDebtor(checkout.debtor);
      })
      .catch(() => setCheckout(undefined));
  }

  useEffect(() => {
    if (!loaded) {
      switch (source) {
        case 'invoice':
          fetchInvoices(id);
          break;
        case 'payment':
          fetchPayment(id);
          break;
        case 'creditNote':
          fetchCreditNote(id);
          break;
        case 'credit':
          fetchCredit(id);
          break;
        case 'checkout':
          fetchCheckout(id);
          break;
      }
      setLoaded(true);
    }
    if (payment && invoices.length === 0) {
      if (payment.invoice && payment.invoice.internalRef) {
        fetchInvoices(payment.invoice.internalRef);
      }
    }
    if (invoices.length !== 0 && !payment) {
      for (let i in invoices) {
        if (invoices[i].paymentId) {
          fetchPayment(invoices[i].paymentId);
          break;
        }
      }
    }
    if (checkout && !!checkout.paymentIdRef && !payment) {
      fetchPayment(checkout.paymentIdRef);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, payment, credit, invoices, creditNote, loaded, checkout]);

  const reload = () => {
    setEventSteps([]);
    if (payment) {
      fetchPayment(payment.id);
    }
    if (credit) {
      fetchCredit(credit.id);
    }
    if (invoices.length !== 0) {
      fetchInvoices(invoices[0].id);
    }
    if (checkout) {
      fetchCheckout(checkout.id);
    }
  };

  function highlight(relatedObject: string) {
    let current = (getRef(relatedObject) as React.RefObject<any>).current;
    if (current && current.className.indexOf('MuiPaper-elevation10') === -1) {
      current.className = current.className + ' MuiPaper-elevation10';
    }
  }

  function removeHighlight(relatedObject: string) {
    let current = (getRef(relatedObject) as React.RefObject<any>).current;
    current.className = current.className.replace('MuiPaper-elevation10').trim();
  }

  return (
    <WithDebtor debtor={debtor}>
      <Grid container spacing={2} direction={'column'}>
        <Grid item alignItems="center" spacing={2} md={12}>
          <DebtorCard debtor={debtor} />
        </Grid>
        <Grid item alignItems="center" spacing={2}></Grid>

        {/* lower container */}
        <Grid item container justifyContent="space-evenly" spacing={2} alignItems={'flex-start'}>
          <ReloadContext.Provider value={{ reload: reload }}>
            {/* checkout */}
            {checkout && (
              <Grid key={checkout?.id} item xl={3} md={3}>
                <CheckoutCard checkout={checkout} active={source === 'checkout'} />
              </Grid>
            )}

            {/* invoices */}
            {invoices.length !== 0 && (
              <Grid item container justifyContent="center" alignItems={'stretch'} xl={3} md={3} direction={'column-reverse'}>
                {invoices.map((invoice, index) => (
                  <>
                    {index !== 0 && (
                      <Grid key={'arrow'} item container justifyContent={'center'} style={{ margin: '20px 0' }} md={12}>
                        <ArrowUpwardIcon></ArrowUpwardIcon>
                      </Grid>
                    )}
                    <Grid key={invoice?.id} item xl={12} md={12}>
                      <InvoiceCard invoice={invoice} setRef={setRef} active={source === 'invoice' && invoice.id === id} />
                    </Grid>
                  </>
                ))}
              </Grid>
            )}

            {/* credit note */}
            {creditNote && (
              <Grid key={creditNote?.id} item xl={3} md={3}>
                <CreditNoteCard creditNote={creditNote} setRef={setRef} />
              </Grid>
            )}

            {/* Payment */}
            {payment && (
              <Grid key={payment?.id} item xl={3} md={3}>
                <PaymentCard payment={payment} active={source === 'payment'} setRef={setRef} />
              </Grid>
            )}

            {/* Credit */}
            {credit && (
              <Grid key={credit?.id} item xl={3} md={3}>
                <CreditCard credit={credit} active={source === 'credit'} setRef={setRef} />
              </Grid>
            )}
          </ReloadContext.Provider>

          {/* Events */}

          <Grid key={'events'} item className={classes.alignLeft} xl={3} md={3}>
            <Stepper orientation="vertical" activeStep={-1}>
              {eventSteps.map((step, index) => (
                <Step
                  key={step.label + index}
                  active={true}
                  onMouseOver={() => highlight(step.relatedObject)}
                  onMouseLeave={() => removeHighlight(step.relatedObject)}
                >
                  <StepLabel icon={step.icon}>{step.label}</StepLabel>

                  <StepContent>
                    {step.content}
                    <br />
                    {step.user}
                    {step.reason && <p>{step.reason}</p>}
                    {step.reasonMessage && (
                      <Paper elevation={0} className={classes.other}>
                        {step.reasonMessage}
                      </Paper>
                    )}
                  </StepContent>
                </Step>
              ))}
              {eventSteps.length === 0 && <Skeleton variant="rect" />}
            </Stepper>
          </Grid>
        </Grid>
      </Grid>
    </WithDebtor>
  );
}
