import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import CheckoutRow from './CheckoutRow';
import TableHeader from '../../../components/table/TableHeader';
import TablePagination from '../../../components/table/Pagination';
import { CheckoutListDataContext } from '../context/CheckoutListDataContext';
import { Skeleton } from '@material-ui/lab';

type CheckoutsTableProps = {
  showPagination: Boolean;
};

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: '200px',
  },
  main: {
    padding: theme.spacing(1),
  },
}));

const CheckoutsTable = ({ showPagination }: CheckoutsTableProps) => {
  const { t } = useTranslation('checkouts');
  const classes = useStyles();
  const context = useContext(CheckoutListDataContext);
  const preColumnsForCheckout = !context.isPrefilteredForDebtor
    ? [
        {
          label: t('status'),
          id: 'state',
        },
        {
          label: t('debtor name'),
          id: 'debtor',
        },
      ]
    : [];
  const columns = preColumnsForCheckout.concat([
    {
      label: t('product title'),
      id: 'productTitle',
    },
    {
      label: t('product price'),
      id: 'productPrice',
    },

    {
      label: t('creationDate'),
      id: 'createdDateTimeUTC',
    },
    {
      label: t('CustomFields'),
      id: 'customFields',
    },
  ]);

  const initalLoad = !context.page && context.loading;
  const emptyResultList = !!context.page && context.checkouts.length === 0;
  const emptyFilteredResultList = emptyResultList && context.filters?.used;

  return (
    <>
      {emptyResultList ? (
        <>
          {emptyFilteredResultList ? (
            <Typography style={{ marginTop: '40px' }}>{t('no results for your search')}</Typography>
          ) : (
            <Grid item xs={12} className={classes.main}>
              <MuiAlert elevation={6} variant="filled" severity="warning">
                {t('no checkouts yet')}
              </MuiAlert>
            </Grid>
          )}
        </>
      ) : (
        <>
          <TableContainer style={{ flex: '1 1 auto' }}>
            <Table>
              <TableHeader columns={columns} actions={true}></TableHeader>
              <TableBody>
                <>
                  {initalLoad && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1}>
                        <Skeleton className={classes.skeleton} />
                      </TableCell>
                    </TableRow>
                  )}
                  {context.checkouts?.map((checkout, index) => (
                    <CheckoutRow checkout={checkout} index={index} key={checkout.id}></CheckoutRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          {showPagination && <TablePagination transformPageSizeLabel={(pageSize) => t('pageSize', { pageSize: pageSize }) as string} />}
        </>
      )}
    </>
  );
};
export default CheckoutsTable;
