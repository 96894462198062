import { CompanyAccount } from '../types/Company/Company';
import { User } from '../types/User/User';
import { BankAccountList } from '../types/Bank/BankAccount';

export default class ClientCache {
  private _user: Promise<User> | undefined;
  private _companyAccount: Promise<CompanyAccount> | undefined;
  private _bankAccounts: Promise<BankAccountList> | undefined;

  public clearCache() {
    this._user = undefined;
    this._companyAccount = undefined;
    this._bankAccounts = undefined;
  }

  get user(): Promise<User> | undefined {
    return this._user;
  }

  set user(value: Promise<User> | undefined) {
    this._user = value;
  }

  get companyAccount(): Promise<CompanyAccount> | undefined {
    return this._companyAccount;
  }

  set companyAccount(value: Promise<CompanyAccount> | undefined) {
    this._companyAccount = value;
  }

  set bankAccounts(value: Promise<BankAccountList> | undefined) {
    this._bankAccounts = value;
  }

  get bankAccounts(): Promise<BankAccountList> | undefined {
    return this._bankAccounts;
  }
}
