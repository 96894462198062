import { Chip, Grid, makeStyles } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MuiAlert from '@material-ui/lab/Alert';
import { Button } from '@payactive/app-common';
import IBAN from 'iban';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../components/ui/blocks/DataTable';
import PageHeader from '../../../components/ui/blocks/PageHeader';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import ConnectBankAccount from './ConnectBankAccount';
import EbicsOnboardingDialog from './sepa/EbicsOnboardingDialog';
import TransactionsExportDialog from './TransactionsExportDialog';
import EditBankAccountDialog from './EditBankAccountDialog';
import BankService from '../../../services/BankService';
import EbicsService from '../../../services/EbicsService';
import BankAccountStatus from './BankAccountStatus';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
  },
}));

//TODO to Typescript
export default function BankAccountScreen() {
  const alerting = useContext(AlertContext);
  const { t } = useTranslation(['company', 'global']);
  const classes = useStyles();

  const [ebicsConnectionId, setEbicsConnectionId] = useState(null);
  const [editBankAccount, setEditBankAccount] = useState(false);

  const [bankAccounts, setBankAccounts] = useState([]);
  const [bankAccount, setBankAccount] = useState(null);
  const [openConnectDialog, setOpenConnectDialog] = useState(false);
  const [showTransactionsExportDialog, setShowTransactionsExportDialog] = useState(false);
  const [accountId, setAccountId] = useState();
  const [allowTransactionsCsv, setAllowTransactionsCsv] = useState(false);
  useEffect(() => {
    loadBankAccounts();
  }, []);

  const loadBankAccounts = () => {
    Promise.all([BankService.getBankAccounts(true), EbicsService.getEbicsBics()]).then((values) => {
      const bics = values[1];
      const accounts = values[0].bankAccounts.map((bankAccount) => ({
        ...bankAccount,
        ebicsEnabled: bankAccount.bic in bics,
      }));
      setAllowTransactionsCsv(values[0].allowTransactionsCsv);
      setBankAccounts(accounts);
    });
  };

  const onConnectFinish = () => {
    alerting.sendAlert('success', t('bank accounts connected'));
    setOpenConnectDialog(false);
    loadBankAccounts();
  };

  const onConnectCancelled = () => {
    setOpenConnectDialog(false);
  };

  const showDefault = (bankAccount) => {
    if (bankAccount.default) {
      return <StarIcon color="secondary" />;
    } else {
      return <StarBorderIcon onClick={() => makeBankAccountAsDefault(bankAccount)} style={{ cursor: 'pointer' }} />;
    }
  };

  function makeBankAccountAsDefault(account) {
    BankService.updateBankAccount(account.id, { default: true }).then((res) => {
      alerting.sendAlert('success', t('default_bank_account'));
      loadBankAccounts();
    });
  }

  const onSuccess = () => {
    setEditBankAccount(false);
    alerting.sendAlert('success', t('patch_bank_account'));
    loadBankAccounts();
  };

  const onAction = (action, account) => {
    switch (action.id) {
      case 'edit':
        setBankAccount(account);
        setEditBankAccount(true);
        break;
      case 'copyId':
        navigator.clipboard.writeText(account.id).then(() => alerting.sendAlert('success', t('copied')));
        break;
      case 'makeDefault':
        makeBankAccountAsDefault(account);
        break;
      case 'activateDirectDebit':
        setEbicsConnectionId(account.ebicsConnectionReference);
        break;
      case 'downloadTransactions':
        setAccountId(account.id);
        setShowTransactionsExportDialog(true);
        break;
      default:
    }
  };

  const filterActions = (row, actions) =>
    actions(row).filter((action) => {
      switch (action.id) {
        case 'edit':
          return true;
        case 'activateDirectDebit':
          return !row.directDebitActive;
        case 'downloadTransactions':
          return allowTransactionsCsv;
        case 'copyId':
        case 'makeDefault':
          return true;
        default:
          return false;
      }
    });

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader title={t('bank account')} subtitle={t('settings')} />
        <Button label={t('connect bank accounts')} name="connect" icon="power" onClick={() => setOpenConnectDialog(true)} />
      </Grid>
      {bankAccounts.length > 0 ? (
        <DataTable
          columns={[
            {
              label: 'Default',
              id: 'default',
            },
            {
              label: 'Bank',
              id: 'bankName',
            },
            {
              label: 'Label',
              id: 'bankAccountLabel',
            },
            {
              label: 'BIC',
              id: 'bic',
            },
            {
              label: 'IBAN',
              id: 'iban',
            },
            {
              label: t('global:payment methods'),
              id: 'paymentMethods',
            },
            {
              label: 'Status',
              id: 'status',
            },
          ]}
          rows={bankAccounts}
          cellTransformations={{
            iban: (iban) => IBAN.printFormat(iban),
            default: (p, row) => showDefault(row),
            status: (p, row) => <BankAccountStatus bankAccount={row} t={t} onRenewal={() => setOpenConnectDialog(true)} />,
            paymentMethods: (p, row) => (
              <>
                <p>
                  <Chip label={t('global:online payment')} color="secondary" />
                </p>
                {row.ebicsEnabled && row.directDebitActive && <Chip label={t('global:direct debit')} color="secondary" variant="default" />}
                {row.ebicsEnabled && !row.directDebitActive && (
                  <Chip
                    label={t('global:direct debit')}
                    color="secondary"
                    variant="outlined"
                    onClick={() => setEbicsConnectionId(row.ebicsConnectionReference)}
                  />
                )}
              </>
            ),
          }}
          showPagination={false}
          actions={(row) => {
            return [
              { id: 'edit', label: t('edit') },
              { id: 'copyId', label: t('copy id') },
              { id: 'makeDefault', label: t('make default') },
              {
                id: 'activateDirectDebit',
                label: t('activateDirectDebit'),
                isHighlighted: row.ebicsEnabled && !row.directDebitActive,
              },
              { id: 'downloadTransactions', label: t('download transactions') },
            ];
          }}
          filterActionsPerRow={filterActions}
          onAction={onAction}
          showActionNotifications={(row) => (row.ebicsEnabled && !row.directDebitActive ? 1 : 0)}
        />
      ) : (
        <Grid item xs={12} className={classes.main}>
          <Alert severity="warning">{t('no bank account connected')}</Alert>
        </Grid>
      )}

      {openConnectDialog && <ConnectBankAccount open={true} onFinish={onConnectFinish} onCancelled={onConnectCancelled} />}

      {ebicsConnectionId && <EbicsOnboardingDialog ebicsConnectionId={ebicsConnectionId} onClose={() => setEbicsConnectionId(null)} />}

      {showTransactionsExportDialog && <TransactionsExportDialog onClose={() => setShowTransactionsExportDialog(false)} accountId={accountId} />}

      {editBankAccount && <EditBankAccountDialog onSuccess={onSuccess} onClose={() => setEditBankAccount(false)} bankAccount={bankAccount} />}
    </>
  );
}
