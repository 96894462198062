import { CheckoutLinkActionType } from './CheckoutLinkActionHelper';
import { useContext, useState } from 'react';
import { CheckoutLinksDataContext } from '../CheckoutLinksDataContext';
import { MenuItem } from '@material-ui/core';
import { EditPaymentLinkDialog } from '../dialogs/EditPaymentLinkDialog';

const CheckoutLinkEditAction = ({ checkoutLink, setMenuAnchor, label }: CheckoutLinkActionType) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { reload } = useContext(CheckoutLinksDataContext);
  return (
    <div key={checkoutLink.id + 'editC'}>
      <MenuItem
        key={checkoutLink.id + 'edit'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>

      {openDialog && (
        <EditPaymentLinkDialog
          isOpen={openDialog}
          checkoutLink={checkoutLink}
          onCancel={() => {
            setOpenDialog(false);
            reload();
          }}
        />
      )}
    </div>
  );
};

export default CheckoutLinkEditAction;
