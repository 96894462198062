import { Button, FormControl, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxField from '../../../../../components/FormFields/CheckboxField';
import { bankInfoByBic } from '../banks';
import EbicsService from '../../../../../services/EbicsService';

interface Props {
  ebicsConnectionId: string;
  ebicsConnection: any;
}

export default function SendingIniLetterForm({ ebicsConnectionId, ebicsConnection }: Props): ReactElement {
  const { t } = useTranslation('sepa');

  const downloadIniLetter = async () => {
    await EbicsService.downloadIniLetter(ebicsConnectionId);
  };

  return (
    <>
      <p>
        <Typography>{t('please download the ini letter')}</Typography>
      </p>
      <p>
        <Button onClick={downloadIniLetter} variant="contained" color="primary" name="download-request">
          {t('download ini letter')}
        </Button>
      </p>
      <Alert severity="info" variant="outlined">
        <>{bankInfoByBic(ebicsConnection.bic).iniProcessInfo}</>
      </Alert>
      <p>
        <FormControl component="fieldset">
          <CheckboxField name="ini_nextStep" label={<Typography>{t('i have send the ini letter')}</Typography>} />
        </FormControl>
      </p>
    </>
  );
}
