import { CreditNote } from '../../../types/CreditNote/CreditNote';
import { useTranslation } from 'react-i18next';
import { useRowStyles } from '../../../utils/TableRowHelper';
import React, { useContext, useState } from 'react';
import { Avatar, Badge, Grid, Icon, IconButton, Menu, TableCell, TableRow } from '@material-ui/core';
import StateWithIcon from '../../../components/StateWithIcon';
import LinkToDebtorDetailPage from '../../debtorDetailPage/LinkToDebtorDetailPage';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import getActionsForCreditNote from '../actions/CreditNoteActionHelper';
import { CreditNotesListDataContext } from '../CreditNotesListDataContextProvider';

export default function CreditNotesRow({ creditNote, index }: { creditNote: CreditNote; index: number }) {
  const { t } = useTranslation(['debitors', 'invoices']);
  const classes = useRowStyles();

  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);

  const isPrefiltered = useContext(CreditNotesListDataContext).isPrefilteredForDebtor;
  const actions = getActionsForCreditNote(creditNote, () => setMenuAnchor(undefined), setMenuAnchor);

  return (
    <TableRow hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <>
        <TableCell key={creditNote.id + 'status'}>
          <Grid container>
            <Grid item>
              <StateWithIcon state={creditNote.status} label={t(`invoices:${creditNote.status}`)} />
            </Grid>
          </Grid>
        </TableCell>
        {!isPrefiltered && (
          <TableCell key={creditNote.id + 'debtor'}>
            <LinkToDebtorDetailPage target={'creditNotes'} debtor={creditNote.debtor} debtorRef={creditNote.debtor.id}></LinkToDebtorDetailPage>
          </TableCell>
        )}
        <TableCell key={creditNote.id + 'total'}>{formatCurrency(creditNote.total)}</TableCell>
        <TableCell key={creditNote.id + 'creationDate'}>{formatDateTime(creditNote.createdDateTimeUTC)}</TableCell>
        <TableCell key={creditNote.id + 'number'}>{creditNote.invoiceNumber}</TableCell>

        <TableCell key={creditNote.id + 'action'}>
          {!!actions && (
            <Badge badgeContent={0} color="error" overlap="circular">
              <IconButton onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <Avatar className={classes.avatar}>
                  <Icon>more_horiz</Icon>
                </Avatar>
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuAnchor(undefined)}
                PaperProps={{
                  className: classes.menu,
                  style: {
                    width: 'min-content',
                  },
                }}
              >
                {actions}
              </Menu>
            </Badge>
          )}
        </TableCell>
      </>
    </TableRow>
  );
}
