import { makeStyles } from '@material-ui/styles';
import { FormikInputField } from '@payactive/app-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
export default function UserInformationForm(props) {
  const { t } = useTranslation(['onboarding', 'validations']);
  const classes = useStyles();
  const {
    formField: { firstName, lastName, emailAddress, password, passwordConfirmation },
    values,
  } = props;
  return (
    <>
      {values.invitationType === 'ONBOARD_AGENT_CLIENT' ? (
        <FormikInputField className={classes.formControl} name={emailAddress.name} label={emailAddress.label} fullWidth />
      ) : (
        <FormikInputField
          className={classes.formControl}
          disabled
          name={emailAddress.name}
          label={emailAddress.label}
          value={values.emailAddress}
          fullWidth
        />
      )}
      <FormikInputField className={classes.formControl} name={firstName.name} label={t('validations:firstName')} fullWidth />
      <FormikInputField className={classes.formControl} name={lastName.name} label={t('validations:lastName')} fullWidth />
      <FormikInputField className={classes.formControl} type="password" name={password.name} label={t('validations:password')} fullWidth />
      <FormikInputField
        className={classes.formControl}
        type="password"
        name={passwordConfirmation.name}
        label={t('validations:password_confirmation')}
        fullWidth
      />
    </>
  );
}
