import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../components/contexts/WithAlertContext';
import { ServiceContext } from '../../components/contexts/WithServiceContext';
import PageHeader from '../../components/ui/blocks/PageHeader';
import DeactivatedDialog from './DeactivatedDialog';
import DescriptionPaper from './DescriptionPaper';
import agentImg from './images/agent.png';
import paymentImg from './images/payment.png';
import subscriptionImg from './images/subscription.png';
import ServiceBox from './ServiceBox';
import TermsAndConditionsDialog from './TermsAndConditionsDialog';
import FeatureService from '../../services/FeatureService';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  fragment: {
    display: 'flex',
    flexDirection: 'row',
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
  },
  serviceTitle: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
    color: 'secondary',
  },
  paketTitle: {
    marginTop: theme.spacing(6),
    textAlign: 'left',
    color: 'secondary',
  },
  subtitle: {
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  hide: {
    visibility: 'hidden',
  },
  secondGrid: {
    marginRight: '100px',
  },
  deactivate: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  activate: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
}));
//TODO
// to TypeScript
// reduce complexity

export default function Services() {
  const [servicesList, setServicesList] = useState([]);
  const [isOpenPaper, setIsOpenPaper] = useState(false);
  const [res, setRes] = useState([]);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedServiceObject, setSelectedServiceObject] = useState(null);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation(['services', 'company']);

  const alerting = useContext(AlertContext);
  const productState = useContext(ServiceContext);

  const servicesArray = [
    {
      title: t('transactions title'),
      subtitle: t('transactions subtitle'),
      name: 'subscription',
      info: t('subscription info'),
      link: t('subscription info link'),
      service: 'SUBSCRIPTION_SERVICE',
      image: subscriptionImg,
      // information: t('agent information'),
      // information: t('subscription information'),
      description: [t('subscription desc 1'), t('subscription desc 2'), t('subscription desc 3')],
      prices: [
        {
          tagLine: t('per transaction'),
          price: {
            value: 0.6,
            unit: '%',
          },
          smallPrint: 'min: 0,30€, max: 2€. ' + t('different conditions'),
        },
      ],
    },
    {
      second: true,
      title: t('transactions title'),
      subtitle: t('transactions subtitle'),
      name: 'payments',
      info: t('payment info'),
      link: t('payment info link'),
      service: 'PAYMENT_SERVICE',
      image: paymentImg,
      description: [t('payments desc 1'), t('payments desc 2'), t('payments desc 3')],
      prices: [
        {
          tagLine: t('per transaction'),
          price: {
            value: 1.4,
            unit: '%',
          },
          smallPrint: 'min: 0,30€, max: 2€. ' + t('different conditions'),
        },
      ],
    },
    {
      title: t('other services title'),
      subtitle: t('other services subtitle'),
      name: 'agent',
      info: t('agent service info'),
      link: t('agent info link'),
      service: 'AGENT_SERVICE',
      image: agentImg,
      information: t('agent information'),
      description: [t('agent desc 1'), t('agent desc 2'), t('agent desc 3')],
      prices: [
        {
          tagLine: t('per') + ' ' + t('agent title'),
          price: {
            value: 2,
            unit: '€',
          },
          smallPrint: t('per') + ' ' + t('month'),
        },
        {
          tagLine: t('per transaction'),
          price: {
            value: 1.4,
            unit: '%',
          },
          smallPrint: 'min: 0,30€, max: 2€. ' + t('different conditions'),
        },
      ],
    },
  ];

  const isServiceActive = (serviceId) => servicesList?.some((service) => service.service === serviceId);

  useEffect(() => {
    loadServices();
  }, [res]);

  const loadServices = () => {
    FeatureService.getServices().then((services) => {
      setServicesList(services);
    });
  };

  const handleChange = (service) => {
    setSelectedServiceObject(service);
    const hasTheServiceActive = servicesList.some((e) => e['service'] === service.service);
    if (hasTheServiceActive) {
      setIsDeactivated(true);
      servicesList.forEach((serv) => {
        if (serv.service === service.service) {
          setSelectedId(serv);
        }
      });
    } else {
      setIsOpenPaper(true);
    }
  };

  const termsAndConditions = () => {
    selectedServiceObject.service === 'AGENT_SERVICE' ? setShowTermsAndConditions(true) : handleActivate();
  };

  const handleActivate = () => {
    const filterServiceKey = { service: selectedServiceObject.service };
    FeatureService.activateService(filterServiceKey).then((res) => {
      if (res) {
        setRes(res);
        alerting.sendAlert('success', t('success activated'));
        productState.serviceActivated(selectedServiceObject.service);
      }
    });
    closeHandler();
    loadServices();
  };

  const handleDeactivate = (serv) => {
    setIsOpenPaper(false);
    FeatureService.deactivateServices(serv.id).then((res) => {
      if (res) {
        loadServices();
        setRes(res);
        alerting.sendAlert('success', t('success deactivated'));
        productState.serviceDeactivated(serv.service);
      }
    });
  };

  const closeHandler = () => {
    setIsOpenPaper(false);
  };
  let hasTheServiceActive;
  const serviceButtonLabel = (service) => {
    hasTheServiceActive = servicesList.some((e) => e['service'] === service.service);
    if (hasTheServiceActive) {
      return t('deactivate button');
    } else {
      return t('activate button');
    }
  };

  return (
    <div className={classes.fragment}>
      <div>
        <PageHeader title={t('services and pakete')} subtitle={t('company:settings')} />
        <Grid container direction="row" alignContent="left" spacing={2}>
          {servicesArray.map((service, index) => (
            <Grid item key={index} /* className={`${service.second && classes.secondGrid}`} */>
              <Typography color="secondary" className={`${classes.serviceTitle} ${service.second && classes.hide}`} variant="body1">
                {service.title}
              </Typography>
              <Typography color="secondary" className={`${classes.subtitle} ${service.second && classes.hide}`}>
                {service.subtitle}
              </Typography>
              <ServiceBox
                handleChange={() => handleChange(service)}
                servicesList={servicesList}
                service={service}
                buttonLabel={() => serviceButtonLabel(service)}
                isActive={isServiceActive(service.service)}
              />
            </Grid>
          ))}
        </Grid>
        {/* - Hiding subscription packages because of SE-1514 }
        <Typography color="secondary" className={classes.paketTitle} variant="body1">
          {t('pakets title')}{' '}
        </Typography>
        <Grid container direction="row" alignContent="left" spacing={10}>
          {paketsArray.map((paket, i) => (
            <Grid item key={i}>
              <PerformancePaket paket={paket} />
            </Grid>
          ))}
        </Grid>
        {*/}
      </div>
      {isOpenPaper && !!selectedServiceObject && (
        <DescriptionPaper open={true} onClose={closeHandler} service={selectedServiceObject} activate={termsAndConditions} />
      )}
      {isDeactivated && (
        <DeactivatedDialog
          onClose={() => setIsDeactivated(null)}
          onDeactivate={() => handleDeactivate(selectedId)}
          open={true}
          service={selectedServiceObject}
        />
      )}

      {showTermsAndConditions && <TermsAndConditionsDialog onClose={() => setShowTermsAndConditions(false)} open={true} activate={handleActivate} />}
    </div>
  );
}
