import { Payment } from '../../../types/Payment/Payment';
import { TFunction } from 'i18next';
import PaymentCancelAction from './PaymentCancelAction';
import PaymentEventAction from './PaymentEventAction';
import PaymentMarkAsPaidAction from './PaymentMarkAsPaidAction';
import PaymentReminderAction from './PaymentReminderAction';
import PaymentLinkCopyAction from './PaymentLinkCopyAction';
import PaymentRefundCompleteAction from './PaymentRefundCompleteAction';
import React from 'react';
import PaymentDetailsAction from './PaymentDetailsAction';
import { PaymentMethod } from '../../../types/Invoice/PaymentMethod';
import PaymentMarkAsDonationAction from './PaymentMarkAsDonationAction';
import { CompanyAccount } from '../../../types/Company/Company';
import PaymentDownloadDonationAction from './PaymentDownloadDonationAction';

const getActionsForPayment = (payment: Payment, setMenuAnchor: (x: any) => void, t: TFunction, cardView = false, company?: CompanyAccount) => {
  const actions = [];

  if (['PENDING', 'CHARGED_BACK', 'MANUAL', 'ABORTED', 'ERROR', 'CREATING'].includes(payment.state) && payment.paymentMethod === 'ONLINE_PAYMENT') {
    actions.push(<PaymentCancelAction payment={payment} setMenuAnchor={setMenuAnchor} label={t('cancel')} />);
  }
  if (['PENDING', 'CHARGED_BACK', 'MANUAL', 'ABORTED', 'ERROR', 'COMPLETED'].includes(payment.state) && payment.paymentMethod === 'ONLINE_PAYMENT') {
    actions.push(<PaymentMarkAsPaidAction payment={payment} setMenuAnchor={setMenuAnchor} label={t('mark_verified')} />);
  }
  if (
    company?.accountSettings?.isDonationReceiptsEnabled &&
    !payment.donation &&
    !['ABORTED', 'ERROR', 'CANCELLED', 'REFUND_IN_PROGRESS', 'REFUND_COMPLETED'].includes(payment.state)
  ) {
    actions.push(<PaymentMarkAsDonationAction payment={payment} setMenuAnchor={setMenuAnchor} label={t('mark_donation')} />);
  }
  if (['PENDING', 'CHARGED_BACK', 'MANUAL', 'ERROR', 'ABORTED'].includes(payment.state) && payment.paymentMethod === 'ONLINE_PAYMENT') {
    actions.push(<PaymentLinkCopyAction payment={payment} setMenuAnchor={setMenuAnchor} label={t('copy payment link')} />);
  }
  if (['REFUND_IN_PROGRESS'].includes(payment.state)) {
    actions.push(<PaymentRefundCompleteAction payment={payment} setMenuAnchor={setMenuAnchor} label={t('refund complete')} />);
  }
  if (payment.paymentMethod !== PaymentMethod.DIRECT_DEBIT && ['PENDING', 'CHARGED_BACK', 'MANUAL', 'ABORTED'].includes(payment.state)) {
    actions.push(<PaymentReminderAction payment={payment} setMenuAnchor={setMenuAnchor} label={t('send payment reminder')} />);
  }
  if (!cardView) {
    actions.push(<PaymentEventAction payment={payment} setMenuAnchor={setMenuAnchor} label={t('events')} />);
    actions.push(<PaymentDetailsAction payment={payment} setMenuAnchor={setMenuAnchor} label={t('Details')} />);
  }
  if (company?.accountSettings?.isDonationReceiptsEnabled && payment.donation?.documentPublicUrl) {
    actions.push(<PaymentDownloadDonationAction payment={payment} setMenuAnchor={setMenuAnchor} label={t('download_donation')} />);
  }

  return actions;
};
export type PaymentActionType = {
  payment: Payment;
  setMenuAnchor: (x: any) => void;
  label: string;
};

export { getActionsForPayment };
