import { AxiosInstance } from 'axios';
import ApiClient from './ApiClient';
import { Page, PageMetadata } from '../types/Page';
import { CreditNote, CreditNoteFilter } from '../types/CreditNote/CreditNote';
import { authService } from './AuthService';
import { CreditNotePDF } from '../types/CreditNote/CreditNotePDF';
import { prepareFilterParameters } from '../types/Filter';

export default class CreditNoteService extends ApiClient {
  private static get creditNoteClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }

  public static async getAllCreditNotes(size: number, page: any, sort: string, filters: CreditNoteFilter) {
    let params: CreditNoteFilter & { size: number; page: any; sort: string } = {
      size: size,
      page: page,
      sort: 'createdDateTimeUTC,' + sort,
      ...prepareFilterParameters(filters),
    };

    let res = await this.creditNoteClient.request<Page<CreditNote, 'creditNotes'>>({
      method: 'GET',
      url: `${this.apiDomain}/creditNotes`,
      params: params,
      signal: this.abortController.signal,
    });
    if (this.countListener) {
      this.countListener(res.data.page);
    }
    return { results: res.data._embedded?.creditNotes || [], page: res.data.page };
  }

  public static async downloadCreditNote(creditNoteId: string) {
    const headers = {
      Authorization: 'Bearer ' + authService.accessToken,
      'Content-Type': 'application/pdf; charset=utf-8',
      Accept: 'application/pdf; charset=utf-8',
    };

    let res = await this.creditNoteClient.request<CreditNotePDF>({
      method: 'GET',
      url: `${this.apiDomain}/creditNotes/${creditNoteId}`,
      headers: headers,
    });
    const content = res.data.content;
    const fileName = `${res.data.name}.pdf`;
    CreditNoteService.downloadCreditNoteAsPdf(fileName, content);
  }

  public static async getCreditNoteReference(creditNoteId: string) {
    let params;
    return await this.creditNoteClient
      .request<CreditNote>({
        method: 'GET',
        url: `${this.apiDomain}/creditNotes/${creditNoteId}/references`,
        params: params,
      })
      .then((res) => res.data);
  }

  public static async getCreditNoteForCredit(creditId: string) {
    return await this.creditNoteClient
      .request<CreditNote>({
        method: 'GET',
        url: `${this.apiDomain}/creditNotes/byCreditId/${creditId}`,
      })
      .then((res) => res.data);
  }

  private static downloadCreditNoteAsPdf(filename: string, content: Array<string> | undefined) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:application/pdf;base64,' + content);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
