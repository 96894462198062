import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useContext } from 'react';
import { Button, FormikInputField, FormikSelectField } from '@payactive/app-common';
import { useTranslation } from 'react-i18next';
import { LegalFormsAsArray, requiresHrNumber } from '../../../../components/LegalForms';
import { AddressContext } from '../../../../components/contexts/WithAddressContext';
import AddressInformation from '../../../settings/masterdata/masterdata/AddressInformation';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  select: {
    textAlign: 'left',
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
}));

export default function CompanyInformationForm(props) {
  const classes = useStyles();

  const { t } = useTranslation('company');
  const addressContext = useContext(AddressContext);
  const {
    formField: { companyName, companyLegalForm, companyRegistrationNumber },
    values,
  } = props;
  return (
    <>
      <FormikInputField className={classes.formControl} name={companyName} label={t('companyName')} fullWidth />
      <FormikSelectField className={classes.formControl} name={companyLegalForm} label={t('companyLegalForm')} data={LegalFormsAsArray} fullWidth />
      {requiresHrNumber(values.companyLegalForm) && (
        <FormikInputField className={classes.formControl} name={companyRegistrationNumber} label={t('companyRegistrationNumber')} fullWidth />
      )}
      <Grid item className={classes.buttonContainer}>
        <Button
          label={t('add address')}
          icon="add_box"
          onClick={() => {
            addressContext.updateIsAddress(!addressContext.isAddress);
          }}
        />
      </Grid>
      {addressContext.isAddress && <AddressInformation />}
    </>
  );
}
