import React, { ReactElement, useState } from 'react';

const AlertContext = React.createContext<{
  sendAlert: (severity: Severity, message: string, duration?: number) => void;
  currentAlert: Alert | undefined;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>({
  sendAlert: () => {},
  currentAlert: undefined,
  isOpen: false,
  setIsOpen: () => {},
});

type Severity = 'error' | 'success' | 'warning';

interface Alert {
  severity: Severity;
  message: string;
  duration: number;
}
export default function WithAlertContext({ children }: { children: ReactElement }) {
  const [isOpen, setIsOpen] = useState(false);
  const [alert, setAlert] = useState<Alert>();

  const sendAlert = (severity: Severity, message: string, duration?: number) => {
    setAlert({
      severity: severity,
      message: message,
      duration: duration ? duration : 3000,
    });
    setIsOpen(true);
  };
  return <AlertContext.Provider value={{ currentAlert: alert, sendAlert, isOpen, setIsOpen }}>{children}</AlertContext.Provider>;
}

export { AlertContext };
