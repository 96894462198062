import { MenuItem } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { InvoiceListDataContext } from '../InvoiceListDataContextProvider';
import { InvoiceActionType } from './InvoiceActionHelper';
import InvoiceService from '../../../services/InvoiceService';
import ReloadContext from '../../../components/contexts/ReloadContext';

const InvoiceRefundCompleteAction = ({ invoice, closeMenu, label }: InvoiceActionType) => {
  const { t } = useTranslation(['debitors', 'invoices']);
  const alerting = useContext(AlertContext);
  const invoiceReload = useContext(InvoiceListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;
  const reload = () => {
    invoiceReload();
    contextReload();
  };
  return (
    <MenuItem
      key={invoice.id + 'refund'}
      onClick={() => {
        closeMenu();
        InvoiceService.completesInvoiceRefund(invoice.id).then((res) => {
          if (res) {
            alerting.sendAlert('success', t('successfully marked as refunded'));
            reload();
          } else {
            alerting.sendAlert('success', t('unsuccessfully marked as refunded'));
          }
        });
      }}
    >
      {label}
    </MenuItem>
  );
};

export default InvoiceRefundCompleteAction;
