import { Links } from '../Page';

export type AgentClient = {
  id: string;
  name: string;
  active: boolean;
  createdAt: string;
  _links?: Links;
};

export namespace AgentClients {
  export enum Status {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
  }
}
