import { AxiosInstance, AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import { CheckoutLink, NewCheckoutLink } from '../types/CheckoutLink/CheckoutLink';
import { Page, PageMetadata } from '../types/Page';
import { TeamMember } from '../types/CheckoutLink/TeamMember';

export default class CheckoutLinkService extends ApiClient {
  private static get checkoutLinkClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }

  public static async createCheckoutLink(data: NewCheckoutLink) {
    return await this.checkoutLinkClient.request<NewCheckoutLink>({
      method: 'POST',
      url: this.apiDomain + '/paylinks',
      data: data,
    });
  }

  public static async editCheckoutLink(checkoutLinkId: string, payload: NewCheckoutLink): Promise<{ result: CheckoutLink }> {
    const response = await this.checkoutLinkClient.put<NewCheckoutLink, AxiosResponse<CheckoutLink>>(
      this.apiDomain + '/paylinks/' + checkoutLinkId,
      payload,
    );

    if (response.status === 200) {
      return { result: response.data as CheckoutLink };
    } else {
      throw new Error('CheckoutLink not found');
    }
  }

  public static async getCheckoutLinks(): Promise<{ results: CheckoutLink[]; page: PageMetadata }> {
    let res = await this.checkoutLinkClient.request<Page<CheckoutLink, 'paylinks'>>({
      method: 'GET',
      url: this.apiDomain + '/paylinks',
    });

    return { results: res.data._embedded?.paylinks || [], page: res.data.page };
  }

  public static async deleteCheckoutLink(checkoutLinkId: string): Promise<boolean> {
    let res = await this.checkoutLinkClient.request({
      method: 'DELETE',
      url: this.apiDomain + '/paylinks/' + checkoutLinkId,
    });

    return res.status === 200;
  }

  public static async getCheckoutLinksTeamMembers(): Promise<TeamMember[]> {
    let res = await this.checkoutLinkClient.request<TeamMember[]>({
      method: 'GET',
      url: this.apiDomain + '/paylinks/team-members',
    });

    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error('CheckoutLink members not found');
    }
  }
}
