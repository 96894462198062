import { Box, Grid } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import { Form, useFormikContext } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ColorSetting from './ColorSetting';
import PaperWithTitle from './PaperWithTitle';

interface BrandingSettings {
  brandColor?: string;
  brandTextColor?: string;
}

function LogoAndColors() {
  const { isSubmitting, dirty } = useFormikContext<BrandingSettings>();
  const { t } = useTranslation('branding');

  return (
    <Form>
      <PaperWithTitle title={t('logo and colors')} tooltip={t('logo and colors tooltip')}>
        <Box component="div">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <ColorSetting label={t('brand color')} toolTipText={t('brand color tooltip')} inputFieldName="brandColor" inputFieldLabel="Hex Code" />
            </Grid>
            <Grid item>
              <ColorSetting
                label={t('brand text color')}
                toolTipText={t('brand text color tooltip')}
                inputFieldName="brandTextColor"
                inputFieldLabel="Hex Code"
              />
            </Grid>
          </Grid>
        </Box>
        <Box component="div" mt={1}>
          <Button type="submit" loading={isSubmitting} disabled={!dirty || isSubmitting} name="submit" label={t('global:save')} />
        </Box>
      </PaperWithTitle>
    </Form>
  );
}

export default LogoAndColors;

export type { BrandingSettings };
