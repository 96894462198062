import React, { useState } from 'react';
import { Form } from 'formik';
import { Box, Divider, FormControlLabel, Grid, InputAdornment, makeStyles, Switch, Typography } from '@material-ui/core';
import BankAccountsSuggest from '../../../components/BankAccountSuggest';
import DebtorsAutoSuggest from '../../../components/DebtorsAutoSuggest';
import { FormikInputField, FormikSelectField, FormikSizedInputField } from '@payactive/app-common';
import { useTranslation } from 'react-i18next';
import { Debtor } from '../../../types/Debtor/Debtor';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DateFilter from '../../../components/filter/DateFilter';
import MetadataFields from './MetadataFields';
import { InstalmentPlans } from '../../../types/InstalmentPlan/InstalmentPlan';
import { FormikErrors } from 'formik/dist/types';
import { AnyObject } from 'yup/es/types';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  totalAmount: {
    width: '15%',
  },
  interval: {
    width: '50%',
  },
  startDate: {
    width: '80%',
  },
  select: {
    textAlign: 'left',
  },
  input: {
    padding: theme.spacing(1),
  },
  checkbox: {
    color: '#086992',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
}));

type InstalmentPlanFormProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<AnyObject>>;
  debtor?: Debtor;
  values: any;
};
const InstalmentPlanForm = ({ setFieldValue, debtor, values }: InstalmentPlanFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation('instalmentPlans');

  const [isDisabled, setIsDisabled] = useState(false);
  const [isConnectedBank, setIsConnectedBank] = useState(true);
  const [interval] = useState([{ value: InstalmentPlans.Interval.MONTHLY, label: t(InstalmentPlans.Interval.MONTHLY) }]);

  return (
    <Form>
      <Typography variant="h6">{t('creditor account')}</Typography>

      <BankAccountsSuggest className={classes.input} setFieldValue={setFieldValue} setIsConnectedBank={setIsConnectedBank} />

      <Typography variant="h6">{t('customer')}</Typography>
      <DebtorsAutoSuggest
        className={`${classes.input} ${!isConnectedBank && classes.disabled}`}
        setLocalFieldValue={setFieldValue}
        setIsDisabled={setIsDisabled}
        debtor={debtor || values.debitor}
        directDebitActive={false}
        disabled={isDisabled}
      />
      <br />

      <Typography className={`${isDisabled && classes.disabled}`} variant="h6">
        {t('payment details')}{' '}
      </Typography>

      <FormikInputField
        className={`${classes.input} ${(isDisabled || !isConnectedBank) && classes.disabled}`}
        icon="euro_symbol"
        value={values.totalNetAmount}
        type="number"
        name="totalNetAmount"
        label={t('total amount')}
        fullWidth
      />
      <FormikSizedInputField
        className={`${classes.input} ${(isDisabled || !isConnectedBank) && classes.disabled}`}
        icon="short_text"
        max={120}
        name="purpose"
        label={t('purpose')}
        fullWidth
        value={values.purpose}
      />
      <Typography className={`${(isDisabled || !isConnectedBank) && classes.disabled}`} variant="h6" color={'primary'}>
        {t('rate settings')}
      </Typography>
      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item xs={6}>
          <FormikSelectField name="interval" label={t('interval')} data={interval} className={classes.interval} fullWidth />
        </Grid>
        <Grid item xs={6}>
          <FormikInputField
            type="number"
            name="intervalAmount"
            required
            label={t('rate')}
            placeholder="0,00"
            InputProps={{
              endAdornment: <>{<InputAdornment position="end">€</InputAdornment>}</>,
            }}
          />
        </Grid>
      </Grid>
      <Grid>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => {
                setFieldValue('requireDownPayment', e.target.checked && true);
                setFieldValue('downPayment', undefined);
              }}
              checked={values.requireDownPayment}
              name="requireDownPayment"
            />
          }
          label={t('require down payment')}
        />
      </Grid>
      {values.requireDownPayment && (
        <Grid>
          <FormikInputField
            className={`${classes.input} ${(isDisabled || !isConnectedBank) && classes.disabled}`}
            icon="euro_symbol"
            value={values.downPayment}
            type="number"
            name="downPayment"
            label={t('down payment')}
          />
        </Grid>
      )}

      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item xs={8}>
          <Grid container alignItems={'center'}>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateFilter
                  onChange={(date) => setFieldValue('startDate', date)}
                  label={t('start from')}
                  value={values.startDate}
                  disablePast={true}
                ></DateFilter>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <span> {t('each on')} </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container justifyContent={'flex-start'} alignItems={'center'}>
            <Grid item xs={6}>
              <FormikInputField name="intervalDay" label={t('interval day')} type="number" />
            </Grid>
            <Grid item xs={6}>
              <span> {t('of the month')}</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} item>
          <Box marginTop={1}>
            <Divider />
            <Box marginTop={1}>
              <MetadataFields />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
};

export default InstalmentPlanForm;
