import React, { useState } from 'react';
import PaperlessStatusEventsDialog from '../dialogs/PaperlessStatusEventsDialog';
import { MenuItem } from '@material-ui/core';
import { PEvent } from '../../../types/PEvent';
import { PaymentActionType } from './PaymentActionHelper';
import PaymentService from '../../../services/PaymentService';

const PaymentEventAction = ({ payment, setMenuAnchor, label }: PaymentActionType) => {
  const [statusDialog, setStatusDialog] = useState(false);
  const [paymentEvents, setPaymentEvents] = useState<PEvent[]>([] as PEvent[]);

  return (
    <div key={payment.id + 'eventsC'}>
      <MenuItem
        key={payment.id + 'events'}
        onClick={() => {
          setMenuAnchor(null);
          PaymentService.getPaymentEvents(payment.id).then((events) => {
            setPaymentEvents(events);
            setStatusDialog(true);
          });
        }}
      >
        {label}
      </MenuItem>
      <PaperlessStatusEventsDialog onlinePayment={payment} events={paymentEvents} open={statusDialog} onCancel={() => setStatusDialog(false)} />
    </div>
  );
};

export default PaymentEventAction;
