import { Grid } from '@material-ui/core';
import PageHeader from '../../components/ui/blocks/PageHeader';
import GenericTableFilters, { StateFilter } from '../../components/filter/GenericTableFilters';
import { useTranslation } from 'react-i18next';
import SubscriptionListDataContextProvider, { SubscriptionListDataContext } from './SubscriptionsContextProvider';
import SubscriptionTable from './table/SubscriptionTable';
import { Subscriptions } from '../../types/Subscription/Subscription';
import DebtorPortalLinkButton from './dialogs/DebtorPortalLinkButton';

const SubscriptionsView = () => {
  const { t } = useTranslation(['global', 'payments']);

  const filterArray: StateFilter[] = [
    { name: t('payments:ACTIVE'), value: Subscriptions.Status.ACTIVE },
    { name: t('payments:CANCELLED'), value: Subscriptions.Status.CANCELLED },
    { name: t('payments:CANCELLATION_REQUESTED'), value: Subscriptions.Status.CANCELLATION_REQUESTED },
  ];

  return (
    <SubscriptionListDataContextProvider pageSizeOptions={[5, 10, 30]}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid>
          <PageHeader title={t('payments:subscriptions')} />
        </Grid>
        <Grid>
          <DebtorPortalLinkButton />
        </Grid>
      </Grid>
      <Grid>
        <GenericTableFilters states={filterArray} context={SubscriptionListDataContext} t={t} />
      </Grid>
      <SubscriptionTable showPagination={true} />
    </SubscriptionListDataContextProvider>
  );
};

export default SubscriptionsView;
