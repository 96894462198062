import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../components/LoadingButton';
import CancelFields from '../../payments/dialogs/CancelFields';
import InvoiceService from '../../../services/InvoiceService';
import { CancelReasons } from '../../../types/CancelReasons';

interface Props {
  open: boolean;
  loading: boolean;
  invoicePdf: any;
  onCancel: () => void;
  onFinalize: () => void;
  onDelete: (reasons: CancelReasons) => void;
}

//TODO make independent from pdf, move functions from upper control into this class (onFinalize, loadPDF, ...)
// see CorrectionInvoiceDialog.tsx

export default function CancelInvoiceDialog({ open, loading, onCancel, onFinalize, onDelete, invoicePdf }: Props): ReactElement {
  const [cancellationAccepted, setCancellationAccepted] = useState(false);

  const initialValues = {
    externalReason: '',
    internalReason: '',
  };

  const downloadCancellationInvoice = () => {
    const fileName = `Stornierung_${invoicePdf.name}`;
    InvoiceService.downloadPDF(fileName, invoicePdf.content);
  };

  const _handleSubmit = (reasons: CancelReasons) => {
    onDelete(reasons);
  };

  const handleChange = () => {
    setCancellationAccepted(!cancellationAccepted);
  };

  const { t } = useTranslation('debitors');

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return false;
          }
          onCancel();
        }}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{t('delete title')}</DialogTitle>
        <Alert severity="warning">
          <Typography>{t('delete subtitle')}</Typography>
          <DialogContentText id="alert-dialog-description">
            <Typography>{!!invoicePdf ? t('delete content finalize') : t('delete content title')}</Typography>
          </DialogContentText>
        </Alert>
        {!!invoicePdf ? (
          <>
            <DialogContent>
              <p>
                <Button onClick={downloadCancellationInvoice} variant="contained" color="primary" name={t('preview cancellation')}>
                  {t('preview cancellation')}
                </Button>
              </p>
              <p>
                <FormControlLabel
                  label={t('cancellation invoice reviewed')}
                  control={<Checkbox checked={cancellationAccepted} onChange={handleChange} />}
                />
              </p>
            </DialogContent>
            <DialogActions>
              <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:close')} />
              <LoadingButton disabled={!cancellationAccepted} loading={loading} onClick={onFinalize}>
                {t('finalize and send cancellation')}
              </LoadingButton>
            </DialogActions>
          </>
        ) : (
          <Formik initialValues={initialValues} validationSchema={CancelReasons.schema} onSubmit={_handleSubmit}>
            {({ handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <DialogContent>
                  <CancelFields handleChange={handleChange} handleBlur={handleBlur} />
                </DialogContent>
                <DialogActions>
                  <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:close')} />
                  <LoadingButton loading={isSubmitting} onClick={() => handleSubmit()}>
                    {t('preview cancellation')}
                  </LoadingButton>
                </DialogActions>
              </>
            )}
          </Formik>
        )}
      </Dialog>
    </>
  );
}
