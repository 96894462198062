const setInitialValues = (res, onboardingFormModel, formInitialValues) => {
  formInitialValues[onboardingFormModel.formField.companyName.name] = res.companyAccount?.name ? res.companyAccount?.name : '';
  formInitialValues[onboardingFormModel.formField.companyLegalForm.name] = res.companyAccount?.legalForm ? res.companyAccount?.legalForm : '';
  formInitialValues[onboardingFormModel.formField.companyRegistrationNumber.name] = res.companyAccount?.companyRegistrationNumber
    ? res.companyAccount?.companyRegistrationNumber
    : '';
  formInitialValues[onboardingFormModel.formField.emailAddress.name] = res.to ? res.to : '';
  formInitialValues[onboardingFormModel.formField.firstName.name] = res.userAccount?.firstName ? res.userAccount?.firstName : '';
  formInitialValues[onboardingFormModel.formField.lastName.name] = res.userAccount?.lastName ? res.userAccount?.lastName : '';
  formInitialValues[onboardingFormModel.formField.invitationId.name] = res.invitationId ? res.invitationId : '';
  formInitialValues[onboardingFormModel.formField.invitationType.name] = res.invitationType ? res.invitationType : '';
};
export { setInitialValues };
