import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '../../utils/DateTimeUtil';
import { getDebtorLabel } from '../../utils/LabelHelper';
import DataTable from '../../components/ui/blocks/DataTable';
import LinearProgressWithLabel from '../../components/ui/blocks/LinearProgressWithLabel';
import PageHeader from '../../components/ui/blocks/PageHeader';
import { calculateProgress, formatCurrency } from '../../utils/PaymentUtil';
import StateWithIcon from '../../components/StateWithIcon';
import AgentService from '../../services/AgentService';

//TODO
// to TypeScript
// remove datatable relics

export default function Transactions() {
  const { t } = useTranslation(['payments', 'connectedAccounts']);
  const [trackedTransactions, setTrackedTransactions] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    loadTrackedTransactions();
  }, []);

  const loadTrackedTransactions = () => {
    AgentService.getTrackedTransactions(10, 0, 'desc').then((transactions) => {
      setTrackedTransactions(transactions);
    });
  };

  const handleChangePage = (page) => {
    AgentService.getTrackedTransactions(rowsPerPage, page - 1, 'desc').then((transactions) => {
      setTrackedTransactions(transactions);
    });
  };

  const handleChangeRowsPerPage = (pageSize) => {
    if (pageSize !== rowsPerPage) {
      AgentService.getTrackedTransactions(pageSize, 0, 'desc').then((transactions) => {
        setTrackedTransactions(transactions);
      });
    }
    setRowsPerPage(pageSize);
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader subtitle={t('connectedAccounts:agent clients payments')} title={t('connectedAccounts:transactions')} />
      </Grid>

      <DataTable
        columns={[
          {
            label: t('payments:status'),
            id: 'state',
          },
          {
            label: t('connectedAccounts:agent client'),
            id: 'agentClientCompanyName',
          },
          {
            label: t('connectedAccounts:debitor'),
            id: 'debitor',
          },
          {
            label: t('payments:purpose'),
            id: 'purpose',
          },
          {
            label: t('payments:amount'),
            id: 'amount',
          },
          {
            label: t('payments:creationDate'),
            id: 'createdDateTimeUTC',
          },
          {
            label: t('payments:completion'),
            id: 'complete',
          },
        ]}
        rows={trackedTransactions?.results}
        cellTransformations={{
          amount: (amount) => formatCurrency(amount),
          createdDateTimeUTC: (createdDateTimeUTC) => formatDateTime(createdDateTimeUTC),
          complete: (col, row) => <LinearProgressWithLabel value={calculateProgress(row.state)} />,
          debitor: (debitor) => getDebtorLabel(debitor),

          state: (state) => <StateWithIcon state={state} label={t(state)} />,
        }}
        pageSizeOptions={[10, 30, 50]}
        pageSize={rowsPerPage}
        onPageSizeChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        totalPages={Math.ceil(trackedTransactions?.page?.totalElements / rowsPerPage)}
        transformPageSizeLabel={(pageSize) => t('payments:pageSize', { pageSize: pageSize })}
      />
    </React.Fragment>
  );
}
