import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';
import { ListItem, ListItemText, makeStyles, Switch, Tooltip, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import GridSetting from './GridSetting';

const useStyles = makeStyles((theme) => ({
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  paymentService: {
    textAlign: 'left',
    display: 'block',
    color: '#0198B5',
    marginBottom: theme.spacing(2),
    fontSize: '18px',
  },
  inputField: {
    '&.MuiGrid-root': {
      width: '12%',
    },
  },
  itemText: {
    '&.MuiListItemText-root': {
      maxWidth: 'fit-content',
    },
  },
}));

export default function PaymentSettings() {
  const classes = useStyles();
  const { t } = useTranslation('company');
  const { values, setFieldValue } = useFormikContext<any>();

  return (
    <div className={classes.formControl}>
      <Typography variant="h5" className={classes.paymentService}>
        {t('payment service')}
        <Tooltip
          title={
            <>
              <Typography variant="caption" color="inherit">
                {t('info title')}
              </Typography>
            </>
          }
          placement="top"
        >
          <InfoIcon style={{ color: '#D3D3D3', marginBottom: '-6px', marginLeft: '10px' }} />
        </Tooltip>
      </Typography>
      <ListItem>
        <Switch
          checked={values.paymentReminderEnabled}
          onClick={() => setFieldValue('paymentReminderEnabled', !values.paymentReminderEnabled)}
          edge="end"
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <ListItemText id="switch-list-label-wifi" primary={t('activate payment reminder')} />
      </ListItem>
      {values.paymentReminderEnabled && (
        <GridSetting
          label={t('send payment')}
          toolTipText={t('payment reminder tooltip')}
          inputFieldName="paymentReminderDays"
          inputFieldLabel={t('days num')}
        />
      )}
    </div>
  );
}
