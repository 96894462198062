import { AxiosInstance, AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import { NewSEPAMandate, SEPAMandate } from '../types/SEPA/SEPAMandate';
import { Page, PageMetadata } from '../types/Page';

export default class SEPAMandateService extends ApiClient {
  private static get sepaMandateClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }
  public static async getSEPAMandates(
    size: number,
    page: number,
    sort: string,
    debtorId?: string,
  ): Promise<{ results: SEPAMandate[]; page: PageMetadata }> {
    let params: { [k: string]: string | number } = {
      size: size,
      page: page,
      sort: 'createdDateTimeUTC,' + sort,
    };
    if (debtorId) {
      params['debtor_id'] = debtorId;
    }

    const response = await this.sepaMandateClient.get<Page<SEPAMandate, 'sepaMandates'>>(this.apiDomain + '/sepa-mandates', {
      params,
    });
    if (this.countListener) {
      this.countListener(response.data.page);
    }
    return { results: response.data._embedded?.sepaMandates || [], page: response.data.page };
  }

  public static async createSEPAMandate(payload: NewSEPAMandate): Promise<SEPAMandate> {
    const response = await this.sepaMandateClient.post<NewSEPAMandate, AxiosResponse<SEPAMandate>>(this.apiDomain + '/sepa-mandates', payload);

    return response.data;
  }

  public static async deactivateSEPAMandate(sepaMandateId: string): Promise<SEPAMandate> {
    const response = await this.sepaMandateClient.patch<SEPAMandate>(this.apiDomain + '/sepa-mandates/' + sepaMandateId + '/_deactivate');

    return response.data;
  }
}
