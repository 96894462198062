import { AxiosInstance } from 'axios';
import { SelfServiceUrls } from '../types/SelfService';
import ApiClient from './ApiClient';

export default class SubscriptionService extends ApiClient {
    private static get subscriptionClient(): AxiosInstance {
      return this.client;
    }

    public static async getSelfServiceUrls(): Promise<SelfServiceUrls> {
        const response = await this.subscriptionClient.get<SelfServiceUrls>(this.apiDomain + "/self-service/urls");
        return response.data;
      }
}