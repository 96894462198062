import { List } from '@material-ui/core';
import { getEnv } from '../../../services/envs';
import * as ServiceContext from '../../../components/contexts/WithServiceContext';
import BankAccountScreen from '../../settings/bankdetails/BankAccountScreen';
import ContractPage from '../../settings/contract/ContractPage';
import LogoUploadScreen from '../../settings/logo/LogoUploadScreen';
import Team from '../../settings/team/Team';
import ConnectedAccounts from '../../connectedAccounts/ConnectedAccounts';
import Transactions from '../../connectedAccounts/Transactions';
import DebtorDetailPage from '../../debtorDetailPage/DebtorDetailPage';
import ApiKeys from '../../developer/api/ApiKeys';
import WebHooksView from '../../developer/webhooks/WebHooksView';
import Home from '../../HomePage';
import NavLink from './NavLink';
import PaymentsView from '../../payments/PaymentsView';
import CheckoutsView from '../../checkouts/CheckoutsView';
import InvoicesView from '../../invoices/InvoicesView';
import BrandingPage from '../../settings/branding/BrandingPage';
import ProductsView from '../../products/ProductsView';
import SubscriptionsView from '../../subscriptions/SubscriptionsView';
import TransactionDetailPage from '../../transactionDetailPage/TransactionDetailPage';
import DebtorsView from '../../debtors/DebtorsView';
import InstalmentPlansView from '../../instalmentPlans/InstalmentPlansView';
import MasterDataScreen from '../../settings/masterdata/MasterDataScreen';
import ServiceSettingScreen from '../../settings/serviceSettings/ServiceSettingScreen';
import CheckoutLinks from '../../checkoutLinks/CheckoutLinks';
import { ReactElement } from 'react';
import Services from '../../services/Services';
import { ServiceActivations } from '../../../types/Feature/ServiceActivation';
import CreditNotesView from '../../creditNotes/CreditNotesView';
import CreditsView from '../../credits/CreditsView';

export type NavItem = {
  hide: boolean;
  isNew: boolean;
  label: string;
  nested: NavItem[];
  component?: ReactElement;
  exactMatch?: boolean;
  externalLink?: string;
  icon?: string;
  path?: string;
  service?: ServiceActivations.Service;
};

const defaultNavItem: NavItem = {
  nested: [],
  label: '',
  isNew: false,
  hide: false,
};

export const footerItems: NavItem[] = [
  {
    ...defaultNavItem,
    label: 'Logout',
    path: '/logout',
    icon: 'logout',
  },
];

const nestedPayments: NavItem[] = [
  {
    ...defaultNavItem,
    label: 'payments',
    path: '/dashboard/transactions/payments',
    exactMatch: false,
    service: ServiceActivations.Service.PAYACTIVE_SERVICE_PAYMENT_SERVICE,
    component: <PaymentsView />,
  },
  {
    ...defaultNavItem,
    hide: true,
    path: '/dashboard/transactions/payments/:id/*',
    component: <TransactionDetailPage source={'payment'} />,
  },
  {
    ...defaultNavItem,
    label: 'credits',
    path: '/dashboard/transactions/credits',
    exactMatch: false,
    service: ServiceActivations.Service.PAYACTIVE_SERVICE_SUBSCRIPTION_SERVICE,
    component: <CreditsView />,
  },
  {
    ...defaultNavItem,
    hide: true,
    path: '/dashboard/transactions/credits/:id/*',
    component: <TransactionDetailPage source={'credit'} />,
  },
  {
    ...defaultNavItem,
    label: 'invoices',
    path: '/dashboard/transactions/invoices',
    exactMatch: false,
    service: ServiceActivations.Service.PAYACTIVE_SERVICE_SUBSCRIPTION_SERVICE,
    component: <InvoicesView />,
  },
  {
    ...defaultNavItem,
    label: 'credit notes',
    path: '/dashboard/transactions/credit-notes',
    exactMatch: false,
    service: ServiceActivations.Service.PAYACTIVE_SERVICE_SUBSCRIPTION_SERVICE,
    component: <CreditNotesView />,
  },
  {
    ...defaultNavItem,
    hide: true,
    path: '/dashboard/transactions/invoices/:id/*',
    component: <TransactionDetailPage source={'invoice'} />,
  },
  {
    ...defaultNavItem,
    hide: true,
    path: '/dashboard/transactions/credit-notes/:id/*',
    component: <TransactionDetailPage source={'creditNote'} />,
  },
  {
    ...defaultNavItem,
    label: 'subscriptions',
    path: '/dashboard/transactions/subscriptions',
    exactMatch: true,
    component: <SubscriptionsView />,
  },
  {
    ...defaultNavItem,
    label: 'payment plans',
    path: '/dashboard/transactions/plans',
    exactMatch: true,
    component: <InstalmentPlansView />,
  },
];

const nestedCheckouts: NavItem[] = [
  {
    ...defaultNavItem,
    label: 'checkout overview',
    path: '/dashboard/checkouts',
    exactMatch: true,
    component: <CheckoutsView />,
  },
  {
    ...defaultNavItem,
    hide: true,
    path: '/dashboard/transactions/checkouts/:id/*',
    component: <TransactionDetailPage source={'checkout'} />,
  },
  {
    ...defaultNavItem,
    label: 'products',
    path: '/dashboard/checkouts/products',
    exactMatch: true,
    component: <ProductsView />,
  },
  {
    ...defaultNavItem,
    label: 'checkout links',
    path: '/dashboard/checkouts/links',
    exactMatch: true,
    component: <CheckoutLinks />,
  },
];

const nestedMarketplace: NavItem[] = [
  {
    ...defaultNavItem,
    label: 'connected clients',
    path: '/dashboard/connected-accounts',
    exactMatch: true,
    service: ServiceActivations.Service.PAYACTIVE_SERVICE_AGENT_SERVICE,
    component: <ConnectedAccounts />,
  },
  {
    ...defaultNavItem,
    label: 'client transactions',
    path: '/dashboard/connected-accounts/transactions',
    exactMatch: true,
    component: <Transactions />,
  },
];

const nestedDeveloper: NavItem[] = [
  {
    ...defaultNavItem,
    label: 'apikeys',
    path: '/dashboard/developer/api',
    exactMatch: true,
    component: <ApiKeys />,
  },
  {
    ...defaultNavItem,
    label: 'webhooks',
    path: '/dashboard/developer/webhook',
    component: <WebHooksView />,
  },
  {
    ...defaultNavItem,
    label: 'Api Docs',
    externalLink: 'https://docs.payactive.io/apidoc',
  },
];

const nestedCompanyItems: NavItem[] = [
  {
    ...defaultNavItem,
    label: 'masterdata',
    path: '/dashboard/company/masterdata',
    exactMatch: true,
    component: <MasterDataScreen />,
  },
  {
    ...defaultNavItem,
    label: 'bankaccount',
    path: '/dashboard/company/bankaccount',
    exactMatch: true,
    component: <BankAccountScreen />,
  },
  {
    ...defaultNavItem,
    label: 'team',
    path: '/dashboard/company/team',
    exactMatch: true,
    component: <Team />,
  },
  {
    ...defaultNavItem,
    label: 'services',
    path: '/dashboard/company/services',
    exactMatch: true,
    component: <Services />,
  },
  {
    ...defaultNavItem,
    label: 'service settings',
    path: '/dashboard/company/settings',
    exactMatch: true,
    component: <ServiceSettingScreen />,
  },
  {
    ...defaultNavItem,
    label: 'logo',
    path: '/dashboard/company/logo',
    exactMatch: true,
    component: <LogoUploadScreen />,
  },
  {
    ...defaultNavItem,
    label: 'branding',
    path: '/dashboard/company/branding',
    exactMatch: true,
    component: <BrandingPage />,
  },
];

if (getEnv() !== 'prod') {
  nestedCompanyItems.push({
    ...defaultNavItem,
    label: 'contract',
    path: '/dashboard/company/contract',
    exactMatch: true,
    component: <ContractPage />,
  });
}

export const mainItems: NavItem[] = [
  {
    ...defaultNavItem,
    label: 'Dashboard',
    path: '/dashboard/home',
    exactMatch: true,
    icon: 'speed',
    component: <Home />,
  },
  {
    ...defaultNavItem,
    label: 'debitors',
    path: '/dashboard/debtors',
    exactMatch: false, //false to match path like path: '/dashboard/debtors/:id/:email/*',
    icon: 'people',
    component: <DebtorsView />,
  },
  {
    ...defaultNavItem,
    hide: true,
    path: '/dashboard/debtors/:id/:email/*',
    component: <DebtorDetailPage />,
  },
  {
    ...defaultNavItem,
    label: 'payment transactions',
    icon: 'compare_arrows',
    service: ServiceActivations.Service.PAYACTIVE_SERVICE_PAYMENT_SERVICE,
    nested: nestedPayments,
  },
  {
    ...defaultNavItem,
    label: 'Checkouts',
    icon: 'shopping_cart',
    nested: nestedCheckouts,
  },
  {
    ...defaultNavItem,
    label: 'marketplace features',
    icon: 'settings_input_component',
    nested: nestedMarketplace,
  },
  {
    ...defaultNavItem,
    label: 'developer',
    icon: 'play_for_work',
    nested: nestedDeveloper,
  },
  {
    ...defaultNavItem,
    label: 'settings',
    icon: 'business',
    nested: nestedCompanyItems,
  },
];

const checkProductActivated = (navItem: NavItem, currentActivatedProducts: ServiceActivations.Service[]) => {
  if (!navItem.service) {
    return true;
  } else {
    return currentActivatedProducts.some((service) => service === navItem.service);
  }
};

export function NavItems({ items }: { items: NavItem[] }) {
  return (
    <div>
      <ServiceContext.ServiceContext.Consumer>
        {({ currentActivatedServices }) => (
          <List component="nav">
            {items
              .filter((item) => !item.hide)
              .filter((item) => checkProductActivated(item, currentActivatedServices))
              .map((item, index) => (
                <NavLink navItem={item} index={`navItem${item.label}_${index}`} key={`navItem${item.label}_${index}`} />
              ))}
          </List>
        )}
      </ServiceContext.ServiceContext.Consumer>
    </div>
  );
}

export { checkProductActivated };
