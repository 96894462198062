import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { versions } from './versions';

const build = versions.BuildId;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/i18n/{{ns}}/{{lng}}.json?v=${build}`,
    },
    whitelist: ['en', 'de'],
    fallbackLng: 'en',
    debug: true,
    preload: ['de', 'en'],
    load: 'languageOnly',
    ns: ['global', 'debitors'],
    defaultNS: 'global',
    keySeparator: '.',
    interpolation: {},
    detection: {
      order: ['querystring', 'navigator'],
    },
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
