import { CheckoutLinkActionType } from './CheckoutLinkActionHelper';
import { useContext, useState } from 'react';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { useTranslation } from 'react-i18next';
import { CheckoutLinksDataContext } from '../CheckoutLinksDataContext';
import { MenuItem } from '@material-ui/core';
import DeletePaymentLinkDialog from '../dialogs/DeletePaymentLinkDialog';
import CheckoutLinkService from '../../../services/CheckoutLinkService';

const CheckoutLinkDeleteAction = ({ checkoutLink, setMenuAnchor, label }: CheckoutLinkActionType) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { reload } = useContext(CheckoutLinksDataContext);
  const alerting = useContext(AlertContext);

  const { t } = useTranslation('payments');

  const deletePaylink = () => {
    CheckoutLinkService.deleteCheckoutLink(checkoutLink.id).then((res) => {
      if (res) {
        setOpenDialog(false);
        alerting.sendAlert('success', t('paylink deleted successfully'));
        reload();
      }
    });
  };

  return (
    <div key={checkoutLink.id + 'editC'}>
      <MenuItem
        key={checkoutLink.id + 'edit'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>

      {openDialog && <DeletePaymentLinkDialog open={openDialog} onClose={() => setOpenDialog(false)} onDelete={deletePaylink} />}
    </div>
  );
};

export default CheckoutLinkDeleteAction;
