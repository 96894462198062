import { useTranslation } from 'react-i18next';
import React, { ReactElement, useContext } from 'react';
import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { CheckoutLinksDataContext } from '../CheckoutLinksDataContext';
import MuiAlert from '@material-ui/lab/Alert';
import TableHeader from '../../../components/table/TableHeader';
import { Skeleton } from '@material-ui/lab';
import CheckoutLinkRow from './CheckoutLinkRow';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: '200px',
  },
  main: {
    padding: theme.spacing(1),
  },
}));

const CheckoutLinksTable = ({ emptyListComponent }: { emptyListComponent?: ReactElement }) => {
  const { t } = useTranslation('payments');
  const context = useContext(CheckoutLinksDataContext);
  const classes = useStyles();
  const columns = [
    {
      label: t('payment link url'),
      id: 'payLinkUrl',
    },
    {
      label: t('product'),
      id: 'productName',
    },
    {
      label: t('price'),
      id: 'price',
    },
    {
      label: t('creation'),
      id: 'createdDateTimeUTC',
    },
  ];

  const initialLoad = !context.isInitialized;
  const emptyResultList = context.isInitialized && context.checkoutLinks.length === 0;

  return (
    <>
      {emptyResultList ? (
        <Grid item xs={12} className={classes.main}>
          {emptyListComponent ? (
            <Grid container justifyContent={'space-around'} alignItems={'center'} direction={'column'} spacing={4}>
              <Grid item md={6}></Grid>
              <Grid item md={6}>
                <Typography variant={'body2'}> {t('no payment links help')}</Typography>
              </Grid>
              <Grid item>{emptyListComponent}</Grid>
            </Grid>
          ) : (
            <MuiAlert elevation={6} variant="filled" severity="warning">
              {t('no checkout links linked')}
            </MuiAlert>
          )}
        </Grid>
      ) : (
        <>
          <TableContainer style={{ flex: '1 1 auto' }}>
            <Table>
              <TableHeader columns={columns} actions={true}></TableHeader>
              <TableBody>
                <>
                  {initialLoad && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1}>
                        <Skeleton className={classes.skeleton} />
                      </TableCell>
                    </TableRow>
                  )}
                  {context.checkoutLinks.map((checkoutLink, index) => (
                    <CheckoutLinkRow checkoutLink={checkoutLink} index={index} key={checkoutLink.id}></CheckoutLinkRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default CheckoutLinksTable;
