import CompanyAccountService from '../../../../services/CompanyAccountService';
import EbicsService from '../../../../services/EbicsService';
import { EbicsConnections } from '../../../../types/Ebics/EbicsConnection';

const changeStatusAndGet = async (ebicsConnectionId: string, status: EbicsConnections.Status) => {
  await EbicsService.changeEbicsStatus(ebicsConnectionId, status);
  return await getAndMergeEbicsConnection(ebicsConnectionId);
};

const getAndMergeEbicsConnection = async (ebicsConnectionId: string) => {
  return await Promise.all<any>([EbicsService.getEbicsConnection(ebicsConnectionId), CompanyAccountService.getCompany()]).then((responses) => ({
    ...responses[0],
    creditorIdentifier: responses[1].creditorIdentifier,
  }));
};

export { changeStatusAndGet, getAndMergeEbicsConnection };
