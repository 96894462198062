import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CategoryIcon from '@material-ui/icons/Category';
import EditIcon from '@material-ui/icons/Edit';
import { Grid, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { Button, FormikInputField } from '@payactive/app-common';
import { InvoiceFormValues } from '../../../types/Invoice/Invoice';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { CreateProductDialog } from '../../products/dialogs/CreateProductDialog';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import ProductService from '../../../services/ProductService';
import { Product } from '../../../types/Product/Product';

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    '&[data-focus="true"]': {
      backgroundColor: '#2ed2f559',
    },
  },
  post: {
    margin: 'auto',
    '& .MuiGrid-root': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& .MuiFormControl-root': {
      width: '80%',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '45px',
    },
    '& .MuiIcon-root': {
      position: 'absolute',
      left: '0px',
      top: '32px',
    },
  },
}));

enum PayLinkStep {
  NEWPAYLINK = 0,
  NEWPRODUCT = 1,
}

const SEARCH_TYPE_DELAY_MS = 1000;

export default function ProductOrName({ index }: { index: number }): ReactElement {
  const [products, setProducts] = useState<Product[]>([]);
  const [step, setStep] = useState(PayLinkStep.NEWPAYLINK);
  const { values, setFieldValue } = useFormikContext<InvoiceFormValues>();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isProductSet, setProductSet] = useState(false);
  const [search, setSearch] = useState<string>();
  const [timer, setTimer] = useState<number>();

  const classes = useStyles();
  const { t } = useTranslation('payments');
  const alerting = useContext(AlertContext);

  useEffect(() => {
    if (!search || search.trim().length === 0) {
      ProductService.getProducts(5).then((prod) => {
        if (prod) {
          setProducts(prod.results);
        } else {
          setProducts([]);
        }
      });
      return;
    }
    if (timer) {
      window.clearTimeout(timer);
    }
    setTimer(
      window.setTimeout(() => {
        ProductService.getProducts(5, 0, 'asc', { search: search })
          .then((res) => {
            setProducts(res.results);
          })
          .catch(() => {});
      }, SEARCH_TYPE_DELAY_MS),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const comeBack = (success = false) => {
    setStep(PayLinkStep.NEWPAYLINK);
    setIsOpenDropdown(!success);
  };

  const redirectToPaylink = (newProduct: any) => {
    setFieldValue(`positions.${index}.name`, newProduct.name);
    setFieldValue(`positions.${index}.description`, newProduct.name);
    setFieldValue(`positions.${index}.descriptionSubText`, newProduct.description);
    setFieldValue(`positions.${index}.price`, newProduct.name);
    setFieldValue(`positions.${index}.productId`, newProduct.id);
    setFieldValue(`positions.${index}.price`, newProduct.price);
    setFieldValue(`positions.${index}.taxRate`, newProduct.taxRate.rate === 0 ? -1 : newProduct.taxRate.rate);
    setStep(PayLinkStep.NEWPAYLINK);
    setIsOpenDropdown(false);
    alerting.sendAlert('success', t('product created successfully'));
  };

  const characters = 15;
  useEffect(() => {
    if (values.positions[index].productId) {
      setProductSet(true);
    }
  }, [index, values.positions]);

  return (
    <>
      {step === 0 && (
        <Autocomplete
          autoHighlight
          // @ts-ignore
          value={values.positions[index]}
          onInputChange={(event, inputVal) => {
            setSearch(inputVal);
          }}
          freeSolo
          options={products}
          open={isOpenDropdown}
          onOpen={() => {
            setIsOpenDropdown(!isOpenDropdown);
          }}
          openOnFocus
          clearOnBlur
          classes={{ option: classes.autocomplete }}
          onClose={(e, reason) => {
            if (reason === 'blur') {
              //leaving the autocomplete without select an option
              // @ts-ignore
              setFieldValue(`positions.${index}.name`, e.currentTarget?.value);
              // @ts-ignore
              setFieldValue(`positions.${index}.description`, e.currentTarget?.value);
              setFieldValue(`positions.${index}.taxRate`, -1);
              setProductSet(false);
            }
            return (reason === 'select-option' || reason === 'toggleInput' || reason === 'blur') && setIsOpenDropdown(false);
          }}
          onChange={(event, values, reason) => {
            if (reason === 'select-option') {
              // @ts-ignore
              if (values && 'name' in values) {
                //product is selected
                setFieldValue(`positions.${index}.productId`, values?.id);
                setFieldValue(`positions.${index}.name`, values?.name);
                setFieldValue(`positions.${index}.description`, values?.name);
                setFieldValue(`positions.${index}.descriptionSubText`, values?.description);
                setFieldValue(`positions.${index}.price`, values?.price);
                setFieldValue(`positions.${index}.taxRate`, values?.taxRate.rate === 0 ? -1 : values?.taxRate.rate);
                setProductSet(true);
              } else {
                // @ts-ignore
                if (values && 'props' in values) {
                  //text is selected
                  // @ts-ignore
                  setFieldValue(`positions.${index}.name`, values?.props?.inputValue);
                  // @ts-ignore
                  setFieldValue(`positions.${index}.description`, values?.props?.inputValue);
                  setFieldValue(`positions.${index}.taxRate`, -1);
                  setFieldValue(`positions.${index}.productId`, null);
                  setProductSet(false);
                }
              }
            } else if (reason === 'clear') {
              setFieldValue(`positions.${index}.name`, '');
              setFieldValue(`positions.${index}.description`, '');
              setFieldValue(`positions.${index}.descriptionSubText`, '');
              setFieldValue(`positions.${index}.price`, 0);
              setFieldValue(`positions.${index}.taxRate`, -1);
              setFieldValue(`positions.${index}.productId`, '');
              setProductSet(false);
            }
          }}
          getOptionLabel={(option) => {
            if (option.name) {
              return option.name;
            } else if (option.description) {
              return option.description;
            } else {
              return '';
            }
          }}
          renderOption={(option, state) => {
            if (option.name) {
              return (
                <Grid container direction="row" justifyContent="space-around" alignItems="center">
                  <Grid item md={2}>
                    <CategoryIcon />
                  </Grid>
                  <Grid item md={6}>
                    <Typography style={{ textAlign: 'left', marginLeft: '1rem' }}>
                      {option.name.slice(0, characters) + (option.name.length > characters ? '...' : '')}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography style={{ textAlign: 'left' }}>{formatCurrency(option.price)}</Typography>
                  </Grid>
                </Grid>
              );
            } else {
              return option;
            }
          }}
          getOptionDisabled={(option: any) => {
            return !!(option && option.key === 'empty-input');
          }}
          filterOptions={(options, params) => {
            const addNewButton = (
              <Button
                {...params}
                style={{
                  fontSize: '16px',
                  fontWeight: 300,
                  height: '36px',
                }}
                variant="text"
                name="add new product"
                icon="add_box"
                label={t('add new product')}
                color="secondary"
                onClick={() => setStep(PayLinkStep.NEWPRODUCT)}
              />
            ) as any;

            if (params.inputValue !== '') {
              options.unshift(
                (
                  <Button
                    {...params}
                    style={{
                      color: 'black',
                      fontSize: '16px',
                      fontWeight: 300,
                      height: 'fit-content',
                    }}
                    variant="text"
                    name="new invoice position"
                    label={`${t('global:use item', { name: params.inputValue })}`}
                    color="secondary"
                    onClick={() => {
                      setFieldValue(`positions.${index}.name`, params.inputValue);
                      setFieldValue(`positions.${index}.description`, params.inputValue);
                    }}
                  />
                ) as any,
                addNewButton,
              );
            } else {
              options.unshift(
                (
                  <Typography
                    key={'empty-input'}
                    style={{
                      fontSize: '16px',
                      fontWeight: 300,
                      height: '36px',
                    }}
                  >
                    {t('global:start typing')}{' '}
                  </Typography>
                ) as any,
                addNewButton,
              );
            }
            return options;
          }}
          renderInput={(params) => (
            <FormikInputField
              {...params}
              required
              // defaultValue={`positions.${index}.description`}
              name={`positions.${index}.name`}
              label={t('product or description')}
              InputProps={{
                ...params.InputProps,
                startAdornment: <InputAdornment position="start"> {isProductSet ? <CategoryIcon /> : <EditIcon />}</InputAdornment>,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          )}
        />
      )}

      {step === 1 && (
        <CreateProductDialog
          variant="invoice"
          comeBack={comeBack}
          redirectToOrigin={redirectToPaylink}
          isOpen={step === PayLinkStep.NEWPRODUCT}
          onCancel={() => setStep(PayLinkStep.NEWPAYLINK)}
        />
      )}
    </>
  );
}
