import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Grid, makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AddBoxIcon from '@material-ui/icons/AddBox';
import PageHeader from '../../components/ui/blocks/PageHeader';
import { useTranslation } from 'react-i18next';
import DataTable, { Row } from '../../components/ui/blocks/DataTable';
import InviteCostumerDialog from './InviteCostumerDialog';
import StateWithIcon from '../../components/StateWithIcon';
import { AlertContext } from '../../components/contexts/WithAlertContext';
import AgentService from '../../services/AgentService';
import { PageMetadata } from '../../types/Page';
import { AgentClient, AgentClients } from '../../types/Agent/AgentClient';
import { Action } from '../../components/ui/blocks/ActionMenu';
import { Skeleton } from '@material-ui/lab';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(1),
  },
}));
//TODO
// remove Datatable relics
export default function ConnectedApps() {
  const { t } = useTranslation(['connectedAccounts', 'payments']);
  const classes = useStyles();
  const history = useHistory();
  const alerting = useContext(AlertContext);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [agentClients, setAgentClients] = useState<AgentClient[]>([]);
  const [showInviteCostumerDialog, setShowInviteCostumerDialog] = useState(false);
  const [page, setPage] = useState<PageMetadata>();

  useEffect(() => {
    loadAgentClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAgentClients = () => {
    AgentService.getAgentClients(rowsPerPage, 0, 'desc').then((clients) => {
      setAgentClients(clients.results);
      setPage(clients.page);
    });
  };

  const onMenuClick = (action: Action, row: Row) => {
    switch (action.id) {
      case 'payments':
        history.push('/dashboard/connected-accounts/transactions');
        break;
      case 'remove':
        AgentService.deleteAgentClient(row.id).then((res) => {
          if (res) {
            alerting.sendAlert('success', t('successfully deleted'));
            loadAgentClients();
          } else {
            alerting.sendAlert('error', t('unsuccessfully deleted'));
            loadAgentClients();
          }
        });
        break;
      default:
    }
  };

  const handleChangePage = (page: number) => {
    AgentService.getAgentClients(rowsPerPage, page - 1, 'desc').then((agentClients) => {
      setAgentClients(agentClients.results);
    });
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    if (pageSize !== rowsPerPage) {
      AgentService.getAgentClients(pageSize, 0, 'desc').then((agentClients) => {
        setAgentClients(agentClients.results);
      });
    }
    setRowsPerPage(pageSize);
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader title={t('connected accounts')} />
        <Button variant="contained" color="primary" startIcon={<AddBoxIcon />} onClick={() => setShowInviteCostumerDialog(true)}>
          {t('invite new customers')}
        </Button>
      </Grid>
      {!page && <Skeleton />}

      {!!page && page?.totalElements > 0 ? (
        <DataTable
          columns={[
            {
              label: t('company name'),
              id: 'name',
            },
            {
              label: t('state'),
              id: 'active',
            },
          ]}
          rows={agentClients as unknown as Row[]}
          cellTransformations={{
            name: (name) => name as string,
            active: (active) => (
              <StateWithIcon
                state={active ? AgentClients.Status.ACTIVE : AgentClients.Status.INACTIVE}
                label={active ? t('active') : t('inactive')}
              />
            ),
          }}
          pageSizeOptions={[5, 30, 50]}
          pageSize={rowsPerPage}
          onPageSizeChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          totalPages={Math.ceil(page.totalElements / rowsPerPage)}
          transformPageSizeLabel={(pageSize) => t('payments:pageSize', { pageSize: pageSize }) as string}
          actions={[
            {
              id: 'payments',
              label: t('payments:payments'),
            },
            {
              id: 'remove',
              label: t('remove'),
            },
          ]}
          onAction={onMenuClick}
          showPagination
        />
      ) : (
        <Grid item xs={12} className={classes.main}>
          <Alert severity="warning">{t('no clients')}</Alert>
        </Grid>
      )}
      {showInviteCostumerDialog && <InviteCostumerDialog isOpen={showInviteCostumerDialog} onCancel={() => setShowInviteCostumerDialog(false)} />}
    </React.Fragment>
  );
}
