import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AddBoxIcon from '@material-ui/icons/AddBox';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabPanel from '../../../components/TabPanel';
import PageHeader from '../../../components/ui/blocks/PageHeader';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import InviteDialog from './InviteDialog';
import TeamTable from './TeamTable';
import TeamMemberService from '../../../services/TeamMemberService';
import UserService from '../../../services/UserService';
import { UserWithRole } from '../../../types/User/UserWithRole';
import { User } from '../../../types/User/User';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    margin: theme.spacing(2),
  },
}));

function Team() {
  const { t } = useTranslation('company');

  const alerting = useContext(AlertContext);

  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    setValue(value);
  };
  const [team, setTeam] = useState<UserWithRole[]>([] as UserWithRole[]);
  const [user, setUser] = useState<User>();
  const [pending, setPending] = useState<UserWithRole[]>([] as UserWithRole[]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    refresh();
  }, []);

  const refresh = (invite = false) => {
    !invite && TeamMemberService.getTeam().then(setTeam);
    TeamMemberService.getTeamInvitations().then(setPending);
    !invite && UserService.getUser().then(setUser);
  };

  const onInvite = (email: string) => {
    setLoading(true);
    TeamMemberService.invite(email)
      .then((res) => {
        setLoading(false);
        setDialogOpen(false);
        refresh(true);
        alerting.sendAlert('success', t('invite successful'));
      })
      .catch((err) => {
        setErrors(err);
      });
  };

  const onAction = (action: { id: string }, row: UserWithRole) => {
    if (action.id === 'delete') {
      TeamMemberService.removeFromTeam(row.id).then((res) => {
        if (res) {
          refresh();
          alerting.sendAlert('success', t('team member removed'));
        }
      });
    }
  };

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader title={t('team')} subtitle={t('settings')} />
        <Button className={classes.button} variant="contained" color="primary" startIcon={<AddBoxIcon />} onClick={() => setDialogOpen(true)}>
          {t('invite member')}
        </Button>
      </Grid>

      <Tabs value={value} indicatorColor="primary" textColor="primary" onChange={handleChange} aria-label="disabled tabs example">
        <Tab label={`Active (${team.length})`} />
        {pending.length > 0 && <Tab label={`Pending (${pending.length})`} />}
      </Tabs>
      <TabPanel value={value} index={0}>
        <TeamTable team={team} onAction={onAction} user={user} />
      </TabPanel>
      {pending.length > 0 && (
        <TabPanel value={value} index={1}>
          <TeamTable team={pending} onAction={undefined} user={user} />
        </TabPanel>
      )}
      {dialogOpen && <InviteDialog errors={errors} open={dialogOpen} onCancel={() => setDialogOpen(false)} onInvite={onInvite} loading={loading} />}
    </div>
  );
}

export default Team;
