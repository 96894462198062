import React, { useState } from 'react';
import { Avatar, Badge, Icon, IconButton, makeStyles, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/DateTimeUtil';
import { SEPAMandate, SEPAMandates } from '../../types/SEPA/SEPAMandate';
import { SEPAMandateListDataContext } from './SEPAMandateListDataContext';
import StateWithIcon from '../../components/StateWithIcon';
import IBAN from 'iban';
import DeactivateSEPAMandateDialog from './dialogs/DeactivateSEPAMandateDialog';

type SepaMandateRowProps = {
  sepaMandate: SEPAMandate;
  index: number;
};

const useStyles = makeStyles((theme) => ({
  even: {
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  odd: {
    border: 'none',
    backgroundColor: theme.palette.background.default,
  },
  select: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  icon: {
    color: '#FFFFFF',
  },
  menu: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const ITEM_HEIGHT = 48;

const SEPAMandateRow = ({ sepaMandate, index }: SepaMandateRowProps) => {
  const { t } = useTranslation('sepa');
  const classes = useStyles();
  const [sepaMandateToDelete, setSepaMandateToDelete] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);

  function handleShowSepaMandateDeleteDialog() {
    setSepaMandateToDelete(true);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };
  const actions: Action[] = [{ id: 'delete', label: t('Deactivate'), callback: handleShowSepaMandateDeleteDialog }];

  const filterActions = (action: Action) => {
    switch (action.id) {
      case 'delete':
        return sepaMandate.state === SEPAMandates.State.ACTIVE;
      default:
        return false;
    }
  };
  return (
    <TableRow key={index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <TableCell key={sepaMandate.id + 'mandateReference'}>{sepaMandate.mandateReference}</TableCell>
      <TableCell key={sepaMandate.id + 'iban'}>{IBAN.printFormat(sepaMandate.iban)}</TableCell>
      <TableCell key={sepaMandate.id + 'bic'}>{sepaMandate.bic}</TableCell>
      <TableCell key={sepaMandate.id + 'state'}>
        <StateWithIcon state={sepaMandate.state} label={t(sepaMandate.state)} />
      </TableCell>
      <TableCell key={sepaMandate.id + 'signatureDate'}>{formatDate(sepaMandate.signatureDate)}</TableCell>

      {!!actions && (
        <TableCell align="left">
          <Badge badgeContent={0} color="error" overlap="circular">
            <IconButton onClick={handleClick}>
              <Avatar className={classes.avatar}>
                <Icon>more_horiz</Icon>
              </Avatar>
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuAnchor(undefined)}
              PaperProps={{
                className: classes.menu,
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 'min-content',
                },
              }}
            >
              {actions.filter(filterActions).map((action) => (
                <MenuItem
                  key={action.id}
                  onClick={() => {
                    setMenuAnchor(undefined);
                    action.callback();
                  }}
                >
                  {action.label}
                </MenuItem>
              ))}
            </Menu>
          </Badge>
        </TableCell>
      )}
      <SEPAMandateListDataContext.Consumer>
        {({ reload }) => (
          <>
            {sepaMandateToDelete && (
              <DeactivateSEPAMandateDialog
                SEPAMandateId={sepaMandate.id}
                isOpen={true}
                onCancel={() => setSepaMandateToDelete(false)}
                onSuccess={() => {
                  setSepaMandateToDelete(false);
                  reload();
                }}
              />
            )}
          </>
        )}
      </SEPAMandateListDataContext.Consumer>
    </TableRow>
  );
};

type Action = {
  id: string;
  label: string;
  callback: () => void;
};

export default SEPAMandateRow;
