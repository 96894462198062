import { Debtor } from '../../../types/Debtor/Debtor';
import { TFunction } from 'react-i18next';
import React, { ReactElement } from 'react';
import DebtorNewPaymentAction from './DebtorNewPaymentAction';
import DebtorShowPaymentsAction from './DebtorShowPaymentsAction';
import DebtorEditAction from './DebtorEditAction';
import DebtorDeleteAction from './DebtorDeleteAction';
import DebtorChangePaymentMethodAction from './DebtorChangePaymentMethodAction';
import DebtorResendInvitationAction from './DebtorResendInvitationAction';
import DebtorNewInvoiceAction from './DebtorNewInvoiceAction';
import DebtorNewInstalmentPlanAction from './DebtorNewInstalmentPlanAction';
import DebtorEditAdditionalEmailsAction from './DebtorEditAdditionalEmailsAction';

const getActionsForDebtor = (debtor: Debtor, setMenuAnchor: (x: any) => void, t: TFunction, mode?: 'detailsPage') => {
  const actions: ReactElement[] = [];
  actions.push(<DebtorNewPaymentAction debtor={debtor} setMenuAnchor={setMenuAnchor} label={t('new payment')} />);
  actions.push(<DebtorNewInvoiceAction debtor={debtor} setMenuAnchor={setMenuAnchor} label={t('new invoice')} />);
  actions.push(<DebtorNewInstalmentPlanAction debtor={debtor} setMenuAnchor={setMenuAnchor} label={t('new instalment plan')} />);
  if (mode !== 'detailsPage') {
    actions.push(<DebtorShowPaymentsAction debtor={debtor} setMenuAnchor={setMenuAnchor} label={t('payments')} />);
  }
  actions.push(<DebtorEditAction debtor={debtor} setMenuAnchor={setMenuAnchor} label={t('edit')} />);
  actions.push(<DebtorEditAdditionalEmailsAction debtor={debtor} setMenuAnchor={setMenuAnchor} label={t('edit additional emails')} />);
  actions.push(<DebtorDeleteAction debtor={debtor} setMenuAnchor={setMenuAnchor} label={t('delete')} />);
  actions.push(<DebtorChangePaymentMethodAction debtor={debtor} setMenuAnchor={setMenuAnchor} label={t('change payment method')} />);
  actions.push(<DebtorResendInvitationAction debtor={debtor} setMenuAnchor={setMenuAnchor} label={t('resend invitation')} />);

  return actions;
};

export type DebtorActionType = {
  debtor: Debtor;
  setMenuAnchor: (x: any) => void;
  label: string;
};

export { getActionsForDebtor };
