import { Form, Formik } from 'formik';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';
import LoadingButton from '../../../components/LoadingButton';
import DebtorService from '../../../services/DebtorService';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import * as Yup from 'yup';
import EmailTextField from '../../../components/EmailTextField';
import { Debtor } from '../../../types/Debtor/Debtor';

type Props = {
  debtorId: string;
  open: boolean;
  onClose: () => void;
};

export default function DebtorEditAdditionalEmailsDialog({ debtorId, open, onClose }: Props) {
  const [t] = useTranslation(['debitors', 'global']);
  const alertContext = useContext(AlertContext);

  const [isLoading, setIsLoading] = useState(true);
  const [debtor, setDebtor] = useState<Debtor>();

  const schema = Yup.object().shape({
    additionalEmailAddresses: Yup.array().of(Yup.string().email((obj) => t('global:invalid email', { invalidEmail: obj.value }))),
  });

  const submitEmails = (values: { additionalEmailAddresses: string[] }) => {
    if (debtor) {
      debtor.additionalEmailAddresses = values.additionalEmailAddresses;
      DebtorService.updateDebtor(debtor.id, debtor)
        .then(() => {
          alertContext.sendAlert('success', t('edit additional emails successful'));
          onClose();
        })
        .catch(() => {
          alertContext.sendAlert('error', t('Something went wrong. Please try again.'));
        });
    } else {
      alertContext.sendAlert('error', t('Something went wrong. Please try again.'));
    }
  };

  useEffect(() => {
    DebtorService.getDebtor(debtorId).then((debtor) => {
      setDebtor(debtor);
      setIsLoading(false);
    });
  }, []);

  return (
    <Dialog maxWidth="sm" fullWidth aria-labelledby="alert-dialog-title" open={open} onClose={onClose}>
      <DialogTitle id="debtor-edit-additional-emails-dialog-title">{t('edit additional emails')}</DialogTitle>
      {isLoading ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Formik
          initialValues={{
            additionalEmailAddresses: debtor?.additionalEmailAddresses || [],
          }}
          onSubmit={submitEmails}
          validationSchema={schema}
        >
          {({ values, isSubmitting, setFieldValue, errors }) => (
            <Form>
              <DialogContent dividers>
                <DialogContentText>{t('edit additional emails form description')}</DialogContentText>
                <EmailTextField
                  name={'additionalEmailAddresses'}
                  label={t('additional emails input label')}
                  onEmailsChange={(updatedEmailAddresses) => setFieldValue('additionalEmailAddresses', updatedEmailAddresses)}
                  initialEmails={values.additionalEmailAddresses}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="text" name="cancel" label={t('global:cancel')} disabled={false} onClick={onClose} />
                <LoadingButton type="submit" loading={isSubmitting}>
                  {t('global:save')}
                </LoadingButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
}
