import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import moment from 'moment';
import PaginationProvider from '../../components/table/PaginationContext';
import ProductService from '../../services/ProductService';
import { Product } from '../../types/Product/Product';
import { StateFilter } from '../../components/filter/GenericTableFilters';
import { PageMetadata } from '../../types/Page';
import { Filter } from '../../types/Filter';

export const ProductListDataContext = createContext<{
  products: Product[];
  filters?: {
    stateFilter: StateFilter[];
    setStateFilter: Dispatch<SetStateAction<StateFilter[]>>;
    dateStartFilter: string;
    setDateStartFilter: Dispatch<SetStateAction<string>>;
    dateEndFilter: string;
    setDateEndFilter: Dispatch<SetStateAction<string>>;
    searchFilter: string;
    setSearchFilter: Dispatch<SetStateAction<string>>;
    used: boolean;
  };
  page?: PageMetadata;
  reload: () => void;
  loading: boolean;
}>({
  products: [] as Product[],
  reload: () => {},
  loading: true,
});

type ProductListDataContextProviderProps = {
  pageSizeOptions: number[];
  children: any;
  debtorId?: string;
};

const ProductListDataContextProvider = ({ pageSizeOptions, debtorId, children }: ProductListDataContextProviderProps) => {
  const [productList, setProductList] = useState<Product[]>([] as Product[]);
  const [page, setPage] = useState<PageMetadata>();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [stateFilter, setStateFilter] = useState<StateFilter[]>([] as StateFilter[]);
  const [dateStartFilter, setDateStartFilter] = useState<string>('');
  const [dateEndFilter, setDateEndFilter] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [filtersUsed, setFiltersUsed] = useState(false);

  const isInitialized = React.useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (isInitialized.current) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFilter, dateStartFilter, dateEndFilter, searchFilter, pageSize]);

  useEffect(() => {
    reload();
    isInitialized.current = true;
    return () => {
      isInitialized.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reload = () => {
    setLoading(true);
    let filter: Filter = {};
    if (!!stateFilter) {
      filter.productTypes = Array.from(stateFilter.values()).join(',');
    }
    filter.search = searchFilter;
    filter.startDate = dateStartFilter && moment(dateStartFilter).format('YYYY-MM-DDT23:59:59.999') + 'Z';
    filter.endDate = dateEndFilter && moment(dateEndFilter).format('YYYY-MM-DDT23:59:59.999') + 'Z';

    setFiltersUsed(!!stateFilter || !!dateStartFilter || !!dateEndFilter || !!searchFilter);

    ProductService.getProducts(pageSize, currentPage, 'desc', filter).then(({ results, page }) => {
      setProductList(results);
      setPage(page);
      setLoading(false);
    });
  };

  return (
    <ProductListDataContext.Provider
      value={{
        products: productList,
        filters: {
          stateFilter: stateFilter,
          setStateFilter: setStateFilter,
          dateStartFilter: dateStartFilter,
          setDateStartFilter: setDateStartFilter,
          dateEndFilter: dateEndFilter,
          setDateEndFilter: setDateEndFilter,
          searchFilter: searchFilter,
          setSearchFilter: setSearchFilter,
          used: filtersUsed,
        },
        page: page,
        reload: reload,
        loading: loading,
      }}
    >
      <PaginationProvider
        pageSizeOptions={pageSizeOptions}
        currentPage={currentPage}
        totalPages={page?.totalPages || 0}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        changePageSize={setPageSize}
      >
        {children}
      </PaginationProvider>
    </ProductListDataContext.Provider>
  );
};

export default ProductListDataContextProvider;
