import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { useTranslation } from 'react-i18next';
import LogoProducts from './Logo';
import PriceBox from './PriceBox';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginLeft: 'auto',
    width: '400px',
    wordWrap: 'break-word',
    position: 'absolute',
    right: '0',
    top: '0',
    height: '100%',
  },
  closeButton: {
    display: 'flex',
    marginLeft: 'auto',
  },
  logo: {
    display: 'flex',
    marginTop: '12px',
    flexDirection: 'row',
    lineHeight: '0.75em',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  paperTitle: {
    marginTop: theme.spacing(2),
  },
  buttonIcon: {
    marginRight: '10px',
  },
  button: {
    width: 'fit-content',
  },
  pricesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  priceContainer1: {
    width: theme.spacing(40),
  },
  subtitles: {
    fontSize: '0.7rem',
    marginTop: theme.spacing(2),
    wordWrap: 'break-word',
  },
  subtitle: {
    fontSize: '0.7rem',
    wordWrap: 'break-word',
  },
  texts: {
    fontSize: '1rem',
  },
  dashed: {
    borderBottom: '1px dashed #0198B5',
    margin: '5px 0px',
    width: theme.spacing(20),
  },
  list: {
    listStyleType: 'none',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    marginRight: '10px',
  },
  img: {
    width: '350px',
    height: '150px',
    marginTop: theme.spacing(2),
  },
  information: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    bottom: '20px',
    right: '40px',
  },
  buttonSubtitle: {
    fontSize: '0.7rem',
    marginTop: theme.spacing(1),
  },
}));

export default function DescriptionPaper(props) {
  const classes = useStyles();
  const { onClose, service, activate } = props;
  const { t } = useTranslation('services');

  return (
    <Paper className={classes.paper} elevation={1}>
      {/* logo + close (x) */}
      <div className={classes.headerContainer}>
        <Button className={classes.closeButton} onClick={onClose}>
          <CancelIcon fontSize="large" color="secondary" align="right" />
        </Button>
        <LogoProducts serviceName={service.name} />
      </div>

      {/* image */}
      <img src={service.image} className={classes.img} alt={'service'} />

      {/* service information */}
      <Typography className={classes.information} color="secondary" align="left" variant="body2">
        {service.information}
      </Typography>

      {/* what it offers (list) */}
      <Typography variant="subtitle2" className={classes.subtitles} align="left" color="secondary">
        {t('offers title')}
      </Typography>
      <div className={classes.dashed} />
      {service.description.map((description, i) => (
        <li key={i} className={classes.list}>
          <CheckCircleIcon fontSize="small" color="secondary" className={classes.icon} />
          <Typography color="secondary" variant="subtitle2">
            {description}
          </Typography>
        </li>
      ))}

      {/* price information */}
      <Typography variant="body2" className={classes.subtitles} align="left" color="secondary">
        {t('price title')}
      </Typography>
      <div className={classes.dashed} />

      <div className={classes.pricesContainer}>
        {service.prices.map((price) => (
          <PriceBox smallPrint={price.smallPrint} price={price.price} tagLine={price.tagLine} />
        ))}
      </div>

      {/* button + subtitle */}
      <div className={classes.buttonContainer}>
        <Button color="secondary" variant="contained" className={classes.button} onClick={activate}>
          <TouchAppIcon className={classes.buttonIcon} />
          {t('button activate')}
        </Button>
        <Typography className={classes.buttonSubtitle} color="secondary">
          {t('button subtext')}
        </Typography>
      </div>
    </Paper>
  );
}
