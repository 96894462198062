import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormikSizedInputField } from '@payactive/app-common';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, Formik } from 'formik';
import LoadingButton from '../../../components/LoadingButton';
import { BankAccount } from '../../../types/Bank/BankAccount';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import BankService from '../../../services/BankService';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  bankAccount: BankAccount;
}

export default function EditBankAccountDialog({ onSuccess, onClose, bankAccount }: Props): ReactElement {
  const { t } = useTranslation('company');
  const alerting = useContext(AlertContext);
  const editBankAccount = (values: any) => {
    BankService.updateBankAccount(bankAccount.id, { default: values.isDefault, bankAccountLabel: values.bankAccountLabel })
      .then((res) => {
        if (res) {
          onSuccess();
        }
      })
      .catch((err) => alerting.sendAlert('error', t('error_edit_bank_account')));
  };
  return (
    <Formik initialValues={bankAccount} onSubmit={editBankAccount}>
      {({ isSubmitting }) => (
        <Dialog maxWidth="sm" fullWidth aria-labelledby="invoices-export-dialog" open={true} onClose={onClose}>
          <Form>
            <DialogTitle>{t('edit bank account')}</DialogTitle>
            <DialogContent dividers>
              <FormikSizedInputField style={{ marginBottom: '12px' }} name="bankAccountLabel" label={t('label')} fullWidth max={64} />
            </DialogContent>
            <DialogActions>
              <Button name="close" onClick={onClose} color="primary" variant="text" label={t('global:cancel')} />
              <LoadingButton loading={isSubmitting} type="submit">
                {t('save')}
              </LoadingButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
