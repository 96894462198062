import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import { createSchemas } from './schema';

import WebHookForm from './NewWebhookForm';
import WebhookService from '../../../services/WebhookService';
import { Webhook } from '../../../types/Webhook/Webhook';

interface Props {
  open: boolean;
  webhookRow: Webhook;
  onClose: () => void;
  onEdit: (webhook: Webhook) => void;
}
//TODO remove row relics
export default function EditWebhook({ open, webhookRow, onClose, onEdit }: Props): ReactElement {
  const { t } = useTranslation('developer');

  const { t: tValidations } = useTranslation('validations');
  const schema = createSchemas(tValidations)[0];

  const initialValues: Webhook = {
    id: webhookRow.id,
    name: webhookRow.name,
    url: webhookRow.url,
    events: webhookRow.events,
  };

  const _handleSubmit = (values: Webhook, actions: FormikHelpers<Webhook>) => {
    WebhookService.updateWebhook(webhookRow.id, values)
      .then((res) => {
        actions.setSubmitting(false);
        if (res) {
          onEdit(values);
        } else {
          actions.setStatus('ERROR'); //TODO handle catch
        }
      })
      .catch((err) => {
        actions.setStatus(err);
      });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
    >
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={_handleSubmit}>
        {({ values, status, isSubmitting, errors, setFieldValue }) => (
          <Form>
            <DialogTitle id="alert-dialog-title">{t('edit webhook')}</DialogTitle>
            <DialogContent dividers>
              <WebHookForm status={status} values={values} setFieldValue={setFieldValue} />
            </DialogContent>
            <DialogActions>
              <Button variant="text" name="cancel" label={t('global:cancel')} disabled={false} loading={false} onClick={onClose} />
              <LoadingButton type="submit" loading={isSubmitting} disabled={values.events.length <= 0 || values.name === '' || values.url === ''}>
                {t('global:save')}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
