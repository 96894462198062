import { makeStyles, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import BccEmailsAutoComplete from './BccEmailsAutoComplete';
import BccSelector from './BccSelector';

const useStyles = makeStyles((theme) => ({
  billingService: {
    textAlign: 'left',
    display: 'block',
    color: '#0198B5',
    marginBottom: theme.spacing(2),
    fontSize: '18px',
  },
}));

export default function DocumentSettings(): ReactElement {
  const classes = useStyles();

  const { t } = useTranslation('company');

  return (
    <div>
      <Typography variant="h5" className={classes.billingService}>
        {t('document service')}
      </Typography>
      <BccEmailsAutoComplete />
      <BccSelector />
    </div>
  );
}
