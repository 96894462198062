import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { CustomiseInstalmentPlanFormSchema } from '../forms/schemas';
import InstalmentPlanService from '../../../services/InstalmentPlanService';
import InstalmentPlanPreviewDialog from './InstalmentPlanPreviewDialog';
import { InstalmentPlan } from '../../../types/InstalmentPlan/InstalmentPlan';
import CustomisedInstalmentPlanForm from '../forms/CustomisedInstalmentPlanForm';
import { calculateCoveredAmount } from '../../../utils/InstalmentPlanHelperFunctions';
import { InstalmentPlanPreview, NewInstalmentPlanPreview } from '../../../types/InstalmentPlan/InstalmentPlanPreview';

const initialInstalmentPlanPreviewResponse = {
  totalNetAmount: 0,
  instalmentRates: [],
};

const enum STEPS {
  CREATE = 'CREATE',
  PREVIEW = 'PREVIEW',
}

type NewPreviewForm = NewInstalmentPlanPreview & { purpose: string; coveredAmount: number; interval?: number; intervalDay?: number };
const CustomiseInstalmentPlanDialog = ({
  onSuccess,
  isOpen,
  onCancel,
  instalmentPlan,
}: {
  onSuccess: () => void;
  onCancel: () => void;
  isOpen: boolean;
  instalmentPlan: InstalmentPlan;
}) => {
  const [instalmentPlanPreview, setInstalmentPlanPreview] = useState<InstalmentPlanPreview>(initialInstalmentPlanPreviewResponse);
  const [currentStep, setCurrentStep] = useState<STEPS>(STEPS.CREATE);
  const formRef = useRef(null);

  const { t: tValidations } = useTranslation(['validations', 'instalmentPlans']);

  const { t } = useTranslation(['global', 'instalmentPlans']);

  const schema = CustomiseInstalmentPlanFormSchema(tValidations);

  const alert = useContext(AlertContext);

  const initialValues: NewPreviewForm = {
    purpose: instalmentPlan.purpose,
    totalNetAmount: instalmentPlan.totalNetAmount,
    intervalAmount: instalmentPlan.intervalAmount,
    //metadata: instalmentPlan.metadata,
    coveredAmount: calculateCoveredAmount(instalmentPlan),
  };

  function createInstalmentPlanPreview(values: NewPreviewForm, { setSubmitting }: any) {
    const request = { ...values };
    //request.metadata = convertMetadata(values.metadata);
    InstalmentPlanService.createInstalmentPlanPreviewForExistingInstalmentPlan(instalmentPlan.id, request)
      .then((response) => {
        setInstalmentPlanPreview(response);
        setSubmitting(false);
        setCurrentStep(STEPS.PREVIEW);
      })
      .catch(() => {
        setSubmitting(false);
        onCancel();
        alert.sendAlert('error', t('instalmentPlans:instalment plan customisation preview error'));
      });
  }

  function patchInstalmentPlan(id: string, values: any) {
    const request = { ...values };
    request.metadata = values.metadata;
    InstalmentPlanService.patchInstalmentPlan(id, request)
      .then(() => {
        alert.sendAlert('success', t('instalmentPlans:customise instalment plan success'));
        onSuccess();
      })
      .catch(() => {
        onCancel();
        alert.sendAlert('error', t('instalmentPlans:customise instalment plan error'));
      });
  }

  function createInstalmentPlanObject(instalmentPlanPreview: InstalmentPlanPreview, values: NewPreviewForm): InstalmentPlan {
    return {
      downPayment: values.downPayment,
      instalmentRates: instalmentPlanPreview.instalmentRates,
      interval: values.interval || instalmentPlan.interval,
      intervalAmount: values.intervalAmount,
      intervalDay: values.intervalDay || instalmentPlan.intervalDay,
      purpose: values.purpose,
      totalNetAmount: instalmentPlanPreview.totalNetAmount,
    } as InstalmentPlan;
  }

  return (
    <Formik innerRef={formRef} initialValues={initialValues} validationSchema={schema} onSubmit={createInstalmentPlanPreview}>
      {({ setFieldValue, isValid, values, isSubmitting, dirty }) => (
        <>
          {currentStep === STEPS.CREATE && (
            <Dialog
              maxWidth="sm"
              fullWidth
              open={isOpen}
              onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                  return false;
                }
                onCancel();
              }}
              aria-labelledby="alert-dialog-title"
            >
              <Form>
                <DialogTitle id="alert-dialog-title">{t('instalmentPlans:customize instalment plan')}</DialogTitle>
                <DialogContent dividers>
                  <CustomisedInstalmentPlanForm values={values} />
                </DialogContent>
                <DialogActions className={`${false}`}>
                  <Button onClick={onCancel} color="primary">
                    {t('global:cancel')}
                  </Button>
                  <LoadingButton loading={isSubmitting} type="submit" disabled={!dirty || !isValid} onClick={() => {}}>
                    {t('instalmentPlans:Preview changes')}
                  </LoadingButton>
                </DialogActions>
              </Form>
            </Dialog>
          )}
          {currentStep === STEPS.PREVIEW && (
            <InstalmentPlanPreviewDialog
              onCancel={() => onCancel()}
              onBack={() => {
                setCurrentStep(STEPS.CREATE);
              }}
              instalmentPlan={createInstalmentPlanObject(instalmentPlanPreview, values)}
              dialogActions={
                <LoadingButton loading={isSubmitting} onClick={() => patchInstalmentPlan(instalmentPlan.id, values)}>
                  {t('instalmentPlans:Customise instalment plan')}
                </LoadingButton>
              }
            />
          )}
        </>
      )}
    </Formik>
  );
};
export default CustomiseInstalmentPlanDialog;
