const getApiDomain = () => {
  if (process.env.REACT_APP_API_DOMAIN) {
    return process.env.REACT_APP_API_DOMAIN;
  }

  switch (window.location.hostname) {
    case 'dev.portal.payactive.io':
      return 'https://dev.api.payactive.io';
    case 'stage.portal.payactive.io':
      return 'https://stage.api.payactive.io';
    case 'portal.payactive.io':
      return 'https://api.payactive.io';
    case 'sandbox.portal.payactive.io':
      return 'https://sandbox.api.payactive.io';
    default:
      return 'http://localhost:8080';
  }
};

const getEnv = () => {
  if (process.env.REACT_APP_ENV) {
    return process.env.REACT_APP_ENV;
  }

  switch (window.location.hostname) {
    case 'dev.portal.payactive.io':
      return 'dev';
    case 'stage.portal.payactive.io':
      return 'stage';
    case 'sandbox.portal.payactive.io':
      return 'sandbox';
    case 'portal.payactive.io':
      return 'prod';
    default:
      return 'dev';
  }
};

export { getApiDomain, getEnv };
