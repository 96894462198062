import { useTranslation } from 'react-i18next';
import { useRowStyles } from '../../../utils/TableRowHelper';
import React, { useContext, useState } from 'react';
import { Avatar, Badge, Icon, IconButton, Menu, TableCell, TableRow } from '@material-ui/core';
import StateWithIcon from '../../../components/StateWithIcon';
import { calculateProgress, formatCurrency } from '../../../utils/PaymentUtil';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import LinearProgressWithLabel from '../../../components/ui/blocks/LinearProgressWithLabel';
import { Credit } from '../../../types/Credit/Credit';
import LinkToDebtorDetailPage from '../../debtorDetailPage/LinkToDebtorDetailPage';
import { getCreditActions } from '../actions/CreditActionHelper';
import { CreditNotesListDataContext } from '../../creditNotes/CreditNotesListDataContextProvider';

export default function CreditRow({ credit, index }: { credit: Credit; index: number }) {
  const { t } = useTranslation('credits');
  const classes = useRowStyles();

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const menuOpen = Boolean(menuAnchor);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };

  const isPrefiltered = useContext(CreditNotesListDataContext).isPrefilteredForDebtor;
  const actions = getCreditActions(credit, setMenuAnchor);

  return (
    <TableRow key={index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <>
        <TableCell key={credit.id + 'state'}>
          <StateWithIcon state={credit.creditStatus} label={t(credit.creditStatus)} />
        </TableCell>
        {!isPrefiltered && (
          <TableCell key={credit.id + 'debtor'}>
            <LinkToDebtorDetailPage target={'credits'} debtor={credit.customer} debtorRef={credit.customer.id}></LinkToDebtorDetailPage>
          </TableCell>
        )}
        <TableCell key={credit.id + 'purpose'}>{credit.purpose}</TableCell>
        <TableCell key={credit.id + 'amount'}>{formatCurrency(credit.amount)}</TableCell>
        <TableCell key={credit.id + 'creationDate'}>{formatDateTime(credit.createdDateTimeUTC)}</TableCell>
        <TableCell key={credit.id + 'completion'} style={{ minWidth: '200px' }}>
          <LinearProgressWithLabel value={calculateProgress(credit.creditStatus)} />
        </TableCell>

        <TableCell key={credit.id + 'action'}>
          {!!actions && (
            <Badge badgeContent={0} color="error" overlap="circular">
              <IconButton onClick={handleClick}>
                <Avatar className={classes.avatar}>
                  <Icon>more_horiz</Icon>
                </Avatar>
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuAnchor(null)}
                PaperProps={{
                  className: classes.menu,
                  style: {
                    //maxHeight: ITEM_HEIGHT * 4.5,
                    width: 'min-content',
                  },
                }}
              >
                {actions}
              </Menu>
            </Badge>
          )}
        </TableCell>
      </>
    </TableRow>
  );
}
