import { Grid, Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PageHeader from '../components/ui/blocks/PageHeader';
import PaymentStats from '../components/ui/blocks/PaymentStats';
import CompanyAccountService from '../services/CompanyAccountService';
import { CompanyStats, PaymentsPerState, PaymentsPerStates } from '../types/Company/CompanyStats';
import { CompanyAccount } from '../types/Company/Company';
import { CompanyAccountContext } from '../components/contexts/WithCompanyAccountContext';

export default function HomePage() {
  const { t } = useTranslation(['payments', 'home']);

  const history = useHistory();
  const { companyAccount } = useContext(CompanyAccountContext);

  const [companyStats, setCompanyStats] =
    useState<{ pending: { totalAmount: number; count: number }; completed: { totalAmount: number; count: number }; name: string }>();

  useEffect(() => {
    CompanyAccountService.getCompanyStats().then((stats) => {
      processCompanyStats(stats, companyAccount);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const processCompanyStats = (stats: CompanyStats, info: CompanyAccount) => {
    const pending = extractByTypes(stats.payments, [PaymentsPerStates._id.MANUAL, PaymentsPerStates._id.PENDING]);
    const completed = extractByTypes(stats.payments, [PaymentsPerStates._id.COMPLETED, PaymentsPerStates._id.VERIFIED]);
    setCompanyStats({ pending: pending, completed: completed, name: info.name });
  };

  const extractByTypes = (data: PaymentsPerState[], types: PaymentsPerStates._id[]): { totalAmount: number; count: number } => {
    return data
      .filter((stat) => types.includes(stat._id))
      .reduce(
        (prev, curr) => {
          return {
            totalAmount: prev.totalAmount + curr.totalAmount,
            count: prev.count + curr.count,
          };
        },
        {
          totalAmount: 0,
          count: 0,
        },
      );
  };

  const sendSupportMail = () => {
    history.push('mailto:support@payactive.eu');
  };

  return (
    <>
      <PageHeader subtitle={t('home:overview')} title={companyStats?.name} />
      <Grid container direction="column" justifyContent="center" alignItems="flex-start" spacing={3}>
        <Grid container item spacing={2}>
          <Grid item>
            <PaymentStats
              label={t('payments:pending_transactions')}
              numTransactions={companyStats?.pending.count}
              totalAmount={companyStats?.pending.totalAmount}
            />
          </Grid>
          <Grid item>
            <PaymentStats
              label={t('payments:completed_transactions')}
              numTransactions={companyStats?.completed.count}
              totalAmount={companyStats?.completed.totalAmount}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="secondary">
            {t('home:what_would_you_like_to_do')}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            icon="people"
            label={t('home:create_a_new_customer')}
            name={t('home:create_a_new_customer')}
            onClick={() => history.push('/dashboard/debtors?action=new')}
          />
        </Grid>
        <Grid item>
          <Button
            icon="compare_arrows"
            label={t('home:create_a_new_payment')}
            name={t('home:create_a_new_payment')}
            onClick={() => history.push('/dashboard/transactions/payments?action=new')}
          />
        </Grid>
      </Grid>
    </>
  );
}
