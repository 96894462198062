import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Badge, Grid, Icon, IconButton, makeStyles, Menu, Typography } from '@material-ui/core';
import { Debtor } from '../../types/Debtor/Debtor';
import { getActionsForDebtor } from '../debtors/action/DebtorActionHelper';
import { useRowStyles } from '../../utils/TableRowHelper';
import { BackButton } from '../../components/BackButton';
import { getFullDebtorLabel } from '../../utils/LabelHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    textAlign: 'left',
    maxWidth: '600px',
  },
}));

interface Props {
  subtitle: string;
  onBack: () => void;
  debtor?: Debtor;
  fetchPaymentsCount: () => void;
  fetchInvoicesCount: () => void;
  fetchInstalmentPlansCount: () => void;
}

export default function DebtorsDetailsPageHeader({ subtitle, onBack, debtor }: Props): ReactElement {
  const { t } = useTranslation('debitors');
  const classes = useStyles();
  const menu = useRowStyles();

  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };
  if (!debtor) {
    return <></>;
  }
  const fullName = getFullDebtorLabel(debtor) || `${debtor.emailAddress} (${t('debitor deleted')})`;

  const actions = !!debtor ? getActionsForDebtor(debtor, setMenuAnchor, t) : [];

  return (
    <Grid className={classes.root} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{subtitle}</Typography>
      </Grid>

      <Grid container alignItems={'center'}>
        <BackButton onClick={onBack} />

        <Grid item>
          <Typography variant="h6">{fullName}</Typography>
        </Grid>

        <Grid item>
          {actions && actions.length !== 0 && (
            <Badge badgeContent={0} color="error" overlap="circular">
              <IconButton onClick={handleClick}>
                <Avatar className={menu.avatar}>
                  <Icon>more_horiz</Icon>
                </Avatar>
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuAnchor(undefined)}
                PaperProps={{
                  className: menu.menu,
                  style: {
                    //maxHeight: ITEM_HEIGHT * 4.5,
                    width: 'min-content',
                  },
                }}
              >
                {actions}
              </Menu>
            </Badge>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
