import { Chip, Collapse, Icon, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { checkProductActivated, NavItem } from './NavItems';
import { ServiceContext } from '../../../components/contexts/WithServiceContext';
import { Variant } from '@material-ui/core/styles/createTypography';
import { CompanyAccountContext } from '../../../components/contexts/WithCompanyAccountContext';

const useStyles = makeStyles((theme) => ({
  text: {
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
  },
  nestedText: {
    color: 'white',
  },
  icon: {
    color: 'white',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  chip: {
    borderRadius: '7px',
    marginLeft: '12PX',
    color: 'white',
    height: 'auto',
    backgroundColor: theme.palette.success.main,
    ' & .MuiChip-label': {
      padding: '3px 6px',
      fontSize: '11px',
    },
  },
}));

export default function NavLink({ navItem, index, variant }: { navItem: NavItem; index: string; variant?: Variant | 'inherit' }) {
  const { t } = useTranslation('navigation');
  const { currentActivatedServices } = useContext(ServiceContext);
  const { companyAccount } = useContext(CompanyAccountContext);

  const classes = useStyles();
  const { nested, ...mainItem } = navItem;
  const [open, setOpen] = useState(false);
  const match = useRouteMatch({ path: navItem.path });
  const hasActiveNestedElements = () =>
    nested
      ?.filter((item) => {
        return (item.label !== 'credit notes' && item.label !== 'credits') || companyAccount.bankingSettings?.allowCreditTransfers;
      })
      .some((item) => checkProductActivated(item, currentActivatedServices));

  return (
    <>
      <ListItem
        button
        component={mainItem.externalLink ? 'a' : Link}
        key={index}
        onClick={() => setOpen(!open)}
        target={mainItem.externalLink && '_blank'}
        href={mainItem.externalLink ? mainItem.externalLink : ''}
        to={(!mainItem.externalLink && mainItem.path) || '#'}
        selected={match !== null && (mainItem.exactMatch ? match.isExact : true)}
      >
        <ListItemIcon>
          <Icon className={classes.icon}>{mainItem.icon}</Icon>
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.text,
          }}
          className={classes.text}
        >
          <Typography variant={variant}>{t(navItem.label)}</Typography>
          {navItem.isNew && <Chip className={classes.chip} label={t('new')} />}
        </ListItemText>
        {hasActiveNestedElements() && (open ? <ArrowDropUp className={classes.text} /> : <ArrowDropDown className={classes.text} />)}
      </ListItem>
      {hasActiveNestedElements() && (
        <Collapse in={open} timeout="auto">
          <div>
            <List component="div" disablePadding>
              {navItem.nested
                .filter((item) => !item.hide)
                .filter((item) => checkProductActivated(item, currentActivatedServices))
                .filter((item) => {
                  return item.label !== 'credit notes' || companyAccount.bankingSettings?.allowCreditTransfers;
                })
                .filter((item) => {
                  return item.label !== 'credits' || companyAccount.bankingSettings?.allowCreditTransfers;
                })
                .map((item, index) => (
                  <NavLink index={`navItem${item.label}_${index}`} key={`navItem${item.label}_${index}`} variant="subtitle2" navItem={item} />
                ))}
            </List>
          </div>
        </Collapse>
      )}
    </>
  );
}
