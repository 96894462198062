import { Links } from '../Page';

export type CompanyStats = {
  payments: Array<PaymentsPerState>;
  _links?: Links;
};

export type PaymentsPerState = {
  _id: PaymentsPerStates._id;
  totalAmount: number;
  count: number;
};

export namespace PaymentsPerStates {
  export enum _id {
    CREATING = 'CREATING',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    VERIFIED = 'VERIFIED',
    ABORTED = 'ABORTED',
    MANUAL = 'MANUAL',
    ERROR = 'ERROR',
    CANCELLED = 'CANCELLED',
    REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS',
    REFUND_COMPLETED = 'REFUND_COMPLETED',
  }
}
