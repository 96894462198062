import PaginationProvider from '../../../components/table/PaginationContext';
import React from 'react';
import CreditsTable from '../../credits/table/CreditsTable';
import { CreditsListDataContext } from '../../credits/CreditsListDataContext';

export default function CreditsTab() {
  return (
    <CreditsListDataContext.Consumer>
      {({ currentPage, pageSize, page }) => (
        <>
          <PaginationProvider
            pageSizeOptions={[5, 10, 30]}
            currentPage={currentPage.get}
            totalPages={page?.totalPages || 0}
            pageSize={pageSize.get}
            setCurrentPage={currentPage.set}
            changePageSize={pageSize.set}
          >
            <CreditsTable />
          </PaginationProvider>
        </>
      )}
    </CreditsListDataContext.Consumer>
  );
}
