import { Box, Grid, IconButton } from '@material-ui/core';
import { Button, FormikInputField } from '@payactive/app-common';
import { FieldArray, useFormikContext } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

function MetadataFields() {
  const { values } = useFormikContext<any>();

  const { t } = useTranslation(['instalmentPlans']);

  const metadata = values.metadata || [];
  return (
    <Grid container alignItems="center" justifyContent="space-around">
      <FieldArray
        name="metadata"
        render={({ push, remove }) => (
          <>
            {metadata.map((entry: any, index: number) => (
              <Grid container spacing={3} justifyContent="center" alignItems="center" key={index}>
                <Grid xs={4} item>
                  <FormikInputField fullWidth name={`metadata.${index}.key`} label={t('name')} />
                </Grid>
                <Grid xs={6} item>
                  <FormikInputField fullWidth name={`metadata.${index}.value`} label={t('value')} />
                </Grid>
                <Grid xs={2} item>
                  <IconButton>
                    <DeleteIcon
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Box mt={1}>
              <Button disabled={metadata.length >= 5} name="button" label={t('add metadata field')} onClick={() => push({ name: '', value: '' })} />
            </Box>
          </>
        )}
      />
    </Grid>
  );
}

export default MetadataFields;
