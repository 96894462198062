const onboardFormModel = {
  formId: 'onboardForm',
  formField: {
    companyName: {
      name: 'companyName',
      label: 'Company Name*',
      requiredErrorMsg: 'Company Name is required',
    },
    companyLegalForm: {
      name: 'companyLegalForm',
      label: 'Company Legal Form*',
      requiredErrorMsg: 'Company Legal Form is required',
    },
    companyRegistrationNumber: {
      name: 'companyRegistrationNumber',
      label: 'Company Registration Number*',
      requiredErrorMsg: 'Company Registration Number is required if legal form is GmbH',
    },
    firstName: {
      name: 'firstName',
      label: 'First Name*',
      requiredErrorMsg: 'First Name is required',
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name*',
      requiredErrorMsg: 'Last Name is required',
    },
    emailAddress: {
      name: 'emailAddress',
      label: 'Email Address*',
      requiredErrorMsg: 'E-Mail Address is required',
    },
    password: {
      name: 'password',
      label: 'Password*',
      requiredErrorMsg: 'Password is required',
    },
    passwordConfirmation: {
      name: 'passwordConfirmation',
      label: 'Confirm Password*',
      requiredErrorMsg: 'Password Confirmation is required',
    },
    acceptTermsAndConditions: {
      name: 'acceptTermsAndConditions',
      label: 'I accept the Terms and Conditions',
      requiredErrorMsg: 'Accepting Terms & Conditions is required',
    },
    invitationId: {
      name: 'invitationId',
    },
    invitationType: {
      name: 'invitationType',
    },
  },
};
export default onboardFormModel;
