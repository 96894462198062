import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../components/LoadingButton';
import CorrectionFields from './CorrectionFields';
import InvoiceService from '../../../services/InvoiceService';
import { CancelReasons } from '../../../types/CancelReasons';

interface Props {
  open: boolean;
  invoicePdf?: any;
  loading: boolean;
  onCancel: () => void;
  onFinalize: () => void;
  onCorrect: (reasons: CancelReasons) => void;
}
//TODO make independent from pdf, move functions from upper control into this class (onFinalize, loadPDF, ...)
// see CancelInvoiceDialog.tsx

export default function CorrectionInvoiceDialog({ open, onCorrect, onFinalize, onCancel, loading, invoicePdf }: Props): ReactElement {
  const [correctionAccepted, setCorrectionAccepted] = useState(false);

  const initialValues = {
    externalReason: '',
    internalReason: '',
  };

  const downloadCorrectionInvoice = () => {
    const fileName = `Korrektur_${invoicePdf.name}`;
    InvoiceService.downloadPDF(fileName, invoicePdf.content);
  };

  const _handleSubmit = (reasons: CancelReasons) => {
    onCorrect(reasons);
  };

  const handleChange = () => {
    setCorrectionAccepted(!correctionAccepted);
  };

  const { t } = useTranslation('debitors');

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return false;
          }
          onCancel();
        }}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{t('invoice correction title')}</DialogTitle>
        <Alert severity="warning">
          <Typography>{t('correction subtitle')}</Typography>
          <DialogContentText id="alert-dialog-description">
            <Typography>{!!invoicePdf ? t('correction content finalize') : t('correction content title')}</Typography>
          </DialogContentText>
        </Alert>
        {!!invoicePdf ? (
          <>
            <DialogContent>
              <p>
                <Button onClick={downloadCorrectionInvoice} variant="contained" color="primary" name={t('preview correction')}>
                  {t('preview correction')}
                </Button>
              </p>
              <p>
                <FormControlLabel
                  label={t('correction invoice reviewed')}
                  control={<Checkbox checked={correctionAccepted} onChange={handleChange} />}
                />
              </p>
            </DialogContent>
            <DialogActions>
              <Button name={t('global:close')} onClick={onCancel} color="primary" variant="text" label={t('global:close')} />
              <LoadingButton disabled={!correctionAccepted} loading={loading} onClick={onFinalize}>
                {t('finalize and send correction')}
              </LoadingButton>
            </DialogActions>
          </>
        ) : (
          <Formik initialValues={initialValues} validationSchema={CancelReasons.schema} onSubmit={_handleSubmit}>
            {({ handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <>
                <DialogContent>
                  <CorrectionFields handleChange={handleChange} handleBlur={handleBlur} />
                </DialogContent>
                <DialogActions>
                  <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:close')} />
                  <LoadingButton loading={isSubmitting} onClick={() => handleSubmit()}>
                    {t('preview correction')}
                  </LoadingButton>
                </DialogActions>
              </>
            )}
          </Formik>
        )}
      </Dialog>
    </>
  );
}
