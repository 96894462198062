import { Chip, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme) => ({
  preview: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
}));

export default function PreviewTag(): ReactElement {
  const classes = useStyles();

  return <Chip className={classes.preview} label="Preview Feature" />;
}
