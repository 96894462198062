import { IconButton, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { Fragment, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik/dist/types';
import { InvoiceFormValues } from '../../../types/Invoice/Invoice';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
  },
  dropzone: {
    textAlign: 'center',
    padding: '20px',
    border: '3px dashed #eeeeee',
    backgroundColor: '#fafafa',
    marginBottom: '20px',
  },
}));

interface PaymentInvoiceDropzoneProps {
  setFieldValue: (name: string, value: File | string | undefined) => Promise<void | FormikErrors<InvoiceFormValues>>;
  errorMessage: string;
  touched: boolean;
  className?: string;
  field: {
    name: string;
  };
}

//TODO remove duplicate see @ProductImageDropzone

export default function PaymentInvoiceDropzone({ setFieldValue, errorMessage, touched, className, field }: PaymentInvoiceDropzoneProps) {
  const { t } = useTranslation('payments');

  const classes = useStyles();

  const { name } = field;

  const [invoiceFile, setInvoiceFile] = useState<File>();

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 1) {
      updateInvoiceFile(acceptedFiles[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onDeleteFile = () => {
    updateInvoiceFile(undefined).then(() => {});
  };

  const updateInvoiceFile = async (invoiceFile?: File) => {
    setInvoiceFile(invoiceFile);
    setFieldValue(name, invoiceFile);
    if (!!invoiceFile) {
      const base64Invoice = (await toBase64(invoiceFile)) as string;
      const base64InvoiceString = base64Invoice.split(',')[1];
      setFieldValue('attachmentBase64', base64InvoiceString);
      setFieldValue('attachmentName', invoiceFile.name);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['application/pdf'],
  });

  return (
    <div className={`${classes.dropzone} ${className}`} {...getRootProps()}>
      {!invoiceFile && (
        <Fragment>
          <input name={name} id={name} {...getInputProps()} />
          <span>
            <CloudUploadIcon fontSize="large" />
          </span>
          <p>{t('drop invoiceFile')}</p>
        </Fragment>
      )}
      {invoiceFile && (
        <p>
          {invoiceFile.name}
          <IconButton onClick={onDeleteFile} aria-label="delete">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </p>
      )}
      {errorMessage && touched && (
        <Typography className={classes.error} variant="caption">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
}
