import React, { ReactNode } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@material-ui/core';
import InstalmentPlanPreview from '../InstalmentPlanPreview';
import InstalmentRatePlanTable from '../InstalmentRatePlanTable';
import { useTranslation } from 'react-i18next';
import { InstalmentPlan } from '../../../types/InstalmentPlan/InstalmentPlan';

type InstalmentPlanPreviewProps = {
  onCancel: () => void;
  onBack: () => void;
  instalmentPlan: InstalmentPlan;
  dialogActions: ReactNode;
};
const InstalmentPlanPreviewDialog = ({ onCancel, onBack, instalmentPlan, dialogActions }: InstalmentPlanPreviewProps) => {
  const { t } = useTranslation(['global', 'instalmentPlans']);
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={true}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t('instalmentPlans:payment plan')}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction={'column'} spacing={3}>
          <InstalmentPlanPreview instalmentPlan={instalmentPlan} />
          <Divider />
          <InstalmentRatePlanTable instalmentRates={instalmentPlan.instalmentRates} type={'preview'} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onBack} color="primary">
          {t('global:back')}
        </Button>
        {dialogActions}
      </DialogActions>
    </Dialog>
  );
};

export default InstalmentPlanPreviewDialog;
