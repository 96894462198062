import { Box, Grid, MenuItem, Select, OutlinedInput, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { Pagination } from '@material-ui/lab';
import { PaginationContext } from './PaginationContext';

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  icon: {
    color: '#FFFFFF',
  },
}));

type TablePaginationProps = {
  transformPageSizeLabel: (pageSize: number) => string | number;
};

const TablePagination = ({ transformPageSizeLabel }: TablePaginationProps) => {
  const { pageSizeOptions, currentPage, pageSize, totalPages, changePageSize, setCurrentPage } = useContext(PaginationContext);

  const changePageSizeProxy = (pageSize: number) => {
    changePageSize(pageSize);
  };

  const classes = useStyles();

  return (
    <>
      <Box style={{ height: '1em' }} />
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item style={{ display: 'flex' }} xs={3}>
          <Select
            onChange={(e) => changePageSizeProxy(parseInt(String(e.target.value)))}
            value={pageSize}
            input={<OutlinedInput margin="dense" />}
            className={classes.select}
            classes={{ icon: classes.icon }}
          >
            {pageSizeOptions.map((option, i) => (
              <MenuItem key={i} value={option}>
                {transformPageSizeLabel(option)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Pagination classes={{}} onChange={(event, number) => setCurrentPage(number - 1)} color="secondary" count={totalPages} page={currentPage} />
        </Grid>
      </Grid>
    </>
  );
};

export default TablePagination;
