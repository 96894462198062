import React, { PropsWithChildren, useState } from 'react';
import ApiClient from '../../services/ApiClient';

const ProgressContext = React.createContext<{ progress: boolean; setProgress: (b: boolean) => void }>({
  progress: true,
  setProgress: () => {},
});

export default function WithProgressContext({ children }: PropsWithChildren<{}>) {
  const [progress, setProgress] = useState(false);

  const client = ApiClient.client;
  const noAuthClient = ApiClient.noAuthClient;

  client.interceptors.request.use(
    function (config) {
      setProgress(true);
      return config;
    },
    function (error) {
      setProgress(true);
      return Promise.reject(error);
    },
  );

  client.interceptors.response.use(
    function (response) {
      setProgress(false);
      return response;
    },
    function (error) {
      setProgress(false);
      return Promise.reject(error);
    },
  );

  noAuthClient.interceptors.request.use(
    function (config) {
      setProgress(true);
      return config;
    },
    function (error) {
      setProgress(true);
      return Promise.reject(error);
    },
  );

  noAuthClient.interceptors.response.use(
    function (response) {
      setProgress(false);
      return response;
    },
    function (error) {
      setProgress(false);
      return Promise.reject(error);
    },
  );

  return <ProgressContext.Provider value={{ progress, setProgress }}>{children}</ProgressContext.Provider>;
}

export { ProgressContext };
