import IBAN from 'iban';
import { BankAccount } from '../types/Bank/BankAccount';
import { Debtors } from '../types/Debtor/Debtor';

const getDebtorLabel = (
  debtor: { emailAddress: string; type?: Debtors.Type; firstName: string; lastName: string; companyName?: string },
  extendMail = false,
): string | undefined => {
  const extend = extendMail ? ` (${debtor.emailAddress})` : '';

  let fullName = (!!debtor.firstName ? `${debtor.firstName} ` : '') + (!!debtor.lastName ? `${debtor.lastName} ` : '');
  if (debtor.type !== 'ORGANIZATION' && fullName.trim().length === 0) {
    return undefined;
  }

  return debtor.type === 'ORGANIZATION' ? `${debtor.companyName || debtor.emailAddress}${extend}` : fullName + extend;
};

const getFullDebtorLabel = (
  debtor: { emailAddress: string; type?: Debtors.Type; firstName: string; lastName: string; companyName?: string },
  extendMail = false,
): string | undefined => {
  let fullName = (!!debtor.firstName ? `${debtor.firstName} ` : '') + (!!debtor.lastName ? `${debtor.lastName} ` : '');
  if (debtor.type !== 'ORGANIZATION' && fullName.trim().length === 0) {
    return undefined;
  }

  return debtor.type === 'ORGANIZATION'
    ? `${fullName} (${debtor.companyName || debtor.emailAddress} ${extendMail ? ` ${debtor.emailAddress}` : ''})`
    : `${fullName} ${extendMail ? `(${debtor.emailAddress})` : ''}`;
};

const getBankAccountLabel = (bankAccount: BankAccount) => {
  let defaultPrefix = bankAccount.default ? '⭐️ ' : '';
  let labelSuffix = hasBankAccountLabel(bankAccount) ? ` - (${bankAccount.bankAccountLabel})` : '';
  return `${defaultPrefix}${bankAccount.bankName} - ${IBAN.printFormat(bankAccount.iban)}${labelSuffix}`;
};

const hasBankAccountLabel = (bankAccount: BankAccount) => {
  return bankAccount.bankAccountLabel?.trim().length > 0;
};

export { getDebtorLabel, getFullDebtorLabel, getBankAccountLabel };
