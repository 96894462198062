import { MenuItem } from '@material-ui/core';
import React, { useContext } from 'react';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { PaymentActionType } from './PaymentActionHelper';
import PaymentService from '../../../services/PaymentService';

const PaymentLinkCopyAction = ({ payment, setMenuAnchor, label }: PaymentActionType) => {
  const alerting = useContext(AlertContext);
  const { t } = useTranslation('payments');
  return (
    <MenuItem
      key={payment.id + 'linkCopy'}
      onClick={() => {
        setMenuAnchor(null);
        PaymentService.getPaymentLink(payment.id)
          .then((paymentLink) => {
            copy(paymentLink, {});
            alerting.sendAlert('success', t('payment link copied'));
          })
          .catch(() => {
            alerting.sendAlert('error', t('payment link not found'));
          });
      }}
    >
      {label}
    </MenuItem>
  );
};

export default PaymentLinkCopyAction;
