import { DialogActions } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement, useRef } from 'react';
import { BaseRow, Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { useTranslation } from 'react-i18next';

interface Props {
  onFinish: (rows: PaymentImportRow[]) => void;
  onClose: () => void;
}

export type PaymentImportRow = {
  id: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  amount: string;
  purpose: string;
  metadataString: string;
  metadata?: Record<string, string>;
};

function CsvImporter({ onFinish, onClose }: Props): ReactElement {
  const { t } = useTranslation(['global', 'payments']);

  const allRows = useRef<BaseRow[]>([]);

  return (
    <>
      <Importer
        assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
        restartable={false} // optional, lets user choose to upload another file when import is complete
        onStart={({ file, fields }) => {
          // optional, invoked when user has mapped columns and started import
        }}
        processChunk={async (rows) => {
          const rowsWithId = rows.map((row) => ({
            id: Math.floor(Math.random() * 1000000),
            ...row,
          }));
          allRows.current.push(...rowsWithId);
        }}
        onComplete={({ file, preview, fields, columnFields }) => {
          // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)

          const md1Index = columnFields.indexOf('metadata1');
          const md2Index = columnFields.indexOf('metadata2');
          const md3Index = columnFields.indexOf('metadata3');
          const md1Key = md1Index !== -1 ? preview.columns[md1Index]?.header : undefined;
          const md2Key = md2Index !== -1 ? preview.columns[md2Index]?.header : undefined;
          const md3Key = md3Index !== -1 ? preview.columns[md3Index]?.header : undefined;
          const allData: PaymentImportRow[] = allRows.current.map((value) => {
            const metadata = {};
            if (md1Key) {
              // @ts-ignore
              metadata[md1Key] = value.metadata1;
            }
            if (md2Key) {
              // @ts-ignore
              metadata[md2Key] = value.metadata2;
            }
            if (md3Key) {
              // @ts-ignore
              metadata[md3Key] = value.metadata3;
            }

            return {
              id: value.id as string,
              firstName: value.firstName as string,
              lastName: value.lastName as string,
              emailAddress: value.emailAddress as string,
              amount: value.amount as string,
              purpose: value.purpose as string,
              metadata: metadata,
              metadataString: JSON.stringify(metadata),
            };
          });
          onFinish(allData);
        }}
        onClose={() => {
          // optional, invoked when import is done and user clicked "Finish"
          // (if this is not specified, the widget lets the user upload another file)
          console.log('importer dismissed');
        }}
      >
        <ImporterField name="firstName" label={t('global:fieldNames.firstName')} />
        <ImporterField name="lastName" label={t('global:fieldNames.lastName')} />
        <ImporterField name="emailAddress" label={t('global:fieldNames.emailAddress')} />
        <ImporterField name="amount" label={t('global:fieldNames.amount')} />
        <ImporterField name="purpose" label={t('global:fieldNames.purpose')} />
        <ImporterField name="metadata1" label={t('global:metaNumber', { number: 1 })} optional />
        <ImporterField name="metadata2" label={t('global:metaNumber', { number: 2 })} optional />
        <ImporterField name="metadata3" label={t('global:metaNumber', { number: 3 })} optional />
      </Importer>
      <DialogActions>
        <Button label={t('global:close')} name="import" onClick={onClose} />
      </DialogActions>
    </>
  );
}

export { CsvImporter };
