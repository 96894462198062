import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';
import { EbicsConnection, EbicsConnections } from '../../../../types/Ebics/EbicsConnection';

const ebicsPattern = /^[a-zA-Z\d,=]{1,35}$/;

interface FormikValues {
  creditorIdentifier?: string;
  creditorName?: string;
  nextStep?: boolean;
  creditor_nextStep?: boolean;
  request_nextStep?: boolean;
  ini_nextStep?: boolean;
  withdrawLimit?: number;
  partnerId?: string;
  userId?: string;
}

const initialValues = (ebicsConnection: EbicsConnection, status?: EbicsConnections.Status): FormikValues => {
  switch (status) {
    case EbicsConnections.Status.INITIALIZING_EBICS:
      return {
        partnerId: '',
        userId: '',
        nextStep: false,
      };
    case EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK:
      return {
        creditorIdentifier: ebicsConnection.creditorIdentifier,
        withdrawLimit: ebicsConnection.withdrawLimit,
        creditorName: ebicsConnection.creditorName,
        request_nextStep: false,
      };
    case EbicsConnections.Status.SENDING_INI_LETTER:
      return {
        ini_nextStep: false,
      };
    case EbicsConnections.Status.SETTING_UP_CREDITOR_IDENTIFIER:
      return {
        creditorIdentifier: ebicsConnection.creditorIdentifier,
        creditor_nextStep: false,
      };
    default:
      return {};
  }
};

const schemas = (t: TFunction, status?: EbicsConnections.Status): ObjectSchema<any> | undefined => {
  switch (status) {
    case EbicsConnections.Status.INITIALIZING_EBICS:
      return Yup.object().shape({
        partnerId: Yup.string().required(t('global:required')).matches(ebicsPattern, t('partnerId invalid')).nullable(),
        userId: Yup.string().required(t('global:required')).matches(ebicsPattern, t('userId invalid')).nullable(),
      });
    case EbicsConnections.Status.REQUESTING_EBICS_FROM_BANK:
      return Yup.object().shape({
        withdrawLimit: Yup.number().required(t('global:required')).moreThan(0),
        request_nextStep: Yup.bool().oneOf([true], t('agree to have received creditor id')),
        creditorName: Yup.string().required(t('global:required')).nullable(),
      });
    case EbicsConnections.Status.SENDING_INI_LETTER:
      return Yup.object().shape({
        ini_nextStep: Yup.bool().oneOf([true], t('agree to have received creditor id')),
      });
    case EbicsConnections.Status.SETTING_UP_CREDITOR_IDENTIFIER:
      return Yup.object().shape({
        creditorIdentifier: Yup.string()
          .required(t('global:required'))
          .matches(/^[A-Z]{2}\d{2}[A-Z\d]{3}[A-Z\d]{5,28}$/, t('creditor id invalid'))
          .nullable(),
        creditor_nextStep: Yup.bool().oneOf([true], t('agree to have received creditor id')),
      });
    default:
      return undefined;
  }
};

export { schemas, initialValues };
export type { FormikValues };
export default schemas;
