import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import { Debtor } from '../../../types/Debtor/Debtor';
import DebtorManuallyForm from './DebtorManuallyForm';
import { createSchemas } from './schemas';
import DebtorService from '../../../services/DebtorService';

interface Props {
  open: boolean;
  debtor: Debtor;
  onClose: () => void;
  onEdit: (debtor: Debtor) => void;
}

export default function EditDebtorDialog({ open, debtor, onClose, onEdit }: Props): ReactElement {
  const { t } = useTranslation('debitors');

  const { t: tValidations } = useTranslation('validations');

  const initialValues = debtor;

  const _handleSubmit = (values: Debtor, actions: FormikHelpers<Debtor>) => {
    DebtorService.updateDebtor(debtor.id, values)
      .then((res) => {
        actions.setSubmitting(false);
        if (res) {
          onEdit(values);
        } else {
          actions.setStatus('ERROR'); //TODO handle catch
        }
      })
      .catch((err) => {
        actions.setStatus(err);
      });
  };
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
    >
      <Formik initialValues={initialValues} validationSchema={createSchemas(tValidations)[0]} onSubmit={_handleSubmit}>
        {({ values, status, isSubmitting, setFieldValue }) => (
          <Form>
            <DialogTitle id="alert-dialog-title">{t('edit debitor')}</DialogTitle>
            <DialogContent dividers>
              <DebtorManuallyForm status={status} values={values} setFieldValue={setFieldValue} mode="edit" />
            </DialogContent>
            <DialogActions>
              <Button variant="text" name="cancel" label={t('global:cancel')} disabled={false} loading={false} onClick={onClose} />
              <LoadingButton type="submit" loading={isSubmitting}>
                {t('global:save')}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
