import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, makeStyles, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@payactive/app-common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFReader } from 'reactjs-pdf-reader';
import tcs from './assets/20210830_payactive GmbH_Agent Feature Nutzungsbedingungen (Version Agent).pdf';

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: theme.spacing(2),
  },
  dialogContainer: {},
  pdfContainer: {
    height: '400px',
  },
}));

export default function DeletedDialog({ activate, onClose, open }) {
  const [isChecked, setIsChecked] = useState(false);

  const { t } = useTranslation('services');
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="md"
      className={classes.dialogContainer}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography align="left" variant="h5">
          {t('terms title')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContentText className={classes.text} id="alert-dialog-description">
        <Alert severity="info">{t('terms info')}</Alert>
      </DialogContentText>
      <DialogContent>
        <PDFReader url={tcs} showAllPage={false} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <FormControl component="fieldset">
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} name="terms and conditions" color="primary" />}
            label={t('terms checkbox')}
          />
        </FormControl>
        <Button variant="text" name="cancel" label={t('global:cancel')} disabled={false} loading={false} onClick={onClose} />
        <Button
          name="cancel"
          label={t('global:continue')}
          disabled={!isChecked}
          loading={false}
          onClick={() => {
            activate();
            onClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
