import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import PageHeader from '../../components/ui/blocks/PageHeader';
import React from 'react';
import CreditNotesTable from './table/CreditNotesTable';
import { CreditNotesListDataContext, CreditNotesListDataContextProvider } from './CreditNotesListDataContextProvider';
import GenericTableFilters, { StateFilter } from '../../components/filter/GenericTableFilters';
import { CreditNoteState } from '../../types/CreditNote/CreditNoteState';

export default function CreditNotesView() {
  const { t } = useTranslation(['debitors', 'invoices', 'creditNotes']);

  const filterArray: StateFilter[] = [
    { name: t('invoices:DRAFT'), value: CreditNoteState.DRAFT },
    { name: t('invoices:OPEN'), value: CreditNoteState.OPEN },
    { name: t('invoices:PAID'), value: CreditNoteState.PAID },
    { name: t('invoices:CANCELLED'), value: CreditNoteState.CANCELLED },
  ];

  return (
    <CreditNotesListDataContextProvider pageSizeOptions={[5, 10, 30]} hasPaginationProvider={true}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeader title={t('creditNotes:credit notes')} />
        </Grid>
      </Grid>
      <Grid item>
        <GenericTableFilters states={filterArray} context={CreditNotesListDataContext} t={t} />
      </Grid>

      <CreditNotesTable />
    </CreditNotesListDataContextProvider>
  );
}
