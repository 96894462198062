import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface BankInfo {
  requestProcessInfo: React.ReactElement;
  iniProcessInfo: React.ReactElement;
  costsInfo?: React.ReactElement;
}

const bankInfos: { [key: string]: BankInfo } = {
  GENODEM1GLS: {
    iniProcessInfo: <GLSBankIniInfo />,
    requestProcessInfo: <GLSBankRequestInfo />,
    costsInfo: <GLSBankCostsInfo />,
  },
  TESTDE88XXX: {
    iniProcessInfo: <GLSBankIniInfo />,
    requestProcessInfo: <GLSBankRequestInfo />,
    costsInfo: <GLSBankCostsInfo />,
  },
};

const bankInfoByBic = (bic: string): BankInfo => {
  const info = bankInfos[bic];

  if (info) {
    return info;
  } else {
    return {
      iniProcessInfo: <DefaultBank />,
      requestProcessInfo: <DefaultBank />,
    };
  }
};

function DefaultBank(): ReactElement {
  return <></>;
}

// GLS Bank
function GLSBankIniInfo(): ReactElement {
  const { t } = useTranslation('sepa');

  return <>{t('ini_process.GENODEM1GLS.main')}</>;
}

function GLSBankRequestInfo(): ReactElement {
  const { t } = useTranslation('sepa');

  return <>{t('request_process.GENODEM1GLS.main')}</>;
}

function GLSBankCostsInfo(): ReactElement {
  const { t } = useTranslation('sepa');

  return (
    <>
      <p>{t('request_process.GENODEM1GLS.costs')}</p>
      <p>
        {/* link maintained in AzureFrontDoor */}
        <a target="_blank" rel="noreferrer" href="/gls-onboarding">
          {t('request_process.GENODEM1GLS.link')}
        </a>
      </p>
    </>
  );
}

export { bankInfoByBic };
