import { MenuItem } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import PaymentCancelDialog from '../dialogs/PaymentCancelDialog';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { PaymentListDataContext } from '../PaymentListDataContext';
import { useTranslation } from 'react-i18next';
import CancelPaymentInvoiceDialog from '../dialogs/CancelPaymentInvoiceDialog';
import { PaymentActionType } from './PaymentActionHelper';
import { CancelReasons } from '../../../types/CancelReasons';
import PaymentService from '../../../services/PaymentService';
import ReloadContext from '../../../components/contexts/ReloadContext';

const PaymentCancelAction = ({ payment, setMenuAnchor, label }: PaymentActionType) => {
  const [cancelPayment, setCancelPayment] = useState(false);
  const [cancelInvoicePayment, setCancelInvoicePayment] = useState(false);

  const alerting = useContext(AlertContext);
  const paymentReload = useContext(PaymentListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;
  const reload = () => {
    paymentReload();
    contextReload();
  };
  const { t } = useTranslation('payments');

  const callback = () => {
    payment.invoice?.internalRef ? setCancelInvoicePayment(true) : setCancelPayment(true);
  };

  const doCancelPayment = (reasons: CancelReasons) => {
    PaymentService.cancelPayment(payment, reasons).then((res) => {
      if (res) {
        setCancelPayment(false);
        reload();
        alerting.sendAlert('success', t('payment cancelled'));
      }
    });
  };

  return (
    <div key={payment.id + 'cancelC'}>
      <MenuItem
        key={payment.id + 'cancel'}
        onClick={() => {
          setMenuAnchor(null);
          callback();
        }}
      >
        {label}
      </MenuItem>
      <PaymentCancelDialog open={cancelPayment} onCancel={() => setCancelPayment(false)} onDelete={doCancelPayment} />
      <CancelPaymentInvoiceDialog open={cancelInvoicePayment} onCancel={() => setCancelInvoicePayment(false)} />
    </div>
  );
};

export default PaymentCancelAction;
