import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { CompanySettings } from '../../../../types/Company/CompanySettings';

const initialValues = (
  accountSettings: CompanySettings.Account,
  dunningService: CompanySettings.Dunning,
): CompanySettings.Account & CompanySettings.Dunning => {
  return {
    bccEmailAddressList: accountSettings.bccEmailAddressList || [],
    bccSelection: accountSettings.bccSelection || [],
    paymentReminderDays: accountSettings.paymentReminderDays || 3,
    paymentReminderEnabled: accountSettings.paymentReminderEnabled || false,
    dunningEnabled: dunningService.dunningEnabled || false,
    daysDunning: dunningService.daysDunning || 7,
    daysReminder: dunningService.daysReminder || 1,
    dunningFee: dunningService.dunningFee || 0.5,
    maxDunnings: dunningService.maxDunnings || 0,
  };
};

const createSchemas = (tValidation: TFunction, t: TFunction) => {
  return Yup.object().shape({
    bccEmailAddressList: Yup.array()
      .of(Yup.string().email(t('invalid email')))
      .required(tValidation('required', { field: t('bccEmailAddressList') })),

    paymentReminderDays: Yup.number().positive().min(3, t('min days')),
    dunningEnabled: Yup.boolean(),

    daysDunning: Yup.mixed().when('dunningEnabled', {
      is: true,
      then: Yup.number()
        .min(7, t('min seven days'))
        .integer()
        .required(tValidation('required', { field: t('days num seven') })),
    }),

    daysReminder: Yup.mixed().when('dunningEnabled', {
      is: true,
      then: Yup.number()
        .min(1, t('min one day'))
        .integer()
        .required(tValidation('required', { field: t('days num one') })),
    }),

    dunningFee: Yup.mixed().when('dunningEnabled', {
      is: true,
      then: Yup.number()
        .positive(t('positive'))
        .required(tValidation('required', { field: t('euro') })),
    }),

    maxDunnings: Yup.mixed().when('dunningEnabled', {
      is: true,
      then: Yup.number()
        .min(0, t('min cero'))
        .integer()
        .required(tValidation('required', { field: t('number') })),
    }),
  });
};

export { createSchemas, initialValues };
