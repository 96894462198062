import { MenuItem } from '@material-ui/core';
import { DebtorActionType } from './DebtorActionHelper';
import { useState } from 'react';
import { NewPaymentDialog } from '../../payments/dialogs/NewPaymentDialog';

const DebtorNewPaymentAction = ({ debtor, setMenuAnchor, label }: DebtorActionType) => {
  const [openDialog, setOpenDialog] = useState(false);

  //reload count
  return (
    <div key={debtor.id + 'newPaymentC'}>
      <MenuItem
        key={debtor.id + 'newPayment'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>
      {openDialog && (
        <NewPaymentDialog onSuccess={() => setOpenDialog(false)} isOpen={openDialog} onCancel={() => setOpenDialog(false)} debitor={debtor} />
      )}
    </div>
  );
};

export default DebtorNewPaymentAction;
