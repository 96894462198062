import { CircularProgress, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { Fragment } from 'react';

const STATES = {
  LOADING: 0,
  ERROR: 1,
  READY: 3,
};

export default function WithLoading(props) {
  const { children, state, text } = props;

  return (
    <Fragment>
      {state === STATES.LOADING && (
        <Fragment>
          <CircularProgress />
          <Typography>{text}</Typography>
        </Fragment>
      )}
      {state === STATES.READY && <Fragment>{children}</Fragment>}
      {state === STATES.ERROR && <Alert severity="error">{text}</Alert>}
    </Fragment>
  );
}

export { STATES };
