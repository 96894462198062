import { Avatar, Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../../components/ui/blocks/PageHeader';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import CompanyAccountService from '../../../services/CompanyAccountService';

const useStyles = makeStyles((theme) => ({
  image: {
    height: 'auto',
  },
  avatar: {
    width: '300px',
    height: '300px',
    boxShadow: theme.shadows[3],
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: '600px',
    textAlign: 'left',
  },
}));

const LogoUploadScreen = ({ className, ...rest }: { className?: string }) => {
  const { t } = useTranslation('company');

  const { t: tGlobal } = useTranslation('global');

  const alerting = useContext(AlertContext);

  const classes = useStyles();

  const [logoUrl, setLogoUrl] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFileToUpload(acceptedFiles[0]);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setLogoUrl(reader.result as string);
    });
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpeg'],
  });

  useEffect(() => {
    CompanyAccountService.getCompany().then(({ logoUrl, name }) => {
      setLogoUrl(logoUrl);
      setCompanyName(name);
    });
  }, []);

  const handleChange = () => {
    if (!fileToUpload) {
      //cannot happen, because button to submit is not available if fileToUpload is undefined
      return;
    } else {
      setLoading(true);
      CompanyAccountService.uploadCompanyLogo(fileToUpload).then((res) => {
        setLoading(false);
        if (res) {
          setFileToUpload(undefined);
          alerting.sendAlert('success', t('logo successfully updated'));
        }
      });
    }
  };

  return (
    <>
      <PageHeader title={t('logo')} subtitle={t('settings')} />
      <Card elevation={0} {...rest} className={classes.paper}>
        <CardHeader subheader={t('logo explanation')} title={t('current logo')} />
        <CardContent>
          <p>{!!logoUrl ? <Typography>{t('add logo')}</Typography> : <Typography>{t('change logo')}</Typography>}</p>
          <p>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {
                <Avatar
                  classes={{
                    img: classes.image,
                    root: classes.avatar,
                  }}
                  variant="rounded"
                  srcSet={logoUrl}
                  src={logoUrl}
                >
                  {companyName}
                </Avatar>
              }
            </div>
          </p>
          <p>
            <Button
              loading={loading}
              disabled={!fileToUpload || loading}
              onClick={handleChange}
              fullWidth={false}
              label={tGlobal('save')}
              name={tGlobal('save')}
            />
          </p>
        </CardContent>
      </Card>
    </>
  );
};

LogoUploadScreen.propTypes = {
  className: PropTypes.string,
};

export default LogoUploadScreen;
