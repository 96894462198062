import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Handlebars from 'handlebars';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import PaperWithTitle from './PaperWithTitle';
import { html } from './preview_html';
import TabPanel from './TabPanel';

interface BrandingPreviewProps {
  company_name?: string;
  logoUrl?: string;
  debitor_name?: string;
  creditor_brand_color?: string;
  creditor_text_color?: string;
  company_street?: string;
  company_zip?: string;
  company_town?: string;
}

function BrandingPreview(props: BrandingPreviewProps) {
  const template = Handlebars.compile(html);

  const { t } = useTranslation(['branding']);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <PaperWithTitle title={t('preview')}>
      <AppBar position="static" color="transparent">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Web" />
          <Tab label="Mobile" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div dangerouslySetInnerHTML={{ __html: template(props) }} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Container style={{ maxWidth: '375px' }}>
          <div style={{ maxHeight: '667px', overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: template(props) }} />
        </Container>
      </TabPanel>
    </PaperWithTitle>
  );
}

export default BrandingPreview;
