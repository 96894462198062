import { Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionDialog from '../../../components/ActionDialog';
import LoadingButton from '../../../components/LoadingButton';

export default function InviteDialog({ open, onCancel, onInvite, loading, errors = [] }) {
  const { t } = useTranslation('company');

  const { t: tGlobal } = useTranslation('global');

  const [isOpen, setOpen] = useState(open);

  const [email, setEmail] = useState('');

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const submit = (e) => {
    e.preventDefault();
    onInvite(email);
  };

  return (
    <ActionDialog
      onSubmit={submit}
      open={isOpen}
      onClose={onCancel}
      title={t('invite member')}
      text={t('invite text')}
      content={
        <Fragment>
          {errors.map((error) => (
            <Alert severity="error">{error}</Alert>
          ))}
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            label={t('emailAddress')}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
        </Fragment>
      }
      actions={
        <Fragment>
          <Button onClick={onCancel} color="primary">
            {tGlobal('cancel')}
          </Button>
          <LoadingButton loading={loading} variant="contained" color="primary">
            {t('invite')}
          </LoadingButton>
        </Fragment>
      }
    />
  );
}
