import { TFunction } from 'i18next';
import * as Yup from 'yup';
import IBAN from 'iban';

export const SEPAMandateFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    debitorId: Yup.string().required(t('required', { field: t('debitor') })),
    iban: Yup.mixed()
      .required(t('required', { field: t('iban') }))
      .test('valid', t('sepa:no_valid_iban'), (iban?: string) => {
        return IBAN.isValid(iban || ''.replaceAll(/\s|_/g, ''));
      }),
    bic: Yup.string()
      .required(t('required', { field: t('bic') }))
      .min(8, t('min max bic chars'))
      .max(11, t('min max bic chars')),
    signatureDate: Yup.date().required(t('required', { field: t('signatureDate') })),
    isChecked: Yup.boolean()
      .required(t('required', { field: t('isChecked') }))
      .oneOf([true], t('agree to terms')),
  });
};
