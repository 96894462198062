import { PaymentMethod } from '../Invoice/PaymentMethod';
import { Address } from '../Address';

export interface Debtor extends NewDebtor {
  id: string;
  emailAddress: string;
  additionalEmailAddresses: string[];
  firstName: string;
  lastName: string;
  type: Debtors.Type;
  paymentMethod: PaymentMethod;
  companyName?: string;
  externalRef?: string;
  address?: Address;
  paymentMethodSetupLink?: string;
  sepaMandateCreated?: boolean;
  onboardingState?: 'ONBOARDING_PENDING' | 'ONBOARDING_COMPLETED';
  vatId?: string;
}

export type NewDebtor = {
  readonly id?: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  paymentMethod?: PaymentMethod;
  invitationType?: Debtors.InvitationType;
  externalRef?: string;
  type: Debtors.Type;
  companyName?: string;
  address?: Address;
  vatId?: string;
};
export namespace Debtors {
  export enum Type {
    PERSON = 'PERSON',
    ORGANIZATION = 'ORGANIZATION',
  }

  export enum InvitationType {
    MANUAL = 'MANUAL',
    EMAIL = 'EMAIL',
    LINK = 'LINK',
  }
}
