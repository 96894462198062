import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import MarkAsPaidDialog from '../dialogs/MarkAsPaidDialog';
import * as LabelHelper from '../../../utils/LabelHelper';
import { PaymentActionType } from './PaymentActionHelper';

const PaymentMarkAsPaidAction = ({ payment, setMenuAnchor, label }: PaymentActionType) => {
  const [markAsPaidDialog, setMarkAsPaidDialog] = useState(false);

  return (
    <div key={payment.id + 'markAsPaid'}>
      <MenuItem
        key={payment.id + 'markAsPaid'}
        onClick={() => {
          setMenuAnchor(null);
          setMarkAsPaidDialog(true);
        }}
      >
        {label}
      </MenuItem>
      <MarkAsPaidDialog
        payment={payment}
        open={markAsPaidDialog}
        onCancel={() => setMarkAsPaidDialog(false)}
        customer={LabelHelper.getDebtorLabel(payment.debitor) || payment.debitor.emailAddress}
      />
    </div>
  );
};

export default PaymentMarkAsPaidAction;
