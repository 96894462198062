import { Avatar, Badge, Icon, IconButton, Menu, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { MouseEvent, useState } from 'react';
import { Checkout } from '../../../types/Checkout/Checkout';
import StateWithIcon from '../../../components/StateWithIcon';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import { formatCustomFields } from '../../../utils/CheckoutHelperFunctions';
import { formatCurrency } from '../../../utils/PaymentUtil';
import LinkToDebtorDetailPage from '../../debtorDetailPage/LinkToDebtorDetailPage';
import { Debtors } from '../../../types/Debtor/Debtor';
import { getActionsForCheckout } from '../actions/CheckoutActionHelper';
import { useRowStyles } from '../../../utils/TableRowHelper';

type CheckoutRowProps = {
  checkout: Checkout;
  index: number;
};

const CheckoutRow = ({ checkout, index }: CheckoutRowProps) => {
  const { t } = useTranslation('checkouts');

  const classes = useRowStyles();

  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);

  const handleClick = (event: MouseEvent) => {
    setMenuAnchor(event.currentTarget);
  };
  const actions = getActionsForCheckout(checkout, setMenuAnchor, t, !checkout.paymentIdRef);

  return (
    <TableRow key={index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <TableCell key={checkout.id + 'state'}>
        <StateWithIcon state={checkout.checkoutState} label={t(checkout.checkoutState)} />
      </TableCell>
      <TableCell key={checkout.id + 'debtorFirstName'}>
        <LinkToDebtorDetailPage
          target={'payments'}
          debtor={{
            id: checkout.debitorIdRef || checkout.debtor.id,
            emailAddress: checkout.debitorEmailAddress,
            firstName: checkout.debitorFirstName,
            lastName: checkout.debitorLastName,
            type: Debtors.Type.PERSON,
          }}
          debtorRef={checkout.debitorIdRef}
        ></LinkToDebtorDetailPage>
      </TableCell>
      <TableCell key={checkout.id + 'productTitle'}>{checkout?.productTitle}</TableCell>
      <TableCell key={checkout.id + 'productPrice'}>{formatCurrency(checkout?.productPrice)}</TableCell>
      <TableCell key={checkout.id + 'createdDateTimeUTC'}>{formatDateTime(checkout.createdDateTimeUTC)}</TableCell>
      <TableCell key={checkout.id + 'customFields'}>{formatCustomFields(checkout.customFields)}</TableCell>

      <TableCell key={checkout.id + 'action'}>
        {!!actions && (
          <Badge badgeContent={0} color="error" overlap="circular">
            <IconButton onClick={handleClick}>
              <Avatar className={classes.avatar}>
                <Icon>more_horiz</Icon>
              </Avatar>
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuAnchor(undefined)}
              PaperProps={{
                className: classes.menu,
                style: {
                  width: 'min-content',
                },
              }}
            >
              {actions}
            </Menu>
          </Badge>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CheckoutRow;
