import { Avatar, Badge, Grid, Icon, IconButton, Menu, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { MouseEvent, useContext, useState } from 'react';
import { InvoiceListDataContext } from '../InvoiceListDataContextProvider';
import { Invoice } from '../../../types/Invoice/Invoice';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import StateWithIcon from '../../../components/StateWithIcon';
import LinkToDebtorDetailPage from '../../debtorDetailPage/LinkToDebtorDetailPage';
import { useRowStyles } from '../../../utils/TableRowHelper';
import { getActionsForInvoice } from '../actions/InvoiceActionHelper';
import DebtorService from '../../../services/DebtorService';
import { Debtor } from '../../../types/Debtor/Debtor';
import { InvoiceState } from '../../../types/Invoice/InvoiceState';
import Typography from '@material-ui/core/Typography';

type InvoiceRowProps = {
  invoice: Invoice;
  index: number;
  as?: 'tableRow' | 'grid';
};

export function InvoiceRow({ invoice, index }: InvoiceRowProps) {
  const { t } = useTranslation(['debitors', 'invoices']);
  const classes = useRowStyles();

  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const [debtor, setDebtor] = useState<Debtor>();
  const menuOpen = Boolean(menuAnchor);

  const handleClick = (event: MouseEvent) => {
    DebtorService.getDebtor(invoice.debitor.id).then((debtor) => setDebtor(debtor));
    setMenuAnchor(event.currentTarget);
  };

  const isPrefiltered = useContext(InvoiceListDataContext).isPrefilteredForDebtor;
  const invoiceType = invoice.invoiceType;
  const status = invoice.status;

  const actions = getActionsForInvoice(invoice, () => setMenuAnchor(undefined), t, debtor);

  return (
    <TableRow hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <>
        <TableCell key={invoice.id + 'status'}>
          <Grid container>
            <Grid item>
              <StateWithIcon
                state={invoice.status}
                label={t(`invoices:${invoice.status}`)}
                popoverContent={
                  status === InvoiceState.CONFLICT ? (
                    <Typography>{invoice.paymentStatus === 'INITIATED' ? t('conflict info initiated') : t('conflict info settled')}</Typography>
                  ) : undefined
                }
              />
            </Grid>
          </Grid>
        </TableCell>
        {!isPrefiltered && (
          <TableCell key={invoice.id + 'debtor'}>
            <LinkToDebtorDetailPage target={'invoices'} debtor={invoice.debitor} debtorRef={invoice.debitor.id}></LinkToDebtorDetailPage>
          </TableCell>
        )}
        <TableCell key={invoice.id + 'total'}>{formatCurrency(invoice.total)}</TableCell>
        <TableCell key={invoice.id + 'creationDate'}>{formatDateTime(invoice.createdDateTimeUTC)}</TableCell>
        <TableCell key={invoice.id + 'number'}>{invoice.invoiceNumber}</TableCell>
        <TableCell key={invoice.id + 'type'}>
          {invoiceType === 'CORRECTIVE_INVOICE'
            ? t('corrective invoice')
            : invoiceType === 'CANCELLATION_INVOICE'
            ? t('cancelled invoice')
            : t('invoice')}
        </TableCell>

        {!!actions && (
          <TableCell key={invoice.id + 'action'}>
            <Badge badgeContent={0} color="error" overlap="circular">
              <IconButton onClick={handleClick}>
                <Avatar className={classes.avatar}>
                  <Icon>more_horiz</Icon>
                </Avatar>
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuAnchor(undefined)}
                PaperProps={{
                  className: classes.menu,
                  style: {
                    width: 'min-content',
                  },
                }}
              >
                {actions}
              </Menu>
            </Badge>
          </TableCell>
        )}
      </>
    </TableRow>
  );
}

export default InvoiceRow;
