import ClearIcon from '@material-ui/icons/Clear';
import { Button, FormikSelectField } from '@payactive/app-common';
import { Grid, IconButton } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { taxOptions } from './InvoiceCreationSchema';
import { InvoiceFormValues } from '../../../types/Invoice/Invoice';

const data = taxOptions.map((item) => {
  return { label: item.description, value: `${item.rate}` };
});

export default function TaxSelectField({ name, label, teaser, index }: { name: string; label: ReactNode; teaser: string; index: number }) {
  const [field] = useField(name);
  const { setFieldValue, values } = useFormikContext<InvoiceFormValues>();

  const [active, setActive] = useState(false);

  useEffect(() => {
    if (field.value > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [field]);

  const onClick = () => {
    if (active) {
      setFieldValue(name, -1);
    } else {
      setFieldValue(name, 19);
    }
    setActive((current) => !current);
  };

  return active ? (
    <Grid container>
      <Grid item>
        <FormikSelectField icon="" name={name} label={label} data={data} disabled={!!values?.positions[index]?.productId} />
      </Grid>
      <Grid item>
        <IconButton aria-label="delete" disabled={!!values?.positions[index]?.productId}>
          <ClearIcon fontSize="small" onClick={onClick} />
        </IconButton>
      </Grid>
    </Grid>
  ) : (
    <Button variant="text" label={teaser} name={teaser} onClick={onClick} disabled={!!values?.positions[index]?.productId} />
  );
}
