import { Box, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';

export default function TabPanel(props: { children: ReactNode; value: number; index: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
