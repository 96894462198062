import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import PaymentService from '../../services/PaymentService';
import { Payment } from '../../types/Payment/Payment';
import PaginationProvider from '../../components/table/PaginationContext';

import moment from 'moment';
import { StateFilter } from '../../components/filter/GenericTableFilters';
import { searchDelay } from '../../services/ApiClient';
import { PageMetadata } from '../../types/Page';
import { Filter } from '../../types/Filter';

export const PaymentListDataContext = createContext<{
  payments: Payment[];
  filters?: {
    stateFilter: StateFilter[];
    setStateFilter: Dispatch<SetStateAction<StateFilter[]>>;
    dateStartFilter: string;
    setDateStartFilter: Dispatch<SetStateAction<string>>;
    dateEndFilter: string;
    setDateEndFilter: Dispatch<SetStateAction<string>>;
    searchFilter: string;
    setSearchFilter: Dispatch<SetStateAction<string>>;
    used: boolean;
  };
  page: PageMetadata | undefined;
  reload: () => void;
  loading: boolean;
  isPrefilteredForDebtor: boolean;
  currentPage: { set: (page: number) => void; get: number };
  pageSize: { set: (size: number) => void; get: number };
}>({
  payments: [] as Payment[],
  page: {} as PageMetadata,
  reload: () => {},
  loading: true,
  isPrefilteredForDebtor: false,
  currentPage: {
    set: (page) => {},
    get: 0,
  },
  pageSize: {
    set: (size) => {},
    get: 0,
  },
});

type PaymentListDataContextProviderProps = {
  pageSizeOptions: number[];
  children: any;
  debtorId?: string;
  hasPaginationProvider?: boolean;
};

const PaymentListDataContextProvider = ({
  pageSizeOptions,
  debtorId,
  children,
  hasPaginationProvider = true,
}: PaymentListDataContextProviderProps) => {
  const [paymentList, setPaymentList] = useState<Payment[]>([] as Payment[]);
  const [page, setPage] = useState<PageMetadata>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [stateFilter, setStateFilter] = useState<StateFilter[]>([] as StateFilter[]);
  const [dateStartFilter, setDateStartFilter] = useState<string>('');
  const [dateEndFilter, setDateEndFilter] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [filtersUsed, setFiltersUsed] = useState(false);

  const isInitialized = React.useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    let delaySearch: any;
    if (isInitialized.current) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        delaySearch = setTimeout(() => {
          reload();
        }, searchDelay);
      }
    }
    return () => {
      delaySearch && clearTimeout(delaySearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFilter, dateStartFilter, dateEndFilter, searchFilter, pageSize]);

  useEffect(() => {
    reload();
    isInitialized.current = true;
    return () => {
      isInitialized.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reload = () => {
    setLoading(true);
    const filter: Filter = {
      debtorRef: debtorId,
    };
    if (!!stateFilter) {
      filter.states = Array.from(stateFilter.values()).join(',');
    }
    filter.search = searchFilter;
    filter.startDate = dateStartFilter && moment(dateStartFilter).format('YYYY-MM-DDT23:59:59.999') + 'Z';
    filter.endDate = dateEndFilter && moment(dateEndFilter).format('YYYY-MM-DDT23:59:59.999') + 'Z';

    setFiltersUsed(!!stateFilter || !!dateStartFilter || !!dateEndFilter || !!searchFilter);

    PaymentService.getPayments(pageSize, currentPage || 0, 'desc', filter).then(({ results, page }) => {
      setPaymentList(results);
      setPage(page);
      setLoading(false);
    });
  };

  return (
    <PaymentListDataContext.Provider
      value={{
        payments: paymentList,
        filters: {
          stateFilter: stateFilter,
          setStateFilter: setStateFilter,
          dateStartFilter: dateStartFilter,
          setDateStartFilter: setDateStartFilter,
          dateEndFilter: dateEndFilter,
          setDateEndFilter: setDateEndFilter,
          searchFilter: searchFilter,
          setSearchFilter: setSearchFilter,
          used: filtersUsed,
        },
        page: page,
        reload: reload,
        loading: loading,
        isPrefilteredForDebtor: !!debtorId,
        currentPage: { set: setCurrentPage, get: currentPage },
        pageSize: { set: setPageSize, get: pageSize },
      }}
    >
      {hasPaginationProvider ? (
        <PaginationProvider
          pageSizeOptions={pageSizeOptions}
          currentPage={currentPage || 0}
          totalPages={page?.totalPages || 0}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          changePageSize={setPageSize}
        >
          {children}
        </PaginationProvider>
      ) : (
        children
      )}
    </PaymentListDataContext.Provider>
  );
};

export default PaymentListDataContextProvider;
