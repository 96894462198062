import { FieldArray, useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { DialogActions, Grid, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, FormikInputField } from '@payactive/app-common';
import { createNewInvoicePosition } from './InvoiceDialogFunctions';
import TaxSelectField from './TaxSelectField';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { useTranslation } from 'react-i18next';
import { InvoiceFormValues } from '../../../types/Invoice/Invoice';
import ProductOrName from './ProductOrName';
import { Mode } from './InvoiceDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormHelperText-root.Mui-error': {
      position: 'absolute',
      top: '3rem',
    },
    '& .MuiGrid-spacing-xs-2': {
      marginTop: '1rem',
    },
  },
  tax: {},
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
  dialogActions: {
    marginTop: theme.spacing(3),
  },
}));

export default function InvoicePositions({ mode }: { mode: Mode }): ReactElement {
  const { values, isValid, setFieldValue } = useFormikContext<InvoiceFormValues>();
  const classes = useStyles();
  const { t } = useTranslation('debitors');
  return (
    <FieldArray
      name="positions"
      render={({ push, remove }) => (
        <div className={classes.root}>
          {values?.positions?.length > 0 &&
            values.positions.map((position, index) => (
              <div key={index}>
                {mode === Mode.EDITING && (
                  <Grid container key={index} spacing={2} justifyContent="space-between" alignItems="center">
                    <Grid item md={5}>
                      <ProductOrName index={index} />
                    </Grid>

                    <Grid item md={1}>
                      <FormikInputField type="number" name={`positions.${index}.quantity`} label={t('quantity')} />
                    </Grid>

                    <Grid item md={2}>
                      <CurrencyTextField
                        disabled={!!values.positions[index].productId}
                        label={t('price')}
                        value={values.positions[index].price}
                        name={`positions.${index}.price`}
                        mini1mumValue="0"
                        currencySymbol="€"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        onChange={(event: any, value: any) => setFieldValue(`positions.${index}.price`, value)}
                      />
                    </Grid>

                    <Grid item md={3}>
                      <TaxSelectField index={index} teaser={t('add tax rate')} name={`positions.${index}.taxRate`} label={t('tax rate')} />
                    </Grid>
                    <Grid item md={1}>
                      <IconButton aria-label="delete" disabled={index === 0 && values?.positions?.length === 1}>
                        <DeleteIcon
                          style={{ alignSelf: 'center' }}
                          fontSize="small"
                          onClick={() => {
                            remove(index);
                          }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </div>
            ))}
          {mode === Mode.EDITING && (
            <DialogActions className={classes.dialogActions}>
              <TaxSelectField index={-1} teaser={t('add overall tax rate')} name={'defaultTaxRate'} label={t('tax rate')} />
              <Button
                color="primary"
                disabled={!isValid}
                icon="add"
                onClick={() => {
                  push(createNewInvoicePosition());
                }}
                name="add"
                label={t('add another')}
              />
            </DialogActions>
          )}
        </div>
      )}
    />
  );
}
