import { MenuItem } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PaymentActionType } from './PaymentActionHelper';

const PaymentDetailsAction = ({ payment, setMenuAnchor, label }: PaymentActionType) => {
  let history = useHistory();
  return (
    <div key={payment.id + 'detailsC'}>
      <MenuItem
        key={payment.id + 'details'}
        onClick={() => {
          setMenuAnchor(null);
          history.push(`/dashboard/transactions/payments/${payment.id}/`);
        }}
      >
        {label}
      </MenuItem>
    </div>
  );
};

export default PaymentDetailsAction;
