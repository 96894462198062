import { useHistory } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';
import React from 'react';
import { CreditActionType } from './CreditActionHelper';
import { useTranslation } from 'react-i18next';

export default function CreditDetailsAction({ credit, setMenuAnchor }: CreditActionType) {
  const { t } = useTranslation('credits');

  let history = useHistory();
  return (
    <div key={credit.id + 'detailsC'}>
      <MenuItem
        key={credit.id + 'details'}
        onClick={() => {
          setMenuAnchor(null);
          history.push(`/dashboard/transactions/credits/${credit.id}/`);
        }}
      >
        {t('credit details')}
      </MenuItem>
    </div>
  );
}
