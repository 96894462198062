import React from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/DateTimeUtil';
import InstalmentRateIcon from './InstalmentRateIcon';
import { InstalmentRate } from '../../types/InstalmentPlan/InstalmentRate';

type InstalmentRatePlanProps = {
  instalmentRates: InstalmentRate[];
  type?: string;
};

const InstalmentRatePlanTable = ({ instalmentRates, type }: InstalmentRatePlanProps) => {
  const { t } = useTranslation('instalmentPlans');
  return (
    <>
      <Grid item>
        <Typography variant="body1" color={'primary'}>
          {!!type && type === 'preview' ? t('instalment rates plan preview') : t('instalment rates plan')}
        </Typography>
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              {instalmentRates.map((instalmentRate) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Grid container alignItems={'center'} spacing={2}>
                      <Grid item>
                        <InstalmentRateIcon instalmentRate={instalmentRate} />
                      </Grid>
                      <Grid item>{formatDate(instalmentRate.date)}</Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="right">{instalmentRate.amount} €</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default InstalmentRatePlanTable;
