import { countries, CountryType } from '../types/Country';
import { FormikInputField } from '@payactive/app-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  countryFlagStyles: {
    margin: '0 10px 0 0',
  },
}));

export default function CountrySelect({
  disabled = false,
  name,
  value,
  label,
  classNameAutocomplete,
  required = false,
  classNameInput,
}: {
  disabled?: boolean;
  required?: boolean;
  name: string;
  value?: CountryType;
  label: string;
  classNameAutocomplete?: string;
  classNameInput?: string;
}) {
  const { i18n } = useTranslation();
  const { setFieldValue } = useFormikContext<any>();
  const classes = useStyles();

  const lang = i18n.resolvedLanguage;
  let sortedCountries: CountryType[];
  if (lang === 'de') {
    sortedCountries = countries.sort((a, b) => a.labelDe.localeCompare(b.labelDe));
  } else {
    sortedCountries = countries.sort((a, b) => a.labelEn.localeCompare(b.labelEn));
  }
  return (
    <Autocomplete
      className={classNameAutocomplete}
      id="country-select"
      options={sortedCountries}
      autoHighlight
      getOptionLabel={(option: CountryType) => (lang === 'de' ? option.labelDe : option.labelEn)}
      onChange={(_event, value) => {
        setFieldValue(name, value?.code);
      }}
      value={value}
      renderOption={(option: CountryType) => (
        <Box component="li">
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt={option.code}
            className={classes.countryFlagStyles}
          />
          {lang === 'de' ? option.labelDe : option.labelEn} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <FormikInputField
          className={classNameInput}
          {...params}
          label={label}
          name={name}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          required={required}
        />
      )}
      disabled={disabled}
      fullWidth
    />
  );
}
