import { FormikInputField } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import CountrySelect from '../../../../components/CountrySelect';
import { useFormikContext } from 'formik';
import { countries } from '../../../../types/Country';
import { Address } from '../../../../types/Address';

export default function AddressInformation(): ReactElement {
  const { t } = useTranslation('company');

  const { values }: { values: { address?: Address } } = useFormikContext();
  const countryType = countries.find((country) => {
    const debtorCountry = values.address?.country;
    return (
      debtorCountry === country.code ||
      debtorCountry?.toLowerCase() === country.labelEn.toLowerCase() ||
      debtorCountry?.toLowerCase() === country.labelDe.toLowerCase()
    );
  });

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{t('address')}</Typography>
      </Grid>
      <Grid item container xs={12} alignItems={'stretch'} spacing={2}>
        <Grid item xs={12} alignItems={'stretch'}>
          <FormikInputField name="address.line" label={t('line')} autoComplete="street-address" fullWidth />
        </Grid>
        <Grid item xs={12} alignItems={'stretch'}>
          <FormikInputField name="address.suffix" label={t('suffix')} autoComplete="suffix-address" fullWidth />
        </Grid>
      </Grid>
      <Grid item container xs={12} alignItems={'stretch'} spacing={2}>
        <Grid item xs={4} alignItems={'stretch'}>
          <FormikInputField type="text" name="address.zipCode" autoComplete="postal-code" label={t('zipCode')} fullWidth />
        </Grid>
        <Grid item xs={8} alignItems={'stretch'}>
          <FormikInputField name="address.city" label={t('city')} autoComplete="city" fullWidth />
        </Grid>
        <Grid item xs={12} alignItems={'stretch'}>
          <CountrySelect name="address.country" label={t('country')} value={countryType} />
        </Grid>
      </Grid>
    </Grid>
  );
}
