import React, { ReactElement } from 'react';
import DeleteDialog from '../DeleteDialog';

interface ApiKey {
  name: string;
  prefix: string;
}

interface Props {
  open: boolean;
  apiKey: ApiKey;
  onClose: () => void;
  onDelete: (apiKey: ApiKey) => void;
}

export default function DeleteApiKeyDialog({ open, apiKey, onClose, onDelete }: Props): ReactElement {
  return <DeleteDialog open={open} data={apiKey} text={apiKey.name} title={'delete api key'} onClose={onClose} onDelete={onDelete} />;
}
