import * as React from 'react';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { InstalmentRate, InstalmentRates } from '../../types/InstalmentPlan/InstalmentRate';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import UpdateIcon from '@material-ui/icons/Update';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

type InstalmentRateIconProps = {
  instalmentRate: InstalmentRate;
};

const iconByStatus = (instalmentRate: InstalmentRate): React.ReactElement => {
  switch (instalmentRate.status) {
    case InstalmentRates.Status.SCHEDULED:
      return <ScheduleIcon color={'primary'} />;
    case InstalmentRates.Status.PENDING:
      return <UpdateIcon color={'primary'} />;
    case InstalmentRates.Status.COMPLETED:
      return <CheckCircleIcon color={'primary'} />;
    case InstalmentRates.Status.ERROR:
      return <ErrorIcon color={'error'} />;
    default:
      return <></>;
  }
};

const InstalmentRateIcon = ({ instalmentRate }: InstalmentRateIconProps) => {
  const { t } = useTranslation('instalmentPlans');
  return <Tooltip title={<>{t(instalmentRate.status)}</>}>{iconByStatus(instalmentRate)}</Tooltip>;
};

export default InstalmentRateIcon;
