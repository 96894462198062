import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, makeStyles, TextField, Typography } from '@material-ui/core';

import { Alert, Autocomplete } from '@material-ui/lab';
import { FormikInputField, FormikSelectField, FormikSizedInputField } from '@payactive/app-common';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import BankAccountsSuggest from '../../../components/BankAccountSuggest';
import DebtorsAutoSuggest from '../../../components/DebtorsAutoSuggest';
import { createSchemas } from './schemas';
import { Debtor } from '../../../types/Debtor/Debtor';
import AgentService from '../../../services/AgentService';
import { AgentApp } from '../../../types/Agent/AgentApp';
import PaymentInvoiceDropzone from './PaymentInvoiceDropzone';
import PaymentService from '../../../services/PaymentService';
import { NewPayment, Payments } from '../../../types/Payment/Payment';
import { CompanyAccountContext } from '../../../components/contexts/WithCompanyAccountContext';
import CheckboxField from '../../../components/FormFields/CheckboxField';
import PaymentType = Payments.PaymentType;

const useStyles = makeStyles((theme) => ({
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  select: {
    textAlign: 'left',
  },
  input: {
    padding: theme.spacing(1),
  },
  checkbox: {
    color: '#086992',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
  iconMargin: {
    '& span.material-icons': { marginBottom: '20px' },
  },
}));
const getTomorrowsDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toISOString();
};

interface NewPaymentForm extends NewPayment {
  debitorId: string;
  amount: number;
  purpose: string;
  externalReference: string;
  file?: File;
  agentApp?: AgentApp;
  paymentType: Payments.PaymentType;
  paymentMethod?: Payments.PaymentMethod;
  isDonation?: boolean;
  directDebitActive: boolean;
  chargeDate: string;
  attachmentBase64?: string;
  attachmentName?: string;
  paymentMethods: { value: string; label: string }[];
  paymentNotifications: Payments.Notifications;
  disableNotifications: boolean;
}

const initialValues: NewPaymentForm = {
  debitorId: '',
  amount: 0,
  purpose: '',
  externalReference: '',
  file: undefined,
  agentApp: undefined,
  paymentType: Payments.PaymentType.PAYMENT_REQUEST,
  paymentMethod: undefined,
  isDonation: false,
  directDebitActive: false,
  chargeDate: getTomorrowsDate(),
  attachmentBase64: undefined,
  attachmentName: undefined,
  paymentMethods: [],
  paymentNotifications: Payments.Notifications.EMAIL,
  disableNotifications: false,
};

//TODO paymentMethods (initValues and TypeDef) is set via DebtorAutoSuggest (setFieldValue)
// possibly its better to add a callback to autosuggest and set the field directly here (reduce hidden dependency)
// Tasks: remove setFieldValue as parameter of autosuggest and add aa callback to setDebtor in the corresponding component
// and than take care that everything is happened as expected (see payment, instalment plan, ... creation too)

const NewPaymentDialog = ({
  onSuccess,
  isOpen,
  onCancel,
  debitor,
}: {
  onSuccess: () => void;
  isOpen: boolean;
  onCancel: () => void;
  debitor?: Debtor;
}) => {
  const { companyAccount } = useContext(CompanyAccountContext);
  const [activatedAgentApps, setActivatedAgentApps] = useState<AgentApp[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isConnectedBank, setIsConnectedBank] = useState(true);
  const [preselectedAgent, setPreselectedAgent] = useState<AgentApp>();

  const classes = useStyles();
  const formRef = useRef(null);
  const { t: tValidations } = useTranslation('validations');
  const { t } = useTranslation('payments');
  const schema = createSchemas(tValidations);
  const alerting = useContext(AlertContext);

  const createTrackedTransactions = (paperlessId: string, data: any, setStatus: (status?: any) => void) => {
    AgentService.createTrackedTransactions(data, paperlessId)
      .then(() => {
        onSuccess();
        alerting.sendAlert('success', 'Online payment created');
      })
      .catch((err) => {
        setStatus(err);
        //TODO handle catch
      });
  };

  const _handleSubmit = (values: NewPaymentForm, { setSubmitting, setStatus }: FormikHelpers<NewPaymentForm>) => {
    setSubmitting(true);
    PaymentService.createPayment(values)
      .then((payment) => {
        onSuccess();
        alerting.sendAlert('success', t('transaction created'));

        const agentApp = values.agentApp || preselectedAgent;
        if (agentApp) {
          createTrackedTransactions(
            payment.id,
            {
              agentAllowList: [agentApp.applicationId],
            },
            setStatus,
          );
        }
      })
      .catch((err) => {
        setStatus(err);
        onCancel();
        alerting.sendAlert('error', t('error create transaction'));
      });
  };

  const filterApps = (connectedApps: AgentApp[]) => {
    const localActivatedApps = connectedApps.filter((app) => app.active);
    setActivatedAgentApps(localActivatedApps);

    const params = new URLSearchParams(document.location.search.substring(1));
    const preSelectedAgentId = params.get('agent');
    if (preSelectedAgentId) {
      const agent = localActivatedApps.filter((apps) => apps.applicationId === preSelectedAgentId);
      agent.length !== 0 && setPreselectedAgent(agent[0]);
    }
  };

  useEffect(() => {
    loadConnectedApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadConnectedApps = () => {
    AgentService.getConnectedApps(0, 0, 'desc').then((connectedApps) => {
      filterApps(connectedApps.results);
    });
  };

  return (
    <Formik innerRef={formRef} initialValues={initialValues} validationSchema={schema} onSubmit={_handleSubmit}>
      {({ setFieldValue, errors, touched, handleBlur, values, status, isSubmitting }) => (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={isOpen}
          onClose={(event, reason) => {
            if (reason === 'backdropClick') {
              return false;
            }
            onCancel();
          }}
          aria-labelledby="alert-dialog-title"
        >
          <Form>
            <DialogTitle id="alert-dialog-title">{t('new payment')}</DialogTitle>
            {preselectedAgent && (
              <Alert severity="info">
                {t('info alerting 1')} <strong>{preselectedAgent.applicationName}</strong> {t('info alerting 2')}
              </Alert>
            )}
            <DialogContent dividers>
              <Typography variant="h6">{t('creditor account')}</Typography>

              <BankAccountsSuggest className={classes.input} setFieldValue={setFieldValue} setIsConnectedBank={setIsConnectedBank} />
              {status && <Alert severity="error">{status}</Alert>}

              <Typography variant="h6">{t('customer')}</Typography>
              <DebtorsAutoSuggest
                className={`${classes.input} ${!isConnectedBank && classes.disabled}`}
                setLocalFieldValue={setFieldValue}
                setIsDisabled={setIsDisabled}
                debtor={debitor}
                directDebitActive={values.directDebitActive}
                disabled={isDisabled}
              />

              {status && <Alert severity="error">{status}</Alert>}
              <br />

              <Typography className={`${isDisabled && classes.disabled}`} variant="h6">
                {t('payment details')}
              </Typography>

              <FormikInputField
                className={`${classes.input} ${(isDisabled || !isConnectedBank) && classes.disabled}`}
                icon="euro_symbol"
                value={values.amount}
                type="number"
                name="amount"
                label={t('amount')}
                fullWidth
              />
              <FormikSizedInputField
                className={`${classes.input} ${(isDisabled || !isConnectedBank) && classes.disabled}`}
                icon="short_text"
                max={120}
                name="purpose"
                label={t('purpose')}
                fullWidth
                value={values.purpose}
              />
              <FormikSizedInputField
                className={`${classes.input} ${classes.iconMargin} ${(isDisabled || !isConnectedBank) && classes.disabled}`}
                icon="receipt"
                max={25}
                name="externalReference"
                label={t('external_reference')}
                helperText={t('external_reference_description')}
                fullWidth
                value={values.externalReference}
              />

              {values.debitorId !== '' && (
                <>
                  <div className={`${classes.input} ${(isDisabled || !isConnectedBank) && classes.disabled}`}>
                    <Typography variant="h6">{t('payment method')}</Typography>
                    <FormikSelectField
                      name="paymentMethod"
                      label={t('payment method')}
                      data={values.paymentMethods}
                      icon="credit_card"
                      fullWidth
                      onBlur={() => {
                        if (values.paymentMethod === 'DIRECT_DEBIT') {
                          setFieldValue('paymentNotifications', Payments.Notifications.EMAIL);
                        } else {
                          let newValue = values.disableNotifications ? Payments.Notifications.NONE : Payments.Notifications.EMAIL;
                          setFieldValue('paymentNotifications', newValue);
                        }
                      }}
                    />
                  </div>

                  <FormControl component="fieldset" style={{ width: '100%', marginLeft: '10px' }}>
                    {companyAccount?.accountSettings?.paymentNotificationSettingsEnabled && (
                      <CheckboxField
                        name="disableNotifications"
                        label={<Typography>{t('disable notifications checkbox')}</Typography>}
                        onChange={() => {
                          let newValue = values.disableNotifications ? Payments.Notifications.EMAIL : Payments.Notifications.NONE;
                          setFieldValue('paymentNotifications', newValue);
                        }}
                        disabled={values.paymentMethod === 'DIRECT_DEBIT'}
                      />
                    )}
                  </FormControl>
                </>
              )}

              <FormControl component="fieldset" style={{ width: '100%', marginLeft: '10px' }}>
                {companyAccount?.accountSettings?.isDonationReceiptsEnabled && (
                  <CheckboxField name="isDonation" label={<Typography>{t('is donation checkbox')}</Typography>} />
                )}
                <CheckboxField
                  name="hasAttachment"
                  label={<Typography>{t('has attachment checkbox')}</Typography>}
                  onChange={() => {
                    let newPaymentType = values.paymentType === PaymentType.INVOICE ? PaymentType.PAYMENT_REQUEST : PaymentType.INVOICE;
                    setFieldValue('paymentType', newPaymentType);
                  }}
                />
              </FormControl>

              {values.paymentType === 'INVOICE' && (
                <>
                  <Typography className={`${(isDisabled || !isConnectedBank) && classes.disabled}`} variant="h6">
                    {t('invoice')}
                  </Typography>
                  <Field
                    from="invoice"
                    name="file"
                    component={PaymentInvoiceDropzone}
                    setFieldValue={setFieldValue}
                    errorMessage={errors['file'] ? errors['file'] : undefined}
                    touched={touched['file']}
                    style={{ display: 'flex' }}
                    onBlur={handleBlur}
                    className={`${(isDisabled || !isConnectedBank) && classes.disabled}`}
                  />
                </>
              )}

              {companyAccount?.agentClient && (
                <div className={`${isDisabled && classes.disabled}`}>
                  <Typography variant="h6">{t('more options')}</Typography>
                  {preselectedAgent ? (
                    <TextField
                      fullWidth
                      id="standard-read-only-input"
                      label={t('read only')}
                      defaultValue={preselectedAgent.applicationName}
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="filled"
                    />
                  ) : (
                    <Autocomplete
                      id="agent-apps-autocomplete"
                      options={activatedAgentApps}
                      handleHomeEndKeys
                      selectOnFocus
                      clearOnBlur
                      onChange={(event, value) => setFieldValue('agentApp', value)}
                      getOptionLabel={(agent) => agent.applicationName}
                      renderOption={(agent) => agent.applicationName}
                      renderInput={(params) => <FormikInputField {...params} variant="standard" name="agentApp" label={t('checkbox option')} />}
                    />
                  )}
                </div>
              )}
            </DialogContent>
            <DialogActions className={`${(isDisabled || !isConnectedBank) && classes.disabled}`}>
              <Button onClick={onCancel} color="primary">
                {t('global:cancel')}
              </Button>
              <LoadingButton loading={isSubmitting} type="submit">
                {t('global:create')}
              </LoadingButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

export { NewPaymentDialog };
