import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import DebtorManuallyForm from './DebtorManuallyForm';
import { createSchemas } from './schemas';
import { FormikHelpers } from 'formik/dist/types';
import DebtorService from '../../../services/DebtorService';
import { Debtors, NewDebtor } from '../../../types/Debtor/Debtor';
import { PaymentMethod } from '../../../types/Invoice/PaymentMethod';

const initialValues: NewDebtor = {
  type: Debtors.Type.PERSON,
  paymentMethod: PaymentMethod.ONLINE_PAYMENT,
  companyName: undefined,
  invitationType: Debtors.InvitationType.MANUAL,
  emailAddress: '',
  firstName: '',
  lastName: '',
  address: {
    line: '',
    zipCode: '',
    city: '',
    country: '',
  },
};

export default function DebtorDialog({ isOpen, onCancel, onCreate }: { isOpen: boolean; onCancel: () => void; onCreate: (s: string) => void }) {
  const { t } = useTranslation('debitors');

  const { t: tValidations } = useTranslation('validations');

  const schema = createSchemas(tValidations)[0];

  const _handleSubmit = (values: NewDebtor, { setSubmitting, setStatus }: FormikHelpers<NewDebtor>) => {
    DebtorService.createDebtor(values)
      .then(() => {
        setSubmitting(false);
        onCreate('created');
      })
      .catch((err) => {
        setStatus(t(err.message));
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
    >
      <Formik initialValues={initialValues} validationSchema={schema} onSubmit={_handleSubmit}>
        {({ values, status, isSubmitting, setFieldValue }) => (
          <Form>
            <DialogTitle id="alert-dialog-title">{t('Create new debitor')}</DialogTitle>
            <DialogContent dividers>
              <DebtorManuallyForm status={status} values={values} setFieldValue={setFieldValue} mode="create"></DebtorManuallyForm>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} disabled={isSubmitting} color="primary">
                {t('Cancel')}
              </Button>
              <LoadingButton loading={isSubmitting} type="submit" color="primary" variant="contained">
                {t('Create')}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
