import { MenuItem } from '@material-ui/core';
import { DebtorActionType } from './DebtorActionHelper';
import { useHistory } from 'react-router-dom';

const DebtorShowPaymentsAction = ({ debtor, setMenuAnchor, label }: DebtorActionType) => {
  const history = useHistory();
  return (
    <MenuItem
      key={debtor.id + 'newPayment'}
      onClick={() => {
        setMenuAnchor(null);

        history.push(`/dashboard/debitors/${debtor.id}/${debtor.emailAddress}/payments`);
      }}
    >
      {label}
    </MenuItem>
  );
};

export default DebtorShowPaymentsAction;
