import { NewOnboard } from '../../../../types/Onboard/Onboard';
import { CompanyAccounts } from '../../../../types/Company/Company';

const formInitialValues: NewOnboard = {
  companyName: '',
  companyLegalForm: CompanyAccounts.LegalForm.GmbH,
  companyRegistrationNumber: '',
  firstName: '',
  lastName: '',
  emailAddress: '',
  password: '',
  passwordConfirmation: '',
  acceptTermsAndConditions: false,
  invitationId: '',
  invitationType: '',
};
export default formInitialValues;
