import { Grid, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import { Alert, Button, FormikInputField } from '@payactive/app-common';
import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import PayactiveHeaderLogo from '../../components/ui/blocks/PayactiveHeaderLogo';
import CentricLayout from '../../components/ui/layout/CentricLayout';
import PasswordService from '../../services/PasswordService';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    borderRadius: '5px',
  },
  formField: {
    padding: theme.spacing(1),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

type PasswordResetType = {
  emailAddress: string;
};

export default function PasswordResetRequestPage() {
  const classes = useStyles();
  const { t } = useTranslation('global');
  const theme = useTheme();

  const schema = Yup.object().shape({
    emailAddress: Yup.string().email().required(t('Email address is required')),
  });

  const onSubmit = (values: PasswordResetType, { setSubmitting, setStatus }: FormikHelpers<PasswordResetType>) => {
    PasswordService.requestNewPassword(values.emailAddress).then((res) => {
      setSubmitting(false);
      if (res) {
        setStatus({
          severity: 'success',
          message: t('Password request successful'),
        });
      } else {
        setStatus({
          severity: 'error',
          message: t('Password request unsuccessful'),
        });
      }
    });
  };

  return (
    <CentricLayout>
      <Grid item container alignContent={'center'} direction={'column'}>
        <Grid item xs={12}>
          <PayactiveHeaderLogo color={theme.palette.secondary.main} />
        </Grid>
        <Grid item xs={12}>
          <Typography>{t('Request a new password')}</Typography>
        </Grid>
      </Grid>
      <Paper className={classes.paper} elevation={0}>
        <Formik
          initialValues={{
            emailAddress: '',
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({ values, status, isSubmitting, touched }) => (
            <Form>
              {status && <Alert severity={status.severity}>{status.message}</Alert>}
              <FormikInputField
                disabled={isSubmitting}
                value={values.emailAddress}
                fullWidth
                className={classes.formField}
                icon="account_circle"
                name="emailAddress"
                label={t('email')}
              />
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={false}
                className={classes.formField}
                label={t('Reset password')}
                name="submit"
                fullWidth
              />
            </Form>
          )}
        </Formik>
      </Paper>
      <Grid container alignContent={'center'} direction={'column'}>
        <Link to="/login">{t('back to login')}</Link>
      </Grid>
    </CentricLayout>
  );
}
