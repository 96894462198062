import { useHistory } from 'react-router-dom';
import { Grid, Icon, IconButton } from '@material-ui/core';
import React from 'react';

export function BackButton(props: { onClick?: () => void }) {
  const history = useHistory();
  const backFn = props.onClick || history.goBack;
  return (
    <Grid item>
      <IconButton color="secondary" aria-label="back" size="small" style={{ marginTop: '  7%' }}>
        <Icon onClick={backFn}>arrow_back</Icon>
      </IconButton>
    </Grid>
  );
}
