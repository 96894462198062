import { Dialog, DialogActions, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import { useTranslation } from 'react-i18next';
import LogoProducts from './Logo';

const useStyles = makeStyles((theme) => ({
  title: {
    alignSelf: 'center',
  },
  text: {
    padding: '0 15px',
    alignSelf: 'center',
  },
}));

export default function DeletedDialog({ onClose, open, onDeactivate, service }) {
  const { t } = useTranslation('services');
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
    >
      <DialogTitle className={classes.title} id="alert-dialog-title">
        <LogoProducts serviceName={service?.name} />
      </DialogTitle>
      <DialogContentText className={classes.text} id="alert-dialog-description">
        {t('deactivation')}
      </DialogContentText>
      <DialogActions>
        <Button variant="text" name="cancel" label={t('global:no')} disabled={false} loading={false} onClick={onClose} />
        <Button
          name="cancel"
          label={t('global:yes')}
          disabled={false}
          loading={false}
          onClick={() => {
            onDeactivate();
            onClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
