import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { InputField } from '@payactive/app-common';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import ApiKeyCreatedScreen from './ApiKeyCreatedScreen';
import ApiKeyService from '../../../services/ApiKeyService';

const STATES = {
  CREATE: 'CREATE',
  SUCCESS: 'SUCCESS',
};
//TODO to TypeScript
export default function CreateApiKeyDialog({ visible, onClose }) {
  const { t } = useTranslation('developer');

  const alerting = useContext(AlertContext);

  const [isVisible, setVisibility] = useState(false);
  const [apiKeyName, setApiKeyName] = useState('');
  const [state, setState] = useState(STATES.CREATE);
  const [apiKey, setApiKey] = useState(null);

  const handleClose = () => {
    onClose();
    if (state === STATES.SUCCESS) {
      alerting.sendAlert('success', t('api key created'));
    }
  };

  useEffect(() => {
    setVisibility(visible);
  }, [visible]);

  const onCreate = () => {
    ApiKeyService.createApiKey(apiKeyName).then((res) => {
      if (res) {
        setApiKey(res.apiKey);
        setState(STATES.SUCCESS);
      }
    });
  };

  return (
    <Dialog
      open={isVisible}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        handleClose();
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t('create api key')}</DialogTitle>
      <DialogContent>
        {state === STATES.CREATE && (
          <Fragment>
            <DialogContentText>{t('choose a name for the new api key')}</DialogContentText>
            <InputField id="name" label={t('apikeyname')} type="text" value={apiKeyName} onChange={(e) => setApiKeyName(e.target.value)} fullWidth />
          </Fragment>
        )}
        {state === STATES.SUCCESS && <ApiKeyCreatedScreen apiKey={apiKey} />}
      </DialogContent>
      <DialogActions>
        {state === STATES.CREATE && (
          <Fragment>
            <Button onClick={handleClose} color="primary">
              {t('global:cancel')}
            </Button>
            <Button disabled={apiKeyName === ''} variant="contained" onClick={onCreate} color="primary">
              {t('global:create')}
            </Button>
          </Fragment>
        )}
        {state === STATES.SUCCESS && (
          <Button variant="contained" onClick={handleClose} color="primary">
            {t('global:close')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
