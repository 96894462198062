import ApiClient from './ApiClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { NewServiceActivation, ServiceActivation } from '../types/Feature/ServiceActivation';

export default class FeatureService extends ApiClient {
  private static get featureClient(): AxiosInstance {
    return this.client;
  }

  public static async getServices(): Promise<ServiceActivation[]> {
    let res = await this.featureClient.request<ServiceActivation[]>({
      method: 'GET',
      url: this.apiDomain + '/services',
    });

    return res.data;
  }

  public static async activateService(service: NewServiceActivation): Promise<ServiceActivation> {
    let res = await this.featureClient.request<NewServiceActivation, AxiosResponse<ServiceActivation>>({
      method: 'POST',
      url: this.apiDomain + '/services',

      data: service,
    });
    return res.data;
  }

  public static async deactivateServices(id: string): Promise<ServiceActivation> {
    let res = await this.featureClient.request<ServiceActivation>({
      method: 'PATCH',
      url: this.apiDomain + '/services/' + id + '/deactivate',
    });
    return res.data;
  }
}
