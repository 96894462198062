import { Grid, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { invertColor } from './helper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
  },
  paper: {
    padding: theme.spacing(1),
  },
  label: {
    position: 'absolute',
    backgroundColor: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    top: '-0.7em',
    left: '2em',
  },
}));

interface ColorPickerFieldProps {
  name: string;
  label?: string;
}

function ColorPickerField({ name, label }: ColorPickerFieldProps) {
  const { values, setFieldValue } = useFormikContext<any>();

  const classes = useStyles();

  const [showColorPicker, setShowColorPicker] = useState<Boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contrastColor, setContrastColor] = useState(invertColor(values[name]));

  const toggleColorPicker = (event: any) => {
    setAnchorEl(event.currentTarget);
    setShowColorPicker(!showColorPicker);
  };

  const updateColor = (color: any, event: any) => {
    setFieldValue(name, color.hex);
    setContrastColor(invertColor(color.hex));
  };

  return (
    <Box style={{ position: 'relative' }}>
      <Paper className={classes.paper} variant="outlined">
        <Typography component="span" variant="caption" className={classes.label}>
          {label}
        </Typography>

        <Grid container justifyContent="center" alignItems="center">
          <Grid md={10} item>
            <Typography style={{ color: contrastColor, backgroundColor: values[name] }}>{values[name]}</Typography>
          </Grid>
          <Grid md={2} item>
            <IconButton onClick={toggleColorPicker}>
              <ColorizeIcon fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
      {showColorPicker && (
        <Popover
          open={true}
          anchorEl={anchorEl}
          onClose={toggleColorPicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <ChromePicker color={values[name]} onChange={updateColor} />
        </Popover>
      )}
    </Box>
  );
}

export default ColorPickerField;
