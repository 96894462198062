import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: any) => ({
  grid: {
    textAlign: 'left',
  },
  paper: {
    padding: theme.spacing(4),
    //maxWidth: '600px',
  },
  paperDetailPage: {
    padding: theme.spacing(2),
    //maxWidth: '170px',
  },
}));

interface Props {
  from?: string;
  label: string;
  totalAmount?: number;
  numTransactions?: number;
}

export default function PaymentStats({ from, label, totalAmount, numTransactions }: Props): ReactElement {
  const { t } = useTranslation('payments');

  const classes = useStyles();

  return (
    <Paper className={`${from === 'debitorDetailPage' ? classes.paperDetailPage : classes.paper}`} elevation={0}>
      <Grid className={classes.grid} container justifyContent="flex-start" direction="row" alignContent="flex-start">
        <Grid item xs={12}>
          <Typography color="secondary" variant="caption">
            {label}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography color="secondary" variant="h6">
            {totalAmount !== undefined ? formatCurrency(totalAmount) : <Skeleton />}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography color="secondary" variant="subtitle1">
            {numTransactions} {t('transactions')}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
