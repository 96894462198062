import { AxiosInstance, AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import { NewProduct, Product } from '../types/Product/Product';
import { Page, PageMetadata } from '../types/Page';
import { Filter, prepareFilterParameters } from '../types/Filter';

export default class ProductService extends ApiClient {
  private static get productClient(): AxiosInstance {
    return this.client;
  }

  public static async getProducts(size = 20, page = 0, sort = 'asc', filters?: Filter): Promise<{ results: Product[]; page: PageMetadata }> {
    let params: { [k: string]: any } = {
      size: size,
      page: page,
      sort: 'createdDateTime,' + sort,
    };

    const response = await this.productClient.get<Page<Product, 'products'>>(this.apiDomain + '/products', {
      params: {
        ...params,
        ...prepareFilterParameters(filters || {}),
      },
      signal: this.abortController.signal,
    });
    return { results: response.data._embedded?.products || [], page: response.data.page };
  }

  public static async createProduct(payload: NewProduct, file?: File) {
    const response = await this.productClient.post<NewProduct, AxiosResponse<Product>>(this.apiDomain + '/products', payload);
    await this.updateProductImage(response.data.id, file);
    return response.data;
  }

  private static async updateProductImage(productId: string, file?: File) {
    if (file) {
      let form = new FormData();
      form.append('file', file, file.name);
      form.append('title', file.name);

      await this.productClient.put<any>(this.apiDomain + '/products/' + productId + '/image', form);
    }
  }

  public static async editProduct(productId: string, payload: NewProduct, file?: File) {
    const response = await this.productClient.put<NewProduct, AxiosResponse<Product>>(this.apiDomain + '/products/' + productId, payload);
    await this.updateProductImage(response.data.id, file);
    return response.data;
  }

  public static async deleteProduct(productId: string, cascadeDelete = false) {
    let params = {
      cascadeDelete: cascadeDelete,
    };

    const response = await this.productClient.request({
      method: 'DELETE',
      url: this.apiDomain + '/products/' + productId,
      params: params,
    });
    return response.status === 200;
  }

  public static async getProductCheckDelete(productId: string): Promise<number> {
    let res = await this.productClient.request({
      method: 'GET',
      url: this.apiDomain + '/products/' + productId + '/check-delete-eligibility',
    });

    return res.status;
  }

  public static async getProduct(productId: string) {
    let res = await this.productClient.request({
      method: 'GET',
      url: `${this.apiDomain}/products/${productId}`,
    });
    return res.data;
  }
}
