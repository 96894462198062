import { IconButton, makeStyles, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ProductImage } from '../../../types/Product/Product';
import { FormikErrors } from 'formik/dist/types';
import { InvoiceFormValues } from '../../../types/Invoice/Invoice';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
  },
  dropzone: {
    textAlign: 'center',
    border: '2px dashed #AAAAAA',
    backgroundColor: '#fafafa',
    marginBottom: '20px',
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
  },

  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    marginBottom: 8,
    marginRight: 8,
    width: '100%',
    padding: 4,
    boxSizing: 'border-box',
    alignItems: 'center',
  },

  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },

  img: {
    display: 'block',
    width: 'auto',
    maxWidth: '200px',
    maxHeight: '150px',
    height: '100%',
  },
  showContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

interface ProductImageDropzoneProps {
  setFieldValue: (name: string | undefined, file: string) => Promise<void | FormikErrors<InvoiceFormValues>>;
  errorMessage: string;
  touched: boolean;
  image?: ProductImage;
  field: {
    name?: string;
  };
}

type ExtendedFile = File & {
  preview: string;
  name: string;
};

export default function ProductImageDropzone({ setFieldValue, errorMessage, touched, field, image }: ProductImageDropzoneProps): ReactElement {
  const { t } = useTranslation('payments');

  const classes = useStyles();

  const { name } = field;

  const [file, setFile] = useState<ExtendedFile>();
  function onDrop<ExtendedFile>(acceptedFiles: ExtendedFile[]) {
    if (acceptedFiles.length === 1) {
      updateFile(acceptedFiles[0]);
    }
  }

  const onDeleteFile = () => {
    updateFile(undefined);
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      return file && URL.revokeObjectURL(file?.preview);
    },
    [file],
  );

  const updateFile = (file: any) => {
    file === null
      ? setFile(file)
      : setFile(
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        );
    setFieldValue(name, file);
    setFieldValue('image', file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/jpg, image/png',
  });

  const thumbs = !!file ? (
    <div className={classes.thumb} key={file.name}>
      <div className={classes.thumbInner}>
        <img src={file.preview} className={classes.img} alt={'preview'} />
      </div>
    </div>
  ) : (
    <div className={classes.thumb} key={image?.id}>
      <div className={classes.thumbInner}>
        <img src={image?.blobURL} className={classes.img} alt={`${image?.name}`} />
      </div>
    </div>
  );

  return (
    <div className={classes.dropzone} {...getRootProps()}>
      {!file && !image && (
        <>
          <input name={name} id={name} {...getInputProps()} />
          <span>
            <CloudUploadIcon fontSize="large" />
          </span>
          <>
            <p>{t('drop product')}</p>
            <p style={{ color: '#867f7fde' }}>{t('drop product subtitle')}</p>
          </>
        </>
      )}
      {(file || image) && (
        <div className={classes.showContent} {...getRootProps()}>
          <aside className={classes.thumbsContainer}>{thumbs}</aside>
          <div>
            {file ? file.name : image?.name}
            <IconButton onClick={onDeleteFile} aria-label="delete">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      )}
      {errorMessage && touched && (
        <Typography className={classes.error} variant="caption">
          {errorMessage}
        </Typography>
      )}
    </div>
  );
}
