import { Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import StateWithIcon from '../../../components/StateWithIcon';
import { formatCurrency } from '../../../utils/PaymentUtil';
import InvoiceDownloadAction from '../../invoices/actions/InvoiceDownloadAction';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useContext, useState } from 'react';
import { Invoice } from '../../../types/Invoice/Invoice';
import { useTranslation } from 'react-i18next';
import { getActionsForInvoice, hasDownloadAction } from '../../invoices/actions/InvoiceActionHelper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useCardStyles from './CardStyles';
import { DebtorContext } from '../../../components/contexts/WithDebtor';
import { Metadata } from '../../../types/Metadata';

const InvoiceCard = ({ invoice, setRef, active = false }: { invoice: Invoice; setRef: (key: string) => React.RefObject<any>; active: boolean }) => {
  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);

  const { t } = useTranslation(['debitors', 'invoices', 'global']);
  const classes = useCardStyles();
  const debtor = useContext(DebtorContext).debtor;
  const actions = getActionsForInvoice(invoice, () => setMenuAnchor(undefined), t, debtor, true);
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const metadata: Metadata = invoice.metadata || [];
  return (
    <Card elevation={active ? 3 : 1} ref={setRef(invoice.id)}>
      <CardHeader
        className={classes.alignLeft}
        avatar={
          <Avatar className={classes.avatar}>
            <DescriptionIcon />
          </Avatar>
        }
        action={
          actions.length !== 0 && (
            <IconButton aria-label="settings" onClick={(e) => setMenuAnchor(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={t(`${invoice.invoiceType}`)}
        subheader={formatDateTime(invoice.createdDateTimeUTC)}
      />
      <CardContent>
        <Grid container spacing={2} direction={'column'} alignItems={'flex-start'}>
          <Grid item container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Grid item container direction={'column'} alignItems={'flex-start'}>
                <Typography variant="caption" style={{ color: 'gray' }}>
                  {t('amount')}
                </Typography>
                <Typography color="inherit" variant={'subtitle2'}>
                  {formatCurrency(invoice.total)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <StateWithIcon state={invoice.status} label={t(`invoices:${invoice.status}`)} />
            </Grid>
          </Grid>
          <Grid item container direction={'column'} alignItems={'flex-start'}>
            <Typography variant="caption" style={{ color: 'gray' }}>
              {t('documentNumber')}
            </Typography>
            <Typography color="inherit" variant={'subtitle2'}>
              {invoice.invoiceNumber}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions disableSpacing>
        {hasDownloadAction(invoice) && (
          <InvoiceDownloadAction invoice={invoice} closeMenu={() => {}} label={<GetAppIcon titleAccess={t('download pdf')} color={'primary'} />} />
        )}
        {metadata.length !== 0 && (
          <MenuItem className={classes.expand} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon
              className={clsx(classes.icon, {
                [classes.iconOpen]: expanded,
              })}
            />
          </MenuItem>
        )}
      </CardActions>
      <Collapse in={metadata.length !== 0 && expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2} direction={'row'} alignItems={'flex-start'} className={classes.alignLeft}>
            <Grid item md={12}>
              <Typography color="inherit" variant={'subtitle2'}>
                {t('global:metadata')}
              </Typography>
            </Grid>
            {metadata.map((m) => {
              return (
                <Grid item md={6}>
                  <Typography variant="caption" style={{ color: 'gray' }}>
                    {m.key}
                  </Typography>
                  <Typography color="inherit" variant={'subtitle2'}>
                    {m.value}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Collapse>
      <Menu
        id="long-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuAnchor(undefined)}
        PaperProps={{
          style: {
            //maxHeight: ITEM_HEIGHT * 4.5,
            width: 'min-content',
          },
        }}
      >
        {actions}
      </Menu>
    </Card>
  );
};

export default InvoiceCard;
