export interface PEvent {
  paymentId?: string;
  eventDateTime: string;
  eventType: string;
  triggeredBy?: string;
  reason?: string;
  reasonMessage?: string;
  correlationId: string;
}

export interface CountedEvent extends PEvent {
  count: number;
}

const groupByEventType = function (events: PEvent[]) {
  const EVENT_TYPES_NOT_TO_BE_GROUPED = ['PAYMENT_MODIFIED', 'IN_DUNNING'];

  return (events as CountedEvent[]).reduce<CountedEvent[]>(function (acc, e) {
    const found = acc.find((a) => a.eventType === e.eventType);
    if (found) {
      if (!(acc.length === acc.indexOf(found) + 1)) {
        e.count = 1;
        acc.push(e);
      } else {
        found.count = found.count + 1;
        if (EVENT_TYPES_NOT_TO_BE_GROUPED.indexOf(found.eventType) >= 0) acc.push(e);
      }
    } else {
      e.count = 1;
      acc.push(e);
    }
    return acc;
  }, []);
};

export { groupByEventType };
