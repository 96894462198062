import { createContext, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Debtor } from '../../types/Debtor/Debtor';
import { searchDelay } from '../../services/ApiClient';
import PaginationProvider from '../../components/table/PaginationContext';
import DebtorService from '../../services/DebtorService';
import { PageMetadata } from '../../types/Page';

export const DebtorListDataContext = createContext<{
  debtors: Debtor[];
  filters?: { searchFilter: string; setSearchFilter: Dispatch<SetStateAction<string>>; used: boolean };
  page?: PageMetadata;
  reload: () => void;
  loading: boolean;
  currentPage: { set: (page: number) => void; get: number };
  pageSize: { set: (size: number) => void; get: number };
}>({
  debtors: [] as Debtor[],
  reload: () => {},
  loading: true,
  currentPage: {
    set: (page) => {},
    get: 0,
  },
  pageSize: {
    set: (size) => {},
    get: 0,
  },
});

type DebtorListDataContextProviderProps = {
  pageSizeOptions: number[];
  children: any;
};

const DebtorListDataContextProvider = ({ pageSizeOptions, children }: DebtorListDataContextProviderProps) => {
  const [debtorList, setDebtorList] = useState<Debtor[]>([] as Debtor[]);
  const [page, setPage] = useState<PageMetadata>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [filtersUsed, setFiltersUsed] = useState(false);

  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      reload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    let delaySearch: any;
    if (isInitialized.current) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        delaySearch = setTimeout(() => {
          reload();
        }, searchDelay);
      }
    }
    return () => {
      delaySearch && clearTimeout(delaySearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, pageSize]);

  useEffect(() => {
    reload();
    isInitialized.current = true;
    return () => {
      isInitialized.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reload = () => {
    setLoading(true);

    setFiltersUsed(!!searchFilter);
    DebtorService.getDebtors(pageSize, currentPage, ['lastName,asc', 'firstName,asc'], searchFilter, true).then(({ results, page }) => {
      setDebtorList(results);
      setPage(page);
      setLoading(false);
    });
  };

  return (
    <DebtorListDataContext.Provider
      value={{
        debtors: debtorList,
        filters: {
          searchFilter: searchFilter,
          setSearchFilter: setSearchFilter,
          used: filtersUsed,
        },
        page: page,
        reload: reload,
        loading: loading,
        currentPage: { set: setCurrentPage, get: currentPage },
        pageSize: { set: setPageSize, get: pageSize },
      }}
    >
      <PaginationProvider
        pageSizeOptions={pageSizeOptions}
        currentPage={currentPage || 0}
        totalPages={page?.totalPages || 0}
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        changePageSize={setPageSize}
      >
        {children}
      </PaginationProvider>
    </DebtorListDataContext.Provider>
  );
};

export default DebtorListDataContextProvider;
