import { Button, IconButton, InputAdornment, makeStyles, Tooltip } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  urlContainer: {
    height: '42px',
    background: '#eef0f1',
    border: '1px solid #DDDDDD',
    borderRadius: '3px',
    opacity: '1',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 'auto',
    },
    lineBreak: 'anywhere',
  },
}));

const isValidUrl = (portentialUrl: string) => {
  let url;
  try {
    url = new URL(portentialUrl);
  } catch (error) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const LinkBox = ({
  url,
  enableCopy,
  enablePreview,
  className,
}: {
  url: string;
  enableCopy?: boolean;
  enablePreview?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation('global');
  const classes = useStyles();
  className = [classes.urlContainer, className].join(' ');

  return (
    <div className={className}>
      {url}
      <InputAdornment position="end">
        {isValidUrl(url) && enablePreview && (
          <Button onClick={() => window.open(url, '_blank')} variant="text">
            {t('linkbox.preview')}
          </Button>
        )}
        {enableCopy && (
          <Tooltip title="Copy" placement="top">
            <CopyToClipboard text={url}>
              <IconButton aria-label="copy">
                <FileCopyIcon fontSize="small" />
              </IconButton>
            </CopyToClipboard>
          </Tooltip>
        )}
      </InputAdornment>
    </div>
  );
};

export default LinkBox;
