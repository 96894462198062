import { Invoice, InvoiceFormValues, InvoicePosition, InvoicePositionForm, InvoiceRequest } from '../../../types/Invoice/Invoice';
import { ServicePeriods } from '../../../types/Invoice/ServicePeriods';
import InvoiceService from '../../../services/InvoiceService';
import { TaxRate } from '../../../types/Invoice/TaxRate';
import { taxOptions } from './InvoiceCreationSchema';
import { ProductInvoicePosition, TextInvoicePosition } from '../../../types/Invoice/InvoicePosition';
import { PaymentMethod } from '../../../types/Invoice/PaymentMethod';

const determineSelectValue = (values: any) => {
  if (values.servicePeriodStart !== null && values.servicePeriodEnd !== null) {
    return ServicePeriods.SERVICE_PERIOD;
  } else if (values.servicePeriodStart !== null) {
    return ServicePeriods.SERVICE_DATE;
  } else {
    return ServicePeriods.NONE;
  }
};

function getInvoiceCreatingPositions(positions: Array<ProductInvoicePosition | TextInvoicePosition>): InvoicePositionForm[] {
  return positions.map((pos) => {
    const newPos: InvoicePositionForm = {
      ...{ description: '', descriptionSubText: '', quantity: 0, price: 0, taxRate: -1 },
      ...pos,
      ...{
        taxRate: pos.taxRate?.rate || -1,
      },
    };
    return newPos;
  });
}

const formValuesByInvoice = (invoice: Invoice | undefined): InvoiceFormValues => {
  if (invoice === undefined) {
    return {
      positions: [createNewInvoicePosition()],
      defaultTaxRate: -1,
      directDebitActivated: false,
      directDebitActive: false,
      servicePeriodStart: undefined,
      servicePeriodEnd: undefined,
      servicePeriodVariant: ServicePeriods.NONE,
      reverseCharge: false,
      vatId: undefined,
    };
  } else {
    return {
      creditorBankAccount: invoice.creditorBankAccount,
      positions: getInvoiceCreatingPositions(invoice.positions),
      debitor: invoice.debitor,
      paymentTermInDays: invoice.paymentTermInDays || 30,
      servicePeriodStart: invoice.servicePeriodStart,
      servicePeriodEnd: invoice.servicePeriodEnd,
      directDebitActivated: invoice.paymentMethod === PaymentMethod.DIRECT_DEBIT,
      directDebitActive: invoice.paymentMethod === PaymentMethod.DIRECT_DEBIT,
      defaultTaxRate: invoice.defaultTaxRate?.rate || -1,
      servicePeriodVariant: determineSelectValue(invoice),
      reverseCharge: invoice.reverseCharge,
      vatId: invoice.debitor.vatId,
    };
  }
};

const createNewInvoicePosition = (): InvoicePositionForm => {
  return {
    description: '',
    descriptionSubText: '',
    quantity: 0,
    price: 0,
    taxRate: -1,
  };
};
function getTaxRate(taxRate: number): TaxRate | undefined {
  if (taxRate !== -1) {
    const invoiceTaxRate = taxOptions.find((option) => option.rate === taxRate);
    if (!!invoiceTaxRate) {
      return {
        description: invoiceTaxRate.description,
        rate: invoiceTaxRate.rate,
      };
    }
  } else {
    return undefined;
  }
}

const createNewInvoice = async (values: InvoiceFormValues): Promise<Invoice> => {
  const request = getInvoiceData(values);
  return await InvoiceService.createInvoice(request);
};

function getInvoiceData(values: InvoiceFormValues) {
  if (!values.creditorBankAccount) {
    throw new Error('creditorBankAccount is unknown');
  }
  const positions: InvoicePosition[] = values.positions.map((pos) => {
    return {
      ...pos,
      ...{
        taxRate: getTaxRate(pos.taxRate),
      },
    };
  });
  const request: InvoiceRequest = {
    ...values,
    ...{
      positions: positions,
      creditorBankAccountId: values.creditorBankAccount.id,
      defaultTaxRate: getTaxRate(values.defaultTaxRate),
    },
  };
  return request;
}

const updateInvoice = async (invoice: Invoice, values: InvoiceFormValues): Promise<Invoice> => {
  const request = getInvoiceData(values);
  return await InvoiceService.updateInvoice(invoice.id, request);
};

const finalizeInvoice = async (invoice: Invoice) => {
  return await InvoiceService.createFinalizeInvoice(invoice.id);
};
const finalizeInvoiceCancellation = async (invoice: Invoice) => {
  return await InvoiceService.finalizeInvoiceCancellation(invoice.id);
};
const finalizeInvoiceCorrection = async (invoice: Invoice) => {
  return await InvoiceService.finalizeInvoiceCorrection(invoice.id);
};

export {
  formValuesByInvoice,
  createNewInvoicePosition,
  createNewInvoice,
  updateInvoice,
  finalizeInvoice,
  finalizeInvoiceCancellation,
  finalizeInvoiceCorrection,
};
