import { Divider, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GridSetting from './GridSetting';
import { CompanySettings } from '../../../../types/Company/CompanySettings';

const useStyles = makeStyles((theme) => ({
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  dunningService: {
    textAlign: 'left',
    display: 'block',
    color: '#0198B5',
    marginBottom: theme.spacing(2),
    fontSize: '18px',
  },
  settingsComercial: {
    textAlign: 'left',
    display: 'block',
    color: '#0198B5',
    marginBottom: theme.spacing(2),
    fontSize: '16px',
  },
  inputField: {
    '&.MuiGrid-root': {
      width: '12%',
    },
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function DunningSettings() {
  const classes = useStyles();

  const { t } = useTranslation('dunnings');

  const { values, setFieldValue } = useFormikContext<CompanySettings.Dunning>();

  return (
    <div className={classes.formControl}>
      <Typography variant="h5" className={classes.dunningService}>
        {t('dunning service')}
        <Tooltip
          title={
            <React.Fragment>
              <Typography variant="caption" color="inherit">
                {t('info title')}
              </Typography>
            </React.Fragment>
          }
          placement="top"
        >
          <InfoIcon style={{ color: '#D3D3D3', marginBottom: '-6px', marginLeft: '10px' }} />
        </Tooltip>
      </Typography>
      <ListItem>
        <Switch
          checked={values.dunningEnabled}
          onClick={() => setFieldValue('dunningEnabled', !values.dunningEnabled)}
          edge="end"
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <ListItemText id="switch-list-label-wifi" primary={t('activate automatic dunning')} />
      </ListItem>
      {values.dunningEnabled && (
        <>
          <Typography variant="subtitle2" className={classes.settingsComercial}>
            {t('settings commercial dunning')}
          </Typography>
          <GridSetting
            label={t('send dunning')}
            toolTipText={t('send dunning tooltip')}
            inputFieldName="daysReminder"
            inputFieldLabel={t('min days x', { days: 1 })}
          />
          <Divider className={classes.divider} light />
          <GridSetting
            label={t('dunning level days')}
            toolTipText={t('dunning level days tooltip')}
            inputFieldName="daysDunning"
            inputFieldLabel={t('min days x', { days: 7 })}
          />
          <Divider className={classes.divider} light />
          <GridSetting
            label={t('maximum number of reminders')}
            toolTipText={t('maximum number of reminders tooltip')}
            inputFieldName="maxDunnings"
            inputFieldLabel={t('max number')}
          />
          <Divider className={classes.divider} light />
          <GridSetting
            label={t('additional reminder fees')}
            toolTipText={t('additional reminder fees tooltip')}
            inputFieldName="dunningFee"
            inputFieldLabel={t('euro')}
          />
        </>
      )}
    </div>
  );
}
