import { MenuItem } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import InvoiceDialog from '../dialogs/InvoiceDialog';
import { InvoiceListDataContext } from '../InvoiceListDataContextProvider';
import { InvoiceActionType } from './InvoiceActionHelper';
import ReloadContext from '../../../components/contexts/ReloadContext';

const InvoiceEditAction = ({ invoice, closeMenu, label }: InvoiceActionType) => {
  const [showEdit, setShowEdit] = useState(false);

  const invoiceReload = useContext(InvoiceListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;
  const reload = () => {
    invoiceReload();
    contextReload();
  };
  return (
    <div key={invoice.id + 'editC'}>
      <MenuItem
        key={invoice.id + 'edit'}
        onClick={() => {
          closeMenu();
          setShowEdit(true);
        }}
      >
        {label}
      </MenuItem>
      {showEdit && (
        <InvoiceDialog
          invoice={invoice}
          onClose={() => {
            setShowEdit(false);
            reload();
          }}
        />
      )}
    </div>
  );
};

export default InvoiceEditAction;
