import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField, useFormikContext } from 'formik';
import { at } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanySettings } from '../../../../types/Company/CompanySettings';

export default function BccEmailsAutoComplete() {
  const { values, setFieldValue } = useFormikContext<CompanySettings.Account>();

  const [fieldMetaProps] = useField('bccEmailAddressList');
  const [touched, error] = at(fieldMetaProps, 'touched', 'error');

  const { t } = useTranslation('company');

  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  return (
    <Autocomplete
      freeSolo
      multiple
      options={[]}
      id="bccEmailAddressList"
      onChange={(event, values) => setFieldValue('bccEmailAddressList', values)}
      value={values.bccEmailAddressList}
      renderTags={(value: string[], getTagProps: any) =>
        value.map((option: string, index: number) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
      }
      renderInput={(params) => (
        <TextField
          {...params}
          value={values.bccEmailAddressList}
          variant="standard"
          error={touched && error && true}
          helperText={_renderHelperText()}
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue="abc"
          label={t('label bccEmailAddressList')}
        />
      )}
    />
  );
}
