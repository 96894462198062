import React from 'react';
import StateWithIcon from '../../../components/StateWithIcon';
import { formatDate } from '../../../utils/DateTimeUtil';
import { Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { Button } from '@payactive/app-common';
import { BankAccount, BankAccounts } from '../../../types/Bank/BankAccount';
import { TFunction } from 'i18next';

type Props = {
  bankAccount: BankAccount;
  t: TFunction;
  onRenewal: () => void;
};

const BankAccountStatus = ({ bankAccount, t, onRenewal }: Props) => {
  const { WILL_EXPIRE, HAS_EXPIRED, ACTIVE } = BankAccounts.Status;
  const { expiryDate, status, directDebitActive } = bankAccount;

  if ([HAS_EXPIRED, WILL_EXPIRE].includes(status)) {
    return (
      <>
        <StateWithIcon state={status === HAS_EXPIRED && directDebitActive ? ACTIVE : status || ACTIVE} label={t(status || ACTIVE)} />
        <span>{formatDate(expiryDate)}</span>
        <Tooltip
          title={
            <>
              {' '}
              {status === HAS_EXPIRED && !directDebitActive ? (
                <Typography variant="caption" color="inherit">
                  {t('bank_connection_expired_info')}
                </Typography>
              ) : status === HAS_EXPIRED && directDebitActive ? (
                <Typography variant="caption" color="inherit">
                  {t('bank_connection_expired_with_direct_debit_active')}
                </Typography>
              ) : (
                <Typography variant="caption" color="inherit">
                  {t('bank_connection_will_expire_info')}
                </Typography>
              )}
            </>
          }
          placement="top"
        >
          <InfoIcon style={{ color: '#D3D3D3', marginBottom: '-6px', marginLeft: '10px' }} />
        </Tooltip>
        <Button variant="outlined" onClick={onRenewal} name={t('renew')} style={{ marginLeft: '15px' }} />
      </>
    );
  } else {
    return <StateWithIcon state={status || ACTIVE} label={t(status || ACTIVE)} />;
  }
};

export default BankAccountStatus;
