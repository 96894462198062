import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import Dashboard from './pages/dashboard/Dashboard';
import Logout from './pages/_public/login/Logout';
import RegistrationForm from './components/registration/RegistrationForm';
import SignUpForm from './components/registration/SignUpForm';
import UserAccount from './components/registration/UserAccount';
import WithAlertContext from './components/contexts/WithAlertContext';
import WithAddressContext from './components/contexts/WithAddressContext';
import './i18n';
import JoinFlow from './pages/_public/join/JoinFlow';
import PrivateRoute from './pages/PrivateRoute';
import NotFound404 from './NotFound404';
import { OnboardingFlow } from './pages/_public/onboarding/OnboardingFlow';
import LoginPage from './pages/_public/login/LoginPage';
import PasswordResetPage from './pages/_public/PasswordResetPage';
import PasswordResetRequestPage from './pages/_public/PasswordResetRequestPage';
import Version from './Version';
import CorrelationsView from './pages/debug/CorrelationsView';

let theme = createTheme({
  palette: {
    primary: {
      main: '#086992',
    },
    secondary: {
      main: '#0198B5',
    },
    background: {
      default: '#ECF2F6',
    },
    error: {
      main: '#B83B86',
    },
    warning: {
      main: '#DDAF3B',
    },
    success: {
      main: '#11E0A0',
    },
    contrastThreshold: 3,
  },
  typography: {
    fontFamily: 'Noto Sans KR, sans-serif',
    button: {
      textTransform: 'none',
      fontWeight: 'lighter',
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <WithAlertContext>
        <WithAddressContext>
          <CssBaseline />
          <Version version="1.2.3" />
          <Router basename={process.env.PUBLIC_URL}>
            <div className="App">
              <div />
              <div className="container d-flex align-items-center flex-column">
                <Switch>
                  <Route path="/" exact={true}>
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/login" exact={true}>
                    <LoginPage />
                  </Route>
                  <Route path="/register/passwordreset/request" exact={true}>
                    <PasswordResetRequestPage />
                  </Route>
                  <Route path="/register/passwordreset/reset" exact={true}>
                    <PasswordResetPage />
                  </Route>
                  <Route path="/user" exact={true}>
                    <UserAccount />
                  </Route>
                  <Route path="/signup" exact={true}>
                    <SignUpForm />
                  </Route>
                  <PrivateRoute path="/dashboard*" exact={true}>
                    <Dashboard />
                  </PrivateRoute>
                  <Route path="/onboarding/:invitationId" exact={true}>
                    <OnboardingFlow />
                  </Route>

                  <Route path="/register" exact={true}>
                    <RegistrationForm />
                  </Route>
                  <Route path="/logout" exact={true}>
                    <Logout />
                  </Route>
                  <Route path="/join/:invitationId" exact={true}>
                    <JoinFlow />
                  </Route>

                  <Route path="/debug/correlations/:id" exact={true}>
                    <CorrelationsView />
                  </Route>

                  <Route>
                    <NotFound404 />
                  </Route>
                </Switch>
              </div>
            </div>
          </Router>
        </WithAddressContext>
      </WithAlertContext>
    </ThemeProvider>
  );
}

export default App;
