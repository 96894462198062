import { InstalmentPlan } from '../types/InstalmentPlan/InstalmentPlan';
import { InstalmentRate, InstalmentRates } from '../types/InstalmentPlan/InstalmentRate';

const lastRateDifferentThanIntervalAmount = (instalmentRates: InstalmentRate[]): boolean => {
  if (instalmentRates.length <= 1) return false;
  return instalmentRates[instalmentRates.length - 1].amount !== instalmentRates[instalmentRates.length - 2].amount;
};

const calculateRateInterval = (instalmentRates: InstalmentRate[], downPayment: number) => {
  let numRates = instalmentRates.length;
  if (downPayment > 0) {
    numRates = numRates - 1;
  }
  if (lastRateDifferentThanIntervalAmount(instalmentRates)) {
    numRates = numRates - 1;
  }
  return numRates;
};

const calculateOpenAmount = (instalmentPlan: InstalmentPlan): number => {
  let scheduledRates = instalmentPlan.instalmentRates.filter((instalmentRate) => instalmentRate.status !== InstalmentRates.Status.COMPLETED);
  return scheduledRates.map((rate) => rate.amount).reduce((accum, curr) => accum + curr, 0);
};

const calculateCoveredAmount = (instalmentPlan: InstalmentPlan): number => {
  const pendingAmount = calculatePendingAmount(instalmentPlan);
  const completedAmount = calculateCompletedAmount(instalmentPlan);
  return pendingAmount + completedAmount;
};

const calculatePendingAmount = (instalmentPlan: InstalmentPlan): number => {
  return instalmentPlan.instalmentRates
    .filter((instalmentRate) => instalmentRate.status === InstalmentRates.Status.PENDING)
    .reduce((sum, instalmentRate) => sum + instalmentRate.amount, 0);
};

const calculateCompletedAmount = (instalmentPlan: InstalmentPlan): number => {
  return instalmentPlan.instalmentRates
    .filter((instalmentRate) => instalmentRate.status === InstalmentRates.Status.COMPLETED)
    .reduce((sum, instalmentRate) => sum + instalmentRate.amount, 0);
};

const convertToLocalISODate = (date: Date) => {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
};

export { lastRateDifferentThanIntervalAmount, calculateRateInterval, calculateOpenAmount, calculateCoveredAmount, convertToLocalISODate };
