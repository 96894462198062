import React, { ReactElement } from 'react';
import { DialogActions, Typography } from '@material-ui/core';
import { Alert, Button } from '@payactive/app-common';
import { BaseRow } from 'react-csv-importer';
import { useTranslation } from 'react-i18next';

interface Props {
  rows: BaseRow[];
  instalmentPlansToImport: number;
  onFinish: () => void;
}

export default function Summary({ rows, instalmentPlansToImport, onFinish }: Props): ReactElement {
  const { t } = useTranslation(['global', 'payments']);

  return (
    <div>
      {instalmentPlansToImport === rows.length && (
        <Alert severity="success">
          <Typography>{t('payments:import.success_instalment_plans', { num: rows.length })}</Typography>
        </Alert>
      )}
      {instalmentPlansToImport > rows.length && rows.length !== 0 && (
        <Alert severity="warning">
          <Typography>{t('payments:import.warn_instalment_plans', { created: rows.length, imported: instalmentPlansToImport })}</Typography>
        </Alert>
      )}
      {rows.length === 0 && (
        <Alert severity="error">
          <Typography>{t('payments:import.error_instalment_plans')}</Typography>
        </Alert>
      )}
      <DialogActions>
        <Button label={t('global:close')} name="import" onClick={onFinish} />
      </DialogActions>
    </div>
  );
}
