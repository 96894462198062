import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import PageHeader from '../../components/ui/blocks/PageHeader';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { SplitButton } from '@payactive/app-common';
import { NewPaymentDialog } from './dialogs/NewPaymentDialog';
import PaymentsImportDialog from './import/PaymentsImportDialog';
import { useHistory } from 'react-router-dom';
import PaymentsTable from './table/PaymentsTable';
import PaymentListDataContextProvider, { PaymentListDataContext } from './PaymentListDataContext';
import GenericTableFilters, { StateFilter } from '../../components/filter/GenericTableFilters';
import { Payments } from '../../types/Payment/Payment';

const PaymentsView = () => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  let history = useHistory();
  const { t } = useTranslation(['global', 'payments']);

  const filterArray: StateFilter[] = [
    { name: t('payments:PAID'), value: Payments.State.COMPLETED },
    { name: t('payments:VERIFIED'), value: Payments.State.VERIFIED },
    { name: t('payments:PENDING'), value: Payments.State.PENDING },
    { name: t('payments:MANUAL'), value: Payments.State.MANUAL },
    { name: t('payments:CANCELLED'), value: Payments.State.CANCELLED },
    { name: t('payments:CREATING'), value: Payments.State.CREATING },
    { name: t('payments:ABORTED'), value: Payments.State.ABORTED },
    { name: t('payments:ERRORNAME'), value: Payments.State.ERROR },
    { name: t('payments:CHARGED_BACK'), value: Payments.State.CHARGED_BACK },
    { name: t('payments:REFUND_IN_PROGRESS'), value: Payments.State.REFUND_IN_PROGRESS },
    { name: t('payments:REFUND_COMPLETED'), value: Payments.State.REFUND_COMPLETED },
  ];

  const [showCreateDialog, setShowCreateDialog] = useState(params.action === 'new');
  const [paymentsImportDialog, setPaymentsImportDialog] = useState(false);

  const newPaymentTitle = t('payments:new payment');

  const onPaymentCreationCanceled = () => {
    setShowCreateDialog(false);
    if (window.location.search !== '') {
      history.push('/dashboard');
    }
  };

  return (
    <PaymentListDataContextProvider pageSizeOptions={[5, 10, 30]}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeader subtitle={t('payments')} title={t('payments:current transactions')} />
        </Grid>
        <Grid item style={{ zIndex: 1 }}>
          <SplitButton
            onClick={() => setShowCreateDialog(true)}
            label={newPaymentTitle}
            name={newPaymentTitle}
            onOptionClick={() => setPaymentsImportDialog(true)}
            options={[{ id: 'import_payment', name: t('payments:import.importpaymentrequests') }]}
          />
        </Grid>
      </Grid>
      <Grid>
        <GenericTableFilters states={filterArray} context={PaymentListDataContext} t={t} />
      </Grid>
      <PaymentsTable showPagination={true} />
      <PaymentListDataContext.Consumer>
        {({ reload }) => (
          <>
            {showCreateDialog && (
              <NewPaymentDialog
                onSuccess={() => {
                  setShowCreateDialog(false);
                  reload();
                }}
                isOpen={true}
                onCancel={onPaymentCreationCanceled}
                debitor={undefined}
              />
            )}
            {paymentsImportDialog && (
              <PaymentsImportDialog
                open={true}
                onClose={() => {
                  setPaymentsImportDialog(false);
                  reload();
                }}
              />
            )}
          </>
        )}
      </PaymentListDataContext.Consumer>
    </PaymentListDataContextProvider>
  );
};

export default PaymentsView;
