import { InputField } from '@payactive/app-common';
import React, { useContext } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Checkbox, Grid } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import StateWithIcon from '../StateWithIcon';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DateFilter from './DateFilter';
import TextFilter from './TextFilter';
import { TFunction } from 'react-i18next';
import { Checkouts } from '../../types/Checkout/Checkout';
import { Payments } from '../../types/Payment/Payment';
import { BankAccounts } from '../../types/Bank/BankAccount';
import { InstalmentPlans } from '../../types/InstalmentPlan/InstalmentPlan';
import { Subscriptions } from '../../types/Subscription/Subscription';
import { InvoiceState } from '../../types/Invoice/InvoiceState';
import { Products } from '../../types/Product/Product';
import { CreditNoteState } from '../../types/CreditNote/CreditNoteState';
import { Credits } from '../../types/Credit/Credit';

const useStyles = makeStyles((theme) => ({
  states: {
    whiteSpace: 'nowrap',
  },
  checkbox: {
    textAlign: 'end',
  },
}));

export interface StateFilter {
  name: string;
  value:
    | Checkouts.State
    | Payments.State
    | InvoiceState
    | Credits.State
    | CreditNoteState
    | BankAccounts.Status
    | InstalmentPlans.Status
    | Subscriptions.Status
    | Products.Type;
}

export interface GenericTableFiltersProps {
  states: StateFilter[];
  context: React.Context<any>;
  t: TFunction;
  label?: string;
  disabled?: boolean;
}

const GenericTableFilters = ({ states, context, t, label, disabled = false }: GenericTableFiltersProps) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const classes = useStyles();

  const filters = useContext(context).filters;
  if (!filters) {
    return <></>;
  }

  const { setProductIds, setStateFilter, dateStartFilter, setDateStartFilter, dateEndFilter, setDateEndFilter, searchFilter, setSearchFilter } =
    filters;

  const handleStatesChange = (values: StateFilter[]) => {
    const filter = values.map(({ value }) => value);
    if (label) {
      setProductIds(filter);
    } else {
      setStateFilter(filter);
    }
  };

  return (
    <Grid container spacing={2} alignItems={'center'}>
      {setSearchFilter && (
        <Grid item xs={2}>
          <TextFilter id="instalmentPlanSearch" value={searchFilter} setValue={setSearchFilter} disabled={disabled}></TextFilter>
        </Grid>
      )}
      <Grid item xs={setSearchFilter === undefined ? 6 : 4}>
        <Autocomplete
          multiple
          disabled={disabled}
          id="multiple-limit-tags"
          limitTags={3}
          options={states}
          disableCloseOnSelect
          className={classes.states}
          onChange={(event, values) => {
            handleStatesChange(values);
          }}
          getOptionLabel={(option) => (label ? option.name : option.value)}
          getOptionSelected={(option, value) => {
            return option.value === value.value;
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) =>
              label ? (
                <Chip {...getTagProps({ index })} label={option.name} />
              ) : (
                <StateWithIcon
                  state={option.value as Checkouts.State | Payments.State | InvoiceState | BankAccounts.Status | InstalmentPlans.Status}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ),
            )
          }
          renderOption={(option, state) => (
            <Grid container direction={'row'} alignItems={'center'} justifyContent={label ? 'space-between' : 'flex-start'}>
              <Grid item xs={10}>
                {label ? (
                  <Grid>{option.name}</Grid>
                ) : (
                  <StateWithIcon
                    state={option.value as Checkouts.State | Payments.State | InvoiceState | BankAccounts.Status | InstalmentPlans.Status}
                    label={option.name}
                  />
                )}
              </Grid>
              <Grid item md={2} className={classes.checkbox}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={state.selected} />
              </Grid>
            </Grid>
          )}
          renderInput={(params) => <InputField name="" {...params} variant="standard" label={label ? label : t('filter status')} />}
        />
      </Grid>
      <Grid item xs={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container wrap="nowrap" justifyContent={'space-between'} spacing={2}>
            <Grid item xs={6}>
              <DateFilter onChange={setDateStartFilter} label={t('start from')} value={dateStartFilter} disabled={disabled} />
            </Grid>
            <Grid item xs={6}>
              <DateFilter startDate={dateStartFilter} onChange={setDateEndFilter} label={t('till')} value={dateEndFilter} disabled={disabled} />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

export default GenericTableFilters;
