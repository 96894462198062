import { Chip, Grid, makeStyles } from '@material-ui/core';
import React, { MouseEvent, ReactElement, useState } from 'react';
import { InvoiceState } from '../types/Invoice/InvoiceState';
import { SEPAMandates } from '../types/SEPA/SEPAMandate';
import { Checkouts } from '../types/Checkout/Checkout';
import { Payments } from '../types/Payment/Payment';
import { InstalmentPlans } from '../types/InstalmentPlan/InstalmentPlan';
import { BankAccounts } from '../types/Bank/BankAccount';
import { Subscriptions } from '../types/Subscription/Subscription';
import { AgentClients } from '../types/Agent/AgentClient';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import { useRowStyles } from '../utils/TableRowHelper';
import { CreditNoteState } from '../types/CreditNote/CreditNoteState';
import { Credits } from '../types/Credit/Credit';

const useStyles = makeStyles((theme) => ({
  PENDING: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  WAITING_FOR_COMPLETION: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  COMPLETED: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  ABORTED: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  MANUAL: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  VERIFIED: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  SENT: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  PAID: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  CANCELLED: {
    backgroundColor: theme.palette.grey['700'],
    color: theme.palette.grey['300'],
  },
  ERROR: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  ACTIVE: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  INACTIVE: {
    backgroundColor: theme.palette.grey['400'],
    color: theme.palette.grey['A400'],
  },
  REFUND_IN_PROGRESS: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  REFUND_COMPLETED: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  CHARGED_BACK: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  WILL_EXPIRE: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  HAS_EXPIRED: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  RUNNING: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  DRAFT: {
    backgroundColor: theme.palette.grey['400'],
    color: theme.palette.grey['A400'],
  },
  CANCELLATION_REQUESTED: {
    backgroundColor: 'darkred',
    color: theme.palette.grey[50],
  },
  CANCELLATION_COMPLETED: {
    backgroundColor: theme.palette.grey['400'],
    color: theme.palette.grey['A400'],
  },
  PRODUCT: {
    backgroundColor: theme.palette.grey['400'],
    color: theme.palette.grey['A400'],
  },
  DONATION: {
    backgroundColor: theme.palette.grey['400'],
    color: theme.palette.grey['A400'],
  },
  IN_PROGRESS: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  FULFILLMENT: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  CLOSED: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  OPEN: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  IN_DUNNING: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  CONFLICT: {
    backgroundColor: 'darkred',
    color: theme.palette.grey['50'],
  },
  REMINDED: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  UNCOLLECTIBLE: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  NEW: {
    /*not used*/
  },
  CREATING: {
    /*not used*/
  },
}));

export default function StateWithIcon({
  state,
  label,
  popoverContent,
  ...rest
}: {
  state:
    | Checkouts.State
    | Payments.State
    | InvoiceState
    | CreditNoteState
    | Credits.State
    | BankAccounts.Status
    | InstalmentPlans.Status
    | Subscriptions.Status
    | SEPAMandates.State
    | AgentClients.Status;
  label: string;
  popoverContent?: string | ReactElement;
}) {
  const chipClasses = useStyles();
  const rowStyles = useRowStyles();
  const restClasses = (rest as { className: string })?.className || '';
  const [anchorEl, setAnchorEl] = useState<Element>();
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: MouseEvent) => {
    if (popoverContent) setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    if (popoverContent) setAnchorEl(undefined);
  };

  return (
    <Grid container>
      <Grid item>
        <Chip
          icon={popoverContent ? <InfoIcon className={chipClasses[state]} fontSize={'small'} /> : undefined}
          key={state}
          className={`${restClasses} ${chipClasses[state]}`}
          label={label || state}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          {...rest}
        />
      </Grid>
      <Grid item>
        <Popover
          id="mouse-over-popover"
          className={rowStyles.popover}
          classes={{
            paper: rowStyles.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {popoverContent}
        </Popover>
      </Grid>
    </Grid>
  );
}
