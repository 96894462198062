//see io/payactive/modules/invoice/model/InvoiceStatus.java
export enum InvoiceState {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  CONFLICT = 'CONFLICT',
  REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS',
  CANCELLATION_COMPLETED = 'CANCELLATION_COMPLETED',
  REMINDED = 'REMINDED',
  IN_DUNNING = 'IN_DUNNING',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
}
