import React from 'react';
import { Slide, Snackbar, SnackbarOrigin } from '@material-ui/core';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import Alert from '@material-ui/lab/Alert';

export default function InfoArea() {
  const anchorOrigin: SnackbarOrigin = { vertical: 'top', horizontal: 'right' };

  return (
    <AlertContext.Consumer>
      {({ currentAlert, isOpen, setIsOpen }) =>
        currentAlert && (
          <Snackbar
            TransitionComponent={(props) => <Slide {...props} direction="left" />}
            anchorOrigin={anchorOrigin}
            open={isOpen}
            autoHideDuration={currentAlert.severity === 'error' ? null : currentAlert.duration}
            onClose={() => setIsOpen(false)}
            key={anchorOrigin.vertical + anchorOrigin.horizontal}
            ClickAwayListenerProps={currentAlert.severity === 'error' ? { mouseEvent: false } : {}}
          >
            <Alert variant={'filled'} severity={currentAlert.severity} onClose={() => setIsOpen(false)}>
              {currentAlert.message}
            </Alert>
          </Snackbar>
        )
      }
    </AlertContext.Consumer>
  );
}
