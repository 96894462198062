import React, { ReactElement } from 'react';
import DeleteDialog from '../DeleteDialog';
import { Webhook } from '../../../types/Webhook/Webhook';

interface Props {
  open: boolean;
  webhook: Webhook;
  onClose: () => void;
  onDelete: (webhook: Webhook) => void;
}

export default function DeleteWebhookDialog({ open, webhook, onClose, onDelete }: Props): ReactElement {
  return <DeleteDialog open={open} data={webhook} text={webhook.url} title={'delete webhook'} onClose={onClose} onDelete={onDelete} />;
}
