import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Tab, Tabs } from '@material-ui/core';
import DebtorInfoStats from './DebtorInfoStats';
import TabPanel from '../../components/TabPanel';
import DebtorsDetailsPageHeader from './DebtorsDetailsPageHeader';
import PaymentsTab from './tabs/PaymentsTab';
import { Debtor, Debtors, NewDebtor } from '../../types/Debtor/Debtor';
import PaymentListDataContextProvider from '../payments/PaymentListDataContext';
import InvoiceListDataContextProvider from '../invoices/InvoiceListDataContextProvider';
import SEPAMandateListDataContextProvider, { SEPAMandateListDataContext } from '../sepaMandate/SEPAMandateListDataContext';
import { Button } from '@payactive/app-common';
import CreateSEPAMandateDialog from '../sepaMandate/dialogs/CreateSEPAMandateDialog';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SEPAMandateService from '../../services/SEPAMandateService';
import InvoicesTab from './tabs/InvoicesTab';
import SEPAMandateTab from './tabs/SEPAMandateTab';
import SubscriptionsTab from './tabs/SubscriptionsTab';
import InstalmentPlansTab from './tabs/InstalmentPlansTab';
import SubscriptionService from '../../services/SubscriptionService';
import SubscriptionListDataContextProvider from '../subscriptions/SubscriptionsContextProvider';
import InstalmentPlanService from '../../services/InstalmentPlanService';
import InstalmentPlanListDataContextProvider from '../instalmentPlans/InstalmentPlanListDataContext';
import { DebtorStats } from '../../types/Debtor/DebtorStats';
import DebtorService from '../../services/DebtorService';
import { PaymentMethod } from '../../types/Invoice/PaymentMethod';
import InvoiceService from '../../services/InvoiceService';
import PaymentService from '../../services/PaymentService';
import ReloadContext from '../../components/contexts/ReloadContext';
import PaymentStats from '../../components/ui/blocks/PaymentStats';
import CreditService from '../../services/CreditService';
import { CreditNotesListDataContextProvider } from '../creditNotes/CreditNotesListDataContextProvider';
import CreditsTab from './tabs/CreditsTab';
import { CreditListDataContextProvider } from '../credits/CreditsListDataContext';
import CreditNoteService from '../../services/CreditNoteService';
import CreditNotesTab from './tabs/CreditNotesTab';

export default function DebtorDetailPage(): ReactElement {
  const [debtor, setDebtor] = useState<Debtor | NewDebtor>();
  const [stats, setStats] = useState<DebtorStats>();
  const [tab, setTab] = useState<number>(0);
  const [paymentsCount, setPaymentsCount] = useState<number>(0);
  const [subscriptionsCount, setSubscriptionsCount] = useState<number>(0);
  const [instalmentPlanCount, setInstalmentPlanCount] = useState<number>(0);
  const [invoicesCount, setInvoicesCount] = useState<number>(0);
  const [creditNotesCount, setCreditNotesCount] = useState<number>(0);
  const [SEPAMandatesCount, setSEPAMandatesCount] = useState<number>(0);
  const [creditsCount, setCreditsCount] = useState<number>(0);

  const [showCreateSEPAMandateDialog, setShowCreateSEPAMandateDialog] = useState<boolean>(false);

  const { id: debtorId, email } = useParams<{ id: string; email: string }>();

  const hasAmountPending = !!(stats && stats.totalAmountPending && stats.numPending);

  let history = useHistory();

  const { t } = useTranslation(['debitors', 'payments', 'sepa', 'navigation', 'invoices', 'instalmentPlans', 'credits', 'creditNotes']);

  useEffect(() => {
    getDebtor();
    getStats();
    registerPaymentCountListener();
    registerInvoicesCountListener();
    registerSubscriptionCountListener();
    registerInstalmentPlansCountListener();
    registerSEPAMandatesCountListener();
    registerCreditsCountListener();
    registerCreditNotesCountListener();
    determineWhichTabToOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const registerInvoicesCountListener = () => {
    InvoiceService.setCountListener((page) => {
      setInvoicesCount(page.totalElements);
    });
  };

  const registerPaymentCountListener = () => {
    PaymentService.setCountListener((page) => {
      setPaymentsCount(page.totalElements);
    });
  };

  const registerSubscriptionCountListener = () => {
    SubscriptionService.setCountListener((page) => {
      setSubscriptionsCount(page.totalElements);
    });
  };

  const registerInstalmentPlansCountListener = () => {
    InstalmentPlanService.setCountListener((page) => {
      setInstalmentPlanCount(page.totalElements);
    });
  };

  const registerSEPAMandatesCountListener = () => {
    SEPAMandateService.setCountListener((page) => {
      setSEPAMandatesCount(page.totalElements);
    });
  };

  const registerCreditsCountListener = () => {
    CreditService.setCountListener((page) => {
      setCreditsCount(page.totalElements);
    });
  };

  const registerCreditNotesCountListener = () => {
    CreditNoteService.setCountListener((page) => {
      setCreditNotesCount(page.totalElements);
    });
  };

  const getDebtor = () => {
    DebtorService.getDebtor(debtorId)
      .then((debtor) => {
        setDebtor(debtor);
      })
      .catch(() => {
        setDebtor({
          emailAddress: email,
          firstName: 'unknown',
          lastName: 'unknown',
          type: Debtors.Type.PERSON,
          paymentMethod: PaymentMethod.ONLINE_PAYMENT,
        });
      });
  };

  const getStats = () => {
    DebtorService.getStats(debtorId).then((stats) => {
      setStats(stats);
    });
  };

  const path = window.location.href;

  const determineWhichTabToOpen = () => {
    if (path.includes('payments')) {
      return setTab(0);
    } else if (path.includes('credits')) {
      return setTab(1);
    } else if (path.includes('invoices')) {
      return setTab(2);
    } else if (path.includes('creditNotes')) {
      return setTab(3);
    } else if (path.includes('subscriptions')) {
      return setTab(4);
    } else if (path.includes('instalmentPlans')) {
      return setTab(5);
    } else {
    }
  };

  const handleChangeTab = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const closeDialog = () => {
    setShowCreateSEPAMandateDialog(false);
  };

  return (
    <PaymentListDataContextProvider pageSizeOptions={[5, 10, 30]} debtorId={debtorId} hasPaginationProvider={false}>
      <InvoiceListDataContextProvider pageSizeOptions={[5, 10, 30]} debtorId={debtorId} hasPaginationProvider={false}>
        <SubscriptionListDataContextProvider pageSizeOptions={[5, 10, 30]} debtorId={debtorId} hasPaginationProvider={false}>
          <InstalmentPlanListDataContextProvider pageSizeOptions={[5, 10, 30]} debtorId={debtorId} hasPaginationProvider={false}>
            <SEPAMandateListDataContextProvider pageSizeOptions={[5, 10, 30]} debtorId={debtorId} hasPaginationProvider={false}>
              <CreditListDataContextProvider pageSizeOptions={[5, 10, 30]} debtorId={debtorId} hasPaginationProvider={false}>
                <CreditNotesListDataContextProvider pageSizeOptions={[5, 10, 30]} debtorId={debtorId} hasPaginationProvider={false}>
                  <Grid alignItems="center">
                    <ReloadContext.Provider value={{ reload: getDebtor }}>
                      <DebtorsDetailsPageHeader
                        subtitle={t('debitors:Debitor')}
                        debtor={debtor as Debtor}
                        onBack={() => history.goBack()}
                        fetchPaymentsCount={registerPaymentCountListener}
                        fetchInvoicesCount={registerInvoicesCountListener}
                        fetchInstalmentPlansCount={registerInstalmentPlansCountListener}
                      />
                    </ReloadContext.Provider>
                  </Grid>
                  <Grid item container style={{ marginTop: '2%' }} spacing={2}>
                    {hasAmountPending && (
                      <Grid item xs={2} container>
                        <PaymentStats
                          from="debitorDetailPage"
                          label={t('debitors:pending transactions')}
                          totalAmount={stats.totalAmountPending}
                          numTransactions={stats.numPending}
                        />
                      </Grid>
                    )}
                    <Grid item container xs={stats && stats.totalAmountPending && stats.numPending ? 10 : 12}>
                      <DebtorInfoStats debtor={debtor as Debtor} />
                    </Grid>
                  </Grid>
                  {tab === 6 && (
                    <div style={{ float: 'right', marginTop: '5px' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddBoxIcon />}
                        name={t('sepa:new sepa mandate')}
                        onClick={() => setShowCreateSEPAMandateDialog(true)}
                      ></Button>
                    </div>
                  )}

                  <SEPAMandateListDataContext.Consumer>
                    {({ reload }) => (
                      <>
                        <div>
                          <Tabs
                            value={tab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChangeTab}
                            aria-label="disabled tabs example"
                          >
                            <Tab label={`${t('payments')} (${paymentsCount})`} />
                            <Tab label={`${t('credits:credits')} (${creditsCount})`} />
                            <Tab label={`${t('Invoices')} (${invoicesCount})`} />
                            <Tab label={`${t('creditNotes:credit notes')} (${creditNotesCount})`} />
                            <Tab label={`${t('navigation:subscriptions')} (${subscriptionsCount})`} />
                            <Tab label={`${t('navigation:payment plans')} (${instalmentPlanCount})`} />
                            <Tab label={`${t('sepa:sepa mandates')} (${SEPAMandatesCount})`} />
                          </Tabs>
                          <TabPanel value={tab} index={0}>
                            <PaymentsTab />
                          </TabPanel>
                          <TabPanel value={tab} index={1}>
                            <CreditsTab />
                          </TabPanel>
                          <TabPanel value={tab} index={2}>
                            <InvoicesTab />
                          </TabPanel>
                          <TabPanel value={tab} index={3}>
                            <CreditNotesTab />
                          </TabPanel>
                          <TabPanel value={tab} index={4}>
                            <SubscriptionsTab />
                          </TabPanel>
                          <TabPanel value={tab} index={5}>
                            <InstalmentPlansTab />
                          </TabPanel>
                          <TabPanel value={tab} index={6}>
                            <SEPAMandateTab />
                          </TabPanel>
                        </div>
                        {showCreateSEPAMandateDialog && (
                          <CreateSEPAMandateDialog
                            debitor={debtor}
                            onSuccess={() => {
                              setShowCreateSEPAMandateDialog(false);
                              reload();
                            }}
                            onCancel={closeDialog}
                            isOpen={true}
                          />
                        )}
                      </>
                    )}
                  </SEPAMandateListDataContext.Consumer>
                </CreditNotesListDataContextProvider>
              </CreditListDataContextProvider>
            </SEPAMandateListDataContextProvider>
          </InstalmentPlanListDataContextProvider>
        </SubscriptionListDataContextProvider>
      </InvoiceListDataContextProvider>
    </PaymentListDataContextProvider>
  );
}
