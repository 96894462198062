import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Step, StepLabel, Stepper as MuiStepper } from '@material-ui/core';
import { default as React, ReactElement, useState } from 'react';
import { BaseRow } from 'react-csv-importer';
import { useTranslation } from 'react-i18next';
import { CsvImporter, PaymentImportRow } from './Importer';
import ReviewImport from './ReviewImport';
import Summary from './Summary';

interface Props {
  open: boolean;
  onClose: () => void;
}

enum Steps {
  PREPARE = 0,
  REVIEW = 1,
  IMPORT = 2,
}

export default function PaymentsImportDialog({ open, onClose }: Props): ReactElement {
  const { t } = useTranslation(['global', 'payments']);

  const [currentStep, setCurrentStep] = useState(Steps.PREPARE);
  const [importedRows, setImportedRows] = useState<BaseRow[]>([]);
  const [selectedRowsSize, setSelectedRowsSize] = useState<number>(0);
  const [csvRows, setCsvRows] = useState<PaymentImportRow[]>([]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      aria-labelledby="import-payments-dialog-title"
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item>{t('payments:import.importpaymentrequests')}</Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('payments:import.importpaymentrequests_text')}</DialogContentText>
        <MuiStepper activeStep={currentStep}>
          <Step key={Steps.PREPARE}>
            <StepLabel>{t('payments:import.steps_prepare')}</StepLabel>
          </Step>
          <Step key={Steps.REVIEW}>
            <StepLabel>{t('payments:import.steps_review')}</StepLabel>
          </Step>
          <Step key={Steps.IMPORT} completed={currentStep === Steps.IMPORT}>
            <StepLabel>{t('payments:import.steps_import')}</StepLabel>
          </Step>
        </MuiStepper>
        {currentStep === Steps.PREPARE && (
          <CsvImporter
            onFinish={(rows) => {
              setCurrentStep(Steps.REVIEW);
              setCsvRows(rows);
            }}
            onClose={onClose}
          />
        )}
        {currentStep === Steps.REVIEW && (
          <ReviewImport
            onSuccess={(rows, selectedRows) => {
              setCurrentStep(Steps.IMPORT);
              setImportedRows(rows);
              setSelectedRowsSize(selectedRows);
            }}
            rows={csvRows}
            onClose={onClose}
          />
        )}
        {currentStep === Steps.IMPORT && <Summary rows={importedRows} paymentsToImport={selectedRowsSize} onFinish={onClose} />}
      </DialogContent>
    </Dialog>
  );
}
