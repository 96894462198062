import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinkBox from '../../../components/LinkBox';
import SelfServiceService from '../../../services/SelfServiceService';

export const DebtorPortalLinkDialog = ({ onClose, open }: { onClose: () => void; open: boolean }) => {
  const { t } = useTranslation(['global', 'payments']);
  const [debtorPortalUrl, setDebtorPortalUrl] = useState<string>(t('global:loading'));
  const [isCopyButtonEnabled, setIsCopyButtonEnabled] = useState(false);

  useEffect(() => {
    SelfServiceService.getSelfServiceUrls()
      .then((selfServiceUrls) => {
        setDebtorPortalUrl(selfServiceUrls.debtorPortalUrl);
        setIsCopyButtonEnabled(true);
      })
      .catch(() => setDebtorPortalUrl(t('debtor portal link loading failed')));
  }, [t]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('payments:debtor portal dialog title')}</DialogTitle>
      <DialogContent dividers>
        <DialogContent>
          <DialogContentText>{t('payments:debtor portal dialog description')}</DialogContentText>
        </DialogContent>
        <DialogContent>
          <LinkBox url={debtorPortalUrl} enableCopy={isCopyButtonEnabled} />
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
