import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import InvoiceEventsDialog from '../../payments/dialogs/InvoiceEventsDialog';
import { PEvent } from '../../../types/PEvent';
import { InvoiceActionType } from './InvoiceActionHelper';
import InvoiceService from '../../../services/InvoiceService';

const InvoiceEventAction = ({ invoice, closeMenu, label }: InvoiceActionType) => {
  const [statusDialog, setStatusDialog] = useState(false);
  const [invoiceEvents, setInvoiceEvents] = useState<PEvent[]>([] as PEvent[]);

  return (
    <div key={invoice.id + 'eventsC'}>
      <MenuItem
        key={invoice.id + 'events'}
        onClick={() => {
          closeMenu();
          InvoiceService.getInvoiceEvents(invoice.id).then((events: PEvent[]) => {
            setInvoiceEvents(events);
            setStatusDialog(true);
          });
        }}
      >
        {label}
      </MenuItem>
      <InvoiceEventsDialog invoice={invoice} events={invoiceEvents} open={statusDialog} onCancel={() => setStatusDialog(false)} />
    </div>
  );
};

export default InvoiceEventAction;
