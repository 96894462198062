import { Container, CssBaseline, Divider, Grid, makeStyles, Paper, Step, StepLabel, Stepper } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import PayactiveLogoWithText from '../../../components/PayactiveLogo/PayactiveLogo';
import { authService } from '../../../services/AuthService';
import formInitialValues from './FormModel/formInitialValues';
import onboardFormModel from './FormModel/onboardFormModel';
import UserInformationForm from './Forms/UserInformationForm';
import { setInitialValues } from './helpers';
import SuccessScreen from './SuccessScreen';
import WelcomeScreen from './WelcomeScreen';
import { getSchemaForStep } from './FormModel/validationSchema';
import { AddressContext } from '../../../components/contexts/WithAddressContext';
import PortalService from '../../../services/PortalService';
import { NewOnboard } from '../../../types/Onboard/Onboard';
import CompanyInformationForm from './Forms/CompanyInformationForm';
import SummaryForm from './Forms/SummaryForm';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '1em',
  },
  form: {
    padding: theme.spacing(3),
    minHeight: '430px',
  },
  navigation: {
    padding: '1em',
  },
  logo: {
    textAlign: 'center',
  },
}));

enum ONBOARD_STEPS {
  WELCOME = 0,
  COMPANY_INFORMATION = 1,
  USER_INFORMATION = 2,
  SUMMARY = 3,
  SUCCESS = 4,
}

const { formId, formField } = onboardFormModel;

function OnboardingFlow() {
  const { invitationId } = useParams<{ invitationId: string }>();
  const addressContext = useContext(AddressContext);
  const { t } = useTranslation(['onboarding', 'global']);
  const { t: tValidations } = useTranslation('validations');
  const history = useHistory();
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(ONBOARD_STEPS.WELCOME);

  const _handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const redirectToLogin = ({ emailAddress }: NewOnboard) => {
    history.push('/login?emailAddress=' + emailAddress);
  };

  useEffect(() => {
    PortalService.getOnboardContext(invitationId)
      .then((res) => {
        setInitialValues(res, onboardFormModel, formInitialValues);
      })
      .catch(() => {
        history.push('/404');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getContentByStep = (values: NewOnboard, status: any) => {
    switch (currentStep) {
      case ONBOARD_STEPS.WELCOME:
        return <WelcomeScreen values={undefined} onChange={undefined} />;
      case ONBOARD_STEPS.COMPANY_INFORMATION:
        return <CompanyInformationForm values={values} formField={formField} />;
      case ONBOARD_STEPS.USER_INFORMATION:
        return <UserInformationForm values={values} formField={formField} />;
      case ONBOARD_STEPS.SUMMARY:
        return <SummaryForm values={values} formField={formField} status={status} />;
      case ONBOARD_STEPS.SUCCESS:
        return <SuccessScreen />;
      default:
        return <></>;
    }
  };

  function _submitForm(values: NewOnboard, { setSubmitting, setStatus }: FormikHelpers<NewOnboard>) {
    PortalService.createOnboard(values)
      .then((res) => {
        setSubmitting(false);
        if (res.status === 201) {
          setCurrentStep(currentStep + 1);
          authService.authoriseByResponse(res);
          history.push('/dashboard');
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setStatus(t('User with that E-Mail already exists'));
        } else {
          setStatus(t('error'));
        }
      });
    setSubmitting(true);
  }

  function _handleSubmit(values: NewOnboard, actions: FormikHelpers<NewOnboard>) {
    if (currentStep === ONBOARD_STEPS.SUMMARY) {
      _submitForm(values, actions);
    } else {
      setCurrentStep(currentStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  return (
    <Fragment>
      <CssBaseline />
      <Grid className={classes.logo} item xs={12}>
        <PayactiveLogoWithText pixels={50} />
      </Grid>
      <Grid className={classes.main} container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={10} md={8} lg={6}>
          <Paper elevation={1}>
            <Stepper activeStep={currentStep}>
              <Step>
                <StepLabel>{t('onboarding:welcome')}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t('onboarding:company_information')}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t('onboarding:user_information')}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t('onboarding:summary')}</StepLabel>
              </Step>
            </Stepper>
            <Divider />
            <Formik
              initialValues={formInitialValues}
              validationSchema={getSchemaForStep(currentStep, tValidations, addressContext.isAddress)}
              onSubmit={_handleSubmit}
            >
              {({ values, status }) => (
                <Form id={formId}>
                  <Container className={classes.form}>{getContentByStep(values, status)}</Container>
                  <Divider />
                  <Grid container spacing={3} className={classes.navigation}>
                    {currentStep !== ONBOARD_STEPS.SUCCESS && (
                      <Grid item>
                        <Button
                          disabled={currentStep === ONBOARD_STEPS.WELCOME}
                          onClick={_handleBack}
                          label={t('global:back')}
                          name={t('global:back')}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      {currentStep === ONBOARD_STEPS.SUCCESS ? (
                        <Button onClick={() => redirectToLogin(values)} label={t('onboarding:to_the_portal')} name={t('onboarding:to_the_portal')} />
                      ) : (
                        <Button
                          type="submit"
                          label={currentStep === ONBOARD_STEPS.SUMMARY ? t('onboarding:finish_setup') : t('global:continue')}
                          name={currentStep === ONBOARD_STEPS.SUMMARY ? t('onboarding:finish_setup') : t('global:continue')}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export { ONBOARD_STEPS, OnboardingFlow };
