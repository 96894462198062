import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { Invoice } from '../../../types/Invoice/Invoice';
import { getDebtorLabel } from '../../../utils/LabelHelper';

interface Props {
  open: boolean;
  invoice: Invoice;
  onClose: () => void;
  onDelete: (invoice: Invoice) => void;
}

export default function DeleteDraftInvoiceDialog({ open, invoice, onClose, onDelete }: Props): ReactElement {
  const { t } = useTranslation('debitors');

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
    >
      <DialogTitle id="alert-dialog-title">{t('delete invoice')}</DialogTitle>
      <Alert severity="warning">{t('draft invoices info')}</Alert>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t('customer') + ': ' + getDebtorLabel(invoice?.debitor)}</DialogContentText>
        <DialogContentText id="alert-dialog-description">{t('amount') + ': ' + formatCurrency(invoice?.total)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" name="cancel" label={t('global:no')} disabled={false} loading={false} onClick={onClose} />
        <Button name="cancel" label={t('global:yes')} disabled={false} loading={false} onClick={() => onDelete(invoice)} />
      </DialogActions>
    </Dialog>
  );
}
