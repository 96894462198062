import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import TableHeader from '../../../components/table/TableHeader';
import { Skeleton } from '@material-ui/lab';
import TablePagination from '../../../components/table/Pagination';
import { CreditsListDataContext } from '../CreditsListDataContext';
import CreditRow from './CreditRow';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: '200px',
  },
  main: {
    padding: theme.spacing(1),
  },
}));

export default function CreditsTable() {
  const { t } = useTranslation('credits');

  const classes = useStyles();

  const context = useContext(CreditsListDataContext);
  const preColumns = !context.isPrefilteredForDebtor
    ? [
        {
          label: t('status'),
          id: 'state',
        },
        {
          label: t('customer'),
          id: 'debitor',
        },
      ]
    : [
        {
          label: t('status'),
          id: 'state',
        },
      ];

  const columns = preColumns.concat([
    {
      label: t('purpose'),
      id: 'purpose',
    },
    {
      label: t('amount'),
      id: 'amount',
    },
    {
      label: t('creationDate'),
      id: 'createdDateTimeUTC',
    },
    {
      label: t('completion'),
      id: 'complete',
    },
  ]);

  const initialLoad = !context.page && context.loading;
  const emptyResultList = !!context.page && context.credits.length === 0;
  const emptyFilteredResultList = emptyResultList && context.filters?.used;

  return (
    <>
      {emptyResultList ? (
        <>
          {emptyFilteredResultList ? (
            <Typography style={{ marginTop: '40px' }}>{t('no results for your search')}</Typography>
          ) : (
            <Grid item xs={12} className={classes.main}>
              <MuiAlert elevation={6} variant="filled" severity="warning">
                {t('no credits linked')}
              </MuiAlert>
            </Grid>
          )}
        </>
      ) : (
        <>
          <TableContainer style={{ flex: '1 1 auto' }}>
            <Table>
              <TableHeader columns={columns} actions={true}></TableHeader>
              <TableBody>
                <>
                  {initialLoad && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1}>
                        <Skeleton className={classes.skeleton} />
                      </TableCell>
                    </TableRow>
                  )}
                  {context.credits?.map((credit, index) => (
                    <CreditRow credit={credit} index={index} key={credit.id}></CreditRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination transformPageSizeLabel={(pageSize) => t('pageSize', { pageSize: pageSize }) as string} />
        </>
      )}
    </>
  );
}
