import React, { useContext, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button } from '@payactive/app-common';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelProductDialog from './CancelProductDialog';
import { productSchemas } from '../../checkoutLinks/dialogs/schemas';
import { NewProduct, Product } from '../../../types/Product/Product';
import ProductService from '../../../services/ProductService';
import ProductForm from '../forms/ProductForm';
import { CustomField } from '../../../types/Product/CustomField';
import { AlertContext } from '../../../components/contexts/WithAlertContext';

const useStyles = makeStyles((theme) => ({
  description: {
    '& .MuiInputAdornment-positionStart': {
      alignSelf: 'self-end',
      marginBottom: '8px',
    },
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  fieldLabel: {
    '& .MuiGrid-spacing-xs-1': {
      width: '45%',
    },
  },
  fieldType: {
    width: '40%',
  },
  customFieldContainer: {
    display: 'flex',
    margin: '20px 0px',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  price: {
    width: '35%',
  },
  tax: {
    width: '20%',
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  totalPrice: {
    width: '15%',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
  },
  backDrop: {
    backdropFilter: 'blur(30px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  checkbox: {
    width: '20%',
    '& .MuiCheckbox-root': {
      padding: '0px',
    },
    '& .MuiTypography-body1': {
      fontSize: '11px',
    },
  },
}));

interface EditProductDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  comeBack: () => void;
  redirectToOrigin: (newProduct: any) => void;
  variant: string;
  product: Product;
}

type NewProductForm = NewProduct & { file?: File; customFields: CustomField[] };

enum NewProductStep {
  NEWPRODUCT = 0,
  COMEBACK = 1,
}

function EditProductDialog({ isOpen, onCancel, comeBack, redirectToOrigin, variant, product }: EditProductDialogProps) {
  const { t } = useTranslation('products');
  const { t: tValidations } = useTranslation('validations');
  const schema = productSchemas(tValidations);
  const classes = useStyles();
  const formRef = useRef(null);
  const [step, setStep] = useState(NewProductStep.NEWPRODUCT);
  const alert = useContext(AlertContext);
  const initialValues = {
    name: product.name,
    description: product.description,
    price: product.price,
    defaultDonationAmount: product.defaultDonationAmount,
    donationOptions: product.donationOptions,
    productType: product.productType,
    customFields: (product.customFields ? product.customFields : []) as CustomField[],
    taxRate: product.taxRate,
    image: product.image,
    recurringBillingPeriod: product.recurringBillingPeriod,
    billingType: product.billingType,
  };

  const submit = (values: NewProductForm, actions: any) => {
    //what this fn does:  ALL properties of metadata are '' returns: false
    const formatMetadataValuesIfEmpty = values.customFields?.length > 0 && Object.values(values.customFields[0]).every((x) => !x || x === '');
    if (formatMetadataValuesIfEmpty) {
      values.customFields = [];
    }

    let image = values.file;
    delete values.file;
    const payload = {
      name: values.name,
      description: values.description,
      price: values.price,
      defaultDonationAmount: values.defaultDonationAmount,
      donationOptions: {
        isEnabled: values.donationOptions?.isEnabled,
        option1: values.donationOptions?.option1,
        option2: values.donationOptions?.option2,
        option3: values.donationOptions?.option3,
      },
      productType: values.productType,
      taxRate: values.taxRate,
      recurringBillingPeriod: values.recurringBillingPeriod,
      billingType: values.billingType,
      customFields: values.customFields,
    } as NewProduct;
    ProductService.editProduct(product.id, payload, image)
      .then((response) => {
        if (response) {
          actions.setSubmitting(false);
          redirectToOrigin(response);
        }
      })
      .catch(() => alert.sendAlert('error', t('global:unexpected error')));
  };

  const checkBeforeComeBack = () => {
    setStep(NewProductStep.COMEBACK);
  };

  return (
    <>
      {step === 0 && (
        <Formik innerRef={formRef} initialValues={initialValues} validationSchema={schema} onSubmit={submit} validateOnChange={false}>
          {({ isSubmitting }) => (
            <Dialog
              maxWidth="sm"
              fullWidth
              open={isOpen}
              onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                  return false;
                }
                onCancel();
              }}
              aria-labelledby="alert-dialog-title"
              BackdropProps={{
                classes: {
                  root: classes.backDrop,
                },
              }}
            >
              <Form>
                <div className={classes.titleContainer}>
                  <ArrowBackIcon onClick={checkBeforeComeBack} />
                  <DialogTitle id="alert-dialog-title">{t('edit product')}</DialogTitle>
                </div>
                <DialogContent dividers>
                  <ProductForm />
                </DialogContent>
                <DialogActions>
                  <Button onClick={checkBeforeComeBack} variant="text" color="primary" name="back button" label={t('global:back')} />
                  <LoadingButton loading={isSubmitting} type="submit">
                    {t('save changes')}
                  </LoadingButton>
                </DialogActions>
              </Form>
            </Dialog>
          )}
        </Formik>
      )}
      {step === 1 && (
        <CancelProductDialog
          origin={variant}
          open={step === 1}
          onClose={() => setStep(NewProductStep.NEWPRODUCT)}
          comeBack={comeBack}
          isEdit={true}
        />
      )}
    </>
  );
}
export default EditProductDialog;
