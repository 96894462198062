import { useHistory } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreditNote } from '../../../types/CreditNote/CreditNote';

export default function CreditNoteDetailsAction({
  creditNote,
  setMenuAnchor,
}: {
  creditNote: CreditNote;
  setMenuAnchor: (value: ((prevState: Element | undefined) => Element | undefined) | Element | undefined) => void;
}) {
  const { t } = useTranslation('creditNotes');

  let history = useHistory();
  return (
    <div key={creditNote.id + 'detailsC'}>
      <MenuItem
        key={creditNote.id + 'details'}
        onClick={() => {
          setMenuAnchor(undefined);
          history.push(`/dashboard/transactions/credit-notes/${creditNote.id}/`);
        }}
      >
        {t('credit note details')}
      </MenuItem>
    </div>
  );
}
