import { DebtorActionType } from './DebtorActionHelper';
import { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import DebtorEditAdditionalEmailsDialog from '../dialogs/DebtorEditAdditionalEmailsDialog';

export default function DebtorEditAdditionalEmailsAction({ debtor, setMenuAnchor, label }: DebtorActionType) {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div key={debtor.id + 'debtorEditAdditionalEmailsC'}>
      <MenuItem
        key={debtor.id + 'debtorEditAdditionalEmails'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>
      {openDialog && <DebtorEditAdditionalEmailsDialog debtorId={debtor.id} open={openDialog} onClose={() => setOpenDialog(false)} />}
    </div>
  );
}
