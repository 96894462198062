import React, { createContext, ReactElement, useEffect, useState } from 'react';
import { CheckoutLink } from '../../types/CheckoutLink/CheckoutLink';
import CheckoutLinkService from '../../services/CheckoutLinkService';

export const CheckoutLinksDataContext = createContext<{
  checkoutLinks: CheckoutLink[];
  reload: () => void;
  loading: boolean;
  isInitialized: boolean;
}>({
  checkoutLinks: [] as CheckoutLink[],
  reload: () => {},
  loading: true,
  isInitialized: false,
});

type CheckoutLinksDataContextProviderProps = {
  children: ReactElement;
};

const CheckoutLinksDataContextProvider = ({ children }: CheckoutLinksDataContextProviderProps) => {
  const [checkoutLinks, setCheckoutLinks] = useState<CheckoutLink[]>([] as CheckoutLink[]);
  const [loading, setLoading] = useState(true);

  const isInitialized = React.useRef(false);

  useEffect(() => {
    reload();
    isInitialized.current = true;
    return () => {
      isInitialized.current = false;
    };
  }, []);

  const reload = () => {
    setLoading(true);
    CheckoutLinkService.getCheckoutLinks()
      .then(({ results }: { results: CheckoutLink[] }) => {
        if (results) {
          setCheckoutLinks(results);
          setLoading(false);
        }
      })
      .catch(() => {
        setCheckoutLinks([]);
        setLoading(false);
      });
  };

  return (
    <CheckoutLinksDataContext.Provider
      value={{
        checkoutLinks: checkoutLinks,
        reload: reload,
        loading: loading,
        isInitialized: isInitialized.current,
      }}
    >
      {children}
    </CheckoutLinksDataContext.Provider>
  );
};

export default CheckoutLinksDataContextProvider;
