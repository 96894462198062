import { Container, CssBaseline, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFound404 from '../../NotFound404';
import { AlertContext } from '../../components/contexts/WithAlertContext';
import WithServiceContext from '../../components/contexts/WithServiceContext';
import WithProgressContext from '../../components/contexts/WithProgressContext';
import InfoArea from './InfoArea/InfoArea';
import LeftNavbar from './nav/LeftNavbar';
import { mainItems, NavItem } from './nav/NavItems';
import ProgressBar from '../../components/ProgressBar';
import { useTranslation } from 'react-i18next';
import { CompanyAccount } from '../../types/Company/Company';
import { CompanyAccountContext } from '../../components/contexts/WithCompanyAccountContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    overflowX: 'hidden',
    maxWidth: '100%',
  },
  container: {
    marginLeft: '-0.2%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  headerLogo: {
    margin: theme.spacing(2),
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const [navItems, setNavItems] = useState<NavItem[]>();

  const alerting = useContext(AlertContext);
  const { companyAccount } = useContext(CompanyAccountContext);
  const { t } = useTranslation('global');

  useEffect(() => {
    alerting.sendAlert('success', t('global:you have been successfully logged in'));
    setNavItems(extractNavItems(mainItems, companyAccount));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const extractNavItems = (items: NavItem[], companyAccount: CompanyAccount) => {
    const navItems = [] as NavItem[];
    items.forEach((item) => {
      if (item.nested) {
        item.nested.forEach((subnav) => {
          if (subnav.label !== 'credit notes' && subnav.label !== 'credits') {
            navItems.push(subnav);
            return;
          }

          if (companyAccount.bankingSettings?.allowCreditTransfers) {
            navItems.push(subnav);
          }
        });
      }
      if (item.path) {
        navItems.push(item);
      }
    });
    return navItems;
  };
  if (!navItems) {
    return <></>;
  }

  return (
    <WithServiceContext>
      <WithProgressContext>
        <div className={classes.root}>
          <CssBaseline />

          <InfoArea />

          <LeftNavbar />
          <main className={classes.content}>
            <ProgressBar />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
              <Switch>
                {navItems.map((item, index) => {
                  if (!item.externalLink) {
                    return (
                      <Route key={index} path={item.path} exact={true}>
                        {item.component}
                      </Route>
                    );
                  }
                  return undefined;
                })}
                <Route key={navItems.length + 1}>
                  <Redirect to="/dashboard/home" />
                </Route>
                <Route>
                  <NotFound404 />
                </Route>
              </Switch>
            </Container>
          </main>
        </div>
      </WithProgressContext>
    </WithServiceContext>
  );
}
