import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button } from '@payactive/app-common';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentListDataContext } from '../PaymentListDataContext';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import PaymentService from '../../../services/PaymentService';
import ReloadContext from '../../../components/contexts/ReloadContext';

interface Props {
  open: boolean;
  payment: any;
  customer: string;
  onCancel: () => void;
}

export default function MarkAsPaidDialog({ open, payment, customer, onCancel }: Props): ReactElement {
  const { t } = useTranslation('debitors');
  const paymentReload = useContext(PaymentListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;
  const reload = () => {
    paymentReload();
    contextReload();
  };
  const alerting = useContext(AlertContext);

  const markPaperlessPaid = (sendEmailNotification = false) => {
    PaymentService.markPaymentAsPaid(payment, sendEmailNotification).then((res) => {
      if (res) {
        reload();
        alerting.sendAlert('success', t('payment marked as verified'));
        onCancel();
      }
    });
  };
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
    >
      <DialogTitle id="alert-dialog-title">{t('Mark payment as verified')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Alert severity="info">
            {' '}
            <DialogContentText>{t('the payment is completed')}</DialogContentText>
          </Alert>
          <DialogContentText>{t('payment confirmation email', { customer: customer })}</DialogContentText>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:close')} />
        <Button variant="text" name="cancel" label={t('Only verify')} disabled={false} loading={false} onClick={() => markPaperlessPaid()} />
        <Button
          name="cancel"
          label={t('Verify & Send E-mail')}
          disabled={false}
          loading={false}
          onClick={() => markPaperlessPaid(true)}
          variant="contained"
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
}
