import { Debtor, Debtors } from '../../../types/Debtor/Debtor';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { getActionsForDebtor } from '../action/DebtorActionHelper';
import { useRowStyles } from '../../../utils/TableRowHelper';
import { Avatar, Badge, Chip, Icon, IconButton, makeStyles, Menu, TableCell, TableRow } from '@material-ui/core';
import LinkToDebtorDetailPage from '../../debtorDetailPage/LinkToDebtorDetailPage';

const useStyles = makeStyles((theme) => ({
  ONBOARDING_PENDING: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  ONBOARDING_COMPLETED: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
}));

const DebtorRow = ({ debtor, index }: { debtor: Debtor; index: number }) => {
  const { t } = useTranslation('debitors');

  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);
  const classes = useRowStyles();
  const chipStyles = useStyles();

  const actions = getActionsForDebtor(debtor, setMenuAnchor, t);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };
  let isDeleted = false;
  if (debtor.type === 'ORGANIZATION' && !debtor.companyName) {
    isDeleted = true;
  }
  if (debtor.type !== 'ORGANIZATION' && !(!!debtor.lastName || !!debtor.firstName)) {
    isDeleted = true;
  }

  //const isDeleted = !(!!debtor.lastName || !!debtor.firstName);
  let label = isDeleted ? `(${t('debitor deleted')})` : `${debtor.lastName || ''}, ${debtor.firstName || ''}`;
  if (!isDeleted && !(!!debtor.lastName || !!debtor.firstName)) {
    label = '';
  }

  return (
    <TableRow key={index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <TableCell key={`${debtor.id}name`}>
        <LinkToDebtorDetailPage target={'payments'} debtor={debtor} debtorRef={debtor.id} text={label}></LinkToDebtorDetailPage>
      </TableCell>

      <TableCell key={`${debtor.id}company`}>
        {debtor.type === Debtors.Type.ORGANIZATION && (
          <LinkToDebtorDetailPage target={'payments'} debtor={debtor} debtorRef={debtor.id} text={`${debtor.companyName}`}></LinkToDebtorDetailPage>
        )}
        {isDeleted && `(${t('debitor deleted')})`}
      </TableCell>
      <TableCell key={`${debtor.id}type`}>{t(debtor.type || 'unknown')}</TableCell>
      <TableCell key={`${debtor.id}mail`}>{debtor.emailAddress}</TableCell>
      <TableCell key={`${debtor.id}paymentMethod`}>{t(debtor.paymentMethod || 'unknown')}</TableCell>
      <TableCell key={`${debtor.id}onboardingstate`}>
        <Chip
          key={`${debtor.id}onboardingState`}
          className={chipStyles[debtor.onboardingState || 'ONBOARDING_COMPLETED']}
          label={debtor.onboardingState === 'ONBOARDING_PENDING' ? t('onboarding pending') : t('onboarding completed')}
        />
      </TableCell>
      <TableCell key={`${debtor.id}actions`}>
        {actions && actions.length !== 0 && (
          <Badge badgeContent={0} color="error" overlap="circular">
            <IconButton onClick={handleClick}>
              <Avatar className={classes.avatar}>
                <Icon>more_horiz</Icon>
              </Avatar>
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuAnchor(undefined)}
              PaperProps={{
                className: classes.menu,
                style: {
                  //maxHeight: ITEM_HEIGHT * 4.5,
                  width: 'min-content',
                },
              }}
            >
              {actions}
            </Menu>
          </Badge>
        )}
      </TableCell>
    </TableRow>
  );
};

export default DebtorRow;
