import { CreditNote } from '../../../types/CreditNote/CreditNote';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CreditNoteService from '../../../services/CreditNoteService';
import { ReactNode } from 'react';

export default function CreditNoteDownloadAction({
  creditNote,
  closeMenu,
  label,
}: {
  creditNote: CreditNote;
  closeMenu: () => void;
  label?: ReactNode;
}) {
  const { t } = useTranslation('creditNotes');

  const downloadCreditNote = () => {
    CreditNoteService.downloadCreditNote(creditNote.id);
  };

  return (
    <div key={`${creditNote.id}downloadC`}>
      <MenuItem
        key={`${creditNote.id}download`}
        onClick={() => {
          downloadCreditNote();
          closeMenu();
        }}
      >
        {label ? label : t('download pdf')}
      </MenuItem>
    </div>
  );
}
