import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import CheckoutLinkService from '../../../services/CheckoutLinkService';
import { CheckoutLinks, NewCheckoutLink } from '../../../types/CheckoutLink/CheckoutLink';
import CheckoutLinkDialog from './CheckoutLinkDialog';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

//TODO unused states
export const CreateCheckoutLinkDialog = ({ isOpen, onCancel }: Props) => {
  const { t } = useTranslation('payments');
  const alerting = useContext(AlertContext);

  const _handleSubmit = (values: NewCheckoutLink, actions: any) => {
    CheckoutLinkService.createCheckoutLink(values).then((res) => {
      actions.setSubmitting(false);
      if (res.status === 201) {
        onCancel();
        alerting.sendAlert('success', t('paylink created successfully'));
      }
    });
  };

  return (
    <CheckoutLinkDialog
      initialValues={{
        productId: '',
        checkoutButtonLabel: CheckoutLinks.CheckoutButtonLabel.BUY,
        paymentTime: CheckoutLinks.PaymentTime.INSTANT,
        generateReceipt: false,
        allowQuantityChange: false,
        requireAddress: false,
        notifyCreditor: false,
        creditorEmailAddress: '',
        includeRedirectUrl: false,
        redirectUrl: '',
      }}
      isOpen={isOpen}
      onSubmit={_handleSubmit}
      onCancel={onCancel}
    />
  );
};
