import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import { Alert } from '@payactive/app-common';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import PageHeader from '../../../components/ui/blocks/PageHeader';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import BrandingPreview from './BrandingPreview';
import LogoAndColors from './LogoAndColors';
import CompanyAccountService from '../../../services/CompanyAccountService';
import { CompanySettings } from '../../../types/Company/CompanySettings';
import { CompanyAccount } from '../../../types/Company/Company';
import UserService from '../../../services/UserService';
import { User } from '../../../types/User/User';

const initialValuesDefault: CompanySettings.Branding = {
  brandColor: '#262626',
  brandTextColor: '#fefefe',
};

const BrandingPage = () => {
  const alerting = useContext(AlertContext);

  const schema = Yup.object().shape({
    brandColor: Yup.string().required(),
    brandTextColor: Yup.string().required(),
  });

  const { t } = useTranslation(['branding', 'global', 'company']);

  const [initialValues, setInitialValues] = useState<CompanySettings.Branding>();
  const [company, setCompany] = useState<CompanyAccount>();
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    Promise.all([CompanyAccountService.getCompany(), UserService.getUser()]).then(([company, user]) => {
      if (company && user) {
        setCompany(company);
        if (company.brandingSettings) {
          setInitialValues(company.brandingSettings);
        } else {
          setInitialValues(initialValuesDefault);
        }
        setUser(user);
      } else {
        setError(true);
      }
    });
  }, []);

  const _handleSubmit = async (values: CompanySettings.Branding, { setSubmitting, resetForm }: any) => {
    try {
      await CompanyAccountService.updateBrandingSettings(values);
      alerting.sendAlert('success', t('branding updated'));
      resetForm({ values: values });
    } catch (e) {
      alerting.sendAlert('error', t('branding updated failed'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader title={t('Branding')} subtitle={t('company:settings')} />
      </Grid>
      {error && <Alert severity="error">{t('global:unexpected error')}</Alert>}
      {initialValues ? (
        <Formik initialValues={initialValues} onSubmit={_handleSubmit} validationSchema={schema}>
          {({ values }) => (
            <Grid container>
              <Grid item xs={4}>
                <LogoAndColors />
              </Grid>
              <Grid item xs={8}>
                {company && (
                  <BrandingPreview
                    company_name={company.name}
                    logoUrl={company.logoUrl}
                    company_street={company.address?.street}
                    company_town={company.address?.city}
                    company_zip={company.address?.zipCode}
                    debitor_name={user?.firstName}
                    creditor_brand_color={values.brandColor}
                    creditor_text_color={values.brandTextColor}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Formik>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default BrandingPage;
