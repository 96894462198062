import React, { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { Credit } from '../../../types/Credit/Credit';
import CreditService from '../../../services/CreditService';
import { CreditEvent } from '../../../types/Credit/CreditEvent';
import { useTranslation } from 'react-i18next';
import CreditEventsDialog from '../dialogs/CreditEventsDialog';

export default function CreditEventAction({
  credit,
  setMenuAnchor,
}: {
  credit: Credit;
  setMenuAnchor: (value: ((prevState: Element | null) => Element | null) | Element | null) => void;
}) {
  const { t } = useTranslation('credits');
  const [statusDialog, setStatusDialog] = useState(false);
  const [creditEvents, setCreditEvents] = useState<CreditEvent[]>([] as CreditEvent[]);

  return (
    <div key={credit.id + 'eventsC'}>
      <MenuItem
        key={credit.id + 'events'}
        onClick={() => {
          setMenuAnchor(null);
          CreditService.getCreditEvents(credit.id).then((events) => {
            setCreditEvents(events);
            setStatusDialog(true);
          });
        }}
      >
        {t('events')}
      </MenuItem>
      <CreditEventsDialog credit={credit} events={creditEvents} open={statusDialog} onCancel={() => setStatusDialog(false)} />
    </div>
  );
}
