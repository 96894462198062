import { useTranslation } from 'react-i18next';

import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import TableHeader from '../../components/table/TableHeader';

import TablePagination from '../../components/table/Pagination';
import { Skeleton } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useContext } from 'react';
import { SEPAMandateListDataContext } from './SEPAMandateListDataContext';
import SEPAMandateRow from './SEPAMandateRow';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: '200px',
  },
  main: {
    padding: theme.spacing(1),
  },
}));

const SEPAMandateTable = ({ showPagination }: { showPagination: boolean }) => {
  const { t } = useTranslation('sepa');

  const classes = useStyles();

  const context = useContext(SEPAMandateListDataContext);

  const columns = [
    {
      label: t('mandate reference'),
      id: 'Mandatesreferenz',
    },
    {
      label: t('IBAN'),
      id: 'IBAN',
    },
    {
      label: t('BIC'),
      id: 'BIC',
    },
    {
      label: t('status'),
      id: 'state',
    },
    {
      label: t('signature date'),
      id: 'signatureDate',
    },
  ];

  const initalLoad = !context.page && context.loading;
  const emptyResultList = !!context.page && context.sepaMandates.length === 0;

  return (
    <>
      {emptyResultList ? (
        <>
          <Grid item xs={12} className={classes.main}>
            <MuiAlert elevation={6} variant="filled" severity="warning">
              {t('no sepa mandates found')}
            </MuiAlert>
          </Grid>
        </>
      ) : (
        <>
          <TableContainer style={{ flex: '1 1 auto' }}>
            <Table>
              <TableHeader columns={columns} actions={true}></TableHeader>
              <TableBody>
                <>
                  {initalLoad && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1}>
                        <Skeleton className={classes.skeleton} />
                      </TableCell>
                    </TableRow>
                  )}
                  {context.sepaMandates?.map((sepaMandate, index) => (
                    <SEPAMandateRow sepaMandate={sepaMandate} index={index} key={sepaMandate.id} />
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          {showPagination && <TablePagination transformPageSizeLabel={(pageSize) => t('pageSize', { pageSize: pageSize }) as string} />}
        </>
      )}
    </>
  );
};

export default SEPAMandateTable;
