import React, { createContext } from 'react';

export const PaginationContext = createContext<{
  pageSizeOptions: number[];
  currentPage: number;
  pageSize: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  changePageSize: (pageSize: number) => void;
}>({
  pageSizeOptions: [5, 10, 30],
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  setCurrentPage: () => {},
  changePageSize: () => {},
});

type PaginationProviderProps = {
  pageSizeOptions: number[];
  currentPage: number;
  pageSize: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  changePageSize: (pageSize: number) => void;
  children: any;
};

const PaginationProvider = ({
  pageSizeOptions,
  currentPage,
  totalPages,
  pageSize,
  setCurrentPage,
  changePageSize,
  children,
}: PaginationProviderProps) => {
  return (
    <PaginationContext.Provider
      value={{
        pageSizeOptions: pageSizeOptions,
        currentPage: currentPage + 1,
        pageSize: pageSize,
        totalPages: totalPages,
        changePageSize: changePageSize,
        setCurrentPage: setCurrentPage,
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export default PaginationProvider;
