import { AxiosInstance, AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import { NewSubscription, Subscription } from '../types/Subscription/Subscription';
import { Page, PageMetadata } from '../types/Page';
import { Filter, prepareFilterParameters } from '../types/Filter';

export default class SubscriptionService extends ApiClient {
  private static get subscriptionClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }

  public static async getSubscriptions(
    size: number,
    page: number,
    sort: string,
    filters: Filter,
  ): Promise<{ results: Subscription[]; page: PageMetadata }> {
    let params = {
      size: size,
      page: page,
      sort: 'createdDateTime,' + sort,
      ...prepareFilterParameters(filters),
    };

    const response = await this.subscriptionClient.get<Page<Subscription, 'subscriptions'>>(this.apiDomain + '/subscriptions', { params });

    if (this.countListener) {
      this.countListener(response.data.page);
    }
    return { results: response.data._embedded?.subscriptions || [], page: response.data.page };
  }

  public static async createSubscription(payload: NewSubscription): Promise<Subscription> {
    const response = await this.subscriptionClient.post<NewSubscription, AxiosResponse<Subscription>>(this.apiDomain + '/subscriptions');
    return response.data;
  }

  public static async getSubscription(subscriptionId: string): Promise<Subscription> {
    const response = await this.subscriptionClient.get<Subscription>(this.apiDomain + '/subscriptions/' + subscriptionId);
    return response.data;
  }
  public static async cancelSubscription(subscriptionId: string): Promise<boolean> {
    const response = await this.subscriptionClient.patch(this.apiDomain + '/subscriptions/' + subscriptionId + '/_cancel');
    return response.status === 200;
  }
}
