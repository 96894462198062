import React, { useContext, useState } from 'react';
import DebtorListDataContextProvider, { DebtorListDataContext } from './DebtorListDataContextProvider';
import { Button, Grid } from '@material-ui/core';
import PageHeader from '../../components/ui/blocks/PageHeader';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useTranslation } from 'react-i18next';
import DebtorDialog from './dialogs/DebtorDialog';
import qs from 'qs';
import { AlertContext } from '../../components/contexts/WithAlertContext';
import TextFilter from '../../components/filter/TextFilter';
import DebtorsTable from './table/DebtorsTable';

const DebtorsView = () => {
  const { t } = useTranslation('debitors');

  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [showNewDebtorDialog, setShowDebtorDialog] = useState(params.action === 'new');
  const alerting = useContext(AlertContext);

  return (
    <DebtorListDataContextProvider pageSizeOptions={[5, 10, 30]}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <PageHeader title={t('Debitors')} />
        <Button variant="contained" color="primary" startIcon={<AddBoxIcon />} onClick={() => setShowDebtorDialog(true)}>
          {t('Create new debitor')}
        </Button>
      </Grid>
      <Grid>
        <DebtorListDataContext.Consumer>
          {({ filters }) => (
            <>
              {filters && (
                <Grid container spacing={4} alignItems={'flex-start'}>
                  <Grid item xs={2}>
                    <TextFilter id="instalmentPlanSearch" value={filters.searchFilter} setValue={filters.setSearchFilter}></TextFilter>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </DebtorListDataContext.Consumer>
      </Grid>

      <DebtorsTable showPagination={true} />

      <DebtorListDataContext.Consumer>
        {({ reload }) => (
          <>
            {showNewDebtorDialog && (
              <DebtorDialog
                isOpen={showNewDebtorDialog}
                onCreate={(action: string) => {
                  switch (action) {
                    case 'created':
                      alerting.sendAlert('success', t('new debtor created'));
                      break;
                    case 'invited':
                      alerting.sendAlert('success', t('new debtor invited'));
                      break;
                    default:
                      break;
                  }
                  reload();
                  setShowDebtorDialog(false);
                }}
                onCancel={() => setShowDebtorDialog(false)}
              />
            )}
          </>
        )}
      </DebtorListDataContext.Consumer>
    </DebtorListDataContextProvider>
  );
};

export default DebtorsView;
