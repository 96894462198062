import { Button } from '@payactive/app-common';
import { Form, Formik } from 'formik';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../../../components/contexts/WithAlertContext';
import AddressInformation from './AddressInformation';
import ContactInformation from './ContactInformation';
import TaxInformation from './TaxInformation';
import CompanyInformation from './CompanyInformation';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { createSchemas, initialValues } from './masterdataSchema';
import TermsAndConditions from './TermsAndConditions';
import { CompanyAccount, CompanyAccountPatch, toCompanyAccountPatch } from '../../../../types/Company/Company';
import CompanyAccountService from '../../../../services/CompanyAccountService';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  paperDesign: {
    padding: theme.spacing(2),
  },
  paperLayout: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

export default function MasterData(): ReactElement {
  const classes = useStyles();

  const { t } = useTranslation('company');
  const { t: tValidation } = useTranslation('validations');

  const schema = createSchemas(tValidation, t);
  const alerting = useContext(AlertContext);

  const [companyInfo, setCompanyInfo] = useState<CompanyAccount>();

  useEffect(() => {
    CompanyAccountService.getCompany().then((company) => {
      setCompanyInfo(company);
    });
  }, []);

  const _handleSubmit = (values: CompanyAccountPatch, { setSubmitting, resetForm }: any) => {
    CompanyAccountService.updateCompany(values).then((res) => {
      if (res) {
        setSubmitting(false);
        alerting.sendAlert('success', t('masterdata updated'));
      } else {
        alerting.sendAlert('error', t('settings update error'));
      }
      resetForm({ values: values });
    });
  };

  return (
    <>
      {!companyInfo ? (
        <Skeleton style={{ height: '200px' }} />
      ) : (
        <Formik initialValues={initialValues(toCompanyAccountPatch(companyInfo))} onSubmit={_handleSubmit} validationSchema={schema}>
          {({ values, isSubmitting, dirty }) => (
            <Form autoComplete="on">
              <Grid container direction={'row'} alignItems={'flex-start'} spacing={2}>
                <Grid container item xs={6} spacing={2}>
                  <Grid item xs={12}>
                    <Paper className={classes.paperDesign}>
                      <CompanyInformation values={values} />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paperDesign}>
                      <AddressInformation />
                    </Paper>
                  </Grid>
                </Grid>

                <Grid container item xs={6} spacing={2}>
                  <Grid item xs={12}>
                    <Paper className={classes.paperDesign}>
                      <ContactInformation />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paperDesign}>
                      <TaxInformation />
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper className={classes.paperDesign}>
                      <TermsAndConditions />
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flexStart',
                  borderBottom: '1px solid #D3D3D3',
                  paddingBottom: '1%',
                }}
              >
                <Button type="submit" loading={isSubmitting} disabled={!dirty || isSubmitting} name="submit" label={t('global:save')} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
