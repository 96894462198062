import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

import moment from 'moment';
import { Checkout } from '../../../types/Checkout/Checkout';
import CheckoutService from '../../../services/CheckoutService';
import PaginationProvider from '../../../components/table/PaginationContext';
import { StateFilter } from '../../../components/filter/GenericTableFilters';
import { PageMetadata } from '../../../types/Page';
import { Filter } from '../../../types/Filter';

export const CheckoutListDataContext = createContext<{
  checkouts: Checkout[];
  filters?: {
    productIds: string[];
    setProductIds: Dispatch<SetStateAction<string[]>>;
    stateFilter: StateFilter[];
    setStateFilter: Dispatch<SetStateAction<StateFilter[]>>;
    dateStartFilter: string;
    setDateStartFilter: Dispatch<SetStateAction<string>>;
    dateEndFilter: string;
    setDateEndFilter: Dispatch<SetStateAction<string>>;
    searchFilter: string;
    setSearchFilter: Dispatch<SetStateAction<string>>;
    used: boolean;
  };
  page?: PageMetadata;
  reload: () => void;
  loading: boolean;
  isPrefilteredForDebtor: boolean;
  currentPage: { set: (page: number) => void; get: number };
  pageSize: { set: (size: number) => void; get: number };
}>({
  checkouts: [] as Checkout[],
  filters: undefined,
  page: {} as PageMetadata,
  reload: () => {},
  loading: true,
  isPrefilteredForDebtor: false,
  currentPage: {
    set: (page) => {},
    get: 0,
  },
  pageSize: {
    set: (size) => {},
    get: 0,
  },
});

type CheckoutListDataContextProviderProps = {
  pageSizeOptions: number[];
  children: ReactNode;
  debtorId?: string;
  hasPaginationProvider?: boolean;
};

const CheckoutListDataContextProvider = ({
  pageSizeOptions,
  debtorId,
  children,
  hasPaginationProvider = true,
}: CheckoutListDataContextProviderProps) => {
  const [checkoutList, setCheckoutList] = useState<Checkout[]>([] as Checkout[]);
  const [page, setPage] = useState<PageMetadata>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [productIds, setProductIds] = useState<string[]>([]);
  const [stateFilter, setStateFilter] = useState<StateFilter[]>([] as StateFilter[]);
  const [dateStartFilter, setDateStartFilter] = useState<string>('');
  const [dateEndFilter, setDateEndFilter] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [filtersUsed, setFiltersUsed] = useState(false);

  const isInitialized = React.useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (isInitialized.current) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIds, stateFilter, dateStartFilter, dateEndFilter, searchFilter, pageSize]);

  useEffect(() => {
    reload();
    isInitialized.current = true;
    return () => {
      isInitialized.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reload = () => {
    setLoading(true);
    let filter: Filter = {
      debtorRef: debtorId,
    };
    if (!!stateFilter) {
      filter.states = Array.from(stateFilter.values()).join(',');
    }
    if (!!productIds) {
      filter.productIds = Array.from(productIds.values()).join(',');
    }

    filter.search = searchFilter;
    filter.startDate = dateStartFilter && moment(dateStartFilter).format('YYYY-MM-DDT23:59:59.999') + 'Z';
    filter.endDate = dateEndFilter && moment(dateEndFilter).format('YYYY-MM-DDT23:59:59.999') + 'Z';

    setFiltersUsed(
      (!!productIds && productIds.length !== 0) ||
        (!!stateFilter && stateFilter.length !== 0) ||
        !!dateStartFilter ||
        !!dateEndFilter ||
        !!searchFilter,
    );

    CheckoutService.getCheckouts(pageSize, currentPage || 0, 'desc', filter).then(({ results, page }) => {
      setCheckoutList(results);
      setPage(page);
      setLoading(false);
    });
  };

  return (
    <CheckoutListDataContext.Provider
      value={{
        checkouts: checkoutList,
        filters: {
          productIds: productIds,
          setProductIds: setProductIds,
          stateFilter: stateFilter,
          setStateFilter: setStateFilter,
          dateStartFilter: dateStartFilter,
          setDateStartFilter: setDateStartFilter,
          dateEndFilter: dateEndFilter,
          setDateEndFilter: setDateEndFilter,
          searchFilter: searchFilter,
          setSearchFilter: setSearchFilter,
          used: filtersUsed,
        },
        page: page,
        reload: reload,
        loading: loading,
        isPrefilteredForDebtor: !!debtorId,
        currentPage: { set: setCurrentPage, get: currentPage },
        pageSize: { set: setPageSize, get: pageSize },
      }}
    >
      {hasPaginationProvider ? (
        <PaginationProvider
          pageSizeOptions={pageSizeOptions}
          currentPage={currentPage || 0}
          totalPages={page?.totalPages || 0}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          changePageSize={setPageSize}
        >
          {children}
        </PaginationProvider>
      ) : (
        children
      )}
    </CheckoutListDataContext.Provider>
  );
};

export default CheckoutListDataContextProvider;
