import * as Yup from 'yup';
import registrationFormModel from './registrationFormModel';

const {
  formField: { firstName, lastName, emailAddress, password, passwordConfirmation },
} = registrationFormModel;

export default Yup.object().shape({
  [emailAddress.name]: Yup.string().email().required(`${emailAddress.requiredErrorMsg}`),
  [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
  [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
  [password.name]: Yup.string().required(`${password.requiredErrorMsg}`),
  [passwordConfirmation.name]: Yup.string()
    .oneOf([Yup.ref(password.name), null], 'Passwords must match')
    .required('Password confirmation is required'),
});
