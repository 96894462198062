import React, { Fragment } from 'react';
import './tick.css';

export default function TickSuccess(props) {
  return (
    <Fragment>
      <link rel="stylesheet" href="/assets/css/tick.css" />

      <div className="animation-ctn">
        <div className="icon icon--order-success svg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" height="40">
            <g fill="none" stroke="#1ba39d" stroke-width="2">
              <circle cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashoffset: '960px;' }}></circle>
              <circle
                id="colored"
                fill="#1ba39d"
                cx="77"
                cy="77"
                r="72"
                style={{ strokeDasharray: '480px, 480px', strokeDashoffset: '960px;' }}
              ></circle>
              <polyline
                className="st0"
                stroke="#fff"
                stroke-width="10"
                points="43.5,77.8 63.7,97.9 112.2,49.4 "
                style={{ strokeDasharray: '100px, 100px', strokeDashoffset: '200px;' }}
              />
            </g>
          </svg>
        </div>
      </div>
    </Fragment>
  );
}
