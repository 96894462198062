import { Debtor } from '../Debtor/Debtor';
import { Links } from '../Page';
import { Metadata } from '../Metadata';

export interface Payment extends NewPayment {
  id: string;
  accountId: string;
  amount: number;
  createdDateTimeUTC: Date;
  debitor: Debtor;
  debitorRef: string;
  e2eReference?: string;
  invoice?: DocumentReference;
  donation?: DocumentReference;
  paymentMethod: Payments.PaymentMethod;
  paymentType: Payments.PaymentType;
  purpose: string;
  state: Payments.State;
  verificationId?: string;
  cancelStatus?: {
    cancelReasonInternal: string;
    cancelReasonDebitor: string;
    createdDateTimeUTC?: string;
  };
  metadata?: Metadata;
  _links?: Links;
  correlationId: string;
}

export type NewPayment = {
  paymentMethod?: Payments.PaymentMethod;
  bankAccountId?: string;
  paymentType: Payments.PaymentType;
  debitorId: string;
  amount: number;
  currency?: Payments.Currency;
  chargeDate?: string;
  purpose: string;
  attachmentBase64?: string;
  attachmentName?: string;
  metadata?: Metadata;
  paymentNotifications: Payments.Notifications;
};

export namespace Payments {
  //see io/payactive/modules/payments/model/PaymentStatus.java
  export enum State {
    CREATING = 'CREATING',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    VERIFIED = 'VERIFIED',
    ABORTED = 'ABORTED',
    MANUAL = 'MANUAL',
    ERROR = 'ERROR',
    CANCELLED = 'CANCELLED',
    REFUND_IN_PROGRESS = 'REFUND_IN_PROGRESS',
    REFUND_COMPLETED = 'REFUND_COMPLETED',
    CHARGED_BACK = 'CHARGED_BACK',
  }

  export enum PaymentMethod {
    PAPERLESS = 'PAPERLESS',
    ONLINE_PAYMENT = 'ONLINE_PAYMENT',
    DIRECT_DEBIT = 'DIRECT_DEBIT',
    CREDIT_TRANSFER = 'CREDIT_TRANSFER',
  }

  export enum PaymentType {
    PAYMENT_REQUEST = 'PAYMENT_REQUEST',
    INVOICE = 'INVOICE',
  }

  export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
    GBP = 'GBP',
    CHF = 'CHF',
  }

  export enum Notifications {
    EMAIL = 'EMAIL',
    NONE = 'NONE',
  }
}

export interface DocumentReference {
  id: string;
  name: string;
  documentPublicUrl: string;
  internalRef: string;
}
