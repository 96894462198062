import * as Yup from 'yup';

export type CancelReasons = { externalReason: string; internalReason: string };
// eslint-disable-next-line
export namespace CancelReasons {
  export const schema = Yup.object().shape({
    externalReason: Yup.string().min(5).max(60).required('Purpose is required'),
    internalReason: Yup.string().min(5).max(60),
  });
}
