import React, { ReactElement } from 'react';
import { DialogContentText, Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onCancel: () => void;
}

function CancelPaymentInvoiceDialog({ open, onCancel }: Props): ReactElement {
  const { t } = useTranslation('payments');
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t('invoice related to a payment')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>{t('invoice related to a payment subtitle')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:close')} />
      </DialogActions>
    </Dialog>
  );
}

export default CancelPaymentInvoiceDialog;
