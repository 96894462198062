import { Address } from '../Address';

export type SEPAMandate = NewSEPAMandate & {
  id: string;
  mandateReference: string;
  accountHolder?: string;
  iban: string;
  bic: string;
  state: SEPAMandates.State;
  signatureDate: Date;
  createdDateTimeUTC: Date;
  creditorIdentifier?: string;
  companyName?: string;
  creditorCompanyAddress?: Address;
};

export type NewSEPAMandate = {
  debitorId: string;
  iban: string;
  bic: string;
  signatureDate: Date;
};

export namespace SEPAMandates {
  export enum State {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
  }
}
