import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import LoadingButton from '../../../components/LoadingButton';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import SEPAMandateService from '../../../services/SEPAMandateService';

type DeactivateSEPAMandateDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  SEPAMandateId: string;
};

const DeactivateSEPAMandateDialog = ({ isOpen, onCancel, onSuccess, SEPAMandateId }: DeactivateSEPAMandateDialogProps) => {
  const { t } = useTranslation('payment-plans');
  const [loading, setLoading] = useState(false);
  const alerting = useContext(AlertContext);
  const deleteSEPAMandate = () => {
    setLoading(true);
    SEPAMandateService.deactivateSEPAMandate(SEPAMandateId)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        alerting.sendAlert('error', 'Something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t('deactivate SEPA mandate title')}</DialogTitle>
      <DialogContent dividers>
        <MuiAlert elevation={6} variant="filled" severity="warning">
          {t('deactivate sepa mandate')}
        </MuiAlert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('global:cancel')}
        </Button>
        <LoadingButton type="button" onClick={() => deleteSEPAMandate()} loading={loading}>
          {t('deactivate')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeactivateSEPAMandateDialog;
