import { FormikInputField } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  termsAndConditionsHeader: {
    textAlign: 'left',
    display: 'block',
    color: '#0198B5',
    marginBottom: theme.spacing(2),
    fontSize: '18px',
  },
}));

export default function TermsAndConditions(): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation('company');

  return (
    <>
      <Typography variant="h5" className={classes.termsAndConditionsHeader}>
        {t('terms and conditions')}
        <Tooltip
          title={
            <Typography variant="caption" color="inherit">
              {t('info legal documents')}
            </Typography>
          }
          placement="top"
        >
          <InfoIcon style={{ color: '#D3D3D3', marginBottom: '-6px', marginLeft: '10px' }} />
        </Tooltip>
      </Typography>
      <div>
        <FormikInputField
          className={classes.formControl}
          name="termsAndConditionsConfiguration.termsAndConditionsUrl"
          InputLabelProps={{ shrink: true }}
          placeholder="https://www.mycompany.de/agb"
          label={t('Link to AGBs')}
          fullWidth
        />

        <FormikInputField
          className={classes.formControl}
          name="termsAndConditionsConfiguration.privacyPolicyUrl"
          InputLabelProps={{ shrink: true }}
          placeholder="https://www.mycompany.de/datenschutz"
          label={t('Link to privacy policy')}
          fullWidth
        />
      </div>
    </>
  );
}
