import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { Form, Formik } from 'formik';
import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import InstalmentPlanForm from '../forms/InstalmentPlanForm';
import { InstalmentPlanFormSchema } from '../forms/schemas';
import InstalmentPlanService from '../../../services/InstalmentPlanService';
import InstalmentPlanPreviewDialog from './InstalmentPlanPreviewDialog';
import { InstalmentPlan } from '../../../types/InstalmentPlan/InstalmentPlan';
import { Debtor } from '../../../types/Debtor/Debtor';
import { InstalmentPlanPreview } from '../../../types/InstalmentPlan/InstalmentPlanPreview';

const initialValues = {
  bankAccountId: '',
  debitorId: '',
  debitor: '',
  purpose: '',
  totalNetAmount: 0,
  interval: '',
  startDate: '',
  intervalAmount: 0,
  intervalDay: 0,
  downPayment: undefined,
  metadata: [],
};

const initialInstalmentPlanPreviewResponse = {
  totalNetAmount: 0,
  instalmentRates: [],
};

const enum STEPS {
  CREATE = 'CREATE',
  PREVIEW = 'PREVIEW',
}

const CreateInstalmentPlanDialog = ({
  onSuccess,
  isOpen,
  onCancel,
  debtor,
}: {
  onSuccess: () => void;
  isOpen: boolean;
  onCancel: () => void;
  debtor?: Debtor;
}) => {
  const [instalmentPlanPreview, setInstalmentPlanPreview] = useState<InstalmentPlanPreview>(initialInstalmentPlanPreviewResponse);
  const [currentStep, setCurrentStep] = useState<STEPS>(STEPS.CREATE);
  const formRef = useRef(null);

  const { t: tValidations } = useTranslation('validations');

  const { t } = useTranslation(['global', 'instalmentPlans']);

  const schema = InstalmentPlanFormSchema(tValidations);

  const alert = useContext(AlertContext);

  function createInstalmentPlanPreview(values: any, { setSubmitting }: any) {
    const request = { ...values };
    request.metadata = values.metadata;
    InstalmentPlanService.createInstalmentPlanPreview(request)
      .then((response) => {
        setInstalmentPlanPreview(response);
        setSubmitting(false);
        setCurrentStep(STEPS.PREVIEW);
      })
      .catch(() => {
        setSubmitting(false);
        onCancel();
        alert.sendAlert('error', t('instalmentPlans:instalment plan creation error'));
      });
  }

  function startInstalmentPlan(id: string) {
    InstalmentPlanService.startInstalmentPlan(id)
      .then(() => {
        alert.sendAlert('success', t('instalmentPlans:instalment plan start success'));
        onSuccess();
      })
      .catch(() => {
        onCancel();
        alert.sendAlert('error', t('instalmentPlans:instalment start creation error'));
      });
  }

  function createInstalmentPlan(values: any, setSubmitting: any) {
    values.debtorId = values.debitorId;
    setSubmitting(true);

    const request = { ...values };
    request.metadata = values.metadata;
    InstalmentPlanService.createInstalmentPlanInDraft(request)
      .then(() => {
        setSubmitting(false);
        alert.sendAlert('success', t('instalmentPlans:instalment plan creation success'));
        onSuccess();
      })
      .catch(() => {
        onCancel();
        alert.sendAlert('error', t('instalmentPlans:instalment plan creation error'));
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function createAndStartInstalmentPlan(values: any, setSubmitting: any) {
    values.debtorId = values.debitorId;
    const request = { ...values };
    request.metadata = values.metadata;
    InstalmentPlanService.createInstalmentPlanInDraft(request)
      .then((response) => {
        setSubmitting(false);
        startInstalmentPlan(response.id);
      })
      .catch(() => {
        onCancel();
        alert.sendAlert('error', t('instalmentPlans:instalment plan start error'));
      });
  }

  function createInstalmentPlanObject(instalmentPlanPreview: InstalmentPlanPreview, values: any): InstalmentPlan {
    return {
      downPayment: values.downPayment,
      instalmentRates: instalmentPlanPreview.instalmentRates,
      interval: values.interval,
      intervalAmount: values.intervalAmount,
      intervalDay: values.intervalDay,
      purpose: values.purpose,
      totalNetAmount: instalmentPlanPreview.totalNetAmount,
    } as InstalmentPlan;
  }

  return (
    <Formik innerRef={formRef} initialValues={initialValues} validationSchema={schema} onSubmit={createInstalmentPlanPreview}>
      {({ setFieldValue, setSubmitting, isValid, values, isSubmitting }) => (
        <>
          {currentStep === STEPS.CREATE && (
            <Dialog
              maxWidth="sm"
              fullWidth
              open={isOpen}
              onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                  return false;
                }
                onCancel();
              }}
              aria-labelledby="alert-dialog-title"
            >
              <Form>
                <DialogTitle id="alert-dialog-title">{t('instalmentPlans:new payment plan')}</DialogTitle>
                <DialogContent dividers>
                  <InstalmentPlanForm values={values} setFieldValue={setFieldValue} debtor={debtor} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCancel} color="primary">
                    {t('global:cancel')}
                  </Button>
                  <LoadingButton loading={isSubmitting} type="submit" disabled={!isValid}>
                    {t('instalmentPlans:generate instalment plan')}
                  </LoadingButton>
                </DialogActions>
              </Form>
            </Dialog>
          )}
          {currentStep === STEPS.PREVIEW && (
            <InstalmentPlanPreviewDialog
              onCancel={() => onCancel()}
              onBack={() => {
                setCurrentStep(STEPS.CREATE);
              }}
              instalmentPlan={createInstalmentPlanObject(instalmentPlanPreview, values)}
              dialogActions={
                <>
                  <LoadingButton
                    loading={isSubmitting}
                    onClick={() => {
                      createInstalmentPlan(values, setSubmitting);
                    }}
                  >
                    {t('global:save')}
                  </LoadingButton>
                  <LoadingButton loading={isSubmitting} onClick={() => createAndStartInstalmentPlan(values, setSubmitting)}>
                    {t('instalmentPlans:start instalment plan')}
                  </LoadingButton>
                </>
              }
            />
          )}
        </>
      )}
    </Formik>
  );
};
export default CreateInstalmentPlanDialog;
