import React, { useState } from 'react';
import { Checkout } from '../../../types/Checkout/Checkout';
import { Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import { useTranslation } from 'react-i18next';
import StateWithIcon from '../../../components/StateWithIcon';
import { formatCurrency } from '../../../utils/PaymentUtil';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import clsx from 'clsx';
import useCardStyles from './CardStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getActionsForCheckout } from '../../checkouts/actions/CheckoutActionHelper';

const CheckoutCard = ({ checkout, active = false }: { checkout: Checkout; active: boolean }) => {
  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);
  const classes = useCardStyles();
  const { t } = useTranslation(['checkouts']);

  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const hasCustomFields = checkout.customFields.length !== 0;

  const actions = getActionsForCheckout(checkout, setMenuAnchor, t, true);
  const prodTitle = checkout.quantity !== 1 ? `${checkout.quantity} x ${checkout.productTitle}` : checkout.productTitle;
  return (
    <Card elevation={active ? 3 : 1}>
      <CardHeader
        className={classes.alignLeft}
        avatar={
          <Avatar className={classes.avatar}>
            <ShoppingCartIcon />
          </Avatar>
        }
        action={
          !!actions &&
          actions.length !== 0 && (
            <IconButton aria-label="settings" onClick={(e) => setMenuAnchor(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={'Checkout'}
        subheader={formatDateTime(checkout.createdDateTimeUTC)}
      />
      <CardContent>
        <Grid container spacing={2} direction={'column'} alignItems={'flex-start'} className={classes.alignLeft}>
          <Grid item container spacing={2} direction={'row'} justifyContent={'space-between'}>
            <Grid item>
              <Typography variant="caption" style={{ color: 'gray' }}>
                {t('product price')}
              </Typography>
              <Typography color="inherit" variant={'subtitle2'}>
                {formatCurrency(checkout.totalCheckoutAmount)}
              </Typography>
            </Grid>
            <Grid item>
              <StateWithIcon state={checkout.checkoutState} label={t(checkout.checkoutState)} />
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Typography variant="caption" style={{ color: 'gray' }}>
              {t('product title')}
            </Typography>
            <Typography color="inherit" variant={'subtitle2'}>
              {prodTitle}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Menu
        id="long-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuAnchor(undefined)}
        PaperProps={{
          style: {
            width: 'min-content',
          },
        }}
      >
        {actions}
      </Menu>
      <CardActions>
        {hasCustomFields && (
          <MenuItem className={classes.expand} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon
              className={clsx(classes.icon, {
                [classes.iconOpen]: expanded,
              })}
            />
          </MenuItem>
        )}
      </CardActions>
      <Collapse in={hasCustomFields && expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2} direction={'row'} alignItems={'flex-start'} className={classes.alignLeft}>
            <Grid item md={12}>
              <Typography color="inherit" variant={'subtitle2'}>
                {t('CustomFields')}
              </Typography>
            </Grid>
            {checkout.customFields.map((value) => {
              return (
                <Grid item md={6}>
                  <Typography variant="caption" style={{ color: 'gray' }}>
                    {value.fieldLabel}
                  </Typography>
                  <Typography color="inherit" variant={'subtitle2'}>
                    {value.fieldValue}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CheckoutCard;
