import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { StateFilter } from '../../components/filter/GenericTableFilters';
import { PageMetadata } from '../../types/Page';
import { Credit } from '../../types/Credit/Credit';
import { searchDelay } from '../../services/ApiClient';
import { Filter } from '../../types/Filter';
import moment from 'moment/moment';
import PaginationProvider from '../../components/table/PaginationContext';
import CreditService from '../../services/CreditService';

export const CreditsListDataContext = createContext<{
  credits: Credit[];
  filters?: {
    stateFilter: StateFilter[];
    setStateFilter: Dispatch<SetStateAction<StateFilter[]>>;
    dateStartFilter: string;
    setDateStartFilter: Dispatch<SetStateAction<string>>;
    dateEndFilter: string;
    setDateEndFilter: Dispatch<SetStateAction<string>>;
    searchFilter: string;
    setSearchFilter: Dispatch<SetStateAction<string>>;
    used: boolean;
  };
  page: PageMetadata | undefined;
  reload: () => void;
  loading: boolean;
  isPrefilteredForDebtor: boolean;
  currentPage: { set: (page: number) => void; get: number };
  pageSize: { set: (size: number) => void; get: number };
}>({
  credits: [] as Credit[],
  page: {} as PageMetadata,
  reload: () => {},
  loading: true,
  isPrefilteredForDebtor: false,
  currentPage: {
    set: (page) => {},
    get: 0,
  },
  pageSize: {
    set: (size) => {},
    get: 0,
  },
});

export function CreditListDataContextProvider({
  pageSizeOptions,
  debtorId,
  hasPaginationProvider,
  children,
}: {
  pageSizeOptions: number[];
  debtorId?: string;
  hasPaginationProvider?: boolean;
  children: ReactNode;
}) {
  const [creditList, setCreditList] = useState<Credit[]>([] as Credit[]);
  const [page, setPage] = useState<PageMetadata>();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [stateFilter, setStateFilter] = useState<StateFilter[]>([] as StateFilter[]);
  const [dateStartFilter, setDateStartFilter] = useState<string>('');
  const [dateEndFilter, setDateEndFilter] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [filtersUsed, setFiltersUsed] = useState(false);

  const isInitialized = React.useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    let delaySearch: any;
    if (isInitialized.current) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        delaySearch = setTimeout(() => {
          reload();
        }, searchDelay);
      }
    }
    return () => {
      delaySearch && clearTimeout(delaySearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateFilter, dateStartFilter, dateEndFilter, searchFilter, pageSize]);

  useEffect(() => {
    reload();
    isInitialized.current = true;
    return () => {
      isInitialized.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reload = () => {
    setLoading(true);
    const filter: Filter = {
      debtorRef: debtorId,
    };
    if (!!stateFilter) {
      filter.states = Array.from(stateFilter.values()).join(',');
    }
    filter.search = searchFilter;
    filter.startDate = dateStartFilter && moment(dateStartFilter).format('YYYY-MM-DDT23:59:59.999') + 'Z';
    filter.endDate = dateEndFilter && moment(dateEndFilter).format('YYYY-MM-DDT23:59:59.999') + 'Z';

    setFiltersUsed(stateFilter.length !== 0 || dateStartFilter !== '' || dateEndFilter !== '' || searchFilter !== '');

    CreditService.getCredits(pageSize, currentPage || 0, 'desc', filter).then(({ results, page }) => {
      setCreditList(results);
      setPage(page);
      setLoading(false);
    });
  };

  return (
    <CreditsListDataContext.Provider
      value={{
        credits: creditList,
        filters: {
          stateFilter: stateFilter,
          setStateFilter: setStateFilter,
          dateStartFilter: dateStartFilter,
          setDateStartFilter: setDateStartFilter,
          dateEndFilter: dateEndFilter,
          setDateEndFilter: setDateEndFilter,
          searchFilter: searchFilter,
          setSearchFilter: setSearchFilter,
          used: filtersUsed,
        },
        page: page,
        reload: reload,
        loading: loading,
        isPrefilteredForDebtor: !!debtorId,
        currentPage: { set: setCurrentPage, get: currentPage },
        pageSize: { set: setPageSize, get: pageSize },
      }}
    >
      {hasPaginationProvider ? (
        <PaginationProvider
          pageSizeOptions={pageSizeOptions}
          currentPage={currentPage || 0}
          totalPages={page?.totalPages || 0}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          changePageSize={setPageSize}
        >
          {children}
        </PaginationProvider>
      ) : (
        children
      )}
    </CreditsListDataContext.Provider>
  );
}
