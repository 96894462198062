import { CustomField } from './CustomField';
import { TaxRate } from '../Invoice/TaxRate';

export type Product = NewProduct & {
  id: string;
  createdDateTime: Date;
  name: string;
  description: string;
  price: number;
  productType: Products.Type;
  image: ProductImage;
  customFields: Array<CustomField>;
  taxRate: TaxRate;
  recurringBillingPeriod: Products.RecurringBillingPeriod;
  billingType: Products.BillingType;
};

export type NewProduct = {
  name: string;
  description?: string;
  price: number;
  defaultDonationAmount: number;
  donationOptions?: {
    isEnabled: boolean;
    option1: number;
    option2: number;
    option3: number;
  };
  taxRate?: TaxRate;
  productType?: Products.Type;
  recurringBillingPeriod?: Products.RecurringBillingPeriod;
  billingType?: Products.BillingType;
  customFields?: Array<CustomField>;
};

export namespace Products {
  export enum Type {
    DONATION = 'DONATION',
    PRODUCT = 'PRODUCT',
  }

  export enum RecurringBillingPeriod {
    NONE = 'NONE',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
  }

  export enum BillingType {
    RECURRING = 'RECURRING',
    ONE_TIME = 'ONE_TIME',
  }
}

export interface ProductImage {
  id: string;
  name: string;
  blobURL: string;
}
