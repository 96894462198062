import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../components/LoadingButton';
import InvoiceService from '../../../services/InvoiceService';

interface Props {
  open: boolean;
  invoicePdf: any;
  loading: boolean;
  onCancel: () => void;
  onFinalize: (invoiceId: string) => void;
  invoice: any;
}

export default function FinalizeCorrectionInvoiceDialog({ open, onFinalize, onCancel, loading, invoicePdf, invoice }: Props): ReactElement {
  const [correctionAccepted, setCorrectionAccepted] = useState(false);

  const handleChange = () => {
    setCorrectionAccepted(!correctionAccepted);
  };
  const { t } = useTranslation('debitors');
  if (!open) {
    return <></>;
  }
  const corrective = invoice.invoiceType === 'CORRECTIVE_INVOICE';

  const downloadCorrectionInvoice = () => {
    let fileName = `Korrektur_${invoicePdf.name}`;
    if (!corrective) {
      fileName = `Stornierung_${invoicePdf.name}`;
    }
    InvoiceService.downloadPDF(fileName, invoicePdf.content);
  };
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return false;
          }
          onCancel();
        }}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{corrective ? t('finalize and send correction') : t('finalize and send cancellation')}</DialogTitle>
        <Alert severity="warning">
          <Typography>{corrective ? t('correction subtitle') : t('delete subtitle')}</Typography>
          <DialogContentText id="alert-dialog-description">
            <Typography>{corrective ? t('correction content title') : t('delete content title')}</Typography>
          </DialogContentText>
        </Alert>
        <>
          <DialogContent>
            <p>
              <Button
                onClick={downloadCorrectionInvoice}
                variant="contained"
                color="primary"
                name={corrective ? t('preview correction') : t('preview cancellation')}
              >
                {corrective ? t('preview correction') : t('preview cancellation')}
              </Button>
            </p>
            <p>
              <FormControlLabel
                label={corrective ? t('correction invoice reviewed') : t('cancellation invoice reviewed')}
                control={<Checkbox checked={correctionAccepted} onChange={handleChange} />}
              />
            </p>
          </DialogContent>
          <DialogActions>
            <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:close')} />
            <LoadingButton disabled={!correctionAccepted} loading={loading} onClick={() => onFinalize(invoice.id)}>
              {corrective ? t('finalize and send correction') : t('finalize and send cancellation')}
            </LoadingButton>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
}
