import { AxiosInstance } from 'axios';
import ApiClient from './ApiClient';
import { Checkout } from '../types/Checkout/Checkout';
import { Page, PageMetadata } from '../types/Page';
import { Filter, prepareFilterParameters } from '../types/Filter';

export default class CheckoutService extends ApiClient {
  private static get checkoutClient(): AxiosInstance {
    return this.client;
  }

  private static countListener?: (page: PageMetadata) => void;
  public static setCountListener(callback: (page: PageMetadata) => void) {
    this.countListener = callback;
  }

  public static async getCheckouts(size: number, page: number, sort: string, filters: Filter): Promise<{ results: Checkout[]; page: PageMetadata }> {
    let params: { [k: string]: any } = {
      size: size,
      page: page,
      sort: 'createdDateTimeUTC,' + sort,
    };

    const response = await this.checkoutClient.get<Page<Checkout, 'checkouts'>>(this.apiDomain + '/checkouts', {
      params: { ...params, ...prepareFilterParameters(filters) },
    });
    return { results: response.data._embedded?.checkouts || [], page: response.data.page };
  }

  public static async getCheckoutForPayment(paymentID: string): Promise<Checkout> {
    let params = {
      paymentID: paymentID,
    };

    const response = await this.checkoutClient.get<Checkout>(this.apiDomain + '/checkouts/search', {
      params: params,
    });
    return response.data;
  }

  public static async getCheckout(checkoutId: string): Promise<Checkout> {
    return (await this.checkoutClient.get<Checkout>(this.apiDomain + '/checkouts/' + checkoutId)).data;
  }
}
