import ApiClient from './ApiClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { ApiKey } from '../types/ApiKey/ApiKey';
import { Page } from '../types/Page';

export default class ApiKeyService extends ApiClient {
  private static get apiKeyClient(): AxiosInstance {
    return this.client;
  }

  public static async getApiKeys(): Promise<ApiKey[]> {
    let res = await this.apiKeyClient.request<Page<ApiKey, 'apiKeysList'>>({
      method: 'GET',
      url: this.apiDomain + '/apikeys',
    });

    return res.data._embedded?.apiKeysList || [];
  }

  public static async createApiKey(apiKeyName: string): Promise<ApiKey> {
    let res = await this.apiKeyClient.request<{ name: string }, AxiosResponse<ApiKey>>({
      method: 'POST',
      url: this.apiDomain + '/apikeys',
      data: {
        name: apiKeyName,
      },
    });
    return res.data;
  }

  public static async deleteApiKey(prefix: string): Promise<boolean> {
    let res = await this.apiKeyClient.request({
      method: 'DELETE',
      url: this.apiDomain + '/apikeys/' + prefix,
    });
    return res.status === 200;
  }
}
