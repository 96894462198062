import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Form, Formik } from 'formik';
import LoadingButton from '../../../components/LoadingButton';
import DateFilter from '../../../components/filter/DateFilter';
import BankService from '../../../services/BankService';

interface Props {
  onClose: () => void;
  accountId: string;
}

type FormValues = {
  startDate: string;
  endDate: string;
};

const initialValues = {
  startDate: '',
  endDate: '',
};

export default function TransactionsExportDialog({ onClose, accountId }: Props): ReactElement {
  const { t } = useTranslation('company');

  const exportTransactions = async (values: FormValues) => {
    const res = await BankService.downloadBankAccountTransactions(accountId, values.startDate, values.endDate);
    if (res) {
      onClose();
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={exportTransactions}>
      {({ setFieldValue, values, isSubmitting }) => (
        <Dialog maxWidth="sm" fullWidth aria-labelledby="invoices-export-dialog" open={true} onClose={onClose}>
          <Form>
            <DialogTitle>{t('download bank transactions title')}</DialogTitle>
            <DialogContent dividers>
              <Typography>{t('download information')}</Typography>
              <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateFilter onChange={(date) => setFieldValue('startDate', date.toISOString())} label={t('start')} value={values.startDate} />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateFilter
                      startDate={values.startDate}
                      onChange={(date) => setFieldValue('endDate', date.toISOString())}
                      label={t('end')}
                      value={values.endDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button name="close" onClick={onClose} color="primary" variant="text" label={t('global:cancel')} />
              <LoadingButton loading={isSubmitting} type="submit" disabled={values.endDate === '' || values.startDate === ''}>
                {t('download transactions')}
              </LoadingButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
