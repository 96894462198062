import { DialogActions, makeStyles, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LockIcon from '@material-ui/icons/Lock';
import Alert from '@material-ui/lab/Alert';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import { STATES } from './WithLoading';
import { getEnv } from '../../../services/envs';
import './assets/xs2a.css';
import BankService from '../../../services/BankService';
import { InitBankConnection } from '../../../types/Bank/BankConnection';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

declare var xs2a: any;

const DialogTitle = ({ children, onClose, ...other }: { children: ReactNode; onClose: () => void }) => {
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
//TODO reduce complexity, see app-payment
export default function ConnectBankAccount({ open, onCancelled, onFinish }: { open: boolean; onCancelled: () => void; onFinish: () => void }) {
  const { t } = useTranslation('company');

  const [state, setState] = useState(STATES.LOADING);
  const xs2aRef = useRef<HTMLDivElement | null>(null);
  const [bankConnectionSession, setBankConnectionSession] = useState<InitBankConnection>({
    wizard_session_key: '',
    transaction: '',
  });
  const bankConnectionSessionRef = useRef<InitBankConnection>();
  bankConnectionSessionRef.current = bankConnectionSession;

  const [_open, setOpen] = useState(open);
  const [_loading, setLoading] = useState(true);

  const fintecForm = <div ref={xs2aRef} id="XS2A-Form" data-xs2a={bankConnectionSession.wizard_session_key} />;

  const onClick = () => {
    (xs2aRef.current?.getElementsByClassName('xs2a-submit')[0] as HTMLElement).click();
    setLoading(true);
  };

  useEffect(() => {
    scriptLoaded(BankService.initBankConnection());
  }, []);

  useEffect(() => {
    if (bankConnectionSession.wizard_session_key !== '') {
      setState(STATES.READY);
    }
  }, [bankConnectionSession]);

  useEffect(() => {
    if (state === STATES.READY) {
      xs2a.init();
    }
  }, [state]);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  function scriptLoaded(promise: Promise<InitBankConnection>) {
    promise.then((res) => {
      setBankConnectionSession({
        wizard_session_key: res.wizard_session_key,
        transaction: res.transaction,
      });
    });

    promise.catch(() => {
      setState(STATES.ERROR);
    });

    xs2a.finish(function () {
      let trx = bankConnectionSessionRef.current?.transaction || '';
      BankService.completeBankConnection(trx).then((res) => {
        onFinish();
      });
    });

    xs2a.abort(function () {});

    xs2a.error(function () {
      // clickTCIfInscope()
    });

    xs2a.render(function () {
      setLoading(false);
      // clickTCIfInscope()
    });

    xs2a.configure({
      'validate-login-credentials': false,
    });
  }
  return (
    <Dialog disableBackdropClick={true} open={_open}>
      <link rel="stylesheet" href="https://api.xs2a.com/xs2a.css" />
      <link rel="stylesheet" href="https://api.xs2a.com/xs2a-responsive.css" />
      <DialogTitle {...{ id: 'customized-dialogs-title' }} onClose={onCancelled}>
        {t('Connect your Bank Account')}
      </DialogTitle>
      <DialogContent dividers>
        {getEnv() !== 'prod' && <Alert severity="info">{t('sandbox info')}</Alert>}
        <Typography gutterBottom>{t('authenticate with your bank account')}</Typography>
        {fintecForm}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={onClick}
          loading={_loading || state !== STATES.READY}
          {...{
            startIcon: <LockIcon />,
          }}
        >
          {t('continue')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
