import { Metadata } from '../Metadata';

export interface CheckoutLink {
  id: string;
  payLinkUrl: string;
  productId: string;
  productName: string;
  price: number;
  paymentTime: CheckoutLinks.PaymentTime;
  checkoutButtonLabel: CheckoutLinks.CheckoutButtonLabel;
  generateReceipt: boolean;
  requireAddress: boolean;
  allowQuantityChange: boolean;
  notifyCreditor: boolean;
  includeRedirectUrl: boolean;
  creditorEmailAddress: string;
  createdDateTimeUTC: string;
  redirectUrl: string;
  active?: boolean;
  externalIdRef?: string;
  metadata?: Metadata;
}

export type NewCheckoutLink = {
  id?: string;
  productId: string;
  paymentTime?: CheckoutLinks.PaymentTime;
  checkoutButtonLabel?: CheckoutLinks.CheckoutButtonLabel;
  generateReceipt?: boolean;
  requireAddress?: boolean;
  notifyCreditor?: boolean;
  creditorEmailAddress?: string;
  includeRedirectUrl?: boolean;
  allowQuantityChange?: boolean;
  redirectUrl?: string;
  metadata?: Metadata;
};

export namespace CheckoutLinks {
  export enum PaymentTime {
    PAY_LATER = 'PAY_LATER',
    INSTANT = 'INSTANT',
  }

  export enum CheckoutButtonLabel {
    DONATE = 'DONATE',
    BUY = 'BUY',
    PAY = 'PAY',
    SUBSCRIBE = 'SUBSCRIBE',
  }
}
