import axios, { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import { Registration } from '../types/User/Registration';

export const authService = {
  login,
  logout,
  loggedIn: isLoggedIn,
  get accessToken() {
    return localStorage.getItem('access_token');
  },
  register,
  authoriseByResponse,
};

function isLoggedIn() {
  let expires = localStorage.getItem('expires');
  return localStorage.getItem('access_token') && expires && parseInt(expires) > new Date().getTime();
}

function login(emailAddress: string, password: string, success: () => void, error: () => void) {
  axios({
    method: 'POST',
    url: ApiClient.apiDomain + '/login',
    data: {
      emailAddress: emailAddress,
      password: password,
    },
  }).then(
    (res) => {
      authoriseByResponse(res);
      success();
    },
    (err) => {
      error();
    },
  );
}

function authoriseByResponse(res: AxiosResponse<any>) {
  localStorage.setItem('access_token', res.headers['authorization'].replace('Bearer ', ''));
  let expires = res.headers['expires'];
  localStorage.setItem('expires', String(parseFloat(expires) + new Date().getTime()));
}

function logout() {
  // remove user from local storage to log out user
  localStorage.removeItem('access_token');
  localStorage.removeItem('expires');
  ApiClient.clearCaches();
}

function register(formData: Registration, success: (res: AxiosResponse) => void, error: (err: any) => void) {
  axios({
    method: 'POST',
    url: ApiClient.apiDomain + '/registration',
    data: formData,
  }).then(
    (res) => {
      success(res);
    },
    (err) => {
      error(err);
    },
  );
}
