import ApiClient from './ApiClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { Page } from '../types/Page';
import { NewWebhook, Webhook } from '../types/Webhook/Webhook';

export default class WebhookService extends ApiClient {
  private static get webhookClient(): AxiosInstance {
    return this.client;
  }

  public static async getTypesOfWebhooks(): Promise<string[]> {
    let res = await this.webhookClient.request<string[]>({
      method: 'GET',
      url: this.apiDomain + '/webhook-event-types',
    });

    return res.data;
  }

  public static async getWebhooks(): Promise<Webhook[]> {
    let res = await this.webhookClient.request<Page<Webhook, 'webhooks'>>({
      method: 'GET',
      url: this.apiDomain + '/webhooks',
    });
    if (!!res.data._embedded) {
      return res.data._embedded?.webhooks;
    } else return [] as Webhook[];
  }

  public static async createWebhook(newWebhook: NewWebhook): Promise<Webhook> {
    return (
      await this.webhookClient.request<NewWebhook, AxiosResponse<Webhook>>({
        method: 'POST',
        url: this.apiDomain + '/webhooks',
        data: newWebhook,
      })
    ).data;
  }

  public static async deleteWebhook(id: string): Promise<boolean> {
    let res = await this.webhookClient.request({
      method: 'DELETE',
      url: this.apiDomain + '/webhooks/' + id,
    });

    return res.status === 200;
  }

  public static async updateWebhook(id: string, webhook: NewWebhook): Promise<Webhook> {
    // delete webhook.signingSecret;
    // delete webhook.id;
    // delete webhook.createdDateTimeUTC;

    return (
      await this.webhookClient.request<NewWebhook, AxiosResponse<Webhook>>({
        method: 'PATCH',
        url: this.apiDomain + '/webhooks/' + id,

        data: webhook,
      })
    ).data;
  }
}
