import { Credit } from '../../../types/Credit/Credit';
import CreditEventAction from './CreditEventAction';
import React, { ReactNode } from 'react';
import CreditDetailsAction from './CreditDetailsAction';

export type CreditActionType = {
  credit: Credit;
  setMenuAnchor: (value: ((prevState: Element | null) => Element | null) | Element | null) => void;
};

export function getCreditActions(
  credit: Credit,
  setMenuAnchor: (value: ((prevState: Element | null) => Element | null) | Element | null) => void,
  cardView = false,
) {
  const actions = [] as ReactNode[];

  if (!cardView) {
    actions.push(<CreditEventAction credit={credit} setMenuAnchor={setMenuAnchor} />);
    actions.push(<CreditDetailsAction credit={credit} setMenuAnchor={setMenuAnchor} />);
  }

  if (actions.length === 0) {
    return undefined;
  }

  return actions;
}
