export type Webhook = NewWebhook & {
  id: string;
  signingSecret?: string;
  createdDateTimeUTC?: string;
};

export type NewWebhook = {
  url: string;
  name: string;
  events: Array<string>;
  mode?: Webhooks.Mode;
};

export namespace Webhooks {
  export enum Mode {
    LIVE = 'LIVE',
    DISABLED = 'DISABLED',
  }
}
