import React, { useContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LoadingButton from '../../../components/LoadingButton';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import ProductService from '../../../services/ProductService';

type DeleteProductDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  productId: string;
};

const DeleteProductDialog = ({ isOpen, onCancel, onSuccess, productId }: DeleteProductDialogProps) => {
  const { t } = useTranslation('products');
  const alert = useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const deleteProduct = () => {
    setLoading(true);
    ProductService.deleteProduct(productId)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        alert.sendAlert('error', 'Something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t('delete product title')}</DialogTitle>
      <DialogContent dividers>
        <MuiAlert elevation={6} variant="filled" severity="warning">
          {t('delete product alert')}
        </MuiAlert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('global:cancel')}
        </Button>
        <LoadingButton type="button" onClick={() => deleteProduct()} loading={loading}>
          {t('global:delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProductDialog;
