import clsx from 'clsx';
import UserInfo from './UserInfo';
import { footerItems, mainItems, NavItems } from './NavItems';
import PayactiveHeaderLogo from '../../../components/PayactiveHeaderLogo';
import { Drawer, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: theme.spacing(32),
    [theme.breakpoints.up('xl')]: {
      width: theme.spacing(40),
    },
    backgroundImage: `linear-gradient(${theme.palette.secondary.main}, ${theme.palette.primary.main})`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  headerLogo: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
}));

export default function LeftNavbar() {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper),
      }}
      open={true}
      style={{ overflowX: 'hidden', maxHeight: '100vh' }}
    >
      <PayactiveHeaderLogo className={classes.headerLogo} />
      <UserInfo />
      <Grid container direction="row" alignContent="space-between" style={{ padding: 0, height: '100%' }} spacing={0}>
        <Grid item style={{ width: '100%' }}>
          <NavItems items={mainItems} />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <NavItems items={footerItems} />
        </Grid>
      </Grid>
    </Drawer>
  );
}
