import { Card, CardContent, CardHeader, Grid, IconButton, Link, Menu, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getActionsForDebtor } from '../../debtors/action/DebtorActionHelper';
import { Debtor, Debtors } from '../../../types/Debtor/Debtor';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import { Skeleton } from '@material-ui/lab';
import useCardStyles from './CardStyles';
import { getDebtorLabel } from '../../../utils/LabelHelper';
import { countries } from '../../../types/Country';
import i18n from 'i18next';

const DebtorCard = ({ debtor }: { debtor?: Debtor }) => {
  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);

  const classes = useCardStyles();
  const { t } = useTranslation(['debitors', 'company']);
  if (!debtor) {
    return <Skeleton />;
  }

  const actions = getActionsForDebtor(debtor, setMenuAnchor, t);

  const isPerson = debtor.type === Debtors.Type.PERSON;

  let titleProps: { title: string; subheader?: string };
  const fullName = getDebtorLabel(debtor) || `${debtor.emailAddress} (${t('debitor deleted')})`;
  if (!isPerson && debtor.companyName) {
    titleProps = {
      title: debtor.companyName,
      subheader: fullName,
    };
  } else {
    titleProps = {
      title: fullName,
    };
  }

  const noAddress =
    !debtor.address ||
    (!debtor.address.street && !debtor.address.houseNumber && !debtor.address.zipCode && !debtor.address.city && !debtor.address.country);

  let country;
  if (!noAddress) {
    const countryType = countries.find((country) => {
      return debtor?.address?.country === country.code;
    });

    country = debtor?.address?.country;
    if (countryType !== undefined) {
      country = countryType.labelEn;
      if (i18n.resolvedLanguage === 'de') {
        country = countryType.labelDe;
      }
    }
  }

  const suffixLine = debtor.address?.suffix ? (
    <>
      {debtor.address?.suffix}
      <br />
    </>
  ) : (
    ''
  );

  return (
    <Card elevation={1}>
      <CardHeader
        className={classes.alignLeft}
        avatar={<Avatar className={classes.avatar}>{isPerson ? <PersonIcon /> : <BusinessIcon />}</Avatar>}
        action={
          actions.length !== 0 && (
            <IconButton aria-label="settings" onClick={(e) => setMenuAnchor(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          )
        }
        {...titleProps}
      />
      <CardContent>
        <Grid container spacing={2} direction={'column'} alignItems={'flex-start'} className={classes.alignLeft}>
          <Grid item container spacing={2} direction={'row'} alignItems={'flex-start'} justifyContent={'flex-start'}>
            <Grid item md={4}>
              <Typography variant="caption" style={{ color: 'gray' }}>
                {t('company:address')}
              </Typography>
              {noAddress ? (
                <Typography color="inherit" variant={'subtitle2'}>
                  {t('no address')}
                </Typography>
              ) : (
                <Typography color="inherit" variant={'subtitle2'}>
                  {suffixLine}
                  {debtor.address?.line}
                  <br />
                  {debtor.address?.zipCode}&nbsp;{debtor.address?.city}
                  <br />
                  {country}
                </Typography>
              )}
            </Grid>
            <Grid item md={4}>
              <Typography variant="caption" style={{ color: 'gray' }}>
                {t('company:emailAddress')}
              </Typography>
              <Typography color="inherit" variant={'subtitle2'}>
                <Link href={'mailto:' + debtor.emailAddress}>{debtor.emailAddress}</Link>
              </Typography>
            </Grid>
            <Grid item container spacing={2} direction={'column'} md={4}>
              <Grid item md={4}>
                <Typography variant="caption" style={{ color: 'gray' }}>
                  {t('payment method')}
                </Typography>
                <Typography color="inherit" variant={'subtitle2'}>
                  {t(`${debtor.paymentMethod}`)}
                </Typography>
              </Grid>
              {!isPerson && (
                <Grid item md={4}>
                  <Typography variant="caption" style={{ color: 'gray' }}>
                    {t('vatId')}
                  </Typography>
                  <Typography color="inherit" variant={'subtitle2'}>
                    {t(`${debtor.vatId}`)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Menu
        id="long-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuAnchor(undefined)}
        PaperProps={{
          style: {
            //maxHeight: ITEM_HEIGHT * 4.5,
            width: 'min-content',
          },
        }}
      >
        {actions}
      </Menu>
    </Card>
  );
};

export default DebtorCard;
