import { Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Formik } from 'formik';

import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import CancelFields from './CancelFields';
import { CancelReasons } from '../../../types/CancelReasons';

export default function PaymentCancelDialog({
  open,
  onCancel,
  onDelete,
}: {
  open: boolean;
  onCancel: () => void;
  onDelete: (reasons: CancelReasons) => void;
}) {
  const { t } = useTranslation('payments');

  const initialValues = {
    internalReason: '',
    externalReason: '',
  };

  const _handleSubmit = (reasons: CancelReasons) => {
    onDelete(reasons);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={CancelReasons.schema} onSubmit={_handleSubmit}>
      {({ handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Dialog
          maxWidth="sm"
          fullWidth
          open={open}
          onClose={(event, reason) => {
            if (reason === 'backdropClick') {
              return false;
            }
            onCancel();
          }}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">{t('cancel transaction title')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography>{t('cancel transaction content')}</Typography>
              <CancelFields handleChange={handleChange} handleBlur={handleBlur} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="close" onClick={onCancel} color="primary" variant="text" label={t('global:close')} />
            <LoadingButton loading={isSubmitting} onClick={() => handleSubmit()}>
              {t('cancel')}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
