import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FormikInputField } from '@payactive/app-common';
import { useFormikContext } from 'formik';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikValues } from '../schemas';

interface Props {
  ebicsConnectionId: string;
}

export default function InitializingEbicsForm({ ebicsConnectionId }: Props): ReactElement {
  const { t } = useTranslation('sepa');

  const { status } = useFormikContext<FormikValues>();

  return (
    <>
      {status && <Alert severity="error">{t('ebics error')}</Alert>}
      <p>
        <Typography>{t('enter data from bank letter')}</Typography>
      </p>
      <p>
        <FormikInputField name="userId" label={t('userId')} fullWidth />
      </p>
      <p>
        <FormikInputField name="partnerId" label={t('partnerId')} fullWidth />
      </p>
    </>
  );
}
