import { AxiosInstance } from 'axios';
import ApiClient from './ApiClient';

export default class CorrelationService extends ApiClient {
  private static get correlationClient(): AxiosInstance {
    return this.client;
  }

  public static async getCorrelationItems(id: string) {
    var params = {
      expand: 'includeItems',
    };
    let res = await this.correlationClient.request<string>({
      method: 'GET',
      url: this.apiDomain + '/correlations/' + id,
      params: params,
    });
    return res.data;
  }
}
