import { Avatar, Badge, Icon, IconButton, makeStyles, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import DeleteProductDialog from '../dialogs/DeleteProductDialog';
import { Product, Products } from '../../../types/Product/Product';
import { ProductListDataContext } from '../ProductListDataContext';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import EditProductDialog from '../dialogs/EditProductDialog';
import noImage from '../../../assets/images/no-image-icon.png';

type ProductRowProps = {
  product: Product;
  index: number;
};

const useStyles = makeStyles((theme) => ({
  even: {
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    whiteSpace: 'nowrap',
  },
  odd: {
    border: 'none',
    backgroundColor: theme.palette.background.default,
    whiteSpace: 'nowrap',
  },
  select: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  icon: {
    color: '#FFFFFF',
  },
  menu: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  description: {
    maxWidth: '20vw',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '4.2em',
    WebkitLineClamp: 3,
    boxOrient: 'vertical',
    display: '-webkit-box',
  },
}));

const ITEM_HEIGHT = 48;

const ProductRow = ({ product, index }: ProductRowProps) => {
  const { t } = useTranslation('products');

  const classes = useStyles();

  const [productToDelete, setProductToDelete] = useState(false);
  const [showEditProductDialog, setShowEditProductDialog] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<Element>();
  const menuOpen = Boolean(menuAnchor);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleShowDeleteProductDialog = () => {
    setProductToDelete(true);
  };

  const handleShowEditProductDialog = () => {
    setShowEditProductDialog(true);
  };

  const actions: Action[] = [
    { id: 'edit', label: t('edit'), callback: handleShowEditProductDialog },
    { id: 'delete', label: t('delete'), callback: handleShowDeleteProductDialog },
  ];

  const filterActions = (action: Action) => {
    switch (action.id) {
      case 'delete':
      case 'edit':
        return true;
      default:
        return false;
    }
  };
  return (
    <TableRow key={index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <TableCell key={product.id + 'image'}>
        <img width={40} height={40} src={product?.image ? product?.image?.blobURL : noImage} alt={'product'} />{' '}
      </TableCell>
      <TableCell key={product.id + 'name'}>{product.name}</TableCell>
      <TableCell key={product.id + 'productDescription'}>
        <div className={classes.description}>{t(product.description)}</div>
      </TableCell>
      <TableCell key={product.id + 'productType'}>{t(product.productType)}</TableCell>
      <TableCell key={product.id + 'price'}>
        {`${formatCurrency(product.price)}`}
        {product.productType === Products.Type.DONATION ? '' : ` (${product.taxRate.rate} %)`}
      </TableCell>
      <TableCell key={product.id + 'createdDateTime'}>{formatDateTime(product.createdDateTime)}</TableCell>
      {!!actions && (
        <TableCell align="left">
          <Badge badgeContent={0} color="error" overlap="circular">
            <IconButton onClick={handleClick}>
              <Avatar className={classes.avatar}>
                <Icon>more_horiz</Icon>
              </Avatar>
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuAnchor(undefined)}
              PaperProps={{
                className: classes.menu,
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: 'min-content',
                },
              }}
            >
              {actions.filter(filterActions).map((action) => (
                <MenuItem
                  key={action.id}
                  onClick={() => {
                    setMenuAnchor(undefined);
                    action.callback();
                  }}
                >
                  {action.label}
                </MenuItem>
              ))}
            </Menu>
          </Badge>
        </TableCell>
      )}
      <ProductListDataContext.Consumer>
        {({ reload }) => (
          <>
            {productToDelete && (
              <DeleteProductDialog
                isOpen={true}
                onCancel={() => setProductToDelete(false)}
                onSuccess={() => {
                  setProductToDelete(false);
                  reload();
                }}
                productId={product.id}
              />
            )}
            {showEditProductDialog && (
              <EditProductDialog
                comeBack={() => setShowEditProductDialog(false)}
                redirectToOrigin={() => {
                  setShowEditProductDialog(false);
                  reload();
                }}
                isOpen={true}
                onCancel={() => setShowEditProductDialog(false)}
                product={product}
                variant={'products'}
              />
            )}
          </>
        )}
      </ProductListDataContext.Consumer>
    </TableRow>
  );
};

type Action = {
  id: string;
  label: string;
  callback: () => void;
};

export default ProductRow;
