import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../components/ui/blocks/DataTable';

export default function TeamTable({ team, onAction, user }) {
  const { t } = useTranslation('company', 'global');

  const pendingOrActiveMember = () => {
    const columns = [
      {
        label: t('emailAddress'),
        id: 'emailAddress',
      },
      {
        label: t('role'),
        id: 'role',
      },
    ];
    if (team.some((item) => item.firstName !== null)) {
      columns.unshift({
        label: t('name'),
        id: 'name',
      });
    }
    return columns;
  };

  return (
    <>
      <DataTable
        columns={pendingOrActiveMember()}
        rows={team}
        showPagination={false}
        cellTransformations={{
          name: (name, row) => (row.firstName ? `${row.firstName} ${row.lastName}` : ''),
        }}
        actions={onAction && [{ id: 'delete', label: t('global:delete') }]}
        showActions={(row) => row.emailAddress !== user.emailAddress}
        onAction={onAction}
      />
    </>
  );
}
