import ApiClient from './ApiClient';
import { PasswordReset } from '../types/User/PasswordReset';

export default class PasswordService extends ApiClient {
  public static async requestNewPassword(email: string) {
    let res = await this.noAuthClient.request({
      method: 'POST',
      url: this.apiDomain + '/password/_reset',
      data: {
        emailAddress: email,
      },
    });

    return res.status === 201;
  }

  public static async resetPassword(values: PasswordReset) {
    let res = await this.noAuthClient.request<PasswordReset>({
      method: 'PUT',
      url: this.apiDomain + '/password',
      data: values,
    });
    return res.status === 201;
  }
}
