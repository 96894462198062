import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Debtor } from '../../../types/Debtor/Debtor';
import { getDebtorLabel } from '../../../utils/LabelHelper';

interface DeleteDebtorDialogProps {
  open: boolean;
  debtor: Debtor;
  onClose: () => void;
  onDelete: (debtor: Debtor) => void;
}

export default function DeleteDebtorDialog({ open, debtor, onClose, onDelete }: DeleteDebtorDialogProps): ReactElement {
  const { t } = useTranslation('debitors');

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
    >
      <DialogTitle id="alert-dialog-title">{t('delete debitor')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{getDebtorLabel(debtor)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" name="cancel" label={t('global:no')} disabled={false} loading={false} onClick={onClose} />
        <Button name="cancel" label={t('global:yes')} disabled={false} loading={false} onClick={() => onDelete(debtor)} />
      </DialogActions>
    </Dialog>
  );
}
