import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@material-ui/core';
import InstalmentPlanPreview from '../InstalmentPlanPreview';
import InstalmentRatePlanTable from '../InstalmentRatePlanTable';
import { useTranslation } from 'react-i18next';
import { InstalmentPlan } from '../../../types/InstalmentPlan/InstalmentPlan';

type InstalmentPlanDetailsProps = {
  onClose: () => void;
  instalmentPlan: InstalmentPlan;
};
const InstalmentPlanDetailsDialog = ({ onClose, instalmentPlan }: InstalmentPlanDetailsProps) => {
  const { t } = useTranslation(['global', 'instalmentPlans']);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={true}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t('instalmentPlans:payment plan')}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction={'column'} spacing={3}>
          <InstalmentPlanPreview instalmentPlan={instalmentPlan} />
          <Divider />
          <InstalmentRatePlanTable instalmentRates={instalmentPlan.instalmentRates} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('global:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InstalmentPlanDetailsDialog;
