import { TFunction } from 'i18next';
import * as Yup from 'yup';

const FILE_SIZE = 1024 * 1024 * 2;
const SUPPORTED_FORMATS = ['application/pdf'];
export const createSchemas = (t: TFunction) => {
  return Yup.object().shape({
    debitorId: Yup.string().required(t('required', { field: t('debitor') })),
    amount: Yup.number()
      .required(t('required', { field: t('amount') }))
      .positive(t('amount positive')),
    purpose: Yup.string()
      .min(5)
      .max(120)
      .required(t('required', { field: t('purpose') })),
    externalReference: Yup.string().max(25),
    paymentType: Yup.mixed().when('paymentMethod', {
      is: 'ONLINE_PAYMENT',
      then: Yup.string().nullable().required(t('is required')),
    }),
    paymentMethod: Yup.string().nullable().required(t('is required')),
    file: Yup.mixed().when('paymentType', {
      is: 'INVOICE',
      then: Yup.mixed()
        .required(t('required', { field: t('file') }))
        .test('fileSize', t('file size'), (value) => value && value.size <= FILE_SIZE)
        .test('fileFormat', t('unsupported format'), (value) => value && SUPPORTED_FORMATS.includes(value.type)),
    }),
  });
};
