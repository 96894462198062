import { useTranslation } from 'react-i18next';
import InstalmentPlanService from '../../../services/InstalmentPlanService';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import LoadingButton from '../../../components/LoadingButton';
import React, { useContext, useState } from 'react';
import { AlertContext } from '../../../components/contexts/WithAlertContext';

type CancelInstalmentPlanDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  instalmentPlanId: string;
};

const CancelInstalmentPlanDialog = ({ isOpen, onCancel, onSuccess, instalmentPlanId }: CancelInstalmentPlanDialogProps) => {
  const { t } = useTranslation('instalmentPlans');
  const alert = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  const deleteInstalmentPlan = () => {
    setLoading(true);
    InstalmentPlanService.cancelInstalmentPlan(instalmentPlanId)
      .then((result) => {
        onSuccess();
      })
      .catch((error) => {
        alert.sendAlert('error', 'Something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t('cancel instalment plan')}</DialogTitle>
      <DialogContent dividers>
        <MuiAlert elevation={6} variant="filled" severity="warning">
          {t('cancel instalment plan alert')}
        </MuiAlert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('global:cancel')}
        </Button>
        <LoadingButton type="button" onClick={() => deleteInstalmentPlan()} loading={loading}>
          {t('instalmentPlans:cancel')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelInstalmentPlanDialog;
