import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DebtorListDataContext } from '../DebtorListDataContextProvider';
import { useContext } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import TableHeader from '../../../components/table/TableHeader';
import { Skeleton } from '@material-ui/lab';
import TablePagination from '../../../components/table/Pagination';
import DebtorRow from './DebtorRow';

type DebtorsTableProps = {
  showPagination: Boolean;
};

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: '200px',
  },
  main: {
    padding: theme.spacing(1),
  },
}));

const DebtorsTable = ({ showPagination }: DebtorsTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation(['debitors', 'company']);

  const context = useContext(DebtorListDataContext);

  const columns = [
    {
      label: t('Name'),
      id: 'lastName',
    },
    {
      label: t('company:companyName'),
      id: 'companyName',
    },
    {
      label: t('Type'),
      id: 'type',
    },
    {
      label: t('E-Mail Address'),
      id: 'emailAddress',
    },
    {
      label: t('Payment Method'),
      id: 'paymentMethod',
    },
    {
      label: t('Onboarding status'),
      id: 'onboardingState',
    },
  ];

  const initialLoad = !context.page && context.loading;
  const emptyResultList = !!context.page && context.debtors.length === 0;
  const emptyFilteredResultList = emptyResultList && context.filters?.used;

  return (
    <>
      {emptyResultList ? (
        <>
          {emptyFilteredResultList ? (
            <Typography style={{ marginTop: '40px' }}>{t('no results for your debtor search')}</Typography>
          ) : (
            <Grid item xs={12} className={classes.main}>
              <MuiAlert elevation={6} variant="filled" severity="warning">
                {t('no debtors')}
              </MuiAlert>
            </Grid>
          )}
        </>
      ) : (
        <>
          <TableContainer style={{ flex: '1 1 auto' }}>
            <Table>
              <TableHeader columns={columns} actions={true}></TableHeader>
              <TableBody>
                <>
                  {initialLoad && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1}>
                        <Skeleton className={classes.skeleton} />
                      </TableCell>
                    </TableRow>
                  )}
                  {context.debtors?.map((debtor, index) => (
                    <DebtorRow debtor={debtor} index={index} key={debtor.id}></DebtorRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          {showPagination && <TablePagination transformPageSizeLabel={(pageSize) => t('pageSize', { pageSize: pageSize }) as string} />}
        </>
      )}
    </>
  );
};

export default DebtorsTable;
