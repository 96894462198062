import React, { useState } from 'react';
import PaymentReminderDialog from '../dialogs/PaymentReminderDialog';
import { MenuItem } from '@material-ui/core';
import { PaymentActionType } from './PaymentActionHelper';

const PaymentReminderAction = ({ payment, setMenuAnchor, label }: PaymentActionType) => {
  const [showPaymentReminderDialog, setShowPaymentReminderDialog] = useState(false);
  return (
    <div key={payment.id + 'reminderC'}>
      <MenuItem
        key={payment.id + 'reminder'}
        onClick={() => {
          setMenuAnchor(null);
          setShowPaymentReminderDialog(true);
        }}
      >
        {label}
      </MenuItem>
      <PaymentReminderDialog
        isOpen={showPaymentReminderDialog}
        onCancel={() => setShowPaymentReminderDialog(false)}
        onSuccess={() => setShowPaymentReminderDialog(false)}
        paymentId={payment.id}
        debtor={`${payment.debitor.firstName} ${payment.debitor.lastName}`}
      />
    </div>
  );
};

export default PaymentReminderAction;
