import React, { CSSProperties, ReactElement } from 'react';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import { TFunction, Trans } from 'react-i18next';
import { EventStep } from '../../../types/EventStep';
import { Invoice } from '../../../types/Invoice/Invoice';
import { groupByEventType, PEvent } from '../../../types/PEvent';
import DescriptionIcon from '@material-ui/icons/Description';
import { Avatar } from '@material-ui/core';

const EventAvatar = ({ children, style = {} }: { children: ReactElement; style?: CSSProperties }) => {
  return <Avatar style={Object.assign({ width: '24px', height: '24px' }, style)}>{children}</Avatar>;
};

const icon = <DescriptionIcon fontSize={'small'} />;

const mergeEvents = (events: PEvent[]) => {
  return groupByEventType(events);
};

const eventsToSteps = (invoice: Invoice, events: PEvent[], t: TFunction) => {
  const steps = [] as EventStep[];

  events.sort((a, b) => {
    return new Date(a.eventDateTime).getTime() - new Date(b.eventDateTime).getTime();
  });

  const mergedEvents = mergeEvents(events);

  mergedEvents.forEach((event) => {
    const step: EventStep = {
      count: 1,
      label: formatDateTime(event.eventDateTime),
      content: event.eventType,
      paymentId: event.paymentId,
      reason: undefined,
      reasonMessage: undefined,
      final: false,
      error: false,
      datetime: new Date(event.eventDateTime),
      relatedObject: invoice.id,
      icon: <EventAvatar>{icon}</EventAvatar>,
    };

    switch (event.eventType) {
      case 'CREATED':
        step.content = <span>{t('invoice created')}</span>;
        break;
      case 'REOPEN':
        step.content = <span>{t('invoice reopened')}</span>;
        break;
      case 'FINALIZED':
        step.content = <span>{t('invoice finalized')}</span>;
        break;
      case 'PAYMENT_CREATED':
        let id = step.paymentId;
        step.content = (
          <span>
            <Trans t={t} i18nKey="payment created">
              Related payment with ID: <b>{{ id }}</b>
            </Trans>
          </span>
        );
        break;
      case 'EXISTING_PAYMENT_LINKED':
        let paymentId = step.paymentId;
        step.content = (
          <span>
            <Trans t={t} i18nKey="existing payment linked">
              Payment with ID: <b>{{ paymentId }}</b>
            </Trans>
          </span>
        );
        break;
      case 'CORRECTION_INVOICE_CREATED':
        step.content = <span>{t('correction invoice created')}</span>;
        break;
      case 'CANCELLED_BY_CORRECTION_INVOICE':
        step.content = <span>{t('cancelled by correction invoice')}</span>;
        break;
      case 'CANCELLED_BY_CANCELLATION_INVOICE':
        step.content = <span>{t('cancelled by cancellation invoice')}</span>;
        break;
      case 'CANCELLED':
        step.content = (
          <span>
            {t('cancelled by creditor')} (<b>{event.triggeredBy}</b>)
          </span>
        );
        step.reasonMessage = (
          <>
            <p>
              <span> {t('reason exteral')} </span>
              <span>{invoice.cancelStatus?.cancelReasonDebitor}</span>
            </p>
            {invoice.cancelStatus?.cancelReasonInternal && (
              <p>
                <span>{t('reason internal')} </span>
                <span>{invoice.cancelStatus?.cancelReasonInternal}</span>
              </p>
            )}
          </>
        );
        step.final = true;
        step.error = true;
        break;
      case 'REFUND_IN_PROGRESS':
        step.content = <span>{t('invoice refund in progress')}</span>;
        break;
      case 'CANCELLATION_COMPLETED':
        step.content = <span>{t('invoice cancellation completed')}</span>;
        break;
      case 'REFUND_COMPLETED':
        step.content = <span>{t('invoice refund completed')}</span>;
        break;
      case 'REMINDED':
        step.content = <span>{t('invoice reminded')}</span>;
        break;
      case 'IN_DUNNING':
        step.content = <span>{t('invoice in dunning')}</span>;
        break;
      case 'UNCOLLECTIBLE':
        step.content = <span>{t('invoice uncollectible')}</span>;
        break;
      default:
        break;
    }

    if (step.count > 1) {
      step.label = step.label + ' (' + step.count + ' in total)';
    }

    if (step.final && !step.error) {
      step.icon = <EventAvatar style={{ backgroundColor: 'green' }}>{icon}</EventAvatar>;
    } else if (step.final && step.error) {
      step.icon = <EventAvatar style={{ backgroundColor: 'green' }}>{icon}</EventAvatar>;
    }

    steps.push(step);
  });
  return steps;
};
export { eventsToSteps };
