import React, { PropsWithChildren, ReactElement } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { authService } from '../services/AuthService';
import WithCompanyAccountContext from '../components/contexts/WithCompanyAccountContext';

export default function PrivateRoute({ children, path, exact }: PropsWithChildren<{ path: string; exact: boolean }>): ReactElement {
  const { pathname, search } = useLocation();
  return (
    <Route
      path={path}
      exact={exact}
      render={() => {
        return authService.loggedIn() ? (
          <WithCompanyAccountContext>{children}</WithCompanyAccountContext>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { pendingPathname: pathname, pendingSearch: search },
            }}
          />
        );
      }}
    />
  );
}
