type IbanMask = IMask.AnyMaskedOptions & { startsWith: string };

const IbanMasks: IbanMask[] = [
  { mask: '#### 0000 0000 #### #### #### ####', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'AL' },
  { mask: '#### 0000 0000 #### #### ####', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'AD' },
  { mask: '#### 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'AT' },
  { mask: '#### #### 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'AZ' },
  { mask: '#### aaaa #### #### #### ##', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'BH' },
  { mask: '#### #### 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'BY' },
  { mask: '#### 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'BE' },
  { mask: '#### 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'BA' },
  { mask: '#### 0000 0000 0000 0000 0000 000a #', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'BR' },
  { mask: '#### aaaa 0000 00## #### ##', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'BG' },
  { mask: '#### 0000 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'CR' },
  { mask: '#### 0000 0000 0000 0000 0', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'HR' },
  { mask: '#### 0000 0000 #### #### #### ####', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'CY' },
  { mask: '#### 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'CZ' },
  { mask: '#### 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'DK' },
  { mask: '#### aaaa 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'DO' },
  { mask: '#### 0000 0000 0000 0000 000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'TL' },
  { mask: '#### 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'EE' },
  { mask: '#### 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'FO' },
  { mask: '#### 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'FI' },
  { mask: '#### 0000 0000 00## #### #### #00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'FR' },
  { mask: '#### ##00 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'GE' },
  { mask: '#### 0000 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'DE' },
  { mask: '#### aaaa #### #### #### ###', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'GI' },
  { mask: '#### 0000 000# #### #### #### ###', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'GR' },
  { mask: '#### 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'GL' },
  { mask: '#### #### #### #### #### #### ####', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'GT' },
  { mask: '#### 0000 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'HU' },
  { mask: '#### 0000 0000 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'IS' },
  { mask: '#### #### 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'IE' },
  { mask: '#### 0000 0000 0000 0000 000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'IL' },
  { mask: '#### a000 0000 000# #### #### ###', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'IT' },
  { mask: '#### aaaa 0000 0000 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'JO' },
  { mask: '#### 0000 0000 0000 0### #### #### ##', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'KZ' },
  { mask: '#### 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'XK' },
  { mask: '#### aaaa #### #### #### #### #### ##', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'KW' },
  { mask: '#### aaaa #### #### #### #', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'LV' },
  { mask: '#### 0000 #### #### #### #### ####', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'LB' },
  { mask: '#### 0000 0### #### #### #', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'LI' },
  { mask: '#### 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'LT' },
  { mask: '#### 000# #### #### ####', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'LU' },
  { mask: '#### 000# #### #### #00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'MK' },
  { mask: '#### aaaa 0000 0### #### #### #### ###', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'MT' },
  { mask: '#### 0000 0000 0000 0000 0000 000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'MR' },
  { mask: '#### aaaa 0000 0000 0000 0000 000a aa', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'MU' },
  { mask: '#### 0000 0000 00## #### #### #00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'MC' },
  { mask: '#### #### #### #### #### ####', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'MD' },
  { mask: '#### 0000 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'ME' },
  { mask: '#### aaaa 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'NL' },
  { mask: '#### 0000 0000 000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'NO' },
  { mask: '#### #### 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'PK' },
  { mask: '#### #### 0000 0000 0000 0000 0000 0', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'PS' },
  { mask: '#### 0000 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'PL' },
  { mask: '#### 0000 0000 0000 0000 0000 0', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'PT' },
  { mask: '#### aaaa #### #### #### #### #### #', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'QA' },
  { mask: '#### aaaa #### #### #### ####', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'RO' },
  { mask: '#### a000 0000 000# #### #### ###', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'SM' },
  { mask: '#### 00## #### #### #### ####', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'SA' },
  { mask: '#### 0000 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'RS' },
  { mask: '#### 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'SK' },
  { mask: '#### 0000 0000 0000 000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'SI' },
  { mask: '#### 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'ES' },
  { mask: '#### 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'SE' },
  { mask: '#### 0000 0### #### #### #', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'CH' },
  { mask: '#### 0000 0000 0000 0000 0000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'TN' },
  { mask: '#### 0000 0### #### #### #### ##', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'TR' },
  { mask: '#### 0000 0000 0000 0000 000', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'AE' },
  { mask: '#### aaaa 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'GB' },
  { mask: '#### 0000 0000 0000 0000 00', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'VA' },
  { mask: '#### #### 0000 0000 0000 0000 ', lazy: false, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: 'VG' },
  { mask: '#### #### #### #### #### #### #### #### ##', lazy: true, definitions: { '#': /[a-zA-Z\d]/ }, startsWith: '' },
];

export default IbanMasks;
export type { IbanMask };
