import { MenuItem } from '@material-ui/core';
import React, { useContext } from 'react';
import { PaymentListDataContext } from '../PaymentListDataContext';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { useTranslation } from 'react-i18next';
import { PaymentActionType } from './PaymentActionHelper';
import PaymentService from '../../../services/PaymentService';
import ReloadContext from '../../../components/contexts/ReloadContext';

const PaymentRefundCompleteAction = ({ payment, setMenuAnchor, label }: PaymentActionType) => {
  const paymentReload = useContext(PaymentListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;
  const reload = () => {
    paymentReload();
    contextReload();
  };
  const alerting = useContext(AlertContext);
  const { t } = useTranslation('payments');

  return (
    <MenuItem
      key={payment.id + 'refund'}
      onClick={() => {
        setMenuAnchor(null);
        PaymentService.completesPaymentRefund(payment.id).then((res) => {
          if (res) {
            reload();
            alerting.sendAlert('success', t('payment marked as refunded'));
          }
        });
      }}
    >
      {label}
    </MenuItem>
  );
};

export default PaymentRefundCompleteAction;
