import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikInputField, FormikSelectField } from '@payactive/app-common';
import { LegalFormsAsArray, requiresHrNumber } from '../../../../components/LegalForms';
import { Grid, Typography } from '@material-ui/core';

export default function CompanyInformation({ values }: { values: { legalForm?: string } }) {
  const { t } = useTranslation('company');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{t('company info')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormikInputField name="name" label={t('companyName')} fullWidth />
      </Grid>
      <Grid item xs={requiresHrNumber(values.legalForm) ? 6 : 12}>
        <FormikSelectField name="legalForm" label={t('companyLegalForm')} data={LegalFormsAsArray} fullWidth />
      </Grid>
      {requiresHrNumber(values.legalForm) && (
        <Grid item xs={6}>
          <FormikInputField name="companyRegistrationNumber" label={t('companyRegistrationNumber')} fullWidth />
        </Grid>
      )}
    </Grid>
  );
}
