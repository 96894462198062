import ApiClient from './ApiClient';
import { NewOnboard } from '../types/Onboard/Onboard';
import { AxiosResponse } from 'axios';

export default class PortalService extends ApiClient {
  public static async getOnboardContext(invitationId: string) {
    let res = await this.noAuthClient.request({
      method: 'GET',
      url: this.apiDomain + '/portal/onboarding/' + invitationId,
    });

    return res.data;
  }

  public static async createOnboard(values: NewOnboard): Promise<AxiosResponse<any>> {
    return await this.noAuthClient.request<NewOnboard>({
      method: 'POST',
      url: this.apiDomain + '/portal/onboarding',
      data: values,
    });
  }
}
