import { Grid } from '@material-ui/core';
import * as React from 'react';
import ColorPickerField from './ColorPickerField';

type Props = {
  label: string;
  toolTipText: string;
  inputFieldName: string;
  inputFieldLabel: string;
};

function ColorSetting({ label, toolTipText, inputFieldName, inputFieldLabel }: Props) {
  return (
    <Grid container direction="row" alignItems="center">
      {/*             <Grid item md={12} lg={4}>
                <Typography>{label}</Typography>
            </Grid>
 */}{' '}
      <Grid item xs={12}>
        <ColorPickerField label={label} name={inputFieldName} />
      </Grid>
    </Grid>
  );
}

export default ColorSetting;
