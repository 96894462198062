import { Credit } from '../../../types/Credit/Credit';
import React, { useState } from 'react';
import useCardStyles from './CardStyles';
import { useTranslation } from 'react-i18next';
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, Menu, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import PaymentIcon from '@material-ui/icons/Payment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import { calculateProgress, formatCurrency } from '../../../utils/PaymentUtil';
import StateWithIcon from '../../../components/StateWithIcon';
import LinearProgressWithLabel from '../../../components/ui/blocks/LinearProgressWithLabel';
import { getCreditActions } from '../../credits/actions/CreditActionHelper';

export default function CreditCard({ credit, active, setRef }: { credit: Credit; active: boolean; setRef: (key: string) => React.RefObject<any> }) {
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const menuOpen = Boolean(menuAnchor);

  const classes = useCardStyles();
  const { t } = useTranslation(['payments', 'credits']);
  const actions = getCreditActions(credit, setMenuAnchor, true);

  return (
    <Card elevation={active ? 3 : 1} ref={setRef(credit.id)}>
      <CardHeader
        className={classes.alignLeft}
        avatar={
          <Avatar className={classes.avatar}>
            <PaymentIcon />
          </Avatar>
        }
        action={
          !!actions &&
          actions.length !== 0 && (
            <IconButton aria-label="settings" onClick={(e) => setMenuAnchor(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={t(`credits:${credit.creditMethod}`)}
        subheader={formatDateTime(credit.createdDateTimeUTC)}
      />
      <CardContent>
        <Grid container spacing={2} direction={'column'} alignItems={'flex-start'} className={classes.alignLeft}>
          <Grid item container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Grid item container direction={'column'} alignItems={'flex-start'}>
                <Typography variant="caption" style={{ color: 'gray' }}>
                  {t('amount')}
                </Typography>
                <Typography color="inherit" variant={'subtitle2'}>
                  {formatCurrency(credit.amount)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <StateWithIcon state={credit.creditStatus} label={t(`credits:${credit.creditStatus}`)} />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="caption" style={{ color: 'gray' }}>
              {t('purpose')}
            </Typography>
            <Typography color="inherit" variant={'subtitle2'}>
              {credit.purpose}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{ height: '52px' }}>
        <LinearProgressWithLabel value={calculateProgress(credit.creditStatus)} fullWidth />
      </CardActions>
      <Menu
        id="long-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        PaperProps={{
          style: {
            //maxHeight: ITEM_HEIGHT * 4.5,
            width: 'min-content',
          },
        }}
      >
        {actions}
      </Menu>
    </Card>
  );
}
