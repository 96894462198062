import ApiClient from './ApiClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { BankConnection, InitBankConnection, UpdateBankConnection } from '../types/Bank/BankConnection';
import { AvailableAccount } from '../types/Bank/AvailableAccount';
import { BankAccount, BankAccountList, UpdateBankAccount } from '../types/Bank/BankAccount';
import { Transaction } from '../types/Bank/Transaction';

export default class BankService extends ApiClient {
  private static bankAccounts?: Promise<BankAccountList>;
  private static get bankClient(): AxiosInstance {
    return this.client;
  }

  public static async initBankConnection(): Promise<InitBankConnection> {
    let res = await this.bankClient.request<InitBankConnection>({
      method: 'POST',
      url: this.apiDomain + '/bankconnections',
    });

    return res.data;
  }

  public static async completeBankConnection(trx: string): Promise<BankConnection> {
    let res = await this.bankClient.request<BankConnection>({
      method: 'POST',
      url: this.apiDomain + '/bankconnections/' + trx,
    });

    return res.data;
  }

  /* We still keep these methods because we want to use them in the future in a library - so please, don't remove them */
  public static async updateBankConnection(bankConnectionId: string, update: UpdateBankConnection): Promise<boolean> {
    let res = await this.bankClient.request<UpdateBankConnection, AxiosResponse>({
      method: 'PATCH',
      url: this.apiDomain + '/bankconnections/' + bankConnectionId,
      data: update,
    });

    return res.status === 200;
  }

  public static async getBankConnection(bankConnectionId: string): Promise<BankConnection> {
    let res = await this.bankClient.request<BankConnection>({
      method: 'GET',
      url: this.apiDomain + '/bankconnections/' + bankConnectionId,
    });

    return res.data;
  }

  public static async getBankConnectionAvailableAccounts(bankConnectionId: string): Promise<AvailableAccount[]> {
    let res = await this.bankClient.request<{ availableAccounts: AvailableAccount[] }>({
      method: 'GET',
      url: this.apiDomain + '/bankconnections/' + bankConnectionId + '/availableAccounts',
    });

    return res.data.availableAccounts;
  }
  /* end of library methods*/

  public static async getBankAccounts(force = false): Promise<BankAccountList> {
    if (!ApiClient.cache.bankAccounts || force) {
      ApiClient.cache.bankAccounts = this.bankClient
        .request<BankAccountList>({
          method: 'GET',
          url: this.apiDomain + '/bankaccounts',
        })
        .then((res) => res.data);
    }

    return ApiClient.cache.bankAccounts;
  }

  public static async updateBankAccount(bankAccountId: string, bankAccountUpdate: UpdateBankAccount): Promise<boolean> {
    let res = await this.bankClient.request<UpdateBankAccount>({
      method: 'PATCH',
      url: this.apiDomain + '/bankaccounts/' + bankAccountId,
      data: bankAccountUpdate,
    });
    ApiClient.cache.bankAccounts = undefined;
    return res.status === 204;
  }

  public static async downloadBankAccountTransactions(bankAccountId: string, start: string, end: string): Promise<boolean> {
    const params = {
      start: start,
      end: end,
    };
    let res = await this.bankClient.request<{ transactions: Transaction[] }>({
      method: 'GET',
      url: this.apiDomain + '/bankaccounts/' + bankAccountId + '/transactions',
      params: params,
    });

    if (res.status === 200) {
      const fileName = 'transactions.csv';
      const content = this.createTransactionsCSV(res.data.transactions);
      super.downloadCSV(fileName, content);
      return true;
    } else {
      return false;
    }
  }

  private static createTransactionsCSV(transactions: Transaction[]) {
    const csv = [];
    csv.push(['bookingDate', 'amount', 'purpose', 'counterHolder'].join(';'));
    transactions.forEach((transaction) => {
      csv.push([transaction.bookingDate, transaction.amount, transaction.purpose, transaction.counterHolder].join(';'));
    });
    return csv.join('\n');
  }

  public static async getDefaultBankAccount() {
    const bank = await BankService.getBankAccounts();
    if (!bank) {
      return undefined;
    }
    return bank.bankAccounts.find((bank: BankAccount) => bank.default);
  }
}
