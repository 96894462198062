import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { TestFunction } from 'yup';
import { httpsUrlValidator } from '../../../utils/ValidatorUtil';
import { NewProduct, Products } from '../../../types/Product/Product';
import { AnyObject } from 'yup/es/types';

const paylinkSchemas = (t: TFunction) => {
  return Yup.object().shape({
    productId: Yup.string().required(t('required', { field: t('product') })),
    checkoutButtonLabel: Yup.string(),
    paymentTime: Yup.string(),
    generateReceipt: Yup.boolean(),
    requireAddress: Yup.boolean(),
    notifyCreditor: Yup.boolean(),
    creditorEmailAddress: Yup.string().when('notifyCreditor', {
      is: true,
      then: Yup.string().required(t('required', { field: t('emailAddress') })),
    }),
    includeRedirectUrl: Yup.boolean(),
    redirectUrl: Yup.string().when('includeRedirectUrl', {
      is: true,
      then: httpsUrlValidator.required(t('required', { field: t('redirect url') })),
    }),
    metadata: Yup.array().of(
      Yup.object().shape(
        {
          externalIdentifier: Yup.string(),
          fieldLabel: Yup.string().when('fieldType', {
            is: (fieldType: string, required: boolean) => fieldType !== '' || required,
            then: Yup.string().required(t('required', { field: t('fieldLabel') })),
            otherwise: Yup.string().notRequired(),
          }),
          fieldType: Yup.string().when('fieldLabel', {
            is: (fieldLabel: string, required: boolean) => fieldLabel !== '' || required,
            then: Yup.string().required(t('required', { field: t('fieldType') })),
            otherwise: Yup.string().notRequired(),
          }),
        },
        [['fieldLabel', 'fieldType']],
      ),
    ),
  });
};

type TestContextExtended = Yup.TestContext & {
  from: {
    value: NewProduct;
  }[];
};

const productSchemas = (t: TFunction) => {
  const gtePriceTest: TestFunction<number | undefined, AnyObject> = function (value: number | undefined, context: Yup.TestContext) {
    if (!value) return false;
    const extendedContext = context as TestContextExtended;
    // from[0] contains correct object when called from defaultDonationAmount
    // from[1] contains correct object when called from donationOptions
    const price = 'price' in extendedContext.from[0].value ? extendedContext.from[0].value.price : extendedContext.from[1].value.price;
    return value >= price;
  };

  return Yup.object().shape({
    name: Yup.string().required(t('required', { field: t('productName') })),
    description: Yup.string().max(1000),
    price: Yup.number()
      .required(t('required', { field: t('price') }))
      .positive(t('has to be positive')),
    defaultDonationAmount: Yup.number().when('productType', {
      is: Products.Type.DONATION,
      then: Yup.number().test('defaultDonationAmount', t('default donation amount has to be bigger than min price'), gtePriceTest),
    }),
    taxRate: Yup.object().shape({
      rate: Yup.number(),
    }),
    file: Yup.mixed(),
    billingType: Yup.string().required(t('required', { field: t('type') })),
    recurringBillingPeriod: Yup.string().when('billingType', {
      is: Products.BillingType.RECURRING,
      then: Yup.string().required(t('required', { field: t('billing period') })),
    }),
    donationOptions: Yup.object().shape({
      option1: Yup.number().when('isEnabled', {
        is: true,
        then: Yup.number().positive(t('has to be positive')).test('option1', t('donation option has to be bigger than minimum'), gtePriceTest),
      }),
      option2: Yup.number().when('isEnabled', {
        is: true,
        then: Yup.number().positive(t('has to be positive')).test('option2', t('donation option has to be bigger than minimum'), gtePriceTest),
      }),
      option3: Yup.number().when('isEnabled', {
        is: true,
        then: Yup.number().positive(t('has to be positive')).test('option3', t('donation option has to be bigger than minimum'), gtePriceTest),
      }),
    }),
    customFields: Yup.array().of(
      Yup.object().shape({
        fieldType: Yup.string().required(t('required', { field: t('fieldType') })),
      }),
    ),
  });
};

export { paylinkSchemas, productSchemas };
