import { MenuItem } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { InvoiceActionType } from './InvoiceActionHelper';
import InvoiceService from '../../../services/InvoiceService';

const InvoiceDownloadAction = ({ invoice, closeMenu, label }: InvoiceActionType) => {
  const { t } = useTranslation(['debitors', 'invoices']);
  const alerting = useContext(AlertContext);
  return (
    <MenuItem
      key={invoice.id + 'events'}
      onClick={() => {
        closeMenu();
        InvoiceService.downloadInvoice(invoice.id)
          .then(() => {
            alerting.sendAlert('success', t('successfully downloaded'));
          })
          .catch(() => {
            alerting.sendAlert('error', t('unsuccessfully downloaded'));
          });
      }}
    >
      {label}
    </MenuItem>
  );
};

export default InvoiceDownloadAction;
