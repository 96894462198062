import DateFnsUtils from '@date-io/date-fns';
import { Grid, Icon, Select } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DateFilter from '../../../components/filter/DateFilter';
import { ServicePeriods } from '../../../types/Invoice/ServicePeriods';

interface ServicePeriodProps {
  disabled: boolean;
}

function ServicePeriod({ disabled }: ServicePeriodProps) {
  const { t } = useTranslation('debitors');

  const { values, setFieldValue } =
    useFormikContext<{ servicePeriodStart?: string; servicePeriodEnd?: string; servicePeriodVariant: ServicePeriods }>();

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.value === ServicePeriods.NONE) {
      setFieldValue('servicePeriodStart', undefined);
      setFieldValue('servicePeriodEnd', undefined);
      setFieldValue('servicePeriodVariant', ServicePeriods.NONE);
    } else if (event.target.value === ServicePeriods.SERVICE_DATE) {
      if (values.servicePeriodStart) {
        resetStartDate();
      }
      setFieldValue('servicePeriodEnd', undefined);
      setFieldValue('servicePeriodVariant', ServicePeriods.SERVICE_DATE);
    } else if (event.target.value === ServicePeriods.SERVICE_PERIOD) {
      if (values.servicePeriodStart) {
        resetStartDate();
      }
      if (values.servicePeriodEnd && values.servicePeriodStart) {
        let date = new Date();
        date.setDate(date.getDate() + 1);
        setFieldValue('servicePeriodEnd', date.toISOString());
      } else if (values.servicePeriodEnd) {
        resetEndDate();
      }
      setFieldValue('servicePeriodVariant', ServicePeriods.SERVICE_PERIOD);
    }
  };

  const resetStartDate = () => {
    setFieldValue('servicePeriodStart', new Date().toISOString());
  };

  const resetEndDate = () => {
    let date = values.servicePeriodStart ? new Date(values.servicePeriodStart) : new Date();
    date.setDate(date.getDate() + 1);
    setFieldValue('servicePeriodEnd', date.toISOString());
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={1} style={{}}>
      <Grid item xs={1}>
        <Icon color="primary" style={{ verticalAlign: 'top' }}>
          date_range
        </Icon>
      </Grid>
      <Grid item xs={5} style={{ height: '56px' }} container alignItems={'flex-end'}>
        <Select disabled={disabled} native onChange={handleChange} value={values.servicePeriodVariant} style={{ width: '100%' }}>
          <option value={ServicePeriods.NONE}>{t('none')}</option>
          <option value={ServicePeriods.SERVICE_DATE}>{t(ServicePeriods.SERVICE_DATE)}</option>
          <option value={ServicePeriods.SERVICE_PERIOD}>{t(ServicePeriods.SERVICE_PERIOD)}</option>
        </Select>
      </Grid>
      {values.servicePeriodVariant !== ServicePeriods.NONE && (
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateFilter
              disabled={disabled}
              onChange={(date) => (date ? setFieldValue('servicePeriodStart', date.toISOString()) : resetStartDate())}
              label={values.servicePeriodVariant === ServicePeriods.SERVICE_DATE ? t('on') : t('begin')}
              value={values.servicePeriodStart || ''}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
      {values.servicePeriodVariant === ServicePeriods.SERVICE_PERIOD && (
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DateFilter
              startDate={values.servicePeriodStart}
              disabled={disabled}
              onChange={(date) => (date ? setFieldValue('servicePeriodEnd', date.toISOString()) : resetEndDate())}
              label={t('end')}
              value={values.servicePeriodEnd || ''}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      )}
    </Grid>
  );
}

export default ServicePeriod;
