import { makeStyles } from '@material-ui/core/styles';

const useCardStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  alignLeft: {
    textAlign: 'left',
  },
  expand: {
    marginLeft: 'auto',
  },
  icon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default useCardStyles;
