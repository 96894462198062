import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import Settings from './setting/Settings';
import PageHeader from '../../../components/ui/blocks/PageHeader';
import CompanyAccountService from '../../../services/CompanyAccountService';
import { CompanyAccount } from '../../../types/Company/Company';

export default function ServiceSettingScreen() {
  const { t } = useTranslation('company');

  const [companyInfo, setCompanyInfo] = useState<CompanyAccount>();

  useEffect(() => {
    loadCompanyInfo();
  }, []);

  const loadCompanyInfo = () => {
    CompanyAccountService.getCompany().then((company) => {
      setCompanyInfo(company);
    });
  };

  return companyInfo ? (
    <>
      <PageHeader title={t('service settings')} subtitle={t('settings')} />
      <Settings
        accountSettings={
          companyInfo.accountSettings || {
            bccEmailAddressList: [],
            paymentReminderDays: 3,
            paymentReminderEnabled: false,
            isDonationReceiptsEnabled: false,
          }
        }
        dunningService={
          companyInfo.dunningServiceCreditorConfiguration || {
            dunningEnabled: false,
            dunningFee: 0.5,
            daysReminder: 0,
            daysDunning: 7,
            maxDunnings: 0,
          }
        }
      />
    </>
  ) : (
    <Skeleton />
  );
}
