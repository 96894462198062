import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { authService } from './AuthService';
import Qs from 'qs';
import ClientCache from './ClientCache';

export const searchDelay = 500;

export default class ApiClient {
  private static _instance: AxiosInstance;
  private static _no_auth_instance: AxiosInstance;
  private static _abortController: AbortController;
  private static readonly pdfBase64 = 'data:application/pdf;base64,';
  private static readonly csvCharsetUtf8 = 'data:text/csv;charset=utf-8,';

  protected static cache = new ClientCache();

  public static get abortController(): AbortController {
    this._abortController && this._abortController.abort();
    this._abortController = new AbortController();
    return this._abortController;
  }

  public static get apiDomain(): string {
    if (process.env.REACT_APP_API_DOMAIN) {
      return process.env.REACT_APP_API_DOMAIN;
    }

    switch (window.location.hostname) {
      case 'dev.portal.payactive.io':
        return 'https://dev.api.payactive.io';
      case 'stage.portal.payactive.io':
        return 'https://stage.api.payactive.io';
      case 'portal.payactive.io':
        return 'https://api.payactive.io';
      case 'sandbox.portal.payactive.io':
        return 'https://sandbox.api.payactive.io';
      default:
        return 'http://localhost:8080';
    }
  }

  public static get client(): AxiosInstance {
    return this._instance || (this._instance = this.createAuthClient());
  }

  public static get noAuthClient(): AxiosInstance {
    return this._no_auth_instance || (this._no_auth_instance = this.createNoAuthClient());
  }

  private static createAuthClient(): AxiosInstance {
    const instance = this.createNoAuthClient();
    instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (!!config.headers) {
          const token = authService.accessToken;
          if (!!token) {
            config.headers.Authorization = 'Bearer ' + token;
          }
          if (!config.headers['Accept']) config.headers['Accept'] = 'application/json';
          if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json';
          config.headers['x-payactive'] = 'portal';
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
    instance.interceptors.response.use((value) => {
      if (value.status >= 200 && value.status < 400) {
        return value;
      }
      if (value.status === 401) {
        //TODO login
      }
      throw new Error('status not ok');
    });
    return instance;
  }

  private static createNoAuthClient(): AxiosInstance {
    return axios.create({
      paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
    });
  }

  static downloadPDF(filename: string, content: Array<string> | string) {
    this.downloadFile(content, filename, this.pdfBase64);
  }

  static downloadCSV(filename: string, content: string) {
    this.downloadFile(content, filename, this.csvCharsetUtf8);
  }

  private static downloadFile(content: Array<string> | string, filename: string, mimeType: string) {
    const element = document.createElement('a');
    element.setAttribute('href', mimeType + content);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  static isFilterSet(filterValue?: string): boolean {
    return !!filterValue && filterValue.trim() !== '';
  }

  static clearCaches() {
    this.cache.clearCache();
  }
}
