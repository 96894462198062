import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ObjectSchema } from 'yup';
import checkoutFormModel from './onboardFormModel';
import { ONBOARD_STEPS } from '../OnboardingFlow';

const {
  formField: {
    companyName,
    companyLegalForm,
    companyRegistrationNumber,
    firstName,
    lastName,
    emailAddress,
    password,
    passwordConfirmation,
    acceptTermsAndConditions,
  },
} = checkoutFormModel;

const getSchemaForStep = (step: ONBOARD_STEPS, t: TFunction, isAddress: boolean): ObjectSchema<any> | void => {
  const noAddressShape = {
    [companyName.name]: Yup.string().required(`${companyName.requiredErrorMsg}`),
    [companyLegalForm.name]: Yup.string().required(`${companyLegalForm.requiredErrorMsg}`),
    [companyRegistrationNumber.name]: Yup.string().when(companyLegalForm.name, {
      is: 'GmbH',
      then: Yup.string().required(`${companyRegistrationNumber.requiredErrorMsg}`),
    }),
  };

  const addressShape = {
    [companyName.name]: Yup.string().required(`${companyName.requiredErrorMsg}`),
    [companyLegalForm.name]: Yup.string().required(`${companyLegalForm.requiredErrorMsg}`),
    [companyRegistrationNumber.name]: Yup.string().when(companyLegalForm.name, {
      is: 'GmbH',
      then: Yup.string().required(`${companyRegistrationNumber.requiredErrorMsg}`),
    }),
    address: Yup.object().shape({
      street: Yup.string().required(t('required', { field: t('street') })),
      zipCode: Yup.string()
        .required(t('required', { field: t('zipCode') }))
        .matches(/^(?:\d{5}|\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{2}\s\d{2})$/, t('only numbers')),
      houseNumber: Yup.string().required(t('required', { field: t('houseNumber') })),
      city: Yup.string().required(t('required', { field: t('city') })),
      country: Yup.string().required(t('required', { field: t('country') })),
    }),
  };

  switch (step) {
    case ONBOARD_STEPS.COMPANY_INFORMATION: {
      return Yup.object().shape(isAddress ? addressShape : noAddressShape);
    }
    case ONBOARD_STEPS.USER_INFORMATION: {
      return Yup.object().shape({
        [emailAddress.name]: Yup.string().email().required(`${emailAddress.requiredErrorMsg}`),
        [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
        [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
        [password.name]: Yup.string().required(`${password.requiredErrorMsg}`),
        [passwordConfirmation.name]: Yup.string()
          .oneOf([Yup.ref(password.name), null], 'Passwords must match')
          .required('Password confirmation is required'),
      });
    }
    case ONBOARD_STEPS.SUMMARY: {
      return Yup.object().shape({
        [acceptTermsAndConditions.name]: Yup.bool().oneOf([true], 'You must accept the Terms and Conditions'),
      });
    }
  }
};

export { getSchemaForStep };
