import { MenuItem } from '@material-ui/core';
import { DebtorActionType } from './DebtorActionHelper';
import { useContext, useState } from 'react';
import { DebtorListDataContext } from '../DebtorListDataContextProvider';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { Debtor } from '../../../types/Debtor/Debtor';
import { useTranslation } from 'react-i18next';
import DeleteDebtorDialog from '../dialogs/DeleteDebtorDialog';
import DebtorService from '../../../services/DebtorService';
import ReloadContext from '../../../components/contexts/ReloadContext';

const DebtorDeleteAction = ({ debtor, setMenuAnchor, label }: DebtorActionType) => {
  const [openDialog, setOpenDialog] = useState(false);
  const debtorReload = useContext(DebtorListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;

  const reload = () => {
    debtorReload();
    contextReload();
  };
  const alerting = useContext(AlertContext);

  const { t } = useTranslation('debitors');

  const handleDeleteDebtor = (debtor: Debtor) => {
    DebtorService.deleteDebtor(debtor.id).then((res) => {
      if (res) {
        setOpenDialog(false);
        alerting.sendAlert('success', t('debitor deleted'));
        reload();
      }
    });
  };

  return (
    <div key={debtor.id + 'deleteC'}>
      <MenuItem
        key={debtor.id + 'delete'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>

      {openDialog && <DeleteDebtorDialog debtor={debtor} onClose={() => setOpenDialog(false)} onDelete={handleDeleteDebtor} open={true} />}
    </div>
  );
};

export default DebtorDeleteAction;
