import { TextField, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { Fragment } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

export default function ApiKeyCreatedScreen({ apiKey }) {
  const { t } = useTranslation('developer');

  return (
    <Fragment>
      <Typography>{t('copy api key')}</Typography>

      <TextField
        multiline
        value={apiKey}
        disabled
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Copy" placement="top">
                <CopyToClipboard text={apiKey}>
                  <IconButton aria-label="copy">
                    <FileCopyIcon fontSize="default" />
                  </IconButton>
                </CopyToClipboard>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </Fragment>
  );
}
