import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { authService } from '../../services/AuthService';
import ApiClient from '../../services/ApiClient';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

//TODO move API call to Services
// to TypeScript
// unused States
// combine imports
export default function UserAccount(props) {
  const classes = useStyles();
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const headers = {
      Authorization: 'Bearer ' + authService.accessToken,
    };
    //TODO use UserService.getUser
    axios({
      method: 'GET',
      url: ApiClient.apiDomain + '/user',
      headers: headers,
    }).then((res) => {
      setEmailAddress(res.data.emailAddress);
      setFirstName(res.data.firstName);
      setLastName(res.data.lastName);
    });
  });

  const handleLogout = (evt) => {
    evt.preventDefault();
    authService.logout();
    setRedirect(true);
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to="/login" />;
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      {renderRedirect()}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          You are signed in and this is your data
        </Typography>
        <form className={classes.form} onSubmit={handleLogout}>
          <TextField
            variant="outlined"
            margin="normal"
            value={emailAddress}
            fullWidth
            disabled
            id="emailAddress"
            label="Email Address"
            name="emailAddress"
            autoComplete="email"
            autoFocus
          />
          <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" id="password" value={firstName} />
          <TextField variant="outlined" margin="normal" required fullWidth name="lastName" label="Password" id="lastName" value={lastName} />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Logout
          </Button>
        </form>
      </div>
    </Container>
  );
}
