import React from 'react';
import { FormikSizedInputField } from '@payactive/app-common';
import { useTranslation } from 'react-i18next';

interface Props {
  handleChange: any;
  handleBlur: any;
}

const CorrectionFields = ({ handleChange, handleBlur }: Props) => {
  const { t } = useTranslation('debitors');
  return (
    <div>
      <FormikSizedInputField
        icon="short_text"
        max={60}
        name="externalReason"
        label={t('reason for correction ex')}
        helperText={t('reason visible for debitor')}
        multiline
        fullWidth
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <FormikSizedInputField
        icon="short_text"
        max={60}
        name="internalReason"
        label={t('reason for correction in')}
        helperText={t('reason visible for creditor')}
        multiline
        fullWidth
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default CorrectionFields;
