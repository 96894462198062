import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  paperDesign: {
    //width: '48.5%',
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contactInformationHeader: {
    textAlign: 'left',
    display: 'block',
    color: '#0198B5',
    marginBottom: theme.spacing(2),
    fontSize: '18px',
  },
}));

interface PaperWithTitleProps {
  title: string;
  tooltip?: string;
  children: any;
}

function PaperWithTitle({ title, tooltip, children }: PaperWithTitleProps) {
  const classes = useStyles();

  return (
    <Paper className={classes.paperDesign}>
      <Box component="div">
        <Typography variant="h5" className={classes.contactInformationHeader}>
          {title}
          {!!tooltip && (
            <Tooltip
              title={
                <Typography variant="caption" color="inherit">
                  {tooltip}
                </Typography>
              }
              placement="top"
            >
              <InfoIcon style={{ color: '#D3D3D3', marginBottom: '-6px', marginLeft: '10px' }} />
            </Tooltip>
          )}
        </Typography>
      </Box>
      {children}
    </Paper>
  );
}

export default PaperWithTitle;
