import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import TableHeader from '../../../components/table/TableHeader';
import TablePagination from '../../../components/table/Pagination';
import { Skeleton } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useContext } from 'react';
import { SubscriptionListDataContext } from '../SubscriptionsContextProvider';
import SubscriptionRow from './SubscriptionRow';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: '200px',
  },
  main: {
    padding: theme.spacing(1),
  },
}));

const SubscriptionTable = ({ showPagination }: { showPagination: boolean }) => {
  const { t } = useTranslation('payments');

  const classes = useStyles();

  const context = useContext(SubscriptionListDataContext);
  const preColumns = !context.isPrefilteredForDebtor
    ? [
        {
          label: t('status'),
          id: 'status',
        },
        {
          label: t('customer'),
          id: 'debtor',
        },
      ]
    : [
        {
          label: t('status'),
          id: 'status',
        },
      ];

  const columns = preColumns.concat([
    {
      label: t('abo-modell'),
      id: 'abo-modell',
    },
    {
      label: t('amount'),
      id: 'price',
    },
    {
      label: t('interval'),
      id: 'interval',
    },
    {
      label: t('duration'),
      id: 'duration',
    },
    {
      label: t('next payment'),
      id: 'nextPaymentDueDate',
    },
    {
      label: t('creationDate'),
      id: 'createdDateTime',
    },
  ]);

  const initalLoad = !context.page && context.loading;
  const emptyResultList = !!context.page && context.subscriptions.length === 0;
  const emptyFilteredResultList = emptyResultList && context.filters?.used;

  return (
    <>
      {emptyResultList ? (
        <>
          {emptyFilteredResultList ? (
            <Typography style={{ marginTop: '40px' }}>{t('no subscriptions results for your search')}</Typography>
          ) : (
            <Grid item xs={12} className={classes.main}>
              <MuiAlert elevation={6} variant="filled" severity="warning">
                {t('no subscriptions yet')}
              </MuiAlert>
            </Grid>
          )}
        </>
      ) : (
        <>
          <TableContainer style={{ flex: '1 1 auto' }}>
            <Table>
              <TableHeader columns={columns} actions={true}></TableHeader>
              <TableBody>
                <>
                  {initalLoad && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1}>
                        <Skeleton className={classes.skeleton} />
                      </TableCell>
                    </TableRow>
                  )}
                  {context.subscriptions?.map((subscription, index) => (
                    <SubscriptionRow subscription={subscription} index={index}></SubscriptionRow>
                  ))}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          {showPagination && <TablePagination transformPageSizeLabel={(pageSize) => t('pageSize', { pageSize: pageSize }) as string} />}
        </>
      )}
    </>
  );
};

export default SubscriptionTable;
