import { MenuItem } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CheckoutActionType } from './CheckoutActionHelper';

const CheckoutDetailsAction = ({ checkout, setMenuAnchor, label }: CheckoutActionType) => {
  let history = useHistory();
  return (
    <MenuItem
      key={checkout.id + 'details'}
      onClick={() => {
        setMenuAnchor(null);
        history.push(`/dashboard/transactions/checkouts/${checkout.id}/`);
      }}
    >
      {label}
    </MenuItem>
  );
};

export default CheckoutDetailsAction;
