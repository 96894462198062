import React, { useState } from 'react';
import { Button, FormikInputField, FormikSelectField, FormikSizedInputField } from '@payactive/app-common';
import { Checkbox, Divider, FormControlLabel, Grid, makeStyles, Typography } from '@material-ui/core';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { Field, FieldArray, useFormikContext } from 'formik';
import ProductImageDropzone from '../../checkoutLinks/dialogs/ProductImageDropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { Products } from '../../../types/Product/Product';
import CurrencyTextField from '../../../components/CurrencyTextField';

const useStyles = makeStyles(() => ({
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: '7px 0 0',
    },
    '& .MuiTypography-body1': {
      fontSize: '12px',
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  billingType: {
    margin: '10px 0 0 0',
  },
}));

interface CustomField {
  value: string;
  label: string;
  selected: boolean;
}

export const taxRatesForProduct = [
  { value: '19', label: 'USt 19%' },
  { value: '7', label: 'USt 7%' },
  { value: '', label: 'None' },
];

function ProductForm() {
  const { setFieldTouched, setFieldValue, touched, values, errors, handleBlur } = useFormikContext<any>();
  const classes = useStyles();
  //boolean states
  const { t } = useTranslation('payments');
  const [hasCustomFields, setHasCustomFields] = useState(values.customFields.length > 0);
  const [hasDonationOptions, setHasDonationOptions] = useState(values.donationOptions?.isEnabled);

  const customFieldsOptions: CustomField[] = [
    { value: 'TEXT', label: t('text'), selected: false },
    { value: 'DATE', label: t('date'), selected: false },
  ];

  const type = [
    { value: 'RECURRING', label: t('subscription') },
    { value: 'ONE_TIME', label: t('one time') },
  ];

  const interval = [
    { value: 'MONTHLY', label: t('monthly') },
    { value: 'YEARLY', label: t('yearly') },
  ];

  const productTypes = [
    { value: Products.Type.PRODUCT, label: t('product') },
    { value: Products.Type.DONATION, label: t('donation') },
  ];

  return (
    <Grid container spacing={2} alignItems={'stretch'}>
      <Grid item xs={12}>
        <FormikSizedInputField name="name" label={t('product name')} fullWidth max={64} required placeholder={t('product name placeholder')} />
      </Grid>
      <Grid item xs={12}>
        <FormikSizedInputField
          rows={4}
          multiline
          name="description"
          label={t('product description')}
          fullWidth
          max={1000}
          placeholder={t('product description placeholder')}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikSelectField
          name="productType"
          label={t('product type')}
          data={productTypes}
          onBlur={() => {
            if (values.productType === Products.Type.DONATION) {
              setFieldValue('taxRate', { description: '', rate: 0 });
            }

            if (values.productType === Products.Type.PRODUCT) {
              setHasDonationOptions(false);
              setFieldValue('donationOptions.isEnabled', false);
            }
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={4} alignContent={'stretch'}>
        <CurrencyTextField
          name={'price'}
          label={values.productType === Products.Type.DONATION ? t('min donation amount') : t('price net')}
          currencySymbol={'€'}
          onBlur={() => {
            // fix me ...
            setFieldTouched('defaultDonationAmount', true, true);
            setFieldTouched('donationOptions.option1', true, true);
            setFieldTouched('donationOptions.option2', true, true);
            setFieldTouched('donationOptions.option3', true, true);
          }}
          required
          fullWidth
        />
      </Grid>
      <>
        {values.productType === Products.Type.PRODUCT ? (
          <>
            <Grid item xs={4} alignContent={'stretch'}>
              <FormikSelectField name="taxRate.rate" label={t('tax')} data={taxRatesForProduct} fullWidth />
            </Grid>
            <Grid item xs={4} alignContent={'stretch'}>
              <FormikInputField
                InputProps={{ disableUnderline: true }}
                name="total price"
                label={t('total price')}
                value={formatCurrency((values.price / 100) * values.taxRate.rate + values.price)}
                fullWidth
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={4}>
            <CurrencyTextField name={'defaultDonationAmount'} label={t('default donation amount')} currencySymbol={'€'} fullWidth />
          </Grid>
        )}
      </>
      <Grid item container xs={12} justifyContent={'flex-start'}>
        {values.productType === Products.Type.DONATION && (
          <Grid item xs={6}>
            <Button
              name="add donation options"
              label={hasDonationOptions ? t('remove donation options') : t('add donation options')}
              variant="outlined"
              icon={hasDonationOptions ? 'remove_box' : 'add_box'}
              color="secondary"
              onClick={() => {
                if (hasDonationOptions) {
                  setFieldValue('donationOptions.isEnabled', false);
                  setHasDonationOptions(false);
                } else {
                  setFieldValue('donationOptions.isEnabled', true);
                  setHasDonationOptions(true);
                }
              }}
              fullWidth
            />
          </Grid>
        )}
      </Grid>

      {hasDonationOptions && (
        <>
          <Grid item xs={4}>
            <CurrencyTextField
              name={'donationOptions.option1'}
              label={t(`donation option 1`)}
              currencySymbol={'€'}
              required={true}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={4}>
            <CurrencyTextField
              name={'donationOptions.option2'}
              label={t(`donation option 2`)}
              currencySymbol={'€'}
              required={true}
              fullWidth={true}
            />
          </Grid>
          <Grid item xs={4}>
            <CurrencyTextField
              name={'donationOptions.option3'}
              label={t(`donation option 3`)}
              currencySymbol={'€'}
              required={true}
              fullWidth={true}
            />
          </Grid>
        </>
      )}

      <Grid item xs={4}>
        <FormikSelectField name="billingType" fullWidth label={t('typ')} data={type} required />
      </Grid>
      {values.billingType === 'RECURRING' && (
        <Grid item xs={4}>
          <FormikSelectField name="recurringBillingPeriod" fullWidth label={t('interval')} data={interval} />
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          {t('product image')}
        </Typography>
        <Field
          name="file"
          component={ProductImageDropzone}
          image={values.image}
          setFieldValue={setFieldValue}
          errorMessage={errors['file'] ? errors['file'] : undefined}
          touched={touched['file']}
          style={{ display: 'flex' }}
          onBlur={handleBlur}
        />
        <Divider />
      </Grid>

      <Grid item container xs={12} alignItems={'flex-end'} spacing={2}>
        <FieldArray
          name="customFields"
          render={({ push, remove }) => (
            <>
              {hasCustomFields &&
                values.customFields?.map((field: any, index: number) => (
                  <>
                    <Grid item xs={6}>
                      <FormikSizedInputField name={`customFields.${index}.fieldLabel`} label={t('field label')} max={120} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                      <FormikSelectField name={`customFields.${index}.fieldType`} label={t('field type')} data={customFieldsOptions} fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                      <FormControlLabel
                        className={classes.checkbox}
                        value={`customFields.${index}.required`}
                        control={
                          <Checkbox
                            checked={values.customFields[index].required}
                            onChange={(e) => {
                              setFieldValue(`customFields.${index}.required`, e.target.checked);
                            }}
                          />
                        }
                        label={t('required')}
                        labelPlacement="top"
                      />
                    </Grid>
                    <Grid item xs={1} alignContent={'flex-end'}>
                      <DeleteIcon
                        style={{
                          alignSelf: 'end',
                          color: '#AAAAAA',
                          marginLeft: '12px',
                        }}
                        fontSize="medium"
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    </Grid>
                  </>
                ))}
              <Grid item xs={6}>
                <Button
                  name="add custom field"
                  label={t('add custom field')}
                  variant="outlined"
                  icon="add_box"
                  color="secondary"
                  onClick={() => {
                    setHasCustomFields(true);
                    push({
                      externalIdentifier: '',
                      fieldLabel: '',
                      fieldType: '',
                      required: false,
                    });
                  }}
                  fullWidth
                />
              </Grid>
            </>
          )}
        />
      </Grid>
    </Grid>
  );
}

export default ProductForm;
