import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import LoadingButton from '../../../components/LoadingButton';
import { useTranslation } from 'react-i18next';
import MuiAlert from '@material-ui/lab/Alert';
import InstalmentPlanService from '../../../services/InstalmentPlanService';
import { AlertContext } from '../../../components/contexts/WithAlertContext';

type StartInstalmentPlanDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  instalmentPlanId: string;
};

const StartInstalmentPlanDialog = ({ isOpen, onCancel, onSuccess, instalmentPlanId }: StartInstalmentPlanDialogProps) => {
  const { t } = useTranslation('instalmentPlans');
  const [loading, setLoading] = useState(false);
  const alert = useContext(AlertContext);
  const startInstalmentPlan = () => {
    setLoading(true);
    InstalmentPlanService.startInstalmentPlan(instalmentPlanId)
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        alert.sendAlert('error', 'Something went wrong');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t('start instalment plan')}</DialogTitle>
      <DialogContent dividers>
        <MuiAlert elevation={6} variant="filled" severity="warning">
          {t('start instalment plan alert')}
        </MuiAlert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('global:cancel')}
        </Button>
        <LoadingButton type="button" loading={loading} onClick={() => startInstalmentPlan()}>
          {t('global:start')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default StartInstalmentPlanDialog;
