import React from 'react';
import { SEPAMandateListDataContext } from '../../sepaMandate/SEPAMandateListDataContext';
import PaginationProvider from '../../../components/table/PaginationContext';
import SEPAMandateTable from '../../sepaMandate/SEPAMandateTable';

export default function SEPAMandateTab() {
  return (
    <SEPAMandateListDataContext.Consumer>
      {({ currentPage, pageSize, page }) => (
        <>
          <PaginationProvider
            pageSizeOptions={[5, 10, 30]}
            currentPage={currentPage.get}
            totalPages={page?.totalPages || 0}
            pageSize={pageSize.get}
            setCurrentPage={currentPage.set}
            changePageSize={pageSize.set}
          >
            <SEPAMandateTable showPagination={true} />
          </PaginationProvider>
        </>
      )}
    </SEPAMandateListDataContext.Consumer>
  );
}
