import { TFunction } from 'i18next';
import * as Yup from 'yup';

export const InstalmentPlanFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    debitorId: Yup.string().required(t('required', { field: t('debitor') })),
    bankAccountId: Yup.string().required(t('required', { field: t('bankaccount') })),
    totalNetAmount: Yup.number()
      .required(t('required', { field: t('amount') }))
      .positive(t('amount positive')),
    purpose: Yup.string()
      .min(5)
      .max(120)
      .required(t('required', { field: t('purpose') })),
    interval: Yup.string().required(t('required', { field: t('interval') })),
    startDate: Yup.date().required(t('required', { field: t('startDate') })),
    intervalAmount: Yup.number()
      .required(t('required', { field: t('intervalAmount') }))
      .positive(t('interval amount positive'))
      .when('totalNetAmount', (totalNetAmount) => {
        return Yup.number().max(totalNetAmount, t('interval rate cannot be larger than total amount'));
      }),
    downPayment: Yup.number()
      .positive(t('interval amount positive'))
      .when(['totalNetAmount', 'intervalAmount'], (totalNetAmount, intervalAmount) => {
        return Yup.number().max(totalNetAmount - intervalAmount, t('interval rate cannot be larger than total amount'));
      }),
    intervalDay: Yup.number()
      .required(t('required', { field: t('intervalDay') }))
      .min(1, t('min max interval day'))
      .max(31, t('min max interval day')),
    metadata: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required(),
        value: Yup.string().required(),
      }),
    ),
  });
};

export const CustomiseInstalmentPlanFormSchema = (t: TFunction) => {
  return Yup.object().shape({
    totalNetAmount: Yup.number()
      .required(t('required', { field: t('amount') }))
      .positive(t('amount positive'))
      .when('coveredAmount', (coveredAmount) => {
        return Yup.number().min(
          coveredAmount,
          t('instalmentPlans:total net amount cannot be smaller than covered amount') + ': ' + coveredAmount + ' €',
        );
      }),
    purpose: Yup.string()
      .min(5)
      .max(120)
      .required(t('required', { field: t('purpose') })),
    intervalAmount: Yup.number()
      .required(t('required', { field: t('intervalAmount') }))
      .positive(t('interval amount positive'))
      .when(['totalNetAmount', 'coveredAmount'], (totalNetAmount, coveredAmount) => {
        return Yup.number().max(
          totalNetAmount - coveredAmount,
          t('instalmentPlans:interval rate cannot be larger than total amount') + ': ' + (totalNetAmount - coveredAmount) + ' €',
        );
      }),
    metadata: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(),
        value: Yup.string().required(),
      }),
    ),
  });
};
