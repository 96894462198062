import { makeStyles } from '@material-ui/core';

export const useRowStyles = makeStyles((theme) => ({
  even: {
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  odd: {
    border: 'none',
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    color: 'darkred',
    marginLeft: theme.spacing(1),
  },
  menu: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
