import React, { ChangeEvent, ReactNode } from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';

export default function CheckboxField({
  name,
  label,
  onChange = () => {},
  disabled = false,
  ...rest
}: {
  name: string;
  label: ReactNode;
  onChange?: () => void;
  disabled?: boolean;
}) {
  const [field, meta, helper] = useField(name);
  const { setValue } = helper;

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.checked);
    onChange();
  }

  return (
    <FormControl error={!!(meta.touched && meta.error)} disabled={disabled} {...rest}>
      <FormControlLabel
        value={meta.value}
        checked={meta.value}
        control={
          <Checkbox
            {...field}
            onChange={(event) => {
              _onChange(event);
              onChange();
            }}
          />
        }
        label={label}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
