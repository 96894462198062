import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckboxField from '../../../../components/FormFields/CheckboxField';
import InfoWithLabel from '../../../../components/ui/blocks/InfoWithLabel';
import tcs from '../assets/20210301_payactive GmbH_Allgemeine Nutzungsbedingungen.pdf';
import avv from '../assets/20210301_payactive GmbH_Auftragsverarbeitungsvertrag.pdf';
import pricing from '../assets/Preisliste_18_08_2023.pdf';
import onboardFormModel from '../FormModel/onboardFormModel';
import { NewOnboard } from '../../../../types/Onboard/Onboard';

export default function SummaryForm({
  formField: { acceptTermsAndConditions },
  values,
  status,
}: {
  values: NewOnboard;
  formField: typeof onboardFormModel.formField;
  status: any;
}) {
  const { t } = useTranslation(['company', 'onboarding']);

  return (
    <Box style={{ textAlign: 'left' }}>
      {status && <Alert severity="error">{status}</Alert>}
      <p>
        <InfoWithLabel label={t('company:companyName')} info={values.companyName} />
      </p>
      <p>
        <InfoWithLabel label={t('company:companyLegalForm')} info={values.companyLegalForm} />
      </p>
      {values.companyRegistrationNumber && (
        <p>
          <InfoWithLabel label={t('company:companyRegistrationNumber')} info={values.companyRegistrationNumber} />
        </p>
      )}
      <Divider />
      <p>
        <InfoWithLabel label={t('onboarding:yourName')} info={`${values.firstName} ${values.lastName}`} />
      </p>
      <p>
        <InfoWithLabel label={t('onboarding:your_email')} info={values.emailAddress} />
      </p>
      <Divider />

      <p>
        <CheckboxField name={acceptTermsAndConditions.name} label={<Typography>{t('onboarding:accept_terms_and_conditions')}</Typography>} />
      </p>
      <List>
        <ListItem>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <a target="_blank" rel="noopener noreferrer" href={tcs}>
                {t('onboarding:tcs')}
              </a>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <a target="_blank" rel="noopener noreferrer" href={avv}>
                {t('onboarding:avv')}
              </a>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <a target="_blank" rel="noopener noreferrer" href={pricing}>
                {t('onboarding:pricing')}
              </a>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
}
