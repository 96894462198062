import { FormikInputField } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  taxInformationHeader: {
    textAlign: 'left',
    display: 'block',
    color: '#0198B5',
    marginBottom: theme.spacing(2),
    fontSize: '18px',
  },
}));

function VatIdInput(props: any): ReactElement {
  const { ...other } = props;
  return (
    <MaskedInput
      {...other}
      mask={[/[A-Z]/, /[A-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholder="DE123456789"
    />
  );
}
export default function TaxInformation(): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation('company');
  return (
    <>
      <Typography variant="h5" className={classes.taxInformationHeader}>
        {t('tax information')}
        <Tooltip
          title={
            <Typography variant="caption" color="inherit">
              {t('info tax information')}
            </Typography>
          }
          placement="top"
        >
          <InfoIcon style={{ color: '#D3D3D3', marginBottom: '-6px', marginLeft: '10px' }} />
        </Tooltip>
      </Typography>
      <div>
        <FormikInputField
          type="text"
          className={classes.formControl}
          name="vatIdentificationNumber"
          label={t('vat id')}
          InputLabelProps={{ shrink: true }}
          fullWidth
          InputProps={{
            inputComponent: VatIdInput,
          }}
        />
        <FormikInputField
          className={classes.formControl}
          name="taxIdentificationNumber"
          InputLabelProps={{ shrink: true }}
          placeholder="12/345/67890"
          label={t('tax id')}
          fullWidth
        />
      </div>
    </>
  );
}
