import { createContext, ReactNode } from 'react';
import { Debtor } from '../../types/Debtor/Debtor';

export const DebtorContext = createContext<{ debtor?: Debtor }>({});

const WithDebtor = ({ children, debtor }: { children: ReactNode; debtor?: Debtor }) => {
  return <DebtorContext.Provider value={{ debtor: debtor }}>{children}</DebtorContext.Provider>;
};

export default WithDebtor;
