import { CreditNote } from '../../../types/CreditNote/CreditNote';
import React, { ReactNode } from 'react';
import CreditNoteDownloadAction from './CreditNoteDownloadAction';
import CreditNoteDetailsAction from './CreditNoteDetailsAction';

const hasDownloadAction = (creditNote: CreditNote) => {
  return ['OPEN', 'CANCELLED', 'PAID'].includes(creditNote.status);
};

export default function getActionsForCreditNote(
  creditNote: CreditNote,
  closeMenu: () => void,
  setMenuAnchor: (value: ((prevState: Element | undefined) => Element | undefined) | Element | undefined) => void,
  cardView = false,
) {
  const actions: ReactNode[] = [];

  if (hasDownloadAction(creditNote)) {
    actions.push(<CreditNoteDownloadAction creditNote={creditNote} closeMenu={closeMenu} />);
  }

  if (!cardView && hasDownloadAction(creditNote)) {
    actions.push(<CreditNoteDetailsAction creditNote={creditNote} setMenuAnchor={setMenuAnchor} />);
  }

  if (actions.length === 0) {
    return undefined;
  }

  return actions;
}

export { hasDownloadAction };
