import React from 'react';
import PaginationProvider from '../../../components/table/PaginationContext';
import { InstalmentPlanListDataContext } from '../../instalmentPlans/InstalmentPlanListDataContext';
import InstalmentPlansTable from '../../instalmentPlans/table/InstalmentPlansTable';

export default function InstalmentPlansTab() {
  return (
    <InstalmentPlanListDataContext.Consumer>
      {({ currentPage, pageSize, page }) => (
        <>
          <PaginationProvider
            pageSizeOptions={[5, 10, 30]}
            currentPage={currentPage.get}
            totalPages={page?.totalPages || 0}
            pageSize={pageSize.get}
            setCurrentPage={currentPage.set}
            changePageSize={pageSize.set}
          >
            <InstalmentPlansTable showPagination={true} />
          </PaginationProvider>
        </>
      )}
    </InstalmentPlanListDataContext.Consumer>
  );
}
