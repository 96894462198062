import ApiClient from './ApiClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { Page } from '../types/Page';
import { Account, User } from '../types/User/User';

export default class UserService extends ApiClient {
  private static get userClient(): AxiosInstance {
    return this.client;
  }

  public static async getAccounts(): Promise<Account[]> {
    let res = await this.userClient.request<Page<Account, 'accountDTOList'>>({
      method: 'GET',
      url: this.apiDomain + '/user/accounts',
    });

    return res.data._embedded?.accountDTOList || [];
  }

  public static async getUser(): Promise<User> {
    if (!this.cache.user) {
      this.cache.user = this.userClient
        .request<User, AxiosResponse<User>>({
          method: 'GET',
          url: this.apiDomain + '/user',
        })
        .then((res) => {
          return res.data;
        });
    }
    return this.cache.user;
  }
}
