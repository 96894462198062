import { Links } from '../Page';
import { CompanyBankAccount } from '../Bank/BankAccount';
import { CompanySettings } from './CompanySettings';
import { AgentApp } from '../Agent/AgentApp';
import { Address } from '../Address';

export type CompanyAccount = CompanyAccountPatch & {
  companyPublicId?: string;
  name: string;
  companyRegistrationNumber?: string;
  legalForm?: CompanyAccounts.LegalForm;
  bankAccount?: CompanyBankAccount;
  address?: Address;
  taxIdentificationNumber?: string;
  vatIdentificationNumber?: string;
  agentEnabled?: boolean;
  logoUrl?: string;
  connectedApps?: Array<AgentApp>;
  creditorIdentifier?: string;
  accountSettings?: CompanySettings.Account;
  bankingSettings?: CompanySettings.Banking;
  dunningServiceCreditorConfiguration?: CompanySettings.Dunning;
  brandingSettings?: CompanySettings.Branding;
  contactInformation?: CompanyAccounts.ContactInformation;
  termsAndConditionsConfiguration?: CompanySettings.TermsAndConditions;
  agentClient?: boolean;
  _links?: Links;
};

const toCompanyAccountPatch = (companyAccount: CompanyAccount): CompanyAccountPatch => {
  return {
    name: companyAccount.name,
    companyRegistrationNumber: companyAccount.companyRegistrationNumber,
    legalForm: companyAccount.legalForm,
    address: companyAccount.address,
    vatIdentificationNumber: companyAccount.vatIdentificationNumber,
    taxIdentificationNumber: companyAccount.taxIdentificationNumber,
    contactInformation: companyAccount.contactInformation,
    creditorIdentifier: companyAccount.creditorIdentifier,
  };
};

export { toCompanyAccountPatch };
export type CompanyAccountPatch = {
  name?: string;
  companyRegistrationNumber?: string;
  legalForm?: CompanyAccounts.LegalForm;
  address?: Address;
  vatIdentificationNumber?: string;
  taxIdentificationNumber?: string;
  contactInformation?: CompanyAccounts.ContactInformation;
  creditorIdentifier?: string;
};

export namespace CompanyAccounts {
  export enum LegalForm {
    GmbH = 'GmbH',
    GmbHCoKG = 'GmbHCoKG',
    AG = 'AG',
    GbR = 'GbR',
    OHG = 'OHG',
    KG = 'KG',
    UG = 'UG',
    EU = 'EU',
    FB = 'FB',
    EG = 'EG',
    EV = 'EV',
  }

  export type ContactInformation = {
    emailAddress: string;
    phoneNumber?: string;
    websiteUrl?: string;
  };
}
