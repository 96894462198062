import { TFunction } from 'i18next';
import * as Yup from 'yup';

const taxIdRegex =
  /\b(?:\d{2,3}(?:\s?\/\s?|\s)\d{3}(?:\s?\/\s?|\s)\d{5}|\d{3}(?:\s?\/\s?|\s)\d{4}(?:\s?\/\s?|\s)\d{4}|\d{5}(?:\s?\/\s?|\s)\d{5}|\d{10,11})\b/gm;

const vatIdRegex = /^[A-Za-z]{2,4}(?=.{2,12}$)[-_ \d]*(?:[a-zA-Z][-_ \d]*){0,2}$/gm;

//MISSING company types
const initialValues = (company: any) => {
  return {
    name: company.name || '',
    legalForm: company.legalForm || '',
    companyRegistrationNumber: company.companyRegistrationNumber || '',
    address: {
      line: company.address?.line || '',
      zipCode: company.address?.zipCode || '',
      city: company.address?.city || '',
      country: company.address?.country || '',
    },
    contactInformation: {
      emailAddress: company.contactInformation?.emailAddress || '',
      phoneNumber: company.contactInformation?.phoneNumber || undefined,
      websiteUrl: company.contactInformation?.websiteUrl || '',
    },
    vatIdentificationNumber: company.vatIdentificationNumber || '',
    taxIdentificationNumber: company.taxIdentificationNumber || '',
    termsAndConditionsConfiguration: {
      termsAndConditionsUrl: company.termsAndConditionsConfiguration?.termsAndConditionsUrl || '',
      privacyPolicyUrl: company.termsAndConditionsConfiguration?.privacyPolicyUrl || '',
    },
  };
};

const createSchemas = (tValidation: TFunction, t: TFunction) => {
  return Yup.object().shape({
    name: Yup.string().required(tValidation('required', { field: t('companyName') })),
    legalForm: Yup.string().required(tValidation('required', { field: t('legalForm') })),
    companyRegistrationNumber: Yup.string().when('legalForm', {
      is: 'GmbH',
      then: Yup.string().required(tValidation('required', { field: t('companyRegistrationNumber') })),
    }),
    address: Yup.object().shape({
      line: Yup.string().required(tValidation('required', { field: t('street') })),
      zipCode: Yup.string()
        .required(tValidation('required', { field: t('zipCode') }))
        .matches(/^(?:\d{5}|\d{4}|\d{2}-\d{3}|\d{3}-\d{2}|\d{2}\s\d{2})$/, tValidation('only numbers')),
      city: Yup.string().required(tValidation('required', { field: t('city') })),
      country: Yup.string().required(tValidation('required', { field: t('country') })),
    }),
    contactInformation: Yup.object().shape({
      emailAddress: Yup.string().required(tValidation('required', { field: t('emailAddress') })),
      websiteUrl: Yup.string().required(tValidation('required', { field: t('Website') })),
    }),
    taxIdentificationNumber: Yup.string().matches(taxIdRegex, tValidation('valid', { field: t('tax id') })),
    vatIdentificationNumber: Yup.string().matches(vatIdRegex, tValidation('valid', { field: t('vat id') })),
  });
};

export { createSchemas, initialValues };
