import React, { useContext, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { PaymentActionType } from './PaymentActionHelper';
import MarkAsDonationDialog from '../dialogs/MarkAsDonationDialog';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import PaymentService from '../../../services/PaymentService';
import { useTranslation } from 'react-i18next';

const PaymentMarkAsDonationAction = ({ payment, setMenuAnchor, label }: PaymentActionType) => {
  const [markAsDonationDialog, setMarkAsDonationDialog] = useState(false);
  const [isMarkedAsDonation, setIsMarkedAsDonation] = useState(false);
  const { t } = useTranslation('payments');
  const alerting = useContext(AlertContext);

  const markPaperlessAsDonation = () => {
    PaymentService.markPaymentAsDonation(payment).then((res) => {
      if (res) {
        setIsMarkedAsDonation(true);
        alerting.sendAlert('success', t('payments:payment marked as donation'));
        setMarkAsDonationDialog(false);
      }
    });
  };

  return !isMarkedAsDonation ? (
    <div key={payment.id + 'markAsDonation'}>
      <MenuItem
        key={payment.id + 'markAsDonation'}
        onClick={() => {
          setMenuAnchor(null);
          setMarkAsDonationDialog(true);
        }}
      >
        {label}
      </MenuItem>
      <MarkAsDonationDialog open={markAsDonationDialog} onCancel={() => setMarkAsDonationDialog(false)} onMarkAsDonation={markPaperlessAsDonation} />
    </div>
  ) : (
    <></>
  );
};

export default PaymentMarkAsDonationAction;
