import { useTranslation } from 'react-i18next';
import InvoiceListDataContextProvider, { InvoiceListDataContext } from './InvoiceListDataContextProvider';
import { Button, Grid } from '@material-ui/core';
import PageHeader from '../../components/ui/blocks/PageHeader';
import React, { useState } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import InvoiceDialog from './dialogs/InvoiceDialog';
import { InvoiceTable } from './table/InvoiceTable';
import GetAppIcon from '@material-ui/icons/GetApp';
import InvoicesExportDialog from './dialogs/InvoicesExportDialog';
import GenericTableFilters, { StateFilter } from '../../components/filter/GenericTableFilters';
import { InvoiceState } from '../../types/Invoice/InvoiceState';
import InvoiceExportDataContextProvider from './dialogs/InvoiceExportDataContextProvider';

const InvoicesView = () => {
  const { t } = useTranslation(['debitors', 'invoices']);
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [showInvoicesExportDialog, setShowInvoicesExportDialog] = useState(false);

  const filterArray: StateFilter[] = [
    { name: t('invoices:DRAFT'), value: InvoiceState.DRAFT },
    { name: t('invoices:OPEN'), value: InvoiceState.OPEN },
    { name: t('invoices:PAID'), value: InvoiceState.PAID },
    { name: t('invoices:CANCELLED'), value: InvoiceState.CANCELLED },
    { name: t('invoices:CONFLICT'), value: InvoiceState.CONFLICT },
    { name: t('invoices:REFUND_IN_PROGRESS'), value: InvoiceState.REFUND_IN_PROGRESS },
    { name: t('invoices:CANCELLATION_COMPLETED'), value: InvoiceState.CANCELLATION_COMPLETED },
    { name: t('invoices:REMINDED'), value: InvoiceState.REMINDED },
    { name: t('invoices:IN_DUNNING'), value: InvoiceState.IN_DUNNING },
    { name: t('invoices:UNCOLLECTIBLE'), value: InvoiceState.UNCOLLECTIBLE },
  ];

  return (
    <InvoiceListDataContextProvider pageSizeOptions={[5, 10, 30]}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeader title={t('invoice')} />
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" startIcon={<AddBoxIcon />} onClick={() => setShowInvoiceDialog(true)}>
                {t('create new invoice')}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" startIcon={<GetAppIcon />} onClick={() => setShowInvoicesExportDialog(true)}>
                {t('export')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <GenericTableFilters states={filterArray} context={InvoiceListDataContext} t={t} />
      </Grid>
      <InvoiceTable showPagination={true} />

      {showInvoiceDialog && (
        <InvoiceDialog
          invoice={undefined}
          onClose={() => {
            setShowInvoiceDialog(false);
          }}
        />
      )}
      {showInvoicesExportDialog && (
        <InvoiceExportDataContextProvider>
          <InvoicesExportDialog onClose={() => setShowInvoicesExportDialog(false)} />
        </InvoiceExportDataContextProvider>
      )}
    </InvoiceListDataContextProvider>
  );
};

export default InvoicesView;
