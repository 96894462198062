import { Form, Formik } from 'formik';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, makeStyles, Switch, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Product, Products } from '../../../types/Product/Product';
import { formatCurrency } from '../../../utils/PaymentUtil';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, FormikInputField, FormikSelectField } from '@payactive/app-common';
import LoadingButton from '../../../components/LoadingButton';
import { CreateProductDialog } from '../../products/dialogs/CreateProductDialog';
import DeleteProductDialog from './DeleteProductDialog';
import React, { useContext, useEffect, useState } from 'react';
import { paylinkSchemas } from './schemas';
import { useTranslation } from 'react-i18next';
import { NewCheckoutLink } from '../../../types/CheckoutLink/CheckoutLink';
import { FormikErrors } from 'formik/dist/types';
import ProductService from '../../../services/ProductService';
import { AlertContext } from '../../../components/contexts/WithAlertContext';

const useStyles = makeStyles(() => ({
  productsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  rightProductContent: {
    display: 'flex',
  },
  toggleContainer: {
    margin: '20px 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  emailField: {
    width: '90%',
    marginLeft: 'auto',
  },
  autocomplete: {
    '&[data-focus="true"]': {
      backgroundColor: '#2ed2f559',
    },
  },
  backDrop: {
    backdropFilter: 'blur(30px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
}));

enum PayLinkStep {
  NEWPAYLINK = 0,
  NEWPRODUCT = 1,
  DELETEPRODUCT = 2,
}

type Props = {
  isOpen: boolean;
  onSubmit: (values: NewCheckoutLink, actions: any) => void;
  onCancel: () => void;
  initialValues: NewCheckoutLink;
};

export default function CheckoutLinkDialog({ initialValues, isOpen, onSubmit, onCancel }: Props) {
  const alerting = useContext(AlertContext);
  const { t } = useTranslation('payments');
  const { t: tValidations } = useTranslation('validations');
  const schema = paylinkSchemas(tValidations);

  const [step, setStep] = useState(PayLinkStep.NEWPAYLINK);
  const [products, setProducts] = useState<Product[]>([] as Product[]);
  const [option, setOption] = useState();
  const [product, setProduct] = useState<Product | null>(null);
  const [isUrlField, setIsUrlField] = useState(initialValues.includeRedirectUrl);
  const [checkoutLinkRelated, setCheckoutLinkRelated] = useState(true);

  const [isOpenDropdown, setIsOpenDropdown] = useState(true);
  const [requireAddress, setRequireAddress] = useState(initialValues.requireAddress);

  const classes = useStyles();
  const isEditMode = !!initialValues.id;

  const BUTTON_LABELS = [
    { value: 'BUY', label: t('buy') },
    { value: 'DONATE', label: t('donate') },
    { value: 'PAY', label: t('pay') },
    { value: 'SUBSCRIBE', label: t('subscribe') },
  ];

  console.log(initialValues);

  useEffect(() => {
    if (!!initialValues.productId) {
      ProductService.getProduct(initialValues.productId).then((product) => setProduct(product));
      setIsOpenDropdown(false);
    }

    ProductService.getProducts(1000, 0, 'desc', {}).then(({ results, page }) => {
      if (!!results) {
        setProducts(results);
      } else {
        setProducts([]);
      }
    });
  }, []);

  const handleGenerateReceiptToggleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (generateReceipt: string, p: boolean) => Promise<void | FormikErrors<NewCheckoutLink>>,
  ) => {
    setFieldValue('generateReceipt', e.target.checked && true);
    setFieldValue('requireAddress', e.target.checked && true);
    setRequireAddress(e.target.checked);
  };

  const checkBeforeDelete = (option: any) => {
    setOption(option);
    ProductService.getProductCheckDelete(option.id).then((status) => {
      if (status === 200) {
        setCheckoutLinkRelated(false);
      } else if (status === 409) {
        setCheckoutLinkRelated(true);
      }
      setStep(PayLinkStep.DELETEPRODUCT);
    });
  };

  const checkForButtonLabel = (id: string) => {
    const selectedProduct = products.find((product) => product.id === id);
    if (selectedProduct && selectedProduct.billingType === 'RECURRING') {
      return BUTTON_LABELS;
    }
    return BUTTON_LABELS.filter((option) => option.value !== 'SUBSCRIBE');
  };

  const deleteProduct = (id: string, cascadeDelete: boolean) => {
    ProductService.deleteProduct(id, cascadeDelete).then((res) => {
      if (res) {
        alerting.sendAlert('success', t('product deleted successfully'));
        setStep(PayLinkStep.NEWPAYLINK);
        setIsOpenDropdown(true);
      }
    });
  };

  const comeBack = () => {
    setStep(PayLinkStep.NEWPAYLINK);
    setIsOpenDropdown(true);
  };

  const redirectToPaylink = (newProduct: any) => {
    setStep(PayLinkStep.NEWPAYLINK);
    setIsOpenDropdown(true);

    alerting.sendAlert('success', t('product created successfully'));
  };

  return (
    <>
      {step === 0 && (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
          {({ setFieldValue, values, isSubmitting }) => (
            <Dialog
              maxWidth="sm"
              fullWidth
              open={isOpen}
              onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                  return false;
                }
                onCancel();
              }}
              aria-labelledby="alert-dialog-title"
              BackdropProps={{
                classes: {
                  root: classes.backDrop,
                },
              }}
            >
              <Form>
                <DialogTitle id="alert-dialog-title">{isEditMode ? t('edit payment link') : t('create new payment link')}</DialogTitle>
                <DialogContent dividers>
                  <Autocomplete
                    autoHighlight
                    options={products}
                    value={product || null}
                    open={isOpenDropdown}
                    onOpen={() => setIsOpenDropdown(!isOpenDropdown)}
                    clearOnBlur={true}
                    classes={{ option: classes.autocomplete }}
                    onClose={(e, reason) => {
                      return (reason === 'select-option' || reason === 'toggleInput') && setIsOpenDropdown(false);
                    }}
                    getOptionLabel={(option) => {
                      return option.name ? option.name : t('choose or create a product');
                    }}
                    onChange={(event, values, reason) => {
                      if ((values && 'name' in values) || reason === 'clear') {
                        setProduct(values);
                        setFieldValue('productId', values?.id);
                        if (values?.productType === Products.Type.DONATION || values?.billingType === Products.BillingType.RECURRING) {
                          setFieldValue('allowQuantityChange', false);
                        }
                      } else {
                        setStep(PayLinkStep.NEWPRODUCT);
                      }
                    }}
                    renderOption={(option, state) => {
                      if (option.name) {
                        return (
                          <div className={classes.productsContainer}>
                            <Typography>{option.name}</Typography>
                            <div className={classes.rightProductContent}>
                              <Typography>{formatCurrency(option.price)}</Typography>
                              <DeleteIcon
                                style={{
                                  alignSelf: 'center',
                                  color: '#AAAAAA',
                                  marginLeft: '12px',
                                }}
                                fontSize="small"
                                onClick={() => {
                                  checkBeforeDelete(option);
                                }}
                              />
                            </div>
                          </div>
                        );
                      } else {
                        return option;
                      }
                    }}
                    filterOptions={(options, params) => {
                      options.unshift(
                        (
                          <Button
                            {...params}
                            style={{
                              fontSize: '16px',
                              fontWeight: 300,
                              height: '36px',
                            }}
                            variant="text"
                            name="add new product"
                            icon="add_box"
                            label={t('add new product')}
                            color="secondary"
                          />
                        ) as any,
                      );
                      return options;
                    }}
                    renderInput={(params) => (
                      <FormikInputField
                        {...params}
                        required
                        name="productId"
                        label={t('product')}
                        placeholder={t('choose or create a product')}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                  <FormikSelectField name="checkoutButtonLabel" label={t('button label')} data={checkForButtonLabel(values.productId)} fullWidth />
                  <div className={classes.toggleContainer}>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => handleGenerateReceiptToggleChange(e, setFieldValue)}
                          name="generateReceipt"
                          checked={values.generateReceipt}
                        />
                      }
                      label={t('generate receipt')}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={(e) => {
                            setFieldValue('requireAddress', e.target.checked && true);
                            setRequireAddress(e.target.checked);
                          }}
                          checked={requireAddress}
                          disabled={values.generateReceipt === true}
                          name="requireAddress"
                        />
                      }
                      label={t('ask customer address')}
                    />
                    <FormControlLabel
                      disabled={product?.productType === Products.Type.DONATION || product?.billingType === Products.BillingType.RECURRING}
                      control={
                        <Switch
                          checked={values.allowQuantityChange}
                          onChange={(e) => setFieldValue('allowQuantityChange', e.target.checked && true)}
                          name="allowQuantityChange"
                        />
                      }
                      label={t('quantity selectable')}
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isUrlField}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue('includeRedirectUrl', true);
                              setIsUrlField(true);
                            } else {
                              setIsUrlField(false);
                              setFieldValue('includeRedirectUrl', false);
                              setFieldValue('redirectUrl', '');
                            }
                          }}
                          name="includeRedirectUrl"
                        />
                      }
                      label={t('URL for redirect after checkout')}
                    />
                    {isUrlField && (
                      <FormikInputField
                        className={classes.emailField}
                        name="redirectUrl"
                        placeholder="https://payactive.eu"
                        label={t('redirect url')}
                        fullWidth
                      />
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCancel} variant="text" color="primary" name="cancel button" label={t('global:cancel')} />
                  <LoadingButton loading={isSubmitting} type="submit">
                    {t('save paylink')}
                  </LoadingButton>
                </DialogActions>
              </Form>
            </Dialog>
          )}
        </Formik>
      )}
      {step === 1 && (
        <CreateProductDialog
          variant="paylinks"
          comeBack={comeBack}
          redirectToOrigin={redirectToPaylink}
          isOpen={step === PayLinkStep.NEWPRODUCT}
          onCancel={() => setStep(PayLinkStep.NEWPAYLINK)}
        />
      )}

      {step === 2 && (
        <DeleteProductDialog
          open={step === 2}
          onClose={() => setStep(PayLinkStep.NEWPAYLINK)}
          extra={checkoutLinkRelated ? 'withPaylinks' : 'noPaylinks'}
          onDelete={deleteProduct}
          product={option}
        />
      )}
    </>
  );
}
