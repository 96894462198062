import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { ProductInvoicePosition, TextInvoicePosition } from '../../../types/Invoice/InvoicePosition';

interface Props {
  taxes: {} | any;
  subTotal?: number;
  total?: number;
  positions: Array<ProductInvoicePosition | TextInvoicePosition>;
  defaultTaxRate: number;
  reverseCharge: boolean;
}

export default function ViewPositions(props: Props) {
  const { t } = useTranslation('debitors');

  const taxesKeys = !!props.taxes ? Object.keys(props.taxes) : [];
  const { reverseCharge } = props;

  return (
    <TableContainer>
      <br />
      <Divider />
      <Table aria-label="custom table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>{t('description')}</b>
            </TableCell>
            <TableCell align="right">
              <b>{t('quantity')}</b>
            </TableCell>
            <TableCell align="right">
              <b>{t('price')}</b>
            </TableCell>
            <TableCell align="right">
              <b>{t('tax rate')}</b>
            </TableCell>
            <TableCell align="right">
              <b>{t('lineTotal')}</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.positions.map((position, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <b>{position.description}</b>
                <br />
                <span>{position.descriptionSubText}</span>
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {position.quantity}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {formatCurrency(position.price)}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {reverseCharge
                  ? '-'
                  : position.taxRate?.rate === -1
                  ? props.defaultTaxRate === -1
                    ? '-'
                    : `${props.defaultTaxRate}%`
                  : `${position.taxRate?.rate}%`}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {formatCurrency(position.quantity * position.price)}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell rowSpan={4} />
            <TableCell colSpan={3}>
              <b>{t('subTotal')}</b>
            </TableCell>
            <TableCell align="right">
              <b>{formatCurrency(props.subTotal)}</b>
            </TableCell>
          </TableRow>
          {!reverseCharge &&
            taxesKeys?.map((key, index) => (
              <TableRow key={index}>
                <TableCell>{`${key}% USt.`}</TableCell>
                <TableCell colSpan={3} align="right">
                  {formatCurrency(props.taxes[key])}
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={3}>
              <b>{t('total')}</b>
            </TableCell>
            <TableCell align="right">
              <b>{formatCurrency(reverseCharge ? props.subTotal : props.total)}</b>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableBody></TableBody>
      </Table>
    </TableContainer>
  );
}
