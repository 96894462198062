import React, { useContext } from 'react';
import { Form, Formik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import LoadingButton from '../../../components/LoadingButton';
import WebHookForm from './NewWebhookForm';
import { createSchemas } from './schema';

import { useTranslation } from 'react-i18next';
import WebhookService from '../../../services/WebhookService';

const initialValues = {
  url: '',
  name: '',
  events: [],
};
//TODO to Typescript
export default function CreateNewWebhook(props) {
  const { t } = useTranslation('developer');

  const { t: tValidations } = useTranslation('validations');

  const schema = createSchemas(tValidations)[0];

  const alerting = useContext(AlertContext);

  const { isOpen, onCancel, onSuccess, setSigningSecret } = props;

  const _handleSubmit = (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    WebhookService.createWebhook(values)
      .then((webhook) => {
        if (webhook) {
          onSuccess();
          alerting.sendAlert('success', t('new webhook created'));
          setSigningSecret(webhook.signingSecret);
        } else {
          throw new Error();
        }
        setSubmitting(false);
      })
      .catch(() => {
        setStatus({
          status: 'BAD_REQUEST',
          message: 'Validation errors',
          validationErrors: [
            {
              field: 'url',
              rejectedValue: values.url,
              message: 'URL is not available!',
            },
          ],
        });
      });
  };

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={_handleSubmit}>
      {({ setFieldValue, errors, touched, handleBlur, values, status, isSubmitting }) => (
        <Dialog
          maxWidth="sm"
          fullWidth
          aria-labelledby="alert-dialog-title"
          open={isOpen}
          onClose={(event, reason) => {
            if (reason === 'backdropClick') {
              return false;
            }
            onCancel();
          }}
        >
          <Form>
            <DialogTitle id="alert-dialog-title">{t('add new webhook')}</DialogTitle>
            <DialogContent dividers>
              <Typography variant="body1">{t('webhook details')}</Typography>
              <WebHookForm status={status} values={values} setFieldValue={setFieldValue} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="primary">
                {t('global:cancel')}
              </Button>
              <LoadingButton disabled={values.events.length <= 0} loading={isSubmitting} type="submit">
                {t('create endpoint')}
              </LoadingButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
