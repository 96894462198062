import React, { MouseEvent, ReactElement, useContext, useState } from 'react';
import { Subscription, Subscriptions } from '../../../types/Subscription/Subscription';
import { Avatar, Badge, Icon, IconButton, makeStyles, Menu, MenuItem, TableCell, TableRow } from '@material-ui/core';
import StateWithIcon from '../../../components/StateWithIcon';
import { formatCurrency } from '../../../utils/PaymentUtil';
import { formatDate, formatDateTime } from '../../../utils/DateTimeUtil';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { SubscriptionListDataContext } from '../SubscriptionsContextProvider';
import SubscriptionCancelDialog from '../dialogs/SubscriptionCancelDialog';
import SubscriptionService from '../../../services/SubscriptionService';
import LinkToDebtorDetailPage from '../../debtorDetailPage/LinkToDebtorDetailPage';

interface SubscriptionRowProps {
  subscription: Subscription;
  index: number;
}

const useStyles = makeStyles((theme) => ({
  even: {
    border: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  odd: {
    border: 'none',
    backgroundColor: theme.palette.background.default,
  },
  select: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  icon: {
    color: '#FFFFFF',
  },
  menu: {
    backgroundColor: theme.palette.secondary.main,
    color: '#FFFFFF',
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },
}));

const SubscriptionRow = ({ subscription, index }: SubscriptionRowProps): ReactElement => {
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const menuOpen = Boolean(menuAnchor);
  const reload = useContext(SubscriptionListDataContext).reload || (() => {});

  const { t } = useTranslation('payments');
  const classes = useStyles();

  const alerting = useContext(AlertContext);
  const isPreFiltered = useContext(SubscriptionListDataContext).isPrefilteredForDebtor;

  const handleClick = (event: MouseEvent) => {
    setMenuAnchor(event.currentTarget);
  };

  const doCancelSubscription = () => {
    SubscriptionService.cancelSubscription(subscription.id)
      .then((res) => {
        if (res) {
          setCancelSubscription(false);
          reload();
          alerting.sendAlert('success', t('subscription cancelled'));
        }
      })
      .catch((error) => {
        alerting.sendAlert('error', t('subscription error cancelled'));
      });
  };

  const actions: Action[] = [
    {
      id: 'cancel',
      label: t('kündigen'),
      callback: () => setCancelSubscription(true),
    },
  ];

  const ITEM_HEIGHT = 48;

  return (
    <TableRow key={index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <TableCell key={subscription.id + 'state'}>
        <StateWithIcon
          state={subscription.status}
          label={t(subscription.status)}
          popoverContent={
            subscription.status === Subscriptions.Status.CANCELLATION_REQUESTED ? (
              <>
                {t('cancellation_request_details')}
                <br />
                {t('cancellation_request_target_date', {
                  targetDate: formatDate(subscription.cancellationRequest.targetDate),
                })}

                {subscription.cancellationRequest.reason && (
                  <>
                    <br />
                    {t('cancellation_request_details_reason', { reason: subscription.cancellationRequest.reason })}
                  </>
                )}
              </>
            ) : undefined
          }
        />
      </TableCell>
      {!isPreFiltered && (
        <TableCell key={subscription.id + 'debtor'}>
          <LinkToDebtorDetailPage target={'subscriptions'} debtor={subscription.debtor} debtorRef={subscription.debtor.id}></LinkToDebtorDetailPage>
        </TableCell>
      )}
      <TableCell key={subscription.id + 'abo-model'}>{subscription.subscriptionTitle}</TableCell>
      <TableCell key={subscription.id + 'price'}>{formatCurrency(subscription.price)}</TableCell>
      <TableCell key={subscription.id + 'interval'}>{t(subscription.interval)}</TableCell>
      <TableCell key={subscription.id + 'duration'}>{t('unlimited')}</TableCell>
      <TableCell key={subscription.id + 'nextPaymentDueDate'}>
        {!!subscription.nextPaymentDueDate ? formatDate(subscription.nextPaymentDueDate) : '--'}
      </TableCell>
      <TableCell key={subscription.id + 'createdDateTime'}>{formatDateTime(subscription.createdDateTime)}</TableCell>

      {!!actions && (
        <TableCell align="left">
          {[Subscriptions.Status.ACTIVE, Subscriptions.Status.CANCELLATION_REQUESTED].includes(subscription.status) && (
            <Badge badgeContent={0} color="error" overlap="circular">
              <IconButton onClick={handleClick}>
                <Avatar className={classes.avatar}>
                  <Icon>more_horiz</Icon>
                </Avatar>
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={menuAnchor}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuAnchor(null)}
                PaperProps={{
                  className: classes.menu,
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: 'min-content',
                  },
                }}
              >
                {actions.map((action, row) => (
                  <MenuItem
                    key={action.id}
                    onClick={() => {
                      setMenuAnchor(null);
                      action.callback();
                    }}
                  >
                    {action.label}
                  </MenuItem>
                ))}
              </Menu>
            </Badge>
          )}
        </TableCell>
      )}
      {cancelSubscription && <SubscriptionCancelDialog open={true} onClose={() => setCancelSubscription(false)} onDelete={doCancelSubscription} />}
    </TableRow>
  );
};

type Action = {
  id: string;
  label: string;
  callback: () => void;
};

export default SubscriptionRow;
