import { Button, Card, CardActions, CardContent, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LogoProducts from './Logo';

const useStyles = makeStyles((theme) => ({
  card: {
    height: 180,
    width: 450,
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  cardContent: {
    paddingTop: '0px',
  },
  logoSubtitle: {
    lineHeight: '1.5em',
    fontSize: '0.7rem',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    borderRadius: '7px',
    marginLeft: 'auto',
    color: 'white',
    backgroundColor: theme.palette.success.main,
  },
  serviceInfo: {
    marginTop: theme.spacing(1),
    textAlign: 'left',
  },
  link: {
    color: theme.palette.secondary.main,
  },
  cardAction: {
    justifyContent: 'center',
    position: 'absolute',
    bottom: '0',
    left: '28%',
    width: '168px',
  },
  active: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
  inactive: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
}));

export default function ServiceBox(props) {
  const classes = useStyles();
  const { service, handleChange, servicesList, isActive } = props;
  const { name, info, link } = service;
  const { t } = useTranslation('services');

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.headerContainer}>
            <LogoProducts serviceName={name} />
            {servicesList.map((serv) => serv.service === service.service && <Chip className={classes.chip} label={t('active')} />)}
          </div>
          <Typography color="secondary" className={classes.serviceInfo} variant="body2" component="h2">
            {info}{' '}
            <a className={classes.link} href="https://payactive.eu/de/loesungen" target="_blank" rel="noreferrer">
              {link}{' '}
            </a>
          </Typography>
        </CardContent>
        <CardActions className={classes.cardAction}>
          <Button className={isActive ? classes.active : classes.inactive} variant="contained" onClick={handleChange}>
            {isActive ? t('deactivate button') : t('activate button')}
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
}
