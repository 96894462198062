import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import { Button } from '@payactive/app-common';

import { Alert } from '@material-ui/lab';
import React, { FC, useContext, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/LoadingButton';
import { createCsvStructure, ExportData } from './InvoicesExportFunctions';
import { InvoiceFilter } from '../../../types/Invoice/Invoice';
import InvoiceService from '../../../services/InvoiceService';
import GenericTableFilters, { StateFilter } from '../../../components/filter/GenericTableFilters';
import { InvoiceState } from '../../../types/Invoice/InvoiceState';
import { InvoiceExportDataContext } from './InvoiceExportDataContextProvider';
import moment from 'moment/moment';
import { AlertContext } from '../../../components/contexts/WithAlertContext';

interface InvoicesExportDialogProps {
  onClose: () => void;
}

enum ExportStatus {
  PREPARE,
  COLLECT,
  DOWNLOAD,
  EMPTY,
}

const InvoicesExportDialog: FC<InvoicesExportDialogProps> = ({ onClose }) => {
  const { t } = useTranslation(['debitors', 'invoices']);
  const alert = useContext(AlertContext);

  const [invoicesToExport, setInvoicesToExport] = useState<ExportData>();
  const [status, setStatus] = useState(ExportStatus.PREPARE);
  const [collectionState, setCollectionState] = useState(0);

  const filterArray: StateFilter[] = [
    { name: t('invoices:DRAFT'), value: InvoiceState.DRAFT },
    { name: t('invoices:OPEN'), value: InvoiceState.OPEN },
    { name: t('invoices:PAID'), value: InvoiceState.PAID },
    { name: t('invoices:CANCELLED'), value: InvoiceState.CANCELLED },
    { name: t('invoices:CONFLICT'), value: InvoiceState.CONFLICT },
    { name: t('invoices:REFUND_IN_PROGRESS'), value: InvoiceState.REFUND_IN_PROGRESS },
    { name: t('invoices:CANCELLATION_COMPLETED'), value: InvoiceState.CANCELLATION_COMPLETED },
    { name: t('invoices:REMINDED'), value: InvoiceState.REMINDED },
    { name: t('invoices:IN_DUNNING'), value: InvoiceState.IN_DUNNING },
    { name: t('invoices:UNCOLLECTIBLE'), value: InvoiceState.UNCOLLECTIBLE },
  ];

  const filters = useContext(InvoiceExportDataContext).filters;

  async function collectInvoices() {
    setStatus(ExportStatus.COLLECT);

    const filter: InvoiceFilter = {};
    if (!!filters?.stateFilter) {
      filter.statuses = Array.from(filters?.stateFilter.values()).join(',');
    }
    filter.search = undefined;
    filter.startDate = filters?.dateStartFilter && moment(filters?.dateStartFilter).format('YYYY-MM-DDT00:00:00.1') + 'Z';
    filter.endDate = filters?.dateEndFilter && moment(filters?.dateEndFilter).format('YYYY-MM-DDT23:59:59.999') + 'Z';

    const res = await InvoiceService.getAllInvoices('desc', filter, setCollectionState, () => {
      alert.sendAlert('error', t('invoices:something wrong during export'));
    });
    if (res.results.length !== 0) {
      const csv = createCsvStructure(res.results, t);
      setInvoicesToExport(csv);
      setStatus(ExportStatus.DOWNLOAD);
    } else {
      setStatus(ExportStatus.EMPTY);
    }
  }

  return (
    <Dialog maxWidth="md" fullWidth aria-labelledby="invoices-export-dialog" open={true} onClose={onClose}>
      <DialogTitle>{t('invoices:export invoices title')}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{t('invoices:export information')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <GenericTableFilters
              states={filterArray}
              context={InvoiceExportDataContext}
              t={t}
              disabled={status !== ExportStatus.PREPARE && status !== ExportStatus.EMPTY}
            />
          </Grid>

          {status === ExportStatus.EMPTY && (
            <Grid item xs={12}>
              <Alert severity="warning" variant="outlined">
                {t('invoices:nothing to export')}
              </Alert>
            </Grid>
          )}
          {status === ExportStatus.COLLECT && (
            <Grid item xs={12}>
              <Alert severity="info" variant="outlined">
                {t('invoices:collection running')}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button name="close" onClick={onClose} color="primary" variant="text" label={t('global:cancel')} />
        {(status === ExportStatus.PREPARE || status === ExportStatus.EMPTY) && (
          <Button name="collect" onClick={() => collectInvoices()} color="primary" label={t('invoices:export invoices')} />
        )}
        {status === ExportStatus.COLLECT && (
          <LoadingButton loading={true} type="submit" onClick={() => collectInvoices()}>
            {t('invoices:collecting')} ({collectionState} {t('Invoices')})
          </LoadingButton>
        )}
        {status === ExportStatus.DOWNLOAD && !!invoicesToExport && (
          <LoadingButton loading={false} /*onClick={onClose}*/ color="primary">
            <CSVLink
              data={invoicesToExport.data}
              headers={invoicesToExport.headers}
              onClick={onClose}
              filename={'invoice-export.csv'}
              style={{ textDecoration: 'none', color: '#fff' }}
            >
              {t('global:download')} ({invoicesToExport.data.length} {t('Invoices')})
            </CSVLink>
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default InvoicesExportDialog;
