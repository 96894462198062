export type BankAccountList = {
  bankAccounts: BankAccount[];
  allowTransactionsCsv: boolean;
};

export type BankAccount = CompanyBankAccount & {
  default?: boolean;
  status: BankAccounts.Status;
  expiryDate: string;
};

export type UpdateBankAccount = {
  bankAccountLabel?: string;
  default?: boolean;
};

export type CompanyBankAccount = {
  accountHolder: string;
  iban: string;
  bic: string;
  id: string;
  bankName: string;
  bankAccountLabel: string;
  ebicsConnectionReference: string;
  directDebitActive: boolean;
  countryCode?: string;
};

export namespace BankAccounts {
  export enum Status {
    ACTIVE = 'ACTIVE',
    WILL_EXPIRE = 'WILL_EXPIRE',
    HAS_EXPIRED = 'HAS_EXPIRED',
  }
}
