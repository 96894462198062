const RegistrationFormModel = {
  formId: 'registrationForm',
  formField: {
    emailAddress: {
      name: 'emailAddress',
      label: 'Email Address*',
      requiredErrorMsg: 'E-Mail Address is required',
    },
    firstName: {
      name: 'firstName',
      label: 'First Name*',
      requiredErrorMsg: 'First Name is required',
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name*',
      requiredErrorMsg: 'Last Name is required',
    },
    password: {
      name: 'password',
      label: 'Password*',
      requiredErrorMsg: 'Password is required',
    },
    passwordConfirmation: {
      name: 'passwordConfirmation',
      label: 'Confirm Password*',
      requiredErrorMsg: 'Password Confirmation is required',
    },
    invitationId: {
      name: 'invitationId',
    },
  },
};
export default RegistrationFormModel;
