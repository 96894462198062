import { LinearProgress, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EbicsConnection, EbicsConnections } from '../../../../../types/Ebics/EbicsConnection';

export default function WaitingForActivation({ ebicsConnection }: { ebicsConnection: EbicsConnection }) {
  const { t } = useTranslation('sepa');

  return (
    <>
      {ebicsConnection.status === EbicsConnections.Status.WAITING_FOR_ACTIVATION ? (
        <>
          <p>
            <Typography>{t('waiting for activation')}</Typography>
          </p>
          <p>
            <LinearProgress />
          </p>
        </>
      ) : (
        <>
          <p>
            <Alert severity="success" variant="outlined">
              <Typography>{t('your account was successfully activated')}</Typography>
            </Alert>
          </p>
        </>
      )}
    </>
  );
}
