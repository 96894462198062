import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { InvoiceListDataContext } from '../InvoiceListDataContextProvider';
import { MenuItem } from '@material-ui/core';
import FinalizeCorrectionInvoiceDialog from '../dialogs/FinalizeCorrectionInvoiceDialog';
import CorrectionInvoiceDialog from '../dialogs/CorrectionInvoiceDialog';
import { InvoiceActionType } from './InvoiceActionHelper';
import InvoiceService from '../../../services/InvoiceService';
import ReloadContext from '../../../components/contexts/ReloadContext';

const InvoiceFinalizeAction = ({ invoice, closeMenu, label }: InvoiceActionType) => {
  const [showFinalize, setShowFinalize] = useState(false);
  const [invoicePdf, setInvoicePdf] = useState<{ name: string; content: any }>();
  const [loading, setLoading] = useState(false);
  const [correctionInvoiceId, setCorrectionInvoiceId] = useState<string>();
  const [showCorrection, setShowCorrection] = useState(false);

  const { t } = useTranslation(['debitors', 'invoices']);
  const alerting = useContext(AlertContext);
  const invoiceReload = useContext(InvoiceListDataContext)?.reload;
  const contextReload = useContext(ReloadContext)?.reload;
  const reload = () => {
    invoiceReload();
    contextReload();
  };

  const previewInvoice = () => {
    InvoiceService.previewCancelInvoice(invoice.id).then((invoicePdfDoc) => {
      setInvoicePdf(invoicePdfDoc !== null ? invoicePdfDoc : undefined);
      setShowFinalize(true);
    });
  };

  const finalizeInvoiceCorrection = () => {
    setLoading(true);
    InvoiceService.finalizeInvoiceCorrection(correctionInvoiceId ? correctionInvoiceId : invoice.id).then((res) => {
      if (res) {
        setLoading(false);
        alerting.sendAlert('success', t('successfully sent'));
        reload();
      } else {
        alerting.sendAlert('error', t('unsuccessfully sent'));
        reload();
      }
      setShowCorrection(false);
      setShowFinalize(false);
      setInvoicePdf(undefined);
    });
  };

  const correctInvoice = (reasons: any) => {
    InvoiceService.createCorrectiveInvoice(invoice.id, reasons)
      .then(({ id }) => {
        setCorrectionInvoiceId(id);
        InvoiceService.previewCancelInvoice(id).then((invoicePdfDoc) => {
          setInvoicePdf(invoicePdfDoc !== null ? invoicePdfDoc : undefined);
        });

        reload();
      })
      .catch(() => {
        alerting.sendAlert('error', t('correction unsuccessfull'));
      });
  };

  return (
    <div key={invoice.id + 'finalize'}>
      <MenuItem
        key={invoice.id + 'finalize'}
        onClick={() => {
          closeMenu();
          previewInvoice();
        }}
      >
        {label}
      </MenuItem>
      {showFinalize && (
        <FinalizeCorrectionInvoiceDialog
          invoice={invoice}
          onCancel={() => {
            setShowFinalize(false);
            reload();
          }}
          onFinalize={finalizeInvoiceCorrection}
          invoicePdf={invoicePdf}
          loading={loading}
          open={true}
        />
      )}
      {showCorrection && (
        <CorrectionInvoiceDialog
          key={invoice.id + 'correct'}
          onCancel={() => {
            setShowCorrection(false);
            setCorrectionInvoiceId(undefined);
            reload();
          }}
          onCorrect={correctInvoice}
          onFinalize={finalizeInvoiceCorrection}
          invoicePdf={invoicePdf}
          loading={loading}
          open={true}
        />
      )}
    </div>
  );
};

export default InvoiceFinalizeAction;
