import { Button, CircularProgress, makeStyles, PropTypes } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React, { MouseEventHandler, ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    /*         position: 'relative',
        width: '100%'
 */
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function LoadingButton({
  loading,
  onClick,
  disabled = false,
  children,
  type = 'submit',
  variant = 'contained',
  color = 'primary',
  ...other
}: {
  loading: boolean;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  variant?: 'text' | 'outlined' | 'contained';
  color?: PropTypes.Color;
  children: ReactNode | string;
}) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button type={type} variant={variant} color="primary" disabled={disabled || loading} onClick={onClick} {...other}>
        {children}
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
    </div>
  );
}
