import { Card, CardActions, CardContent, CardHeader, Collapse, Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import PaymentIcon from '@material-ui/icons/Payment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import StateWithIcon from '../../../components/StateWithIcon';
import { calculateProgress, formatCurrency } from '../../../utils/PaymentUtil';
import LinearProgressWithLabel from '../../../components/ui/blocks/LinearProgressWithLabel';
import { getActionsForPayment } from '../../payments/actions/PaymentActionHelper';
import React, { useState } from 'react';
import { Payment } from '../../../types/Payment/Payment';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useCardStyles from './CardStyles';
import { Metadata } from '../../../types/Metadata';

const PaymentCard = ({ payment, setRef, active = false }: { payment: Payment; setRef: (key: string) => React.RefObject<any>; active: boolean }) => {
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const menuOpen = Boolean(menuAnchor);

  const classes = useCardStyles();
  const { t } = useTranslation(['payments', 'global']);
  const actions = getActionsForPayment(payment, setMenuAnchor, t, true);
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const metadata: Metadata = payment.metadata || [];

  return (
    <Card elevation={active ? 3 : 1} ref={setRef(payment.id)}>
      <CardHeader
        className={classes.alignLeft}
        avatar={
          <Avatar className={classes.avatar}>
            <PaymentIcon />
          </Avatar>
        }
        action={
          actions.length !== 0 && (
            <IconButton aria-label="settings" onClick={(e) => setMenuAnchor(e.currentTarget)}>
              <MoreVertIcon />
            </IconButton>
          )
        }
        title={t(`${payment.paymentMethod}`)}
        subheader={formatDateTime(payment.createdDateTimeUTC)}
      />
      <CardContent>
        <Grid container spacing={2} direction={'column'} alignItems={'flex-start'} className={classes.alignLeft}>
          <Grid item container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Grid item container direction={'column'} alignItems={'flex-start'}>
                <Typography variant="caption" style={{ color: 'gray' }}>
                  {t('amount')}
                </Typography>
                <Typography color="inherit" variant={'subtitle2'}>
                  {formatCurrency(payment.amount)}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <StateWithIcon state={payment.state} label={t(payment.state)} />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="caption" style={{ color: 'gray' }}>
              {t('purpose')}
            </Typography>
            <Typography color="inherit" variant={'subtitle2'}>
              {payment.purpose}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{ height: '52px' }}>
        <LinearProgressWithLabel value={calculateProgress(payment.state)} fullWidth />
        {metadata.length !== 0 && (
          <MenuItem className={classes.expand} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon
              className={clsx(classes.icon, {
                [classes.iconOpen]: expanded,
              })}
            />
          </MenuItem>
        )}
      </CardActions>
      <Collapse in={metadata.length !== 0 && expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2} direction={'row'} alignItems={'flex-start'} className={classes.alignLeft}>
            <Grid item md={12}>
              <Typography color="inherit" variant={'subtitle2'}>
                {t('global:metadata')}
              </Typography>
            </Grid>
            {metadata.map((m) => {
              return (
                <Grid item md={6}>
                  <Typography variant="caption" style={{ color: 'gray' }}>
                    {m.key}
                  </Typography>
                  <Typography color="inherit" variant={'subtitle2'}>
                    {m.value}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Collapse>
      <Menu
        id="long-menu"
        anchorEl={menuAnchor}
        keepMounted
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        PaperProps={{
          style: {
            //maxHeight: ITEM_HEIGHT * 4.5,
            width: 'min-content',
          },
        }}
      >
        {actions}
      </Menu>
    </Card>
  );
};

export default PaymentCard;
