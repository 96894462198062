import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@payactive/app-common';
import { Form, Formik, FormikHelpers } from 'formik';
import { Grid, Paper } from '@material-ui/core';
import PaymentSettings from './PaymentSettings';
import DocumentSettings from './DocumentSettings';
import DunningSettings from './DunningSettings';
import { createSchemas, initialValues } from './settingsSchema';
import { AlertContext } from '../../../../components/contexts/WithAlertContext';
import CompanyAccountService from '../../../../services/CompanyAccountService';
import { CompanySettings } from '../../../../types/Company/CompanySettings';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '48.5%',
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    display: 'flex',
    color: 'green[500]',
    position: 'relative',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paperLayout: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  paperDesign: {
    padding: theme.spacing(2),
  },
}));

export default function Settings({
  accountSettings,
  dunningService,
}: {
  accountSettings: CompanySettings.Account;
  dunningService: CompanySettings.Dunning;
}): ReactElement {
  const alerting = useContext(AlertContext);

  const { t } = useTranslation('company');
  const { t: tValidation } = useTranslation('validations');
  const classes = useStyles();
  const schema = createSchemas(tValidation, t);

  const _handleSubmit = (
    values: CompanySettings.Account & CompanySettings.Dunning,
    { setSubmitting, resetForm }: FormikHelpers<CompanySettings.Account & CompanySettings.Dunning>,
  ) => {
    const accountSettingsValues: CompanySettings.Account = {
      bccEmailAddressList: values.bccEmailAddressList,
      bccSelection: values.bccSelection,
      paymentReminderDays: values.paymentReminderDays,
      paymentReminderEnabled: values.paymentReminderEnabled,
    };
    const dunningSettingsValues: CompanySettings.Dunning = {
      dunningEnabled: values.dunningEnabled,
      daysDunning: values.daysDunning,
      daysReminder: values.daysReminder,
      dunningFee: values.dunningFee,
      maxDunnings: values.maxDunnings,
    };

    const updateCompanyAccountSettingsPromise = CompanyAccountService.updateCompanyAccountSettings(accountSettingsValues);
    const updateDunningSettingsPromise = CompanyAccountService.updateDunningSettings(dunningSettingsValues);

    Promise.all([updateCompanyAccountSettingsPromise, updateDunningSettingsPromise])
      .then(([updateCompanyAccountSettingsResponse, updateDunningSettingsResponse]) => {
        if (updateCompanyAccountSettingsResponse && updateDunningSettingsResponse) {
          setSubmitting(false);
          alerting.sendAlert('success', t('settings updated'));
        } else {
          alerting.sendAlert('error', t('settings update error'));
          window.location.reload();
        }
        resetForm({ values: values });
      })
      .catch(() => {
        alerting.sendAlert('error', t('settings update error'));
        window.location.reload();
      });
  };

  return (
    <>
      <Formik initialValues={initialValues(accountSettings, dunningService)} onSubmit={_handleSubmit} validationSchema={schema}>
        {({ isSubmitting, dirty }) => (
          <>
            <Form autoComplete="on">
              <Grid container spacing={2} alignItems={'stretch'} alignContent={'stretch'}>
                <Grid item xs={6} alignContent={'stretch'}>
                  <Paper className={classes.paperDesign}>
                    <PaymentSettings />
                  </Paper>
                </Grid>

                <Grid item xs={6} alignItems={'stretch'}>
                  <Paper className={classes.paperDesign}>
                    <DocumentSettings />
                  </Paper>
                </Grid>

                <Grid item xs={6} alignItems={'stretch'}>
                  <Paper className={classes.paperDesign}>
                    <DunningSettings />
                  </Paper>
                </Grid>
              </Grid>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flexStart',
                  paddingBottom: '1%',
                }}
              >
                <Button type="submit" loading={isSubmitting} disabled={!dirty || isSubmitting} name="submit" label={t('global:save')} />
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
