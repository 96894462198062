const LegalForms: { [key: string]: string } = {
  GmbH: 'GmbH',
  GmbHCoKG: 'GmbH & Co KG',
  AG: 'AG',
  GbR: 'GbR',
  OHG: 'OHG',
  KG: 'KG',
  UG: 'UG',
  EU: 'Einzelunternehmen',
  FB: 'Freiberufler:in',
  EG: 'Genossenschaft (e.G.)',
  EV: 'eingetragener Verein (e.V.)',
};

type FormsArray = Array<{
  label: string;
  value: string;
}>;

const LegalFormsAsArray: FormsArray = [];
Object.keys(LegalForms).forEach((key) => {
  LegalFormsAsArray.push({
    label: LegalForms[key],
    value: key,
  });
});

const requiresHrNumber = (legalForm: any) => {
  switch (legalForm) {
    case LegalForms.GmbH:
    case LegalForms.UG:
      return true;
    default:
      return false;
  }
};

export { LegalForms, LegalFormsAsArray, requiresHrNumber };
