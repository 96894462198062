import { MenuItem } from '@material-ui/core';
import { DebtorActionType } from './DebtorActionHelper';
import { useContext, useState } from 'react';
import { AlertContext } from '../../../components/contexts/WithAlertContext';
import { useTranslation } from 'react-i18next';
import ResendInvitationDialog from '../dialogs/ResendInvitationDialog';
import DebtorService from '../../../services/DebtorService';

const DebtorResendInvitationAction = ({ debtor, setMenuAnchor, label }: DebtorActionType) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const alerting = useContext(AlertContext);

  const { t } = useTranslation('debitors');

  const resendInvitation = () => {
    setLoading(true);
    DebtorService.resendOnboardInvitation(debtor.id)
      .then((paymentMethodSetupLink) => {
        if (paymentMethodSetupLink) {
          setLoading(false);
          alerting.sendAlert('success', t('invitation sent'));
          setOpenDialog(false);
        } else {
          alerting.sendAlert('error', t('error'));
        }
      })
      .catch(() => {
        alerting.sendAlert('error', t('error'));
        //TODO handle catch
      });
  };

  return (
    <div key={debtor.id + 'newPaymentC'}>
      <MenuItem
        key={debtor.id + 'newPayment'}
        onClick={() => {
          setMenuAnchor(null);
          setOpenDialog(true);
        }}
      >
        {label}
      </MenuItem>
      {openDialog && (
        <ResendInvitationDialog debtor={debtor} onFinalize={resendInvitation} open={true} loading={loading} onCancel={() => setOpenDialog(false)} />
      )}
    </div>
  );
};

export default DebtorResendInvitationAction;
