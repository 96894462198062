import ApiClient from './ApiClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import { EbicsConnection, EbicsConnections, InitializeEbicsConnection, RequestEbicsFromBank } from '../types/Ebics/EbicsConnection';
import { EbicsBic } from '../types/Ebics/EbicsBic';

export default class EbicsService extends ApiClient {
  private static get ebicsClient(): AxiosInstance {
    return this.client;
  }

  public static async getEbicsConnection(ebicsConnectionId: string): Promise<EbicsConnection> {
    const res = await this.ebicsClient.request<EbicsConnection>({
      method: 'GET',
      url: this.apiDomain + '/sepa/ebics-connections/' + ebicsConnectionId,
    });
    return res.data;
  }
  /* no valid endpoint in openapi spec
    public static async patchEbicsConnection(ebicsConnectionId:string, data) {
        const res = await this.ebicsClient.request({
            method: 'PATCH',
            url: this.apiDomain + '/sepa/ebics-connections/' + ebicsConnectionId:string,
            data,
        });
        return res.data;
    }
*/

  public static async changeEbicsStatus(ebicsConnectionId: string, status: EbicsConnections.Status): Promise<EbicsConnection> {
    const res = await this.ebicsClient.request<{ status: EbicsConnections.Status }, AxiosResponse<EbicsConnection>>({
      method: 'PATCH',
      url: this.apiDomain + '/sepa/ebics-connections/' + ebicsConnectionId + '/status',
      data: {
        status: status,
      },
    });
    return res.data;
  }

  public static async sendCreditorIdentifier(ebicsConnectionId: string, creditorIdentifier: string): Promise<EbicsConnection> {
    const res = await this.ebicsClient.request<{ creditorIdentifier: string }, AxiosResponse<EbicsConnection>>({
      method: 'PATCH',
      url: this.apiDomain + '/sepa/ebics-connections/' + ebicsConnectionId + '/actions/send_creditor_identifier',
      data: {
        creditorIdentifier: creditorIdentifier,
      },
    });
    return res.data;
  }

  public static async requestSentToBank(ebicsConnectionId: string, data: RequestEbicsFromBank): Promise<EbicsConnection> {
    const res = await this.ebicsClient.request<RequestEbicsFromBank, AxiosResponse<EbicsConnection>>({
      method: 'PATCH',
      url: this.apiDomain + '/sepa/ebics-connections/' + ebicsConnectionId + '/actions/request_ebics_from_bank',
      data: data,
    });
    return res.data;
  }

  public static async initializeEbics(ebicsConnectionId: string, data: RequestEbicsFromBank): Promise<EbicsConnection> {
    return this.requestSentToBank(ebicsConnectionId, data);
  }

  public static async initEbicsConnection(ebicsConnectionId: string, data: InitializeEbicsConnection): Promise<EbicsConnection> {
    return (
      await this.ebicsClient.request<InitializeEbicsConnection, AxiosResponse<EbicsConnection>>({
        method: 'PATCH',
        url: this.apiDomain + '/sepa/ebics-connections/' + ebicsConnectionId + '/actions/initialize_ebics',
        data: data,
      })
    ).data;
  }

  public static async downloadEbicsBankRequest(ebicsConnectionId: string, data: RequestEbicsFromBank): Promise<boolean> {
    return await this.ebicsClient
      .request<RequestEbicsFromBank, AxiosResponse<string>>({
        method: 'PATCH',
        url: this.apiDomain + '/sepa/ebics-connections/' + ebicsConnectionId + '/ebics-bank-request',
        data,
      })
      .then((res) => {
        let fileName = `Ebics-Antrag.pdf`;
        super.downloadPDF(fileName, res.data);
        return true;
      });
  }

  public static async downloadIniLetter(ebicsConnectionId: string): Promise<boolean> {
    return await this.ebicsClient
      .request<string>({
        method: 'GET',
        url: this.apiDomain + '/sepa/ebics-connections/' + ebicsConnectionId + '/ini_letter',
      })
      .then((res) => {
        let fileName = `Ini-Letter.pdf`;
        super.downloadPDF(fileName, res.data);
        return true;
      });
  }

  public static async sentIniLetter(ebicsConnectionId: string): Promise<EbicsConnection> {
    const res = await this.ebicsClient.request<EbicsConnection>({
      method: 'PATCH',
      url: this.apiDomain + '/sepa/ebics-connections/' + ebicsConnectionId + '/actions/sent_ini_letter',
    });
    return res.data;
  }

  public static async getEbicsBics(): Promise<Record<string, EbicsBic>> {
    let res = await this.ebicsClient.request<Record<string, EbicsBic>>({
      method: 'GET',
      url: this.apiDomain + '/sepa/ebics-bics',
    });
    return res.data;
  }
}
