import { Credit } from '../../../types/Credit/Credit';
import { CreditEvent } from '../../../types/Credit/CreditEvent';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Paper, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { EventStep } from '../../../types/EventStep';
import { makeStyles } from '@material-ui/core/styles';
import { eventsToSteps } from './CreditEventUtil';

const useStyles = makeStyles((theme) => ({
  other: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    marginTop: theme.spacing(1),
  },
}));

export default function CreditEventsDialog({
  credit,
  events,
  open,
  onCancel,
}: {
  credit: Credit;
  events: CreditEvent[];
  open: boolean;
  onCancel: () => void;
}) {
  const { t } = useTranslation('credits');

  const [steps, setSteps] = useState<EventStep[]>([]);

  const classes = useStyles();

  useEffect(() => {
    setSteps(eventsToSteps(credit, events, t));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]); // hook is only triggered when value changes

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onCancel();
      }}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{t('credit state')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description"></DialogContentText>
        <Stepper orientation="vertical" activeStep={steps.length - 1}>
          {steps.map((step, index) => (
            <Step key={step.label + index} active={true}>
              {step.final && !step.error && <StepLabel icon={<CheckCircleIcon style={{ color: 'green' }} />}>{step.label}</StepLabel>}
              {step.final && step.error && <StepLabel icon={<ErrorIcon style={{ color: 'red' }} />}>{step.label}</StepLabel>}
              {!step.final && <StepLabel>{step.label}</StepLabel>}

              <StepContent>
                {step.content}
                <br />
                {step.user}
                {step.reason && <p>{step.reason}</p>}
                {step.reasonMessage && (
                  <Paper elevation={0} className={classes.other}>
                    {step.reasonMessage}
                  </Paper>
                )}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {t('global:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
