import React from 'react';
import { MenuItem } from '@material-ui/core';
import { PaymentActionType } from './PaymentActionHelper';
import Link from '@material-ui/core/Link';
import DonationService from '../../../services/DonationService';

const PaymentDownloadDonationAction = ({ payment, setMenuAnchor, label }: PaymentActionType) => {
  const downloadDonationReceipt = () => {
    DonationService.downloadDonation(payment.id);
  };

  return (
    <div key={payment.id + 'downloadDonation'}>
      <MenuItem
        key={payment.id + 'downloadDonation'}
        onClick={() => {
          setMenuAnchor(null);
        }}
      >
        <Link onClick={downloadDonationReceipt} underline="none" color="inherit">
          {label}
        </Link>
      </MenuItem>
    </div>
  );
};

export default PaymentDownloadDonationAction;
