import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: '0.7rem',
    wordWrap: 'break-word',
  },
  container: {
    width: theme.spacing(20),
  },
}));

interface Price {
  value: number;
  unit: string;
}

interface Props {
  tagLine: string;
  price: Price;
  smallPrint: string;
}

export default function PriceBox({ tagLine, price, smallPrint }: Props): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography align="left" variant="subtitle2" color="secondary">
        {tagLine}
      </Typography>
      <Typography align="left" variant="h4" color="secondary">
        <b>{price?.value}</b> {price?.unit}
      </Typography>
      <Typography align="left" className={classes.subtitle} color="secondary">
        {smallPrint}
      </Typography>
    </div>
  );
}
