import { Grid, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { FormikInputField } from '@payactive/app-common';
import React from 'react';

type Props = {
  label: string;
  toolTipText: string;
  inputFieldName: string;
  inputFieldLabel: string;
};

export default function GridSetting({ label, toolTipText, inputFieldName, inputFieldLabel }: Props) {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={7} alignItems={'flex-start'}>
        <Typography variant={'body1'} align={'left'}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Tooltip
          title={
            <React.Fragment>
              <Typography variant="caption" color="inherit">
                {toolTipText}
              </Typography>
            </React.Fragment>
          }
          placement="top"
        >
          <InfoIcon style={{ color: '#D3D3D3', marginBottom: '-6px', marginLeft: '10px' }} />
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <FormikInputField
          name={inputFieldName}
          label={inputFieldLabel}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}
