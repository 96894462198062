import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { Button } from '@payactive/app-common';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: 'blur(30px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  button: {
    backgroundColor: theme.palette.error.main,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export default function DeletePaymentLinkDialog({ open, onClose, onDelete }: Props): ReactElement {
  const { t } = useTranslation('payments');
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return false;
        }
        onClose();
      }}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">{t('delete paylink')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">{t('delete paylink text')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" name="cancel" label={t('global:cancel')} onClick={onClose} />
        <Button className={classes.button} name="delete" variant="contained" label={t('delete paylink')} onClick={onDelete} />
      </DialogActions>
    </Dialog>
  );
}
