import React from 'react';
import { makeStyles } from '@material-ui/core';
import logo from './logo.png';

const useStyles = makeStyles({
  root: {
    padding: '14px',
  },
  subtext: {
    color: '#14a6c8',
    fontSize: '7pt',
  },
});

export default function PayactiveLogoWithText({ pixels = 50 }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        alt="logo"
        style={{
          maxWidth: pixels + 'px',
        }}
        src={logo}
      />
    </div>
  );
}
