import React from 'react';
import { useTranslation } from 'react-i18next';
import MasterData from './masterdata/MasterData';
import PageHeader from '../../../components/ui/blocks/PageHeader';

export default function MasterDataScreen() {
  const { t } = useTranslation('company');

  return (
    <>
      <PageHeader title={t('masterdata')} subtitle={t('settings')} />
      <MasterData />
    </>
  );
}
