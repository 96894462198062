import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

type TextFilterProps = {
  id?: string;
  key?: string;
  value: string | null;
  setValue: (value: string) => void;
  disabled?: boolean;
};

const TextFilter = ({ value, setValue, id, disabled = false }: TextFilterProps) => {
  const { t } = useTranslation(['global']);
  const textId = id || 'text-search';
  return (
    <FormControl variant="standard">
      <InputLabel htmlFor="text-search">{t('search')}</InputLabel>
      <Input
        disabled={disabled}
        id={textId}
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        endAdornment={
          !!value && (
            <InputAdornment position="end">
              <IconButton aria-label="clear" onClick={() => setValue('')} size="small">
                <Close fontSize="small" />
              </IconButton>
            </InputAdornment>
          )
        }
        onChange={(event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value)}
      />
    </FormControl>
  );
};

export default TextFilter;
