import React from 'react';
import { Form } from 'formik';
import { Box, Divider, Grid, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { FormikInputField, FormikSizedInputField } from '@payactive/app-common';
import { useTranslation } from 'react-i18next';
import MetadataFields from './MetadataFields';

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    textAlign: 'left',
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  totalAmount: {
    width: '15%',
  },
  interval: {
    width: '50%',
  },
  startDate: {
    width: '80%',
  },
  select: {
    textAlign: 'left',
  },
  input: {
    padding: theme.spacing(1),
  },
  checkbox: {
    color: '#086992',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
}));

type CustomisedInstalmentPlanFormProps = {
  values: any;
};
const CustomisedInstalmentPlanForm = ({ values }: CustomisedInstalmentPlanFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation('instalmentPlans');

  return (
    <Form>
      <Typography variant="h6">{t('payment details')} </Typography>
      <FormikInputField
        className={`${classes.input}`}
        icon="euro_symbol"
        value={values.totalNetAmount}
        type="number"
        name="totalNetAmount"
        label={t('total amount')}
        fullWidth
      />
      <FormikSizedInputField
        className={`${classes.input}`}
        icon="short_text"
        max={120}
        name="purpose"
        label={t('purpose')}
        fullWidth
        value={values.purpose}
      />
      <Typography variant="body1" color={'primary'}>
        {t('rate settings')}
      </Typography>
      <Grid container justifyContent={'flex-start'} alignItems={'center'}>
        <Grid item xs={6}>
          <FormikInputField
            type="number"
            name="intervalAmount"
            required
            label={t('rate')}
            placeholder="0,00"
            InputProps={{
              endAdornment: <>{<InputAdornment position="end">€</InputAdornment>}</>,
            }}
          />
        </Grid>
      </Grid>
      <Grid></Grid>
      {values.requireDownPayment && (
        <Grid>
          <FormikInputField
            className={`${classes.input}`}
            icon="euro_symbol"
            value={values.downPayment}
            type="number"
            name="downPayment"
            label={t('down payment')}
          />
        </Grid>
      )}
      <Grid xs={12} item>
        <Box marginTop={1}>
          <Divider />
          <Box marginTop={1}>
            <MetadataFields />
          </Box>
        </Box>
      </Grid>
    </Form>
  );
};

export default CustomisedInstalmentPlanForm;
