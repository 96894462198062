import axios from 'axios';
import ApiClient from '../../../services/ApiClient';

interface RedirectResponse {
  success: boolean;
  location?: string;
}

interface SignedDocumentResponse {
  success: boolean;
  signedDoc?: Blob;
}

const signDocument = async (doc: Blob, name: string): Promise<RedirectResponse> => {
  const form = new FormData();
  form.append('file', doc, name);
  form.append('title', 'test.pdf');
  const res = await axios({
    method: 'POST',
    url: `${ApiClient.apiDomain}/yes/start`,
    data: form,
    withCredentials: true,
  });

  if (res.status === 201) {
    return {
      success: true,
      location: res.headers['location'],
    };
  } else {
    return {
      success: false,
    };
  }
};

const accb = async (query: URLSearchParams): Promise<RedirectResponse> => {
  const res = await axios({
    method: 'GET',
    params: query,
    url: `${ApiClient.apiDomain}/yes/accb`,
    withCredentials: true,
  });

  if (res.status === 200) {
    return {
      success: true,
      location: res.headers['location'],
    };
  } else {
    return {
      success: false,
    };
  }
};

const oidccb = async (query: URLSearchParams): Promise<SignedDocumentResponse> => {
  const res = await axios({
    method: 'GET',
    params: query,
    url: `${ApiClient.apiDomain}/yes/oidccb`,
    responseType: 'blob',
    withCredentials: true,
  });

  if (res.status === 200) {
    return {
      success: true,
      signedDoc: new Blob([res.data], { type: 'application/pdf' }),
    };
  } else {
    return {
      success: false,
    };
  }
};

export { signDocument, accb, oidccb };
