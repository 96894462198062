export type InstalmentRate = {
  amount: number;
  date: string;
  status: InstalmentRates.Status;
  paymentId?: string;
};

export namespace InstalmentRates {
  export enum Status {
    SCHEDULED = 'SCHEDULED',
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    ERROR = 'ERROR',
  }
}
