import { CheckoutLink } from '../../../types/CheckoutLink/CheckoutLink';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useRowStyles } from '../../../utils/TableRowHelper';
import { Avatar, Icon, IconButton, makeStyles, Menu, TableCell, TableRow } from '@material-ui/core';

import { formatCurrency } from '../../../utils/PaymentUtil';
import { formatDateTime } from '../../../utils/DateTimeUtil';
import { getActionsForCheckoutLink } from '../action/CheckoutLinkActionHelper';
import LinkBox from '../../../components/LinkBox';

const useStyles = makeStyles((theme) => ({
  urlContainer: {
    width: '90%',
  },
}));

const CheckoutLinkRow = ({ checkoutLink, index }: { checkoutLink: CheckoutLink; index: number }) => {
  const { t } = useTranslation('payments');

  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const menuOpen = Boolean(menuAnchor);
  const classes = Object.assign(useRowStyles(), useStyles());

  const actions = getActionsForCheckoutLink(checkoutLink, setMenuAnchor, t);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };

  return (
    <TableRow key={index} hover className={index % 2 === 0 ? classes.even : classes.odd}>
      <TableCell key={`${checkoutLink.id}url`}>
        <LinkBox url={checkoutLink.payLinkUrl} className={classes.urlContainer} enableCopy={true} enablePreview={true}></LinkBox>
      </TableCell>
      <TableCell key={`${checkoutLink.id}product`}>{checkoutLink.productName}</TableCell>
      <TableCell key={`${checkoutLink.id}amount`}>{formatCurrency(checkoutLink.price)}</TableCell>
      <TableCell key={`${checkoutLink.id}createdDateTime`}>{formatDateTime(checkoutLink.createdDateTimeUTC)}</TableCell>
      <TableCell key={`${checkoutLink.id}actions`}>
        {actions && actions.length !== 0 && (
          <>
            <IconButton onClick={handleClick}>
              <Avatar className={classes.avatar}>
                <Icon>more_horiz</Icon>
              </Avatar>
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={menuOpen}
              onClose={() => setMenuAnchor(null)}
              PaperProps={{
                className: classes.menu,
                style: {
                  //maxHeight: ITEM_HEIGHT * 4.5,
                  width: 'min-content',
                },
              }}
            >
              {actions}
            </Menu>
          </>
        )}
      </TableCell>
    </TableRow>
  );
};
export default CheckoutLinkRow;
