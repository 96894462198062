import PaginationProvider from '../../../components/table/PaginationContext';
import { CreditNotesListDataContext } from '../../creditNotes/CreditNotesListDataContextProvider';
import React from 'react';
import CreditNotesTable from '../../creditNotes/table/CreditNotesTable';

export default function CreditNotesTab() {
  return (
    <CreditNotesListDataContext.Consumer>
      {({ currentPage, pageSize, page }) => (
        <>
          <PaginationProvider
            pageSizeOptions={[5, 10, 30]}
            currentPage={currentPage.get}
            totalPages={page?.totalPages || 0}
            pageSize={pageSize.get}
            setCurrentPage={currentPage.set}
            changePageSize={pageSize.set}
          >
            <CreditNotesTable />
          </PaginationProvider>
        </>
      )}
    </CreditNotesListDataContext.Consumer>
  );
}
